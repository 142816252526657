import React from "react";
import { Link, Redirect } from "react-router-dom";
import api from "../../../../../api";
import "../../../../Grid/grid.css";
import { Segment, Table, Pagination, Popup, Icon, Tab } from "semantic-ui-react";
import Switch from "react-switch";
import { DatatableTable } from "./DatatableTable";
import ChatFilter from "./ChatFilter";
import { DatatableRow } from "./DatatableRow";
import { DatatablePageSizeSelect } from "./DatatablePageSizeSelect";
import Modal from "./Modal";
import CustomErrorField from "../../../../Form/CustomErrorField";
import DatePicker from "react-datepicker";
import jstz from "jstz";
import { Multiselect } from "multiselect-react-dropdown";

const DAY_LIST = [
  { name: "Sunday", id: "0" },
  { name: "Monday", id: "1" },
  { name: "Tuesday", id: "2" },
  { name: "Wednesday", id: "3" },
  { name: "Thursday", id: "4" },
  { name: "Friday", id: "5" },
  { name: "Saturday", id: "6" },
];

const tableHeader = [
  {
    title: "Actions",
    width: "1",
    sort: "",
  },
  {
    title: "Title",
    width: "3",
    sort: "title",
  },
  {
    title: "Campaign Type",
    width: "1",
    sort: "",
  },
  {
    title: "Schedule Date",
    width: "1",
    sort: "start_date",
  },
  {
    title: "Next Welcome Schedule Date",
    width: "1",
    sort: "next_start_date",
  },
  {
    title: "Next Schedule Email Date",
    width: "1",
    sort: "next_scheduled_date",
  },
  {
    title: "Time Zone",
    width: "1",
    sort: "selected_time_zone",
  },
  {
    title: "Bucket(s)",
    width: "1",
    sort: "",
  },
  {
    title: "Contact(s)",
    width: "1",
    sort: "",
  },
  {
    title: "Status",
    width: "1",
    sort: "status",
  },
  {
    title: "Created On",
    width: "1",
    sort: "created_date",
  },

  {
    title: "Other Action",
    width: "1",
    sort: "",
  },
];
const queryParams = [
  "_limit",
  "_order",
  "_sort",
  "q",
  "_page",
  "title",
  "added_datetime",
];

export default class DatatableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: "id",
      _page: 1,
      _order: "desc",
      _limit: 10,
      q: "",
      title: "",
      added_datetime: "",
      startdaterange: "",
      enddaterange: "",
      totalCount: 0,
      checked:false,
      loading: false,
      modalSize:false,
      modalstatus: false,
      modalbody: "",
      modalfooter: "",
      datatableHeader: null,
      datatableRows: null,
      disabled:false,
      auto_id: "",
      csvdata: [],
      rename: "",
      modal_id: "",
      errors: [],
      status: "all",
      setDate: new Date(),
      startTimeForDuration: new Date(),
      current__run_if_new_user_added: 0,
      current__new_user_schedule_interval: "",
      current__new_user_schedule_interval_error: "none",
      timeZone: [],
      errorsT: { timezoneerror: false, errors: [] },
      currenttime: "",
      activePid: null,
      currentSelectedTimezone: "",
      minDate: new Date(),
      edit_campaign: null,
      sendImmediately: false,
      selectedValue: [],
      actionStart: "",
      scheduledQueue:null,
  	  resume_campaign_radio:{resume_campaign_radio : 'no',selectedDate : new Date(),selectedTimezone:'',selectedTimzoneValue:''},
      disabledContent:
        'Please select the email configured from "Select email" to start the campaign.',
    };
    this.csvLink = React.createRef();
    this.setStartDate = this.setStartDate.bind(this);
    this.multiselectRef = React.createRef();
  }

  componentDidMount() {
    this.loadData({});
    this.setLocalTime();
  }

  setLocalTime = (modalType = null) => {
    var timezoneObj = jstz.determine();
    api.marketing
      .getTimezoneData({ timezonename: timezoneObj.name() })
      .then((data) => {
        const timezone = data.data.timezone;
        try {
          this.setState(
            {
              timeZone: data.data.timezone,
              currenttime: data.data.currenttime,
              currentSelectedTimezone: timezoneObj.name(),
            },
            () => {
              this.setState({
                modalbody: modalType ? this.getCampaignBody() : this.getBody(),
              });
            }
          );
          // this.setState({
          //   timeZone: data.data.timezone,
          //   currenttime: data.data.currenttime,
          //   currentSelectedTimezone: timezoneObj.name()
          // });
        } catch (err) {
          console.log(err);
        }
      });
  };
  canStartPlaybook=(id)=>{
    
    if(typeof id=='undefined' || id==''){
      return false;
    }
    let datalist = this.state.lists;
    let playbook = datalist.filter((list) => list.id === id);
    playbook=playbook[0];
    
     let iseditable=false;
      
          let buckettypes=JSON.parse(playbook.bucket_type).map((item)=>{
              return item.name;

          });
        
          if(buckettypes.includes("Email") && playbook.domain_id){
           
            iseditable=true;
          }
          if(buckettypes.includes("Postcard")){
           
            iseditable=true;
          }
          if(buckettypes.includes("SMS") && playbook.phone_id){
            iseditable=true;
          }
          if(buckettypes.includes("SMS") && buckettypes.includes("Email")){
            if( playbook.phone_id && playbook.domain_id){
              iseditable=true;
             
            }else{
              iseditable=false;
             
            }
           
          }
          if(buckettypes.includes("SMS") && buckettypes.includes("Postcard")){
            if( playbook.phone_id){
              iseditable=true;
             
            }else{
              iseditable=false;
             
            }
           
          }
          if(buckettypes.includes("Postcard") && buckettypes.includes("Email")){
            if(playbook.domain_id){
              iseditable=true;
             
            }else{
              iseditable=false;
             
            }
           
          }
          if(buckettypes.includes("Postcard" && !buckettypes.includes("SMS") && !buckettypes.includes("Email"))){
            iseditable=true;
            
          }
          
          return iseditable;
  }

  static directionConverter(order) {
    if (order === "asc") {
      return "ascending";
    } else if (order === "desc") {
      return "descending";
    } else {
      return null;
    }
  }

  handleSort = (clickedColumn) => {
    const { _sort, _order } = this.state;

    let newOrder = _order === "asc" ? "desc" : "asc";
    if (_sort !== clickedColumn) {
      newOrder = "asc";
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = (filter, val) => {
    this.loadData({ q: filter, _page: 1 });

    // this.loadData({ [filter]: val, _page: 1 });
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  loadData = (params) => {
    const newState = Object.assign({}, this.state, params, { loading: false });
    console.log(newState);
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });

    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;

    params["time_zone"] = time_zone;
    params["status"] = this.state.status;
    params["rand_no"]= Math.random();

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    console.log(query);

    let totalCountQuery = "";
    if (params.q !== "") {
      totalCountQuery = `q=${params.q}`;
    }


    //sagar start
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.marketing.listPlayBook(query).then((data) => {
        console.log(data);
        try {
          let datatableRowstemp = null;
          console.log(data.data);
          this.setState({ lists: data.data.list }); //sagar
          console.log(data.data.list);

          const totalrecord = data.data.list.length; // sagar
          console.log(totalrecord);
          if (totalrecord > 0) {
            datatableRowstemp = this.state.lists.map((list, index) => (
              <DatatableRow
                channgeEvent={this.checkboxChangeEvent}
                openContactList={this.openContactList}
                key={index}
                list={list}
                bucketAction={this.bucketAction}
                createPlaybook={this.props.createPlaybook}
              />
            ));
          } else {
            datatableRowstemp = (
              <Table.Row>
                <Table.Cell colSpan={6} textAlign="center">
                  No record found.
                </Table.Cell>
              </Table.Row>
            );
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.data.totalCount, // sagar
            lists: data.data.list, //sagar
          });
        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });

        this.setState(newState);
      });
    });

    //sagar end
  };

  handleSwitch = ( bodyType = null) =>{
    // alert(ev.target.checked)
    this.setState({
      checked: !this.state.checked}, 
      () => this.setState({
      modalbody: bodyType ? this.getCampaignBody() : this.getBody(),
    }));
    console.log(this.state.checked);
  } 

  checkAll = (e) => {
    let datalist = this.state.lists;
    datalist.map(function (list, index) {
      list["checked"] = e.target.checked;
    });
    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          openContactList={this.openContactList}
          key={index}
          list={list}
          bucketAction={this.bucketAction}
          createPlaybook={this.props.createPlaybook}
        />
      )),
      lists: datalist,
    });
  };

  checkboxChangeEvent = (auto_id) => {
    let datalist = this.state.lists;
    let recordlist = datalist.findIndex((list) => list.auto_id === auto_id);

    if (datalist[recordlist]["checked"]) {
      datalist[recordlist]["checked"] = false;
    } else {
      datalist[recordlist]["checked"] = true;
    }

    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          openContactList={this.openContactList}
          key={index}
          list={list}
          createPlaybook={this.props.createPlaybook}
        />
      )),
      lists: datalist,
    });
  };
  convertTZ = (date, tzString) => {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
  }

	bucketAction = (tab, id) => {
    console.log( tab,id,'idd');
		if (tab == "edit") {
      let list_data = this.state.lists;
      let recordid = list_data.findIndex((list_data) => list_data.bid == id);
      this.setState(
        {
        errors: [],
        rename: list_data[recordid]["title"],
        modaltitle: "Rename Bucket",
        modalstatus: true,
        modal_id: id,
        modalfooter: this.modalFooter("", "edit"),
        },
        function () {
        let modalbody = this.modalBody("edit");

        this.setState({ modalbody: modalbody });
        }
      );
		}

		if (tab == "delete") {
      this.setState({
        errors: [],
        modalstatus: true,
        modal_id: id,
        modaltitle: "Confirm your action",
        modalfooter: this.modalFooter("", "delete"),
        modalbody: "Are you sure you want to delete it?",
      });
		}

		if (tab == "selectdate") {
      let date = new Date();
      date = new Date();
      let startTimeForDuration = new Date();
      if (id.start_date == null || id.start_date == "0000-00-00 00:00:00") {
      } else {
        date = new Date(id.start_date);
        startTimeForDuration = new Date(id.next_start_date);
      }

      var timezoneObj = jstz.determine();
      api.marketing
        .getTimezoneData({ timezonename: timezoneObj.name() })
        .then((data) => {
        const timezone = data.data.timezone;
        try {
          this.setState(
          {
            timeZone: data.data.timezone,
            currenttime: data.data.currenttime,
            currentSelectedTimezone: timezoneObj.name(),
            errors: [],
            modalstatus: true,
            modal_id: id.id,
            setDate: date,
            startTimeForDuration: startTimeForDuration,
            modaltitle: "Schedule",
            checked:id.sendEmailImmediatelyToNewUsers,
            modalfooter: this.modalFooter(
            id.id,
            "selectdate",
            id.domain_id == null ? 0 : id.domain_id
            ),
            actionStart: "Start",
          },
          () => {
            this.setState({ modalbody: this.getBody() });
          }
          );
        } catch (err) {
          console.log(err);
        }
      });
    }

		if (tab == "start") {
			this.setState(
				{
				errors: [],
				modalstatus: true,
				modal_id: id,
				modaltitle: "Confirm your action",
				modalfooter: this.modalFooter("", "start"),
				},
				() => {
				this.setState({
					modalbody: "Are you sure you want to start this playbook?",
				});
				}
			);
		}

		if (tab == "resume") {
			var timezoneObj = jstz.determine();
			let date = new Date();
			date = new Date();
			api.marketing
				.getTimezoneData({ timezonename: timezoneObj.name() })
				.then((data) => {
				try {
					this.setState(
					{
						timeZone: data.data.timezone,
						currenttime: data.data.currenttime,
						currentSelectedTimezone: timezoneObj.name(),
						errors: [],
						modalstatus: true,
						modal_id: id,
						setDate: date,
						modaltitle: "Resume Campaign",
						modalfooter: this.modalFooter(
						id.id,
						"resume",
						id.domain_id
						),
						edit_campaign: id,
						actionStart: "resume",
            resume_campaign_radio: {...this.state.resume_campaign_radio, resume_campaign_radio: 'no'},
            modalSize: false 
					},
					() => {
						this.setState({ modalbody: this.getCampaignBodyResume() });
					}
					);
				} catch (err) {
					console.log(err);
				}
			});
      this.setState({activePid: id});
      this.getScheduleQueue(id);
		}

		if (tab == "stop") {
      this.setState(
        {
        errors: [],
        modalstatus: true,
        modal_id: id,
        modaltitle: "Confirm your action",
        modalfooter: this.modalFooter("", "stop"),
        },
        () => {
          this.setState({
            modalbody: "Are you sure you want to stop this playbook?",
          });
        }
      );
		}

		if (tab == "archive") {
		this.setState({
			errors: [],
			modalstatus: true,
			modal_id: id,
			modaltitle: "Confirm your action",
			modalfooter: this.modalFooter("", "archive"),
			modalbody: "Are you sure you want to archive this playbook?",
		});
		}

		if (tab == "duplicate") {
		this.setState({
			errors: [],
			modalstatus: true,
			modal_id: id,
			modaltitle: "Confirm your action",
			modalfooter: this.modalFooter("", "duplicate"),
			modalbody: "Are you sure you want to duplicate this playbook?",
		});
		}

		if (tab == "restore") {
		this.setState({
			errors: [],
			modalstatus: true,
			modal_id: id,
			modaltitle: "Restore playbook",
			modalfooter: this.modalFooter("", "restore"),
			modalbody: "Are you sure you want to restore this playbook to Draft?",
		});
		}

		if (tab == "start_edit") {
			this.setState(
				{
				errors: [],
				modalstatus: true,
				modal_id: id,
				modaltitle: "Confirm your action",
				modalfooter: this.modalFooter("", "start"),
				},
				() => {
				this.setState({
					modalbody: "Are you sure you want to edit this playbook?",
				});
				}
			);
		}

		if (tab == "edit_campaign") {
			var timezoneObj = jstz.determine();
			let date = new Date();
      let nextStartDate = date;
			let startTimeForDuration = new Date();
			if (id.start_date_new == null || id.start_date_new == '0000-00-00 00:00:00' || id.start_date_new == 'N/A') {
			} else {

				date = new Date(id.start_date_new)
				if ((id.next_start_date !== 'N/A') && (id.next_start_date !== '0000-00-00 00:00:00')) {
					startTimeForDuration = new Date(id.next_start_date_real)
				// startTimeForDuration = id.next_start_date_real
				}
			}

      if (id.next_start_date == null || id.next_start_date == '0000-00-00 00:00:00' || id.next_start_date == 'N/A') {
			} else {
				nextStartDate = new Date(id.next_start_date)
				
			}
      
		
			let selectedIntervalday = null;
			if (id.next_start_allday) {
				selectedIntervalday = JSON.parse(id.next_start_allday);
			} else if (
				id.new_user_schedule_interval &&
				(id.next_start_allday == null || id.next_start_allday === "")
			){
				const selDay = id.new_user_schedule_interval;
				const dayArray = DAY_LIST.filter(
				(dayL) => dayL.name.toLowerCase() === selDay.toLowerCase()
				);
				selectedIntervalday = dayArray.length > 0 ? dayArray : [];
			}

			api.marketing
				.getTimezoneData({ timezonename: timezoneObj.name() })
				.then((data) => {
				try {
					this.setState(
					{
						timeZone: data.data.timezone,
						currenttime: data.data.currenttime,
						currentSelectedTimezone: timezoneObj.name(),
						errors: [],
						modalstatus: true,
						modal_id: id.id,
						// setDate: date,
						setDate: startTimeForDuration,
						current__run_if_new_user_added: id.run_if_new_user_added,
						current__new_user_schedule_interval:id.new_user_schedule_interval,
						// selectedValue : id.next_start_allday ? JSON.parse(id.next_start_allday): [],
						selectedValue: selectedIntervalday,
            checked:id.sendEmailImmediatelyToNewUsers,  //sagar
						// startTimeForDuration: startTimeForDuration, 
						startTimeForDuration: nextStartDate, 
						modaltitle: "Edit Schedule",
						modalfooter: this.modalFooter(
						id.id,
						"edit_campaign",
						id.domain_id
						),
						edit_campaign: id,
						actionStart: "Edit",
					},
					() => {
						this.setState({ modalbody: this.getCampaignBody() });
					}
					);
				} catch (err) {
					console.log(err);
				}
			});
		}
		if (tab == "pause_campaign") {
			this.setState(
				{
				errors: [],
				modalstatus: true,
				modal_id: id,
				modaltitle: "Confirm your action",
				modalfooter: this.modalFooter("", "pause"),
				},
				() => {
				this.setState({
					modalbody: "Are you sure you want to pause this playbook?",
				});
				}
			);
		}
	};

  getScheduleQueue = (id) => {
    let date = this.state.resume_campaign_radio.selectedDate.getDate();
    let month = this.state.resume_campaign_radio.selectedDate.getMonth() + 1;
    let year = this.state.resume_campaign_radio.selectedDate.getFullYear();
    let hrs = this.state.resume_campaign_radio.selectedDate.getHours();
    let min = this.state.resume_campaign_radio.selectedDate.getMinutes();
    let sec = this.state.resume_campaign_radio.selectedDate.getSeconds();
    let final =
      year + "-" + month.toString().padStart(2, '0') + "-" + date.toString().padStart(2, '0') + " " + hrs + ":" + min + ":" + sec;
        
    api.marketing.scheduledQueue( {playbook_id : id, ...this.state.resume_campaign_radio,selectedDate:final} ).then( res => {
      try{
        if(res.status != undefined && res.status){
          
          this.setState({scheduledQueue: res.data}, function(){
            this.setState({loading:false,modalbody: this.getCampaignBodyResume()});
          });
        }
      }catch(err){
        console.log( err );
      }
    });
  }

	setStartDate = (date) => {
		this.setState(
			{
			setDate: date,
			},
			() => {
			this.setState({ modalbody: this.getBody() });
			}
		);
	};

	setStartTimeForDuration = (date, bodyType = null) => {
	this.setState(
		{
		startTimeForDuration: date,
		},
		() => {
		this.setState({
			modalbody: bodyType ? this.getCampaignBody() : this.getBody(),
		});
		}
	);
	};

	pad = (size, val) => {
		var s = String(val);
		while (s.length < (size || 2)) {
			s = "0" + s;
		}
		return s;
	};

  renderTimeZone = (list) => {
    if (typeof list != "undefined") {
      return Object.keys(list).map(function (key, index) {
        return (
          <option key={index} value={key}>
            {list[key]}
          </option>
        );
      });
    }
  };

  handleSelectChange = (e, actionType = null) => {
    let list = this.state.timeZone;
    let currentTimeZone = e.target.value;
    let default_val = e.target.value;
    let error = this.state.errors;
    if (e.target.value == "") {
      currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    api.marketing.getCurrentTime({ timezone: currentTimeZone }).then((data) => {
      try {
        list.selectedtimezone = default_val;

        if (
          typeof error != "undefined" &&
          error != null &&
          error.hasOwnProperty("timezoneerror") &&
          default_val != ""
        ) {
          error["timezoneerror"] = false;
        }

        this.setState(
          {
            currenttime: data.data.currenttime,
            currentSelectedTimezone: currentTimeZone,
            minDate: new Date(data.data.currenttime),
            setDate: new Date(data.data.currenttime),
            startTimeForDuration: new Date(data.data.currenttime),
            errors: error,
          },
          function () {
            this.setState({
              modalbody: actionType ? this.getCampaignBody() : this.getBody(),
            });
          }
        );
      } catch (err) {
        console.log(err);
      }
    });
  };

  // sendImmediately

  sendImmediately = (e) => {
    // let startdate = e.target.checked
    //   ? new Date()
    //   : new Date(this.state.edit_campaign.start_date);
    let startdate = e.target.checked
      ? (new Date()).getTime() + 4*60000
      :new Date()

    this.setState(
      {
        sendImmediately: !this.state.sendImmediately,
        setDate: startdate,
      },
      () => {
        this.setState({ modalbody: this.getBody() });
      }
    );
  };

  // when edit campaign button clicked this body will placed in modal

  getCampaignBody = () => {
    const { errorsT, currenttime } = this.state;

    return (
      <div className="form-group row">
        {/* <label className="col-lg-12">
          <a
            className="add-gradient mb-2"
            style={{ color: "#087ab6", marginTop: 10 }}
          >
            <label
              style={{ float: "left" }}
              class="kt-checkbox kt-checkbox--single chat-checkbox checboxrules"
            >
              <input
                type="checkbox"
                name="run_if_new_user_added"
                onClick={(e) => this.sendImmediately(e, "campaign")}
                checked={this.state.sendImmediately === true ? true : false}
              />
              <span></span>
            </label>
            <span style={{ float: "left" }} className="custom-textRule">
              Would you like to send immediately?
            </span>
          </a>
        </label> */}
        <label className="col-lg-3">Date :</label>
        <div className="col-lg-9">
          <DatePicker
            selected={this.state.setDate}
            onChange={this.setStartDate}
            minDate={this.state.minDate}
            timeInputLabel="Time:"
            dateFormat="MMMM d, yyyy h:mm aa"
            showTimeInput
            className="form-control"
            name="setDate"
            excludeOutOfBoundsTimes
            disabled
          />
        </div>
        <label className="col-lg-2"></label>
        <div className="col-lg-10"></div>
        <label className="col-lg-3">Time Zone :</label>
        <div className="col-lg-9">
          <select
            disabled
            className={
              errorsT && errorsT.timezoneerror
                ? "form-control is-invalid "
                : "form-control"
            }
            id="exampleSelectd"
            value={this.state.currentSelectedTimezone}
            onChange={(e) => this.handleSelectChange(e, "edit_campaign")}
            readOnly={true}
          >
            {this.renderTimeZone(this.state.timeZone)}
          </select>
          {currenttime && (
            <span className="form-text text-muted">
              The local date time is currently {currenttime}{" "}
            </span>
          )}

          {errorsT && errorsT.timezoneerror && (
            <CustomErrorField
              message="This field is required"
              id="timezne"
              className="error invalid-feedback"
            />
          )}
        </div>

        <div className="col-lg-12">
          <a
            className="add-gradient mb-3"
            style={{ color: "#087ab6", marginTop: 10 }}
          >
            <label
              style={{ float: "left" }}
              class="kt-checkbox kt-checkbox--single chat-checkbox checboxrules"
            >
              <input
                type="checkbox"
                name="run_if_new_user_added"
                onClick={(e) => this.changeCheckBoxState(e, "campaign")}
                checked={
                  this.state.current__run_if_new_user_added == 1 ? true : false
                }
              />
              <span></span>
            </label>
            <span style={{ float: "left" }} className="custom-textRule">
              Would you like to send an email to the new added users?
            </span>
          </a>
        </div>
        {this.state.current__run_if_new_user_added == 1 ?<div className="col-lg-12 d-flex align-items-center mb-2">
            <div>
                        <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                            <label>
                                <input type="checkbox" checked={this.state.checked} onChange={(e)=>this.handleSwitch(e,"edit_campaign")} />
                                <span />
                            </label>
                        </span>
            </div>
            <div className="mx-4">Send Immediately</div>
          </div>: ""}
        {this.state.checked===false && <><label
          style={{
            display:
              this.state.current__run_if_new_user_added == 1 ? "block" : "none",
          }}
          className="col-lg-3"
        >
          Every :
        </label>
        <div
          style={{
            display:
              this.state.current__run_if_new_user_added == 1 ? "block" : "none",
          }}
          className="col-lg-9"
        >
          <Multiselect
            options={DAY_LIST} // Options to display in the dropdown
            selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
            onSelect={(selectedList, selectedItem) =>
              this.onSelect(selectedList, selectedItem, "startcampaign")
            } // Function will trigger on select event
            onRemove={this.onRemove} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
            closeIcon="circle"
            avoidHighlightFirstOption={true}
            ref={this.multiselectRef}
          />

          <div
            style={{
              display: this.state.current__new_user_schedule_interval_error,
            }}
            className="red"
          >
            {"This field is required"}
          </div>
        </div>
        <label
          style={{
            display:
              this.state.current__run_if_new_user_added == 1 ? "block" : "none",
            marginTop: 10,
          }}
          className="col-lg-2"
        ></label>
        <div
          style={{
            display:
              this.state.current__run_if_new_user_added == 1 ? "block" : "none",
            marginTop: 10,
          }}
          className="col-lg-10"
        ></div>
        <label
          style={{
            display:
              this.state.current__run_if_new_user_added == 1 ? "block" : "none",
          }}
          className="col-lg-3"
        >
          Time :
        </label>
        <div
          style={{
            display:
              this.state.current__run_if_new_user_added == 1 ? "block" : "none",
          }}
          className="col-lg-9"
        >
          <DatePicker
            selected={this.state.startTimeForDuration}
            onChange={(date) =>
              this.setStartTimeForDuration(date, "edit_campaign")
            }
            showTimeInput
            showTimeSelectOnly
            className="form-control"
            // timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </div></>}
      </div>
    );
  };
  
  changeResumeCampaignData = (e,fieldName) => {
		if(fieldName === 'resume_campaign_radio'){
			this.setState({
				...this.state,
        modalSize: e.target.id == 'resume_campaign_radio2' ? true : false,
				resume_campaign_radio : {...this.state.resume_campaign_radio,resume_campaign_radio:e.target.value}
			},function () {
        this.setState({
          modalbody: this.getCampaignBodyResume(),
        });
      });
		}else if(fieldName === 'selectedDate'){
			this.setState({
				...this.state,
        loading:true,
				resume_campaign_radio : {...this.state.resume_campaign_radio,selectedDate:e}
			},function () {
        this.setState({
          modalbody: this.getCampaignBodyResume(),
        });
        this.getScheduleQueue(this.state.activePid);
      });
		}else if( fieldName === "selectedTimezone"){
      let selectedTimeZone = e.target.options[e.target.selectedIndex].text;
      selectedTimeZone = selectedTimeZone.substring(selectedTimeZone.indexOf("(")+1,selectedTimeZone.indexOf(")"));
			this.setState({
				...this.state,
        loading:true,
				resume_campaign_radio : {
          ...this.state.resume_campaign_radio,
          selectedTimezone:e.target.value,
          selectedTimzoneValue:selectedTimeZone.replace('UTC','')}
			}, function () {
        this.setState({
          modalbody: this.getCampaignBodyResume(),
        });
        this.getScheduleQueue(this.state.activePid);
      });
		}
	}
	getCampaignBodyResume = () => {
		const { errorsT, currenttime } = this.state;
    let playbookDetailSelected = this.state.lists.filter( (playbook,index) => playbook.id == this.state.activePid );
		const {resume_campaign_radio,selectedDate,selectedTimezone,selectedTimezoneValue} = this.state.resume_campaign_radio;
    let selectedTimeZone = (this.state.resume_campaign_radio.selectedTimezone != '') ? this.state.resume_campaign_radio.selectedTimezone : playbookDetailSelected[0].selected_time_zone;
    let valueOfTimezone = this.state.timeZone[playbookDetailSelected[0].selected_time_zone];
    if(this.state.resume_campaign_radio.selectedTimezone == ''){
      this.setState({
        resume_campaign_radio:{
          ...this.state.resume_campaign_radio,
          selectedTimzoneValue:valueOfTimezone.substring(valueOfTimezone.indexOf('+'),valueOfTimezone.indexOf('+')+6),
          selectedTimezone:selectedTimeZone,
          resume_campaign_radio : 'no'
        }
      },() => this.getScheduleQueue(this.state.activePid))
    }
		return (
			<>
      <div className="row">
        <div className='col-md-12' >
          <div className="form-group">
            <div className={`col-md-12 ${(this.state.modalSize && this.state.scheduledQueue) && 'd-flex flex-wrap'}`}>
              <div className={`form-check ${(this.state.modalSize && this.state.scheduledQueue) && 'mr-5'}`}>
                <input className="form-check-input" type="radio" name="resume_campaign_radio" id="resume_campaign_radio1" value="no" 
                onChange = {(e) => this.changeResumeCampaignData(e,"resume_campaign_radio")}
                checked = {resume_campaign_radio === 'no' }
                />
                <label className="form-check-label"  style = {{"marginTop":"2px"}} >
                Resume campaign from start (Welcome Branch).
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" name="resume_campaign_radio" id="resume_campaign_radio2" value="yes" 
                onChange = {(e) => this.changeResumeCampaignData(e,"resume_campaign_radio")}
                checked = {resume_campaign_radio === 'yes'}
                />
                <label className="form-check-label"  style = {{"marginTop":"2px"}} >
                Resume campaign from branch paused.
                </label>
              </div>
            </div>

            <div className="col-md-12 mt-2">
              <label className="form-check-label">Please select future schedule date and Time. </label>
              <div className="row">
                <div className={`${(this.state.modalSize && this.state.scheduledQueue) ? 'col-md-6' : 'col-md-9' } mt-3`}>
                  <select
                    className={
                    errorsT && errorsT.timezoneerror
                      ? "form-control is-invalid "
                      : "form-control"
                    }
                    id="exampleSelectd"
                    value={ selectedTimezone}
                    onChange={(e) => this.changeResumeCampaignData(e,"selectedTimezone")}
                    readOnly={true}
                  >
                    {this.renderTimeZone(this.state.timeZone)}
                  </select>
                  {currenttime && (
                    <span className="form-text text-muted">
                    The local date time is currently {currenttime}{" "}
                    </span>
                  )}
                </div>
                <div className={`${(this.state.modalSize && this.state.scheduledQueue) ? 'col-md-6' : 'col-md-9 mt-1' } mt-3`}>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(e) => this.changeResumeCampaignData(e,"selectedDate")}
                    minDate={new Date()}
                    timeInputLabel="Time:"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    showTimeInput
                    className={`form-control`}
                    name="selectedDate"
                    excludeOutOfBoundsTimes
                  />
                </div>
              </div>
            </div>
           {(this.state.modalSize && this.state.scheduledQueue) && 
              <>
                <label class="form-check-label mt-4 mb-2">Future schedule branches:</label>
                <div className="table-responsive" style={{maxHeight: 163, overflowY: 'auto'}}>
                  <table className="table border" id="scheduleBranchTable">
                    <thead>
                      <tr>
                        <th>Branch</th>
                        <th>Schedule Date</th>
                        <th>Next Schedule Date</th>
                        <th>Rule Type</th>
                        <th>Email Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        Object.keys(this.state.scheduledQueue).map((el, i) => 
                          <tr>
                            <td>{el}</td>
                            <td>{this.state.scheduledQueue[el].schedule_time}</td>
                            <td>{this.state.scheduledQueue[el].next_schedule_time}</td>
                            <td>{this.state.scheduledQueue[el].rules_type}</td>
                            <td>{this.state.scheduledQueue[el].count_of_mails}</td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </>
            }
          </div>
        </div>
      </div>
			</>
		);
	};

  // this is for multiselct day

  onSelect = (selectedList, selectedItem, bodytype = null) => {
    console.log(selectedList, selectedItem);
    this.setState(
      {
        selectedValue: selectedList,
        current__new_user_schedule_interval_error: "none",
      },
      () => {
        this.setState({
          modalbody: bodytype ? this.getCampaignBody() : this.getBody(),
        });
      }
    );
  };

  // on remove form multiselect day this will trigger

  onRemove = (selectedList, removedItem) => {
    console.log(selectedList, removedItem);
    this.setState({ selectedValue: selectedList });
  };

  resetValues() {
    // By calling the belowe method will reset the selected values programatically
    this.multiselectRef.current.resetSelectedValues();
  }

  getBody = () => {
    const { errorsT, currenttime } = this.state;

    return (
      <div className="form-group row">
        <div className="col-lg-12">
          <a
            className="add-gradient mb-2"
            style={{ color: "#087ab6", marginTop: 10 }}
          >
            <label
              style={{ float: "left" }}
              class="kt-checkbox kt-checkbox--single chat-checkbox checboxrules"
            >
              <input
                type="checkbox"
                name="run_if_new_user_added"
                onClick={(e) => this.sendImmediately(e)}
                checked={this.state.sendImmediately === true ? true : false}
              />
              <span></span>
            </label>
            <span style={{ float: "left" }} className="custom-textRule">
              Would you like to send immediately?
            </span>
          </a>
        </div>
        <label className="col-lg-3" style={{ padding: 0 }}>
          Date :
        </label>
        <div className="col-lg-9">
          <DatePicker
            selected={this.state.setDate}
            onChange={this.setStartDate}
            minDate={this.state.minDate}
            timeInputLabel="Time:"
            dateFormat="MMMM d, yyyy h:mm aa"
            showTimeInput
            className="form-control"
            name="setDate"
            excludeOutOfBoundsTimes
          />
        </div>
        <label className="col-lg-2"></label>
        <div className="col-lg-10"></div>
        <label className="col-lg-3" style={{ padding: 0 }}>
          Time Zone :
        </label>
        <div className="col-lg-9">
          <select
            className={
              errorsT && errorsT.timezoneerror
                ? "form-control is-invalid "
                : "form-control"
            }
            id="exampleSelectd"
            value={this.state.currentSelectedTimezone}
            onChange={(e) => this.handleSelectChange(e)}
          >
            {this.renderTimeZone(this.state.timeZone)}
          </select>
          {currenttime && (
            <span className="form-text text-muted">
              The local date time is currently {currenttime}{" "}
            </span>
          )}

          {errorsT && errorsT.timezoneerror && (
            <CustomErrorField
              message="This field is required"
              id="timezne"
              className="error invalid-feedback"
            />
          )}
        </div>

        <div className="col-lg-12">
          <a
            className="add-gradient mb-2"
            style={{ color: "#087ab6", marginTop: 10 }}
          >
            <label
              style={{ float: "left" }}
              class="kt-checkbox kt-checkbox--single chat-checkbox checboxrules"
            >
              <input
                type="checkbox"
                name="run_if_new_user_added"
                onClick={(e) => this.changeCheckBoxState(e)}
                checked={
                  this.state.current__run_if_new_user_added == 1 ? true : false
                }
              />
              <span></span>
            </label>
            <span style={{ float: "left" }} className="custom-textRule">
              Would you like to send an email to the new added users?
            </span>
          </a>
        </div>
        {this.state.current__run_if_new_user_added == 1 ?<div className="col-lg-12 d-flex align-items-center mb-2">
            <div>
                        <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                            <label>
                                <input type="checkbox" checked={this.state.checked} onChange={(e)=>this.handleSwitch(e)} />
                                <span />
                            </label>
                        </span>
            </div>
            <div className="mx-4">Send Immediately</div>
          </div>: ""}
        {this.state.checked===false && <><label
          style={{
            display:
              this.state.current__run_if_new_user_added == 1 && this.state.checked===false? "block" : "none",
          }}
          className="col-lg-3"
        >
          Every :
        </label>
        <div
          style={{
            display:
              this.state.current__run_if_new_user_added == 1 && this.state.checked===false? "block" : "none",
          }}
          className="col-lg-9"
        >
          
          <Multiselect
            options={DAY_LIST} // Options to display in the dropdown
            selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
            onSelect={(selectedList, selectedItem) =>
              this.onSelect(selectedList, selectedItem)
            } // Function will trigger on select event
            onRemove={this.onRemove} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
            closeIcon="circle"
            avoidHighlightFirstOption={true}
            ref={this.multiselectRef}
          />
          <div
            style={{
              display: this.state.current__new_user_schedule_interval_error,
            }}
            className="red"
          >
            {"This field is required"}
          </div>
        </div>
        <label
          style={{
            display:
              this.state.current__run_if_new_user_added == 1 && this.state.checked===false? "block" : "none",
            marginTop: 10,
          }}
          className="col-lg-2"
        ></label>
        <div
          style={{
            display:
              this.state.current__run_if_new_user_added == 1 && this.state.checked===false? "block" : "none",
            marginTop: 10,
          }}
          className="col-lg-10"
        ></div>
        <label
          style={{
            display:
              this.state.current__run_if_new_user_added == 1 && this.state.checked===false ? "block" : "none",
          }}
          className="col-lg-3"
        >
          Time :
        </label>
        <div
          style={{
            display:
              this.state.current__run_if_new_user_added == 1 && this.state.checked===false? "block" : "none",
          }}
          className="col-lg-9"
        >
          <DatePicker
            selected={this.state.startTimeForDuration}
            onChange={this.setStartTimeForDuration}
            showTimeInput
            showTimeSelectOnly
            className="form-control"
            // timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </div></>}
      </div>
    );
  };

  modalClose = (closeType = null) => {
    console.log("**********", "closetype", closeType);
    if (closeType && closeType === "edit_campaign") {
      this.setState({
        modalstatus: false,
        sendImmediately: false,
        current__new_user_schedule_interval_error: "none",
        current__run_if_new_user_added:
          this.state.current__run_if_new_user_added == 1 ? 1 : 0,
        current__new_user_schedule_interval:
          this.state.current__new_user_schedule_interval != ""
            ? this.state.current__new_user_schedule_interval
            : "",
        modalbody: "",
      });
    } else {
      this.setState({
        modalstatus: false,
        current__new_user_schedule_interval_error: "none",
        current__run_if_new_user_added: 0,
        current__new_user_schedule_interval: "",
        sendImmediately: false,
        selectedValue: [],
        modalbody: "",
      });
    }
  };

  modalCloseStart = () => {
    this.setState({
      modalstatus: false,
      current__run_if_new_user_added: 0,
      current__new_user_schedule_interval: "",
      sendImmediately: false,
      selectedValue: [],
    });
  };

	selectClose = (id, footer_type = null) => {
		if (
			this.state.current__run_if_new_user_added == 1 &&
			this.state.selectedValue?.length === 0 && this.state.checked===false
		) {
			this.setState(
			{
				current__new_user_schedule_interval_error: "block",
			},
			function () {
					this.setState({
					modalbody: footer_type ? this.getCampaignBody() : this.getBody(),
					actionStart: footer_type ? "Edit" : "Start",
				});
			}
			);
			return;
		}
		// this.modalClose('edit_campaign');
		if (footer_type && footer_type === "edit_campaign_stop") {
			this.bucketAction("stop", id);
		} else if (footer_type && footer_type === "edit_campaign") {
			this.bucketAction("start_edit", id);
		} else if( footer_type && footer_type === "edit_campaign_pause"){
			this.bucketAction("pause", id);
		} else{
			this.bucketAction("start", id);
		}
	};

	modalFooter = (id, type, domain_id = null) => {
    let startplay=this.canStartPlaybook(id);
		if (type == "edit") {
			return (
			<div className="modal-footer">
				<button
				type="button"
				onClick={() => this.modalClose()}
				className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
				data-dismiss="modal"
				>
				<span>Cancel</span>
				</button>
				<button
				type="button"
				onClick={() => this.updateBucketName(this.state.modal_id)}
				className="btn linear-gradient yr-submitbtn btn-sm"
				>
				<span>Save</span>
				</button>
			</div>
			);
		}
		if (type == "delete") {
			return (
			<div className="modal-footer">
				<button
				type="button"
				onClick={() => this.modalClose()}
				className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
				data-dismiss="modal"
				>
				<span>No</span>
				</button>
				<button
				type="button"
				onClick={() => this.deleteBucketName(this.state.modal_id)}
				className={`btn linear-gradient yr-submitbtn btn-sm`}
				>
				<span>Yes</span>
				</button>
			</div>
			);
		}

		if (type == "selectdate") {
			return (
			<div className="modal-footer">
				<button
				type="button"
				onClick={() => this.modalCloseStart()}
				className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
				data-dismiss="modal"
				>
				<span>Cancel</span>
				</button>
				<button
				type="button"
				onClick={() => this.selectClose(id)}
				className={
          startplay == true
            ? "btn linear-gradient yr-submitbtn btn-sm "
            : "btn linear-gradient yr-submitbtn btn-sm disabled"
        }
        disabled={startplay!=true}
				>
				<span>Next</span>
				</button>
				{domain_id == 0 && (
				<div
					className="email-info-content error"
					style={{ position: "unset" }}
				>
					<Popup
					position="top right"
					size="small"
					content={this.state.disabledContent}
					trigger={<Icon name="info" size="large" circular />}
					/>
				</div>
				)}
			</div>
			);
		}

		if (type == "start") {
			return (
			<div className="modal-footer">
				<button
				type="button"
				onClick={() => this.modalCloseStart()}
				className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
				data-dismiss="modal"
				>
				<span>No</span>
				</button>
				<button
				type="button"
				onClick={() => this.updateActionPlaybook("Start")}
				className={`btn linear-gradient yr-submitbtn btn-sm ${this.state.disabled?"yr-disable-btn-while-loading":""}`}
				>
				<span>Yes</span>
				</button>
			</div>
			);
		}

		if (type == "stop") {
			return (
			<div className="modal-footer">
				<button
				type="button"
				onClick={() => this.modalClose()}
				className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
				data-dismiss="modal"
				>
				<span>No</span>
				</button>
				<button
				type="button"
				onClick={() => this.updateActionPlaybook("Stopped")}
				className={`btn linear-gradient yr-submitbtn btn-sm ${this.state.disabled?"yr-disable-btn-while-loading":""}`}
				>
				<span>Yes</span>
				</button>
			</div>
			);
		}

		if (type == "archive") {
			return (
			<div className="modal-footer">
				<button
				type="button"
				onClick={() => this.modalClose()}
				className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
				data-dismiss="modal"
				>
				<span>No</span>
				</button>
				<button
				type="button"
				onClick={() => this.updateActionPlaybook("Archived")}
				className={`btn linear-gradient yr-submitbtn btn-sm ${this.state.disabled?"yr-disable-btn-while-loading":""}`}
				>
				<span>Yes</span>
				</button>
			</div>
			);
		}

		if (type == "duplicate") {
			return (
			<div className="modal-footer">
				<button
				type="button"
				onClick={() => this.modalClose()}
				className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
				data-dismiss="modal"
				>
				<span>No</span>
				</button>
				<button
				type="button"
				onClick={() => this.updateActionPlaybook("Duplicate")}
				className={`btn linear-gradient yr-submitbtn btn-sm ${this.state.disabled?"yr-disable-btn-while-loading":""}`}
				>
				<span>Yes</span>
				</button>
			</div>
			);
		}

		// restore playbook
		if (type === "restore") {
			return (
			<div className="modal-footer">
				<button
				type="button"
				onClick={() => this.modalClose()}
				className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
				data-dismiss="modal"
				>
				<span>No</span>
				</button>
				<button
				type="button"
				onClick={() => this.updateActionPlaybook("Restore")}
				className={`btn linear-gradient yr-submitbtn btn-sm ${this.state.disabled?"yr-disable-btn-while-loading":""}`}
				>
				<span>Yes</span>
				</button>
			</div>
			);
		}
		//  for edit campaign playbook time and stop playbook

		if (type == "edit_campaign") {
			return (
			<div className="modal-footer">
				<button
				type="button"
				onClick={() => this.modalCloseStart()}
				className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
				data-dismiss="modal"
				>
				<span>Cancel</span>
				</button>
				<button
				type="button"
				onClick={() => this.selectClose(id, "edit_campaign_stop")}
				className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
				>
				<span>Stop</span>
				</button>
				<button
				type="button"
				onClick={() => this.selectClose(id, "edit_campaign")}
				className={
          startplay == true
            ? "btn linear-gradient yr-submitbtn btn-sm "
            : "btn linear-gradient yr-submitbtn btn-sm disabled"
        }
        disabled={startplay!=true}
				>
				<span>Next</span>
				</button>
				{domain_id == 0 && (
				<div
					className="email-info-content error"
					style={{ position: "unset" }}
				>
					<Popup
					position="top right"
					size="small"
					content={this.state.disabledContent}
					trigger={<Icon name="info" size="large" circular />}
					/>
				</div>
				)}
			</div>
			);
		}
		// for pausing campaign
		if (type == "pause") {
			return (
				<div className="modal-footer">
					<button
					type="button"
					onClick={() => this.modalClose()}
					className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
					data-dismiss="modal"
					>
					<span>No</span>
					</button>
					<button
					type="button"
					onClick={() => this.updateActionPlaybook("Paused")}
					className={`btn linear-gradient yr-submitbtn btn-sm ${this.state.disabled?"yr-disable-btn-while-loading":""}`}
					>
					<span>Yes</span>
					</button>
				</div>
				);
		}

		// for pausing campaign
		if (type == "resume") {
			return (
				<div className="modal-footer">
					<button
					type="button"
					onClick={() => this.modalClose()}
					className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
					data-dismiss="modal"
					>
					<span>No</span>
					</button>
					<button
					type="button"
					onClick={() => this.updateActionPlaybook("Resume")}
					className={`btn linear-gradient yr-submitbtn btn-sm ${this.state.disabled?"yr-disable-btn-while-loading":""}`}
					>
					<span>Yes</span>
					</button>
				</div>
				);
		}
	};

  modalBody = (tab) => {
    if (tab == "edit") {
      //alert( this.state.rename)
      let time = Date.now();
      //this.setState({rename:})
      const { rename } = this.state;
      return (
        <div className="col-lg-12 col-md-12 col-sm-12">
          <label className="form-control-label">
            Title <span>*</span>
          </label>
          <input
            type="text"
            className={
              Object.keys(this.state.errors).length > 0 &&
              typeof this.state.errors["rename_data"] != "undefined"
                ? "form-control is-invalid focusError"
                : "form-control"
            }
            key={time}
            placeholder="Enter Summary"
            defaultValue={rename}
            onChange={(e) => this.onChangeInput("rename", e)}
          />
          {Object.keys(this.state.errors).length > 0 &&
            typeof this.state.errors["rename_data"] != "undefined" && (
              <CustomErrorField
                message={this.state.errors["rename_data"]}
                id="help-error"
                className="error invalid-feedback"
              />
            )}
        </div>
      );
    }
    if (tab == "delete") {
      return "Are you sure";
    }

  };

  onChangeInput = (type, data) => {
    this.setState({ rename: data.target.value });
  };

  updateBucketName = (id) => {
    let error = [];
    if (this.state.rename == "") {
      error["rename_data"] = "This field is required";
    }
    if (Object.keys(error).length > 0) {
      this.setState({ errors: error }, function () {
        let modalbody = this.modalBody("edit");
        this.setState({ modalbody: modalbody });
      });
    } else {
      this.setState({ loading: true });

      api.marketing
        .updateBucketName({ bid: id, title: this.state.rename })
        .then((data) => {
          try {
            if (data.status == false) {
              let error = [];
              error["rename_data"] = data.message;
              this.setState({ errors: error, loading: false }, function () {
                let modalbody = this.modalBody("edit");
                this.setState({ modalbody: modalbody });
              });
            } else {
              let list_data = this.state.lists;
              let recordid = list_data.findIndex(
                (list_data) => list_data.bid == id
              );
              list_data[recordid].title = this.state.rename;

              this.setState(
                {
                  loading: false,
                  lists: list_data,
                  modalstatus: false,
                  modal_id: "",
                },
                function () {
                  let datatableRowstemp = this.state.lists.map(
                    (list, index) => (
                      <DatatableRow
                        channgeEvent={this.checkboxChangeEvent}
                        openContactList={this.openContactList}
                        key={index}
                        list={list}
                        bucketAction={this.bucketAction}
                        createPlaybook={this.props.createPlaybook}
                      />
                    )
                  );
                  this.setState({ datatableRows: datatableRowstemp });
                }
              );
            }
          } catch (err) {
            console.log(err);
          }
        });
    }
  };

  deleteBucketName = (id) => {
    api.marketing.deleteBucketName({ bid: id }).then((data) => {
      try {
        let list_data = this.state.lists;
        let recordid = list_data.findIndex((list_data) => list_data.bid == id);
        list_data.splice(recordid, 1);
        this.setState(
          {
            loading: false,
            lists: list_data,
            modalstatus: false,
            modal_id: "",
          },
          function () {
            let datatableRowstemp = this.state.lists.map((list, index) => (
              <DatatableRow
                channgeEvent={this.checkboxChangeEvent}
                openContactList={this.openContactList}
                key={index}
                list={list}
                bucketAction={this.bucketAction}
                createPlaybook={this.props.createPlaybook}
              />
            ));
            this.setState({ datatableRows: datatableRowstemp });
          }
        );
      } catch (err) {
        console.log(err);
      }
    });
  };

  getListByStatus = (e) => {
    if (this.state.status != e) {
      document.getElementById("kt_form_status").value = "title";
      document.getElementById("playBookSearchBar").value = "";
      this.setState(
        {
          loading: true,
          status: e,
        },
        () => {
          this.getListByStatusNow();
        }
      );
    }
  };

  getListByStatusNow = () => {
    this.loadData({
      _limit: 10,
      _order: "desc",
      _sort: "id",
      q: "",
      _page: 1,
      title: "",
    });
  };

	//sagar -> function to pass params in updateActionPlaybook
	updateActionPlaybookParams = (action) => {
		let run_if_new_user_added = this.state.current__run_if_new_user_added;
		let new_user_schedule_interval =
			this.state.current__new_user_schedule_interval;

		let date = this.state.setDate.getDate();
		let month = this.state.setDate.getMonth() + 1;
		let year = this.state.setDate.getFullYear();
		let hrs = this.state.setDate.getHours();
		let min = this.state.setDate.getMinutes();
		let sec = this.state.setDate.getSeconds();
		let final =
			year + "-" + month + "-" + date + " " + hrs + ":" + min + ":" + sec;

		let date1 = this.state.startTimeForDuration.getDate();
		let month1 = this.state.startTimeForDuration.getMonth() + 1;
		let year1 = this.state.startTimeForDuration.getFullYear();
		let hrs1 = this.state.startTimeForDuration.getHours();
		let min1 = this.state.startTimeForDuration.getMinutes();
		let sec1 = this.state.startTimeForDuration.getSeconds();
		let final1 =
			year1 + "-" + month1 + "-" + date1 + " " + hrs1 + ":" + min1 + ":" + sec1;

		let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
		let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
		let time_zone = clientTimeZone;

    let selectedDayItems = [];
    if (
      this.state.selectedValue !== null &&
      this.state.selectedValue?.length > 0
    ) {
      selectedDayItems = this.state.selectedValue.sort(function (a, b) {
        return a.id - b.id;
      });
    }
    let params ={}
    if(this.state.checked){
       params = {
        currentSelectedTimezone: this.state.currentSelectedTimezone,
        startTimeForDuration: null,
        startDate: final,
        run_if_new_user_added: run_if_new_user_added,
        new_user_schedule_interval: new_user_schedule_interval,
        id: this.state.modal_id,
        action: action,
        time_zone: time_zone,
        sendImmediately: this.state.sendImmediately,
        startAction: this.state.actionStart,
        selectedValue: null,
        sendEmailImmediatelyToNewUsers:this.state.checked
      };
    }else{

       params = {
        currentSelectedTimezone: this.state.currentSelectedTimezone,
        startTimeForDuration: final1,
        startDate: final,
        run_if_new_user_added: run_if_new_user_added,
        new_user_schedule_interval: new_user_schedule_interval,
        id: this.state.modal_id,
        action: action,
        time_zone: time_zone,
        sendImmediately: this.state.sendImmediately,
        startAction: this.state.actionStart,
        selectedValue: JSON.stringify(selectedDayItems),
        sendEmailImmediatelyToNewUsers:false
      };
    }
    return params
  }
	updateActionPlaybook = (action) => {
    if(!this.state.disabled){
      this.setState({disabled:true})
		//Sagar
		switch (action) {
			case "Duplicate":
				api.marketing
					.duplicatePlaybook(this.updateActionPlaybookParams(action))
					.then((data) => {
					try {
						this.setState({
							loading: false,
							modalstatus: false,
							modal_id: "",
							current__run_if_new_user_added: 0,
							current__new_user_schedule_interval: "",
              disabled:false
						});
						this.loadData({});
					} catch (err) {
						console.log(err);
					}
				});
				break;

			case "Start":
			case "Archived":
			case "Restore":
			case "Draft":
			case "Stopped":
			case "Paused":
				api.marketing
					.changePlaybookStatus(this.updateActionPlaybookParams(action))
					.then((data) => {
					try {
						this.setState({
							loading: false,
							modalstatus: false,
							modal_id: "",
							current__run_if_new_user_added: 0,
							current__new_user_schedule_interval: "",
              disabled:false
						});
						this.loadData({});
					} catch (err) {
						console.log(err);
					}
					});
				break;
			case "Resume":
        let date = this.state.resume_campaign_radio.selectedDate.getDate();
        let month = this.state.resume_campaign_radio.selectedDate.getMonth() + 1;
        let year = this.state.resume_campaign_radio.selectedDate.getFullYear();
        let hrs = this.state.resume_campaign_radio.selectedDate.getHours();
        let min = this.state.resume_campaign_radio.selectedDate.getMinutes();
        let sec = this.state.resume_campaign_radio.selectedDate.getSeconds();
        let final =
          year + "-" + month.toString().padStart(2, '0') + "-" + date.toString().padStart(2, '0') + " " + hrs + ":" + min + ":" + sec;
        let resumeParams = {
          ...this.state.resume_campaign_radio,
          selectedDate: final,
          id:this.state.modal_id,
          action:'Resume '
        };
				api.marketing
				.changePlaybookStatus(resumeParams)
				.then((data) => {
					try {
						this.setState({
							loading: false,
							modalstatus: false,
							modal_id: "",
							current__run_if_new_user_added: 0,
							current__new_user_schedule_interval: "",
              disabled:false
						});
						this.loadData({});
					} catch (err) {
						console.log(err);
					}
				});
			break;
		}
  }
	};

  changeCheckBoxState = (e, boxType = null) => {
    var current__run_if_new_user_added = e.target.checked == true ? 1 : 0;
    // this.resetValues();
    this.setState(
      {
        current__run_if_new_user_added: current__run_if_new_user_added,
        current__new_user_schedule_interval_error: "none",
        selectedValue: [],
        // current__new_user_schedule_interval : (e.target.checked == true) ""
      },
      function () {
        this.setState({
          modalbody: boxType ? this.getCampaignBody() : this.getBody(),
        });
      }
    );
  };

  changeDropDownState = (e, actionType = null) => {
    this.setState(
      {
        current__new_user_schedule_interval_error: "none",
        current__new_user_schedule_interval: e.target.value,
      },
      function () {
        this.setState({
          modalbody: actionType ? this.getCampaignBody() : this.getBody(),
        });
      }
    );
  };

  render() {
    console.log("total count from render", this.state.totalCount);
    return (
      <div>
        <ChatFilter
          filter={{
            chatstatus: this.state.chatstatus,
            chatfrequency: this.state.chatfrequency,
            strsearch: this.state.q,
            startdaterange: this.state.startdaterange,
            enddaterange: this.state.enddaterange,
          }}
          handleSort={this.handleSort}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading}
          csvdata={this.state.csvdata}
          createPlaybook={this.props.createPlaybook}
          getListByStatus={this.getListByStatus}
        />
        {this.state.loading && <div className="ui loading form"></div>}

        <Segment
          className="width-fluid"
          style={{
            width: "100%",
            minHeight: "300px",
            margin: "0px",
            padding: 0,
            paddingTop: "0px",
            overflow: "auto",
          }}
        >
          <DatatableTable
            lists={this.state.lists}
            datatableHeader={tableHeader}
            datatableRows={this.state.datatableRows}
            totalCount={this.state.totalCount}
            totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
            currentPage={this.state._page}
            onChangePage={this.onChangePage}
            column={this.state._sort}
            direction={DatatableList.directionConverter(this.state._order)}
            handleSort={this.handleSort}
            onChangeLimit={this.onChangeLimit}
            limit={this.state._limit.toString()}
            checkAll={this.checkAll}
          />
        </Segment>
      
        <Modal
          modalSize={this.state.modalSize}
          show={this.state.modalstatus}
          footer={this.state.modalfooter}
          onclose={this.modalClose}
          title={this.state.modaltitle}
          body={this.state.modalbody}
          modaltranscript={this.state.modalTranscript}
        />
      </div>
    );
  }
}
