import React from "react";
import "../../../../Modal/dialog.css";
import { Table } from "semantic-ui-react";

const DialogContactDetail = ({
  show,
  title,
  message,
  modalType,
  modalClose,
  buttonmsg,
  modalbuttofun,
  loading,
  detail_data,
}) => {
  console.log(detail_data);
  return (
    <React.Fragment>
      <div
        className={
          show
            ? "modal fade yr-upgrade-popup white-bg new-model-popup show"
            : "modal fade yr-upgrade-popup white-bg new-model-popup"
        }
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          {loading && <div className="ui loading form"></div>}
          <div className="modal-content white-bg yr-campaigns-model">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => modalClose(false, "")}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
            </div>
            <div className="modal-body">
              <div className="">
                <Table id="additional_captured" style={{ marginTop: "20px" }}>
                  <Table.Body>
                    {typeof detail_data != "undefined" &&
                      detail_data.length > 0 &&
                      detail_data.map((obj, index) => {
                        return (
                          <Table.Row>
                            <Table.Cell
                              colspan="2"
                              style={{ textTransform: "capitalize" }}
                            >
                              {obj.column_key}
                            </Table.Cell>
                            <Table.Cell>{obj.value}</Table.Cell>
                          </Table.Row>
                        );
                      })}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DialogContactDetail;
