import React from "react";
import parse, { attributesToProps } from 'html-react-parser';
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../store/Page";

import "./Postcard.css";
import "../../../Grid/grid.css"; 
import "../../../Modal/dialog.css";
import { Message } from "semantic-ui-react";
import "../../Marketing/yr-style.css";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import api from "../../../../api"; 
import utilFunc from "../../../../util_funs";
import './colorPicker/ColorPicker.css';
import InputColorField from './colorPicker/InputColorField'; 
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import util_funs from "../../../../util_funs";

 var displayDataImg=[];
 var displayDataColor=[];
 var displayDataText=[];

class EditPostcard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      errors: [],  
      loading:true, 
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      postcardData:'',
      defaultStyle:'',
      image_url:'',
      html_content:'',
      message:'',
      style:'',
      colour:'',
      qrcode:'',
      pbackurl:'',
      htmlContent:'',
      image_url_old:'',
      src: {},
      imageList: {},
      // crop: [{
      //   unit: '%',
      //   width: 30,
      //   aspect: 16 / 9
      // }]
      crop: {},
      cropedImageList: {}
    }; 
    this.html2canvas = window.html2canvas;
    
  }

  componentDidMount() {
    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "Edit Postcard",
      navigationName: "marketing",
      subHeaderTitle: "Engage",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    this.loadData(); 
    
  }

  loadData = () => {
    const id = util_funs.dcrypt_url(this.props.match.params.id)
    api.postcard.onloadEdit('id='+id).then(data => {
        try { 
            displayDataImg=[];
            displayDataColor=[];
            displayDataText=[]; 
            this.loadHtml(data.data.template.html_content);  
            this.setState({
              loading: false,
              postcardData: data.data.template,
              message:data.data.template.message,
              style:data.data.template.style,
              colour:data.data.template.colour,
              qrcode:data.data.template.qrcode,
              defaultStyle: data.data.style,
              pbackurl:data.data.backurl.data.handwriting_image, 
              htmlContent:data.data.template.html_content, 
              image_url:data.data.template.image_url,
              image_url_old:data.data.template.image_url,
            }); 
            document.querySelector('iframe').contentDocument.write(data.data.template.html_content)
            document.querySelector('.dynamicImg').style.objectFit = 'contain'
            document.querySelector('.dynamicImg').style.objectPosition = 'center'
            //console.log(data.data.template);
         } catch (err) {
            console.log(err);
        }
        });
    };

  
    
showToaster = (color, msg) => {
  this.setState({
    userToaster: true,
    userToasterMsg: msg,
    userToasterColor: color,
  });
  setTimeout(
    function () {
      this.setState({ userToaster: false });
    }.bind(this),
    3000
  );
};


onchange = (e) => { 
  this.setState({ [e.target.name]: e.target.value }); 
  if(e.target.value!=''){
    let errList = this.state.errors;
    delete errList[e.target.name];
    this.setState({ errors: errList });
  }else{
      let errList = this.state.errors;
      errList[e.target.name] = "This filed is required.";
      this.setState({ errors: errList }) 
  }
}
 
updateBackPostcard = (e) =>{
  const errors = {}; 
    if (this.state.message.trim()=='') {
        errors['message'] = "This filed is required.";
    } 
  this.setState({ errors });
  if(Object.keys(errors).length==0){ 
    this.setState({ loading: true });
    api.postcard.backurl({'message':this.state.message,'style':this.state.style,'colour':this.state.colour}).then(data => {
       try {
          this.setState({ loading: false, pbackurl:data.data.backurl.data.handwriting_image}); 
          //this.showToaster('green', data.data);
          //utilFunc.scrollTop(800);
      } catch (err) {
          console.log(err);
      }
    });
  }
}

 
loadHtml = (htmlContent) =>{ 
  
  //var dataC;
  parse(htmlContent, {
     replace: domNode => { 

      if (domNode.attribs && domNode.attribs.class === 'dynamicColor') {
          displayDataColor.push(attributesToProps(domNode.attribs).style.backgroundColor); 
      }

      if (domNode.attribs && domNode.attribs.class === 'dynamicImg') {
         displayDataImg.push(attributesToProps(domNode.attribs).src); 
      }

      if (domNode.attribs && domNode.attribs.class === 'dynamicText') {
         displayDataText.push({text:domNode.children.length==0?'':domNode.children[0].data,color:attributesToProps(domNode.attribs).style.color});  
      }
     
      
    }
  }); 
}
 
onchangeText = (e,key) =>{ 
  document.getElementById('iframe').contentWindow.document.querySelectorAll('.dynamicText')[key].innerText = e.target.value;
}

onchangeColor = (e,name) =>{ 
  //console.log(e.hex); 
  //if(name=='text_color'){

  //}else{
    console.log(e);
    console.log(e.hex);
    const myArr = name.split("_");
    document.getElementById('iframe').contentWindow.document.querySelectorAll('.dynamicColor')[myArr[1]].style.backgroundColor = e.hex; 
  //}
}

onchangeColorText = (e,name) =>{
  const myArr = name.split("_");
  document.getElementById('iframe').contentWindow.document.querySelectorAll('.dynamicText')[myArr[2]].style.color = e.hex;
}

onchangeImage = (event,key) =>{ 
        //console.log(event.target);
        //let newState = this.state.customizeinfo; 
        let imageMIME = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
         
        if(imageMIME.includes(event.target.files[0].type) == true && event.target.files[0].type!=''){
            let errList = this.state.errors;
            delete errList[event.target.name];
            this.setState({ errors: errList });
            //let companyLogo = URL.createObjectURL(event.target.files[0]);
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); 
            // this will add src data in state
            reader.addEventListener('load', () =>
              this.setState(prevState => ({
                src : {
                  ...prevState.src,
                  [key] : reader.result
                }
              }))
            );
            // console.log('*********', this.state.src);
            reader.onloadend = ()=> {
                var base64data = reader.result; 
                // document.getElementById("image_"+key).src = base64data;
                document.getElementById('iframe').contentWindow.document.querySelectorAll('.dynamicImg')[key].src = base64data;
                document.getElementById('remove_'+key).style.display = 'block';
            }
            
        }else{
            let errList = this.state.errors;
            errList[event.target.name] = 'Invalid file format. Only "jpg, jpeg, png, gif" files are supported.';
            this.setState({ errors: errList }) 
        }
   
}

// If you setState the crop in here you should return false.
onImageLoaded = (image, keyd) => {  
  // this.imageRef = image;
  // console.log(this.imageRef);
  this.setState(prevState => ({
    imageList : {
      ...prevState.imageList,
      [keyd] : image
    }
  }))
};

onCropComplete = (crop, imgkey) => {
  this.makeClientCrop(crop,imgkey);
};

onCropChange = (crop, key) => {
  // You could also use percentCrop:
  // this.setState({ crop: percentCrop });
  const filedname = "crop"+key;
  this.setState({ [filedname] : crop} );
};

async makeClientCrop(crop,imgkey) {
  // console.log(this.state.imageList[imgkey]);
  if (this.state.imageList[imgkey] && crop.width && crop.height) {
    const croppedImageUrl = await this.getCroppedImg(
      this.state.imageList[imgkey],
      crop,
      'newFile_'+imgkey+'.jpeg'
    );    
    // document.getElementById('image_'+imgkey).src = croppedImageUrl;
    const ifr = document.getElementById('iframe').contentWindow.document.querySelectorAll('.dynamicImg');
    // console.log(ifr[imgkey].src); 
    ifr[imgkey].src = croppedImageUrl;
    this.setState(prevState => ({
      cropedImageList : {
        ...prevState.cropedImageList,
        [imgkey] : croppedImageUrl
      }
    }));
  }
}

getCroppedImg(image, crop, fileName) {
  const canvas = document.createElement('canvas');
  const pixelRatio = window.devicePixelRatio;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          resolve(reader.result);
        }
      },
      'image/png', // changed jpeg - png by aslam
      1
    );
  });  
}


onchangeImageRemove = (event,key) =>{ 
  // this will remove crop image data from src state 
  this.setState(prevState => ({
    src : {
      ...prevState.src,
      [key] : null
    },
    imageList : {
      ...prevState.imageList,
      [key] : null
    }
  }))
  let companyLogo = displayDataImg[key];
  // document.getElementById('image_'+key).src = companyLogo;
  document.getElementById('iframe').contentWindow.document.querySelectorAll('.dynamicImg')[key].src = companyLogo;
  document.getElementById('remove_'+key).style.display = 'none';

  
} 

savePostcard = (e) =>{
  const errors = {}; 
  if (this.state.message.trim()=='') {
      errors['message'] = "This filed is required.";
  } 
  this.setState({ errors });
  if(Object.keys(errors).length==0){ 
       //let recordid = obj.state.defaultTemplate.findIndex((list_data) => list_data.auto_id == obj.state.choosetemplate);
       // document.getElementById("html-content-holder").innerHTML=obj.state.defaultTemplate[recordid].html_content;
       if(this.state.htmlContent==''){
          this.setState({ loading: true });
              var imgUpdatepath;
              if(this.state.image_url==this.state.image_url_old) {
                    imgUpdatepath = '';
              }else{
                    imgUpdatepath = this.state.image_url;
              }
              api.postcard.savePostcard({'html_content':'',
                'image_url':imgUpdatepath,
                'message':this.state.message,
                'style':this.state.style,
                'qrcode':this.state.qrcode,
                'id':util_funs.dcrypt_url(this.props.match.params.id),
                'colour':this.state.colour
              }).then(data => {
                try {
                    this.setState({ loading: false,}); 
                    this.showToaster('green', data.data);
                    utilFunc.scrollTop(800);
                    this.reloadCropImage();
                } catch (err) {
                    console.log(err);
                }
              });
           
       }else{
         this.setState({ loading: true });

          this.html2canvas(document.getElementById('iframe').contentWindow.document.getElementsByTagName("html")[0],{
            logging: true, 
            letterRendering: 1, 
            useCORS: true,
          }).then((canvas)=> {			
          var extra_canvas = document.getElementById('iframe').contentWindow.document.createElement("canvas");
          extra_canvas.setAttribute('width',1875);
          extra_canvas.setAttribute('height',1275);
          var ctx = extra_canvas.getContext('2d');
          ctx.drawImage(canvas,0,0,canvas.width, canvas.height,0,0,1875,1275);
          ctx.scale(3,3);
          var dataURL = extra_canvas.toDataURL(); 
          //callback(dataURL); 
              api.postcard.savePostcard({'html_content':'<!DOCTYPE html><html lang="en">'+document.getElementById('iframe').contentWindow.document.getElementsByTagName("html")[0].innerHTML+'</html>',
                'image_url':dataURL,
                'message':this.state.message,
                'style':this.state.style,
                'qrcode':this.state.qrcode,
                'id':util_funs.dcrypt_url(this.props.match.params.id),
                'colour':this.state.colour
              }).then(data => {
                try {
                    this.setState({ loading: false,}); 
                    this.showToaster('green', data.data);
                    utilFunc.scrollTop(800);
                    this.reloadCropImage();
                } catch (err) {
                    console.log(err);
                }
              });
          });
      }
  }
}

// for reload crop image on left sidebar added by Firoz

reloadCropImage = () => {
  this.setState({
    src: {},
    imageList: {}
  });
  // console.log(displayDataImg);
  const {cropedImageList} = this.state;
  displayDataImg.forEach((imgData, imgIndex) => {
    if (`crop${imgIndex}` in this.state) {
      this.setState({[`crop${imgIndex}`] : {}});
    }
    if (cropedImageList[imgIndex]) {
      document.getElementById(`image_${imgIndex}`).src = cropedImageList[imgIndex];
    }else{
      document.getElementById(`image_${imgIndex}`).src = imgData;
    }
    document.getElementById(`remove_${imgIndex}`).style.display = 'none';
  })
}
 

onchangeImageOne = (event1) =>{ 

  var imageMIME = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
  var mainfile=event1.target.files[0];
  var mainname=event1.target.name;
  if(mainfile.size <'2000000'){
      var _URL = window.URL || window.webkitURL;
      var img = new Image();
      var objectUrl = _URL.createObjectURL(mainfile);
      var objnew=this;
      var event=event1
      img.onload =  function(event) {
       //alert(this.width + " " + this.height);
        _URL.revokeObjectURL(objectUrl);
        if(this.width == 1875 && this.height==1275){
          if(imageMIME.includes(mainfile.type) == true && mainfile.type!=''){
            let errList = objnew.state.errors;
            delete errList[mainname];
            objnew.setState({ errors: errList });
            let file = mainfile;
            var reader = new FileReader();
            reader.readAsDataURL(mainfile); 
            reader.onloadend = ()=> {
                var base64data = reader.result;  
                objnew.setState({ image_url: base64data }); 
                document.getElementById('remove_one').style.display = 'block';
            }
        }else{
            let errList = objnew.state.errors;
            errList[mainname] = 'Invalid file format. Only "jpg, jpeg, png, gif" files are supported.';
            objnew.setState({ errors: errList }) 
        }
        }else{
          let errList = objnew.state.errors;
          errList[mainname] = 'Image size should be 1875px wide by 1275px tall.';
          objnew.setState({ errors: errList }) 
        }
        


    };
    img.src = objectUrl;

  }else{
    let errList = this.state.errors;
    errList[mainname] = 'Image size should be less than 2 MB.';
    this.setState({ errors: errList }) 
  }



        //console.log(event.target);
        //let newState = this.state.customizeinfo; 
        // let imageMIME = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
         
        // if(imageMIME.includes(event.target.files[0].type) == true && event.target.files[0].type!=''){
        //     let errList = this.state.errors;
        //     delete errList[event.target.name];
        //     this.setState({ errors: errList });
        //     //let companyLogo = URL.createObjectURL(event.target.files[0]);
        //     var reader = new FileReader();
        //     reader.readAsDataURL(event.target.files[0]); 
        //     reader.onloadend = ()=> {
        //         var base64data = reader.result;  
        //         this.setState({ image_url: base64data }); 
        //         document.getElementById('remove_one').style.display = 'block';
        //     }
            
        // }else{
        //     let errList = this.state.errors;
        //     errList[event.target.name] = 'Invalid file format. Only "jpg, jpeg, png, gif" files are supported.';
        //     this.setState({ errors: errList }) 
        // }
   
}

onchangeImageRemoveOne = (event) =>{ 
  let companyLogo = this.state.image_url_old;
  this.setState({ image_url: companyLogo });  
  document.getElementById('remove_one').style.display = 'none';
} 


converthex = (data) =>{ 
  if(data.search("rgb")==-1){
    return data;
  }else{
    let t = data.replace('rgb(','').replace(')','');
    let m = t.split(", ");
    return "#" + this.componentToHex(parseInt(m[0])) + this.componentToHex(parseInt(m[1])) + this.componentToHex(parseInt(m[2]));
  }
}

 componentToHex = (c)=> {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

  render() {
    const { errors } = this.state;  
    return (
      <div style={{ width: "100%", maxWidth: '1280px', margin: '0 auto' }}>
        {this.state.loading && <div className="ui loading form"></div>}
      <button className="zyra--btn zyra--primary-gradient linear-gradient fw-bold padding-lg mt-4 d-flex align-items-center justify-content-center">
         
        <Link
          to={"/marketing/postcard"} 
          class="btn"
          style={{color:'#fff'}}
        >
          {" "}
          <i className="fa fa-chevron-left mr-2"> </i> Back to Templates
          settings
        </Link>
        </button>
      <div className="container-fluid" style={{maxWidth: '1200px'}}>
      <Message
              color={
                this.state.userToasterColor
                  ? this.state.userToasterColor
                  : "teal"
              }
              style={{
                display: this.state.userToaster
                  ? "block"
                  : "none",
                marginTop: 30,
              }}
            >
              {this.state.userToasterMsg}
            </Message>
          <div className="row">
              <div className="col-md-8">
                  <div className="mb-5" >
                      <label className="mb-1">Front of Postcard</label>
                      {this.state.htmlContent && this.state.htmlContent!=''?(
                      // <div id='apendHtml'  style={{transform: "scale(.42) translate(-72%, -65%)",marginBottom: "-100%"}}  className='reset-this' dangerouslySetInnerHTML={{__html: this.state.htmlContent}}></div>
                      <iframe id="iframe"  style={{position:'relative',
                      width: '1875px',
                      height: '1275px',
                      fontFamily: 'sans-serif',
                      transform: 'scale(.42) translate(-70%, -65%)',
                      marginBottom: '-93%',
                      border: 'none'
                      }}></iframe>
                      ):(
                      <img src={this.state.image_url}    className="d-block mt-2" style={{width: '100%', maxHeight: '700px'}} alt="" />
                      )}
                  </div>
                  
              </div>
              <div className="col-md-4">
                  <p className="mt-4">Please click on different sections to update the text elements as you wish</p>
                  <div className="my-3">
                    {this.state.htmlContent!=''?(
                      <div style={{maxHeight: '505px', overflow: 'auto', marginBottom: '3rem'}}>   
                       { displayDataImg.length>0 &&  displayDataImg.map((data,key)=>{
                         return(<div key={key}>
                                  <div>
                                  <label>Image</label>
                                  
                                    {this.state.src[key] ?
                                      <ReactCrop 
                                        ref={`imageCropCl_${key}`}
                                        src={this.state.src[key]}
                                        crop={this.state[`crop${key}`]}
                                        ruleOfThirds
                                        onImageLoaded={(c) => this.onImageLoaded(c, key)}
                                        onComplete={ (c) => this.onCropComplete(c, key)}
                                        onChange={(c) => this.onCropChange(c, key)}
                                        className={`imageCropCl_${key}`}
                                        // imageStyle={{height: '250px', width: '100%'}}
                                        // style={{width: '100%'}}
                                      />
                                      :  
                                      <div className="logo-img">
                                        <img src={data=='' ? "assets/media/logos/upload-bg.png" : data } id={'image_'+key}  alt="" /> 
                                      </div>
                                    }
                                  
                                  <div className="d-flex justify-content-center">
                                    <div className="upload-btn-wrapper">
                                        <button className="btn linear-gradient zy-save-btn width-100">Edit</button>
                                        <input type="file"
                                         name={'image_'+key} 
                                         onChange={(e) => this.onchangeImage(e,key)}
                                         accept="image/jpg,image/png,image/jpeg,image/gif"/>
                                    </div>
                                        <button type="reset" style={{display:'none'}} className="btn linear-gradient zy-cancel-btn ml-2" id={'remove_'+key} onClick={(e) => this.onchangeImageRemove(e,key)} >Remove</button>
                                  </div>
                                    {errors && errors.hasOwnProperty('image_'+key) && (
                                        <div style={{color:'red'}}>Invalid file format. Only "jpg, jpeg, png, gif" files are supported.</div>
                                    )}
                                  </div>
                            </div>)
                       })}
                       { displayDataColor.length>0 &&  displayDataColor.map((data,key)=>{
                         return(<div key={key}>
                              <div className="my-3">
                                    <small>Color </small>
                                   <div className="d-flex border pe-2 zyra--light color-input">
                                   <InputColorField  name={'color_'+key} value={this.converthex(data)} id={'color_'+key} onchangeColor={this.onchangeColor}/>

                                        {/* <input type="color" name={'color_'+key} id={'color_'+key} className="form-control w-75 h-100" 
                                        value={data}
                                        onChange={(e) => this.onchangeColor(e,key)}
                                         />
                                        <span>{data}</span> */}
                                    </div>
                               </div>
                            </div>)
                       })}
                       {/* <div className="my-3">
                            <small>Text Colour </small>
                            <div className="d-flex border pe-2 zyra--light color-input">
                                <input type="color" name='text_color' className="form-control w-75 h-100" 
                                value={data}
                                onChange={(e) => this.onchangeColor(e)}
                                  />
                                <span>{data}</span>
                            </div>
                        </div> */}
                       { displayDataText.length>0 &&  displayDataText.map((data,key)=>{
                         return(<div key={key}>
                                <div className="mb-3">
                                  <textarea className="form-control form-control-lg border-gray rounded-30 zyra-fs-14" rows="4" 
                                    name={'text_'+key}
                                    onChange={(e) => this.onchangeText(e,key)}
                                    >{data.text}</textarea> 
                              </div>


                              <div className="my-3">
                                    <small>Color </small>
                                   <div className="d-flex border pe-2 zyra--light color-input">
                                   <InputColorField  name={'test_color_'+key} value={data.color?this.converthex(data.color):"#FFFFFF"} id={'test_color_'+key} onchangeColor={this.onchangeColorText}/>

                                        {/* <input type="color" name={'color_'+key} id={'color_'+key} className="form-control w-75 h-100" 
                                        value={data}
                                        onChange={(e) => this.onchangeColor(e,key)}
                                         />
                                        <span>{data}</span> */}
                                    </div>
                               </div>

                            </div>)
                       })} 
                      </div>
                    ):(
                      <div style={{maxHeight: '556px', overflow: 'auto', marginBottom: '3rem'}}>
                        <label>Image</label>
                        <div className="logo-img">
                          <img src={this.state.image_url}   alt="" />
                        </div>

                        <div className="d-flex justify-content-center">
                          <div className="upload-btn-wrapper">
                              <button className="btn linear-gradient zy-save-btn width-100">Edit</button>
                              <input type="file"
                                name='image_url' 
                                onChange={(e) => this.onchangeImageOne(e)}
                                accept="image/jpg,image/png,image/jpeg,image/gif"/>
                          </div>
                              <button type="reset" style={{display:'none'}} className="btn linear-gradient zy-cancel-btn ml-2" id={'remove_one'} onClick={(e) => this.onchangeImageRemoveOne(e)} >Remove</button>
                        </div>
                          {errors && errors.hasOwnProperty('image_url') && (
                              <div style={{color:'red'}}>{errors['image_url']}</div>
                          )}
                        </div>
                      
                    )} 
                    

                      
                  </div>
              </div>
          </div>
          <div className="row">
            <div className="col-md-8">  
              <label className="mb-4">Back of Postcard</label>
              <div className="border p-4" style={{width: '100%', height:''}}>
                  <div className="row justify-content-between">
                      <div className="col-md-12">
                          <img src={this.state.pbackurl}  className="d-block my-2" style={{width: '95%'}} alt="" />
                          {/* <pre style={{whiteSpace: 'pre-wrap'}}>{this.state.message}</pre> */}
                      </div>
                      <div className="col-md-4"></div>
                  </div>
              </div>  
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <div className="mb-3">
                  <label>Your Message</label>
                  <textarea className="form-control form-control-lg border-gray rounded-30 zyra-fs-14" rows="6" 
                  name='message'
                  value={this.state.message}
                  onChange={(e) => this.onchange(e)}
                  >
                  </textarea>
                  {errors && errors.hasOwnProperty('message') && (
                            <div className="red">{errors['message']}</div>
                        )}
                </div>
                <div className="mb-3">
                  <label>Choose Handwriting Style</label>
                  <select name='style' value={this.state.style} 
                  className="custom-select border-gray rounded-30 zyra-fs-14"
                  onChange={(e) => this.onchange(e)}
                  >
                          {this.state.defaultStyle!='' && this.state.defaultStyle.data.map((data,key) =>
                              <option key={key} value={data.handwriting_style_id}>{data.name}</option> 
                          )}
                        </select>                     
                  </div>
              <div className="mb-3">
                  <label>Choose Handwriting Ink Color</label>
                  <select name='colour' value={this.state.colour} 
                  className="custom-select border-gray rounded-30 zyra-fs-14"
                  onChange={(e) => this.onchange(e)}
                  >
                          <option key='1' value='#000000'>Black</option>
                          <option key='2' value='#1f0071'>Blue</option>
                          <option key='3' value='#0DB12C'>Green</option>
                          <option key='4' value='#900DB1'>Purple</option>
                          <option key='5' value='#F93E0B'>Red</option>
                        </select>                      
              </div>
              <div className="mb-3">
                  <label>QR Code Link</label>
                  <input type="text" name='qrcode' className="form-control border-gray rounded-30 zyra-fs-14" 
                  value={this.state.qrcode} 
                  onChange={(e) => this.onchange(e)}
                  ></input>
              </div>

              <button 
              onClick={(e) => this.updateBackPostcard(e)}
              className="zyra--primary-gradient linear-gradient zyra--btn radius-30 border-gray fs-btn px-4">Update Handwriting</button>
              </div>
            </div>
          </div>
      </div>
      <button 
      onClick={(e) => this.savePostcard(e)}
      className="float-right zyra--btn zyra--primary-gradient fw-bold shadow linear-gradient mt-3 d-flex align-items-center justify-content-center padding-lg">Save Template</button>
    
     </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(EditPostcard);
