import axios from "axios";
import config from "./index";
import util from "../util";
//https://cors-anywhere.herokuapp.com/" +
const instance = axios.create({
  baseURL: config.apiBaseURL,
});

instance.interceptors.request.use(
  (configurations) => {
    let tokenInfo = util.getAuthTokens();

    if (tokenInfo.access_token)
      configurations.headers[config.access_token_name] = tokenInfo.access_token;

    if (tokenInfo.refresh_token)
      configurations.headers[config.refresh_token_name] =
        tokenInfo.refresh_token;

    return configurations;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    const respData = response.data.data ? response.data.data : null;
    if (
      response.status === 200 &&
      respData &&
      respData.access_token_expired &&
      respData.new_access_token
    ) {
      util.setAccessToken(respData.new_access_token);

      response.config.headers.access_token = JSON.stringify(
        respData.new_access_token
      );

      return instance.request(response.config);
    }

    return response;
  },
  (error) => {
    const erroResp =
      error.response && error.response.data ? error.response.data.data : null;
    if (
      error.response &&
      error.response.status === 401 &&
      erroResp &&
      erroResp.refresh_token_expired
    ) {
      window.location.href = config.authLogoutUrl;
    }
  }
);

export default instance;
