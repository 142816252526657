import React from "react";
import { Table } from "semantic-ui-react";
import PropTypes from "prop-types";
export const DatatableRow = (props) => (

  <Table.Row>
    <Table.Cell>
      {props.list.user_type != "main" ? (<>
        <span>
          <a
            onClick={() => props.deleteCompletedChat(props.list.id)}
            title="Delete"
            className="btn btn-icon gray"
          >
            <i className="la la-trash"></i>
          </a>{" "}
        </span>
        <span>
          <a
            onClick={() => props.editMember(props.list.id)}
            title="Edit"
            className="btn btn-icon gray"
          >
            <i className="la la-edit blue"></i>
          </a>{" "}
        </span>
      </>
      ) : (
        ""
      )}
    </Table.Cell>
    <Table.Cell>
       {props.list.notification_type == 1 ? "All notifications" : "Schedule based"}
    </Table.Cell>
    <Table.Cell>
      {props.list.name}
    </Table.Cell>
    <Table.Cell>
      {props.list.emailphone}
    </Table.Cell>
    <Table.Cell key={props.list?.id}>
      <div className="" style={{ display: 'flex', columnGap: 10 }}>
        <span>
          <input 
            disabled={!props.list.notify_via.includes('email') && (!props.list.notify_via.length && props.list.email == '')} 
            defaultChecked={props.list.notify_via.includes('email') || (!props.list.notify_via.length && props.list.email != '')} 
            checked={props.list.notify_via.includes('email') || (!props.list.notify_via.length && props.list.email != '')} 
            type="radio" 
            name={`email_notifyvia_${props.list.id}`} 
            value={'email'} 
          /> Email
        </span>
        <span>
          <input 
            disabled={!props.list.notify_via.includes('text') && (!props.list.notify_via.length && props.list.phone == '')} 
            defaultChecked={props.list.notify_via.includes('text') || (!props.list.notify_via.length && props.list.phone != '')} 
            checked={props.list.notify_via.includes('text') || (!props.list.notify_via.length && props.list.phone != '')} 
            type="radio" 
            name={`text_notifyvia_${props.list.id}`} 
            value={'text'} /> Text
        </span>
        <span>
          <input disabled={!props.list.notify_via.includes('phonecall')} defaultChecked={props.list.notify_via.includes('phonecall')} checked={props.list.notify_via.includes('phonecall')} type="radio" name={`phonecall_notifyvia_${props.list.id}`} value={'phonecall'} /> Phone Call
        </span>
      </div>
    </Table.Cell>

  </Table.Row>
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
