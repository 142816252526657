import React from "react";
import { connect } from "react-redux";
import {
	Form,Dropdown,TextArea, Input
} from 'formsy-semantic-ui-react';
import { Message } from "semantic-ui-react";
import utilFunc from "../../../util_funs";
import {
    setPageTitle,
    toggleNavigation,
    setMessage,
    setSubHeaderTitle,
    setNavigationName,
    toggleBreadcrum
} from "../../../store/Page";
import {Link} from "react-router-dom";
import api from "../../../api";
const queryString = require("query-string");
const options_type = [
    {text: '1 Start', value: '1' },
    {text: '2 Start', value: '2' },
    {text: '3 Start', value: '3' },
    {text: '4 Start', value: '4' },
    {text: '5 Start', value: '5' },
  ]    
class ReviewForm extends React.Component{
constructor(props){
super(props);
this.state = {
    textToaster: false,
    textToasterMsg: '',
    textToasterColor: '',
  };
}
onValidSubmit= (e) =>{
   const sendData  = {"text":e.text,"rating":e.type,'request_id':e.request_id};
    api.reviews.addCustomerReview(sendData).then(data => {
    this.showToasterTextTempl('green', 'Review has been sent successfully.');
    document.getElementById("reviewForm").reset();
    });
}

showToasterTextTempl = (color, msg) => {
    this.setState({
      textToaster: true,
      textToasterMsg: msg,
      textToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ textToaster: false });
      }.bind(this),
      3000
    );
  };

componentDidMount(){
    const {
        setPageTitle,
        toggleNavigation,
        location,
        PageMessage,
        setMessage,
        setSubHeaderTitle,
        setNavigationName,
        toggleBreadcrum

      } = this.props;
      setPageTitle({ title:"Request A Review" ,subHeaderTitle:'Review Management'});
      setSubHeaderTitle({subHeaderTitle:"Review Management" });
      setNavigationName({navigationName:"reviewManagement"})
      toggleNavigation({ toggle: true });
      toggleBreadcrum({ toggle: false });
    }
render(){
    const errorLabel = <div className="red"/>
    return(
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className ="kt-container kt-body  kt-grid kt-grid--ver"> 
        <div className="kt-content kt-grid__item kt-grid__item--fluid">
            <div className="yr-body-p">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
               
                    <div className="kt-portlet">
                        <div className="kt-portlet__body py-0 request-a-review yr-pd0-lr">
                            <div className="row">
                                <div className="col-lg-6 pt-5 pb-5">
                                    <h3 className="title2">Templates</h3>
                                    <Form  onValidSubmit={this.onValidSubmit} id ="reviewForm">
                                    <div className="kt-form p-lg-5">
                                    <Message
                          color={
                            this.state.textToasterColor
                              ? this.state.textToasterColor
                              : "green"
                          }
                          style={{
                            display: this.state.textToaster ? "block" : "none",
                            marginTop: 30,
                          }}
                        >
                          {this.state.textToasterMsg}
                        </Message>
                                    <div className="form-group row">
                                            <label className="col-lg-3 col-form-label bold">Send review via:</label>
                                            <div className="col-lg-8">
                                                <Dropdown
                                                name="type"
                                                placeholder="Select"
                                                selection
                                                validations={{
                                                customValidation: (values, value) => !(!value || value.length < 1), 
                                                }}
                                                validationErrors={{
                                                customValidation: 'Please select atleast one.',
                                                }}
                                                errorLabel={errorLabel}
                                                options={options_type}
                                                />                          												
                                            </div>																	
                                        </div>
                                        <div className="form-group">
                                            <label className="bold pb-2">Add Review</label>
                                            <Form.TextArea rows="10"  name="text" validations='isRequired' validationErrors={{ isRequired: 'This field is required.' }} errorLabel={errorLabel} >
                                                </Form.TextArea>
                                        </div>
                                        <Input name="request_id" type="hidden" value={this.props.match.params.id} />
                                        <div className="row">
                                            <div className="col-lg-12 d-flex justify-content-end pt-4">
                                                <button type ="submit" className="btn linear-gradient zy-save-btn">Send Review</button>
                                            </div>
                                        </div>
                                        </div>
                                    </Form>	
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    </div>
    );
}


}
const mapStateToProps = (state) => {
    return {
      PageMessage: state.page.message,
      user: state.user.company,
    };
  };
export default connect(mapStateToProps,{
    setPageTitle,
    toggleNavigation,
    setMessage,
    setSubHeaderTitle,
    setNavigationName,
    toggleBreadcrum
  })(ReviewForm);




