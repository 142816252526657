import axios from "../../config/axios";

const widget = {
  needHelp: (formData) =>
    axios
      .post("/integration/widget/needHelp", formData)
      .then((resp) => resp.data),
};

export default widget;
