import React from "react";
import CustomErrorField from "../../../../Form/CustomErrorField";

class TranscriptRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      questions: [""],
      errors: [],
      transscript_rule: '',
      trans_script_rule_button: '',
    };
  }

  componentDidMount() {
    this.onLoad();
  }

  componentDidUpdate(prevProps) {
    if (this.props.button_action_id != prevProps.button_action_id) {
      this.onLoad();
    }
  }

  onLoad = () => {

    this.setState({ transscript_rule: this.props.branchDetails.details.transscript_rule, trans_script_rule_button: this.props.branchDetails.details.trans_script_rule_button })

  };

  changeTranscriptRule = (e, tab) => {
    if (tab == 'rule') {
      if(e.target.value==1){
        this.setState({ transscript_rule: e.target.value, errors: [],trans_script_rule_button:'' })
      }else{
        this.setState({ transscript_rule: e.target.value, errors: [] })
      }
      
    }
    if (tab == 'button_rule') {
      this.setState({ trans_script_rule_button: e.target.value })
    }
  }

  saveTranscriptRule = () => {
    let error = [];
    if (this.state.transscript_rule == '') {
      error[0] = 'Please select at least once'
    }
    if (this.state.transscript_rule == 2 && this.state.trans_script_rule_button == '') {
      error[1] = 'This field is required';
    }
    if (error.length > 0) {
      this.setState({ errors: error })
    } else {
      //code for save
      this.props.saveButton({transscript_rule:this.state.transscript_rule,trans_script_rule_button:this.state.trans_script_rule_button},'transcriptRule');
    }

  }
  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="yr-chat-round-box yr-closebtn">
          <button
            type="button"
            class="close linear-gradient"
            onClick={() => this.props.popupClose()}
          >
            &#10006;
          </button>
          <div className="box-title">Add Transcript Rule</div>
          {this.state.loading && <div className="ui loading form"></div>}


          <div className="reveiew_tab_pane">
            <p></p>
            <div className="row">
              <div className="col-md-12 col-xs-12 nextemaillinkradio">
                <label class="kt-radio kt-radio--single chat-checkbox checboxrules"><input type="radio" name="next_email_rules" value="1" onClick={(e) => this.changeTranscriptRule(e, 'rule')} checked={this.state.transscript_rule==1 ?'checked':''}/><span></span></label>
                <span className="review_tb_content">Automatically send all users a transcript email.</span>


              </div>

            </div>
            <div className="row" style={{ paddingTop: "10px" }}>

              { this.props.bot_type !=="GDF" && <div className="col-md-12 col-xs-12 nextemaillinkradio" style={{ display: "flex" }}>
                <label class="kt-radio kt-radio--single chat-checkbox checboxrules"><input type="radio" name="next_email_rules" value="2" onClick={(e) => this.changeTranscriptRule(e, 'rule')} checked={this.state.transscript_rule==2 ?'checked':''}/><span></span></label>
                <span class="review_tb_content">Button will appear on the chat where user can click to receive email transcript. </span>
              </div>}
              <div className={
                errors.length > 0 && typeof errors[0] != 'undefined'
                  ? "form-control is-invalid focusError"
                  : "form-control focusError"} style={{ display: "none" }}>  </div>
              {errors.length > 0 && typeof errors[0] != 'undefined' && (

                <CustomErrorField
                  message={errors[0]}
                  className="error invalid-feedback "
                />

              )}
              {this.state.transscript_rule == 2 &&
                <div className="col-md-12 col-xs-12 textrule">
                  <input  type="text" name="trans_script_rule_button" value={this.state.trans_script_rule_button} id="trans_script_rule_button"
                    className={
                      errors.length > 0 && typeof errors[1] != 'undefined'
                        ? "form-control is-invalid focusError"
                        : "form-control focusError"}
                    onChange={(e) => this.changeTranscriptRule(e, 'button_rule')} style={{ width: "50%" }} />
                  {errors.length > 0 && typeof errors[1] != 'undefined' && (
                    <CustomErrorField
                      message={errors[1]}
                      className="error invalid-feedback "
                    />
                  )}
                </div>
              }



            </div>
          </div>
          {/* <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button type="reset" className="btn linear-gradient-cancel yr-cancelbtn mg-r"><span> Close </span></button>
                        <button type="submit" className="btn linear-gradient yr-submitbtn">Save</button>
                    </div>
                </div>             */}
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <button
              type="reset"
              class="btn yr-cancelbtn linear-gradient-cancel mg-r"
              onClick={() => this.props.popupClose()}
            >
              <span> Close </span>
            </button>
            <button
              type="submit"
              className="btn linear-gradient yr-submitbtn"
              onClick={() => this.saveTranscriptRule()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default TranscriptRule;
