import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
	name: "user",
	initialState: {
		company: null,
	},
	reducers: {
		setUserLoggedIn: (state, action) => {
			if (action.payload.user) {
				state.company = action.payload.user;
			}
		},
		setUserLoggedOut: (state, action) => {
			state.company = action.payload.user;
		},
	},
});

export const {
	setUserLoggedIn,
	setUserLoggedOut,
	setUserToken,
} = userSlice.actions;

export default userSlice.reducer;
