import React, { useState, useEffect, useRef } from 'react';
import GridList from "../Grid";
import api from "../../api";
import { Segment, Message, Flag } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import ConfirmDialog from "../Modal/ConfirmDialog";
import validationRule from "../../validation";
import Switch from "react-switch";
import utilFunc from "../../util_funs";

validationRule.isRequired();

const ActCrm = () => {

    const formRef = useRef();

    const [defaultState, setDefaultState] = useState({
        location: 0,
        loading: false,
        locationLists: [],
        deafultLocation: false,
        modal: false,
        successMessage: null,
        errorMessage: null,
        dataLoading: null,
        actcrmid: null,
        selectedDrodown: 0,
        disableDefaultLocation: false,
        // formLoading: false,
        // refreshGrid: false
    });

    const [refreshGrid, setrefreshGrid] = useState(false)

    const [actinfo, setActInfo] = useState({});

    const [confirmDialog, setConfirmDialog] = useState(false);
    const [model, setModel] = useState(false);

    // column

    const columnLists = [
        { text: "S.No", key: "id", sort: false },
        { text: "Location", key: "loc.location" },
        { text: "App URL", key: "IN.api_url" },
        { text: "Username", key: "IN.username" },
        // { text: "Password", key: "IN.password" },
        { text: "Basic Token", key: "IN.basic_token" },
        { text: "Database", key: "IN.database_name" },
        { text: "Last Modified Date", key: "IN.created_date" },
        {
            text: "Action",
            key: "action",
            sort: false,
            event: [
                {
                    key: "editCredentials",
                    params: ["actid"],
                    func: (actid) =>
                        editCredentials(actid),
                },
                {
                    key: "deleteCredentials",
                    params: ["actid"],
                    func: (actid) => confirmAction(actid),
                },
            ],
        },
    ];

    useEffect(() => {
        getDataSource();
    }, [])

    // data list 
    const getDataSource = (params) => api.actcrm.list(params);

    const confirmAction = (actId) => {
        setDefaultState({
            ...defaultState,
            actcrmid: actId,
            loading: true
        });
        setConfirmDialog(true);
    }

    // delete credentials
    const deleteCredentials = () => {
        if (defaultState.actcrmid > 0) {
            setConfirmDialog(false);
            api.actcrm
                .deleteCredential({ actcrmid: defaultState.actcrmid })
                .then((resp) => {
                    console.log("result ---", resp);
                    if (resp && resp.message) {
                        setrefreshGrid(true);
                        setDefaultState({
                            ...defaultState,
                            successMessage: resp.message,
                            loading: false,
                            actcrmid: 0,
                        });
                        utilFunc.scrollTop(800);
                        setrefreshGrid(false);
                        setTimeout(() => {
                            setDefaultState({
                                ...defaultState,
                                successMessage: null,
                                loading: false,
                            });
                        }, 4000);
                    } else if (resp.errorMessage) {
                        setDefaultState({
                            ...defaultState,
                            errorMessage: resp.errorMessage,
                            loading: false,
                            actcrmid: 0,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setDefaultState({
                        ...defaultState,
                        loading: false,
                        actcrmid: 0,
                    });
                });
        }
    };

    // edit act credentials
    const editCredentials = (actid) => {
        if (actid) {
            api.actcrm.getActCredentialInfo({ actid }).then((resp) => {
                if (resp && resp.actCrmInfo) {
                    const { actCrmInfo } = resp;
                    setDefaultState({
                        ...defaultState,
                        selectedDrodown: actCrmInfo.location_id
                    })
                    setActInfo(actCrmInfo);
                    createNew();
                }
            });
        }
    };

    const createNew = () => {
        setDefaultState({
            ...defaultState,
            loading: true,
        });
        // setModel(true);
        api.actcrm
            .locationList()
            .then((resp) => {
                if (resp && resp.locationList && resp.locationList.length > 0) {
                    setDefaultState({
                        ...defaultState,
                        loading: false,
                        locationLists: resp.locationList,
                        disableDefaultLocation: resp.haveDeafultLocation ? true : false,

                    });
                    setModel(true);
                } else {
                    setModel(true);
                }
            })
            .catch((err) => {
                setDefaultState({
                    ...defaultState,
                    loading: false,
                });
            });
    };


    // toggle model

    const toggleConfirmModal = () => {
        setDefaultState({
            ...defaultState,
            // confirmDialog: false,
            actcrmid: 0,
            loading: false,
        })

        setConfirmDialog(false);
    }


    // toggel model

    const toggleModal = () => {
        setDefaultState({
            ...defaultState,
            selectedDrodown: 0,
            loading: false,
        })
        setModel(!model);
        setActInfo({});
    }

    // submit form 

    const submitForm = () => {
        formRef.current.submit();
    }

    // form handling

    // component for add act credential form

    const AddCredential = ({ defautlInfo }) => {

        const [formLoading, setFormLoading] = useState(false);
        const [dbError, setdbError] = useState('');

        // state for form data
        const [formData, setFormData] = useState({
            selectedLocation: defautlInfo ? defautlInfo.location_id : '0',
            appurl: defautlInfo ? defautlInfo.api_url : '',
            username: defautlInfo ? defautlInfo.username : '',
            password: defautlInfo ? defautlInfo.password : '',
            databaseName: defautlInfo ? defautlInfo.database_name : '',
            deafultLocationSelected: defautlInfo && defautlInfo.is_default > 0 ? true : false,
            actCrmId: defautlInfo ? defautlInfo.id : '0',
            app_type: defautlInfo ? defautlInfo.app_type : '',
        })

        // onchange handler for form inout field
        const handleFieldChange = (e) => {
            setdbError('');
            if (e.target.name === 'app_type') {
                if (e.target.checked) {
                    setFormData({
                        ...formData,
                        [e.target.name]: e.target.value
                    })
                } else {
                    setFormData({
                        ...formData,
                        [e.target.name]: ''
                    })
                }
            } else {
                setFormData({
                    ...formData,
                    [e.target.name]: e.target.value
                })
            }

        }



        // handle default switch button 

        const handleSwitch = (checked) => {
            setDefaultState({
                ...defaultState,
                deafultLocation: checked
            })
        }

        const getDataBase = async () => {
            if (formData.app_type) {
                if (formData.username && formData.password) {
                    setFormLoading(true);
                    const data = {
                        username: formData.username,
                        password: formData.password
                    };
                    api.actcrm
                        .getDatabase({
                            data
                        })
                        .then((res) => {
                            
                            if (res && res.status === 'True') {
                                const db = JSON.parse(res.data);
                                console.log(db);
                                if (db.length === 1) {
                                    setFormData({
                                        ...formData,
                                        databaseName : db[0]
                                    });
                                }
                            }else{
                                setdbError(res.data);
                            }
                            setFormLoading(false);
                        })
                        .catch((err) => {
                            console.log(err);
                            setFormLoading(false);
                        })
                } else {
                    setFormLoading(false);
                }
            }
        }

        // handle submmiting

        const handleSubmit = () => {
            const data = formData;
            data.deafultLocationSelected = defaultState.deafultLocation ? 1 : 0;
            data.selectedLocation = data.selectedLocation ? data.selectedLocation : 0;
            data.actCrmId = data.actCrmId ? data.actCrmId : 0;
            const url = data.appurl;
            let checkWebApi = [];
            if (url) {
                let urlParts = /^(?:\w+\:\/\/)?([^\/]+)([^\?]*)\??(.*)$/.exec(url);
                console.log(urlParts);
                checkWebApi = urlParts.filter((v, i) => v.includes('act.web.api'));
            }

            if (data.app_type || checkWebApi.length > 0) {
                setFormLoading(true);
                api.actcrm
                    .createNew({
                        data
                    })
                    .then((resp) => {
                        if (resp && resp.message) {
                            setrefreshGrid(true);
                            setDefaultState({
                                ...defaultState,
                                successMessage: resp.message,
                                actcrmid: 0,
                                loading: false
                            });
                            setFormLoading(false);
                            setModel(false);
                            setActInfo({});
                            setrefreshGrid(false);
                            setTimeout(() => {
                                setDefaultState({
                                    ...defaultState,
                                    loading: false,
                                    successMessage: null,
                                });
                            }, 4000);
                        } else if (resp.errorMessage) {

                            setDefaultState({
                                ...defaultState,
                                errorMessage: resp.errorMessage,
                                actcrmid: 0,
                                loading: false
                            });
                            setModel(false);
                            setFormLoading(false);
                            setActInfo({});
                            setTimeout(() => {
                                setDefaultState({
                                    ...defaultState,
                                    loading: false,
                                    errorMessage: null,
                                });
                            }, 4000);
                        }
                    })
                    .catch((err) => {
                        setDefaultState({
                            ...defaultState,
                            actcrmid: 0,
                            loading: false
                        });
                        setTimeout(() => {
                            setModel(false);
                        }, 500);
                        setActInfo({});
                        console.log(err);
                    });
            } else {
                alert('Invalid Act! App url.');
                return false;
            }

        }

        const errorLabel = <div className="red" pointing={"true"} />;
        return (
            <div
                className={model ? "modal fade show " : "modal fade"}
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="staticBackdrop"
                aria-hidden="true"
                style={{ display: model ? "block" : "none" }}
            >
                {formLoading && <div className="ui loading form"></div>}
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close linear-gradient"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={toggleModal}
                        >
                            X
                        </button>
                        <div className="modal-header">
                            <h5 className="modal-title">{"Act!"}</h5>
                        </div>
                        <div className="modal-body">

                            <Form
                                noValidate
                                autoComplete="off"
                                onValidSubmit={handleSubmit}
                                className="kt-form kt-form--label-right"
                                ref={formRef}
                                style={{ margin: "10px 10px 0 0" }}
                            // loading={formLoading}
                            >
                                <div>

                                </div>
                                <div className="form-group row my-act-checkbox">
                                    <div className="col-lg-11">
                                        <label className="kt-checkbox inalign">
                                            <input type="checkbox" name='app_type' value='myact' defaultChecked={formData.app_type === 'myact' ? true : false} onChange={handleFieldChange} />  For Cloud Version  - If you have access to your Act! CRM in the Cloud database by using https://my.act.com <span />
                                        </label>
                                    </div>
                                </div>
                                {/* {defaultState.locationLists.length > 0 && (
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label" style={{ margin: 0 }}>Location : </label>
                                        <div className="col-lg-9">
                                            <div className="field">
                                                <div className="ui input">
                                                    <select
                                                        className="form-control"
                                                        name="selectedLocation"
                                                        onChange={handleFieldChange}
                                                        defaultValue={formData.selectedLocation}
                                                        value={formData.selectedLocation}
                                                    >
                                                        {defaultState.locationLists && defaultState.locationLists.map((opt, optIndex) => (
                                                            <option
                                                                key={optIndex}
                                                                disabled={
                                                                    parseInt(opt.actcrm_id) > 0 && defaultState.selectedDrodown !== opt.key ? true : false
                                                                }
                                                                style={{
                                                                    background: parseInt(opt.actcrm_id) > 0 && defaultState.selectedDrodown !== opt.key ? "#cccaca" : "",
                                                                }}
                                                                value={opt.key}
                                                                selected={defaultState.selectedDrodown === opt.key ? true : false}
                                                            >
                                                                {opt.text}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )} */}
                                {formData.app_type !== 'myact' &&
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label">App URL :</label>
                                        <div className="col-lg-9">
                                            <Form.Input
                                                type="text"
                                                autoComplete="off"
                                                name="appurl"
                                                placeholder="App URL"
                                                required
                                                value={formData.appurl}
                                                onChange={handleFieldChange}
                                                validations="isUrl"
                                                validationErrors={{
                                                    isUrl: 'Please enter valid url.',
                                                    isDefaultRequiredValue: 'This field is required.',
                                                }}
                                                errorLabel={errorLabel}
                                            />
                                            <i style={{ color: "red" }}> Example: &nbsp; https://10350.g2c.cloud-elements.com/act.web.api</i>
                                        </div>
                                    </div>
                                }
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Username</label>
                                    <div className="col-lg-9">
                                        <Form.Input
                                            type="text"
                                            autoComplete="off"
                                            name="username"
                                            value={formData.username}
                                            placeholder="Username"
                                            onChange={handleFieldChange}
                                            validations="isRequired"
                                            validationErrors={{ isRequired: "This field is required.", }}
                                            errorLabel={errorLabel}
                                            onBlur={getDataBase}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Password</label>
                                    <div className="col-lg-9">
                                        <Form.Input
                                            type="password"
                                            autoComplete="off"
                                            name="password"
                                            value={formData.password}
                                            placeholder="Password"
                                            onChange={handleFieldChange}
                                            validations="isRequired"
                                            validationErrors={{ isRequired: "This field is required.", }}
                                            errorLabel={errorLabel}
                                            onBlur={getDataBase}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Database Name</label>
                                    <div className="col-lg-9">
                                        <Form.Input
                                            type="text"
                                            autoComplete="off"
                                            name="databaseName"
                                            value={formData.databaseName}
                                            placeholder="Database Name"
                                            onChange={handleFieldChange}
                                            validations="isRequired"
                                            validationErrors={{ isRequired: "This field is required.", }}
                                            errorLabel={errorLabel}
                                        />
                                        {dbError && <p className='text-warning'>{dbError}</p>}
                                        
                                    </div>
                                </div>
                                {/* {defaultState.locationLists.length > 0 && (
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label">
                                            Include the location data for default keap account?
                                        </label>
                                        <div className="col-lg-9" style={{ paddingTop: "30px" }}>
                                            <Switch
                                                label="Include the location data for default keap account?"
                                                onChange={handleSwitch}
                                                checked={defaultState.deafultLocation}
                                                disabled={defaultState.disableDefaultLocation}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                margin={20}
                                                className="react-switch"
                                                id="material-switch"
                                            />
                                        </div>
                                    </div>
                                )} */}
                            </Form>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={submitForm}
                                type="button"
                                className="btn linear-gradient yr-submitbtn btn-sm"
                                disabled={formLoading}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="kt-portlet__body kt-portlet__body--fit">
                    {model && <AddCredential defautlInfo={actinfo} />}
                    {defaultState.successMessage && (
                        <Message color={"green"}>{defaultState.successMessage}</Message>
                    )}
                    {defaultState.errorMessage && (
                        <Message color={"red"}>{defaultState.errorMessage}</Message>
                    )}
                    {defaultState.loading && <div className="ui loading form"></div>}

                    {confirmDialog && (
                        <ConfirmDialog
                            show={confirmDialog}
                            toggleModal={deleteCredentials}
                            closeAction={toggleConfirmModal}
                            title="Confirm your action"
                            message={"Do you really want to delete this credential ?"}
                        />
                    )}
                    <div className="yr-manageUser-table">
                        <Segment>
                            <div className="keep-integration act--integration">
                                <GridList
                                    dataSource={(params) => getDataSource(params)}
                                    dataColumns={columnLists}
                                    refreshGrid={refreshGrid}
                                >
                                    <div className="col-md-8 kt-margin-b-20-tablet-and-mobile">
                                        <button
                                            style={{ marging: "0 0 15px 0", float: "right" }}
                                            type="button"
                                            className="btn linear-gradient yr-submitbtn"
                                            onClick={createNew}
                                        >
                                            Create New
                                        </button>
                                    </div>
                                </GridList>
                            </div>
                        </Segment>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ActCrm;
