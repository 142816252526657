import React from 'react'

const ViewArchiveChat = (props) => {
    
  return (
    <div>
        <div  dangerouslySetInnerHTML={{__html:props.data}}  className="newmodalscroll cplt_chat"></div>
    </div>
  )
}

export default ViewArchiveChat