import React from "react";
import { Link, withRouter } from "react-router-dom";

class WorkspaceNavigation extends React.Component {
  render() {
    const { location } = this.props;
    return (
      <div className="kt-header__bottom">
        <div className="kt-container" style={{ backgroundColor: "#fff" }}>
          <div
            className="kt-header-menu-wrapper kt-grid__item kt-grid__item--fluid"
            id="kt_header_menu_wrapper"
          >
            <div
              id="kt_header_menu"
              className="kt-header-menu kt-header-menu-mobile "
            >
              <ul className="kt-menu__nav " style={{ padding: 22 }}>

                <li style={{ display: this.props.user.totalAvailableModules.includes("legacy_configuration") ? "" : "none" }}
                  className={`kt-menu__item ${location.pathname == "/automation"
                    ? "kt-menu__item--active linear-gradient-a"
                    : ""
                    }`}
                  aria-haspopup="true"
                >
                  <Link to="/automation" className="kt-menu__link ">
                    <span className="kt-menu__link-text">
                      <i className="fa fa-cog" aria-hidden="true"></i>
                      Legacy conversations
                    </span>
                  </Link>
                </li>

                <li style={{ display: (this.props.user.totalAvailableModules.includes("workspace")) ? "" : "none" }}
                  className={`kt-menu__item ${(location.pathname.includes("/botbuilder"))
                    ? "kt-menu__item--active linear-gradient-a"
                    : ""
                    }`}
                  aria-haspopup="true"
                >
                  <Link to="/botbuilder" className="kt-menu__link ">
                    <span className="kt-menu__link-text">
                      <i className="fa fa-building-o" aria-hidden="true"></i>
                      Workspaces
                    </span>
                  </Link>
                </li>

                {/* <li style={{display:this.props.user.totalAvailableModules.includes("intent_engine")?"":"none"}} 
                  className={`kt-menu__item ${
                    location.pathname == "/#"
                      ? "kt-menu__item--active"
                      : ""
                  }`}
                  aria-haspopup="true"
                >
                  <Link  className="kt-menu__link ">
                    <span className="kt-menu__link-text">
                      <i className="fa fa-building-o" aria-hidden="true"></i>
                      Intent Engine
                    </span>
                  </Link>
                </li> */}

              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(WorkspaceNavigation);
