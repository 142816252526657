import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
export const DatatableRow = props => (
  
  <Table.Row>
    <Table.Cell><a id={props.list.id} className='btn btn-icon' onClick={() => props.editTeam(props.list.id)} title='Edit'><i className='la la-edit blue'></i></a>
    <a onClick={() => props.deleteTeam(props.list.id)} title='Delete' className='btn btn-icon gray'><i className='la la-trash red'></i></a></Table.Cell>
<Table.Cell>{props.list.team_src ?  <img style = {{width:40,height:40,borderRadius:'100%'}} src = {props.userdata.assetPath+"/teamImg/"+props.list.team_src} /> : "" }</Table.Cell>
    <Table.Cell>{props.list.member_name}</Table.Cell>
    <Table.Cell>{props.list.created_date}</Table.Cell>
    <Table.Cell><span title={props.list.email}>{props.list.email}</span></Table.Cell>
    <Table.Cell>{props.list.phone}</Table.Cell>
    <Table.Cell>{props.list.device_key} <a onClick={() => props.ResendDeviceKey(props.list.id)} >Click to resend</a></Table.Cell>
    <Table.Cell className ="inline_text">{props.list.review_sent}</Table.Cell>
    <Table.Cell className ="inline_text">{props.list.review_received}</Table.Cell>
  </Table.Row>
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
