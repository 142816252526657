import React from "react";
import GridList from "../Grid";
import api from "../../api";
import { Segment, Message } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import ConfirmDialog from "../Modal/ConfirmDialog";
import validationRule from "../../validation";
import Switch from "react-switch";
import utilFunc from "../../util_funs";

validationRule.isRequired();

class SalesForce extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      location: 0,
      userName: "",
      password: "",
      token: "",
      locationLists: [],
      deafultLocation: false,
      modal: false,
      successMessage: null,
      errorMessage: null,
      dataLoading: null,
      salesForceId: null,
      selectedDrodown: 0,
      confirmDialog: false,
      disableDefaultLocation: false,
      formLoading: false,
    };
    this.formRef = React.createRef();
    this.columnLists = [
      { text: "S.No", key: "id", sort: false },
      { text: "Location", key: "loc.location" },
      { text: "User Name", key: "SF.user_name" },
      { text: "Token", key: "SF.token" },
      { text: "Last Modified Date", key: "SF.updated_date" },
      {
        text: "Action",
        key: "action",
        sort: false,
        event: [
          {
            key: "editSalesforceCredentials",
            params: ["salesForceId"],
            func: (salesForceId) =>
              this.editSalesforceCredentials(salesForceId),
          },
          {
            key: "deleteSfCredentials",
            params: ["salesForceId"],
            func: (salesForceId) => this.confirmAction(salesForceId),
          },
        ],
      },
    ];
  }
  confirmAction = (salesForceId) =>
    this.setState({ salesForceId, confirmDialog: true });

  deleteSalesforceCredentials = () => {
    if (this.state.salesForceId > 0) {
      this.setState({ confirmDialog: false, loading: true });
      api.salesforce
        .deleteSalesforceCredential({ salesForceId: this.state.salesForceId })
        .then((resp) => {
          if (resp && resp.message) {
            this.setState(
              {
                successMessage: resp.message,
                refreshGrid: true,
                loading: false,
                salesForceId: 0,
              },
              () => {
                setTimeout(() => {
                  this.setState({ refreshGrid: false });
                }, 500);
                setTimeout(() => {
                  this.setState({ successMessage: null });
                }, 5000);
              }
            );
          } else if (resp.errorMessage) {
            this.setState(
              {
                errorMessage: resp.errorMessage,
                loading: false,
                salesForceId: 0,
              },
              () => {
                setTimeout(() => {
                  this.setState({ errorMessage: null });
                }, 5000);
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  editSalesforceCredentials = (salesForceId) => {
    if (salesForceId) {
      this.setState({ salesForceId });
      api.salesforce.getSalesForceInfo({ salesForceId }).then((resp) => {
        if (resp && resp.salesforceInfo) {
          const { salesforceInfo } = resp;
          this.setState(
            {
              userName: salesforceInfo.user_name,
              password: salesforceInfo.password,
              token: salesforceInfo.token,
              selectedDrodown: salesforceInfo.location,
              location: salesforceInfo.location,
              deafultLocation: salesforceInfo.is_default > 0 ? true : false,
            },
            () => {
              this.createNew();
            }
          );
        }
      });
    }
  };

  getDataSource = (params) => api.salesforce.list(params);
  createNew = () => {
    this.setState({ loading: true });
    api.salesforce
      .locationList()
      .then((resp) => {
        this.setState({ loading: false });
        if (resp && resp.locationList && resp.locationList.length > 0) {
          let locationLists = resp.locationList;
          let modal = true;
          let disableDefaultLocation = false;
          if (resp.haveDeafultLocation) {
            disableDefaultLocation = true;
            //if (deafultLocation) deafultLocation = false;
          }
          this.setState({
            locationLists,
            modal,
            disableDefaultLocation,
          });
        } else {
          this.setState({
            modal: true,
          });
        }
      })
      .catch((err) => this.setState({ loading: false }));
  };
  handleSubmit = () => {
    const {
      deafultLocation,
      location,
      userName,
      password,
      token,
      salesForceId,
    } = this.state;

    this.setState({ formLoading: true });
    api.salesforce
      .createNew({
        deafultLocation,
        location,
        userName,
        password,
        token,
        salesForceId,
      })
      .then((resp) => {
        if (resp && resp.message) {
          this.formRef.current.reset();
          this.setState(
            {
              modal: false,
              successMessage: resp.message,
              salesForceId: 0,
              deafultLocation: false,
              location: 0,
              userName: "",
              password: "",
              token: "",
              selectedDrodown: 0,
              formLoading: false,
            },
            () => {
              this.setState({ refreshGrid: true }, () => {
                utilFunc.scrollTop(800);

                setTimeout(() => {
                  this.setState({ refreshGrid: false });
                }, 500);
              });
              setTimeout(() => {
                this.setState({ successMessage: null });
              }, 5000);
            }
          );
        } else if (resp.errorMessage) {
          this.formRef.current.reset();

          this.setState(
            {
              modal: false,
              errorMessage: resp.errorMessage,
              salesForceId: 0,
              deafultLocation: false,
              location: 0,
              userName: "",
              password: "",
              token: "",
              selectedDrodown: 0,
              formLoading: false,
            },
            () => {
              utilFunc.scrollTop(800);

              setTimeout(() => {
                this.setState({ errorMessage: null });
              }, 5000);
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ salesForceId: 0, formLoading: false });
        console.log(err);
      });
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  toggleModal = () => {
    this.setState({
      modal: false,
      userName: "",
      password: "",
      token: "",
      selectedDrodown: 0,
      deafultLocation: false,
      salesForceId: 0,
      location: 0,
    });
  };

  submitForm = () => this.formRef.current.submit();

  handleSwitch = (checked) => this.setState({ deafultLocation: checked });

  createCredentials = () => {
    const errorLabel = <div className="red" pointing={"true"} />;
    return (
      <div
        className={this.state.modal ? "modal fade show " : "modal fade"}
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: this.state.modal ? "block" : "none" }}
      >
        {this.state.formLoading && <div className="ui loading form"></div>}
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.toggleModal}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{"Salesforce"}</h5>
            </div>
            <div className="modal-body">
              <Form
                noValidate
                autoComplete="off"
                onValidSubmit={this.handleSubmit}
                className="kt-form kt-form--label-right"
                ref={this.formRef}
                style={{ margin: "10px 10px 0 0" }}
              >
                {this.state.locationLists.length > 0 && (
                  <div className="form-group row">
                    <label
                      className="col-lg-3 col-form-label"
                      style={{ margin: 0 }}
                    >
                      Location :
                    </label>
                    <div className="col-lg-9">
                      <div className="field">
                        <div className="ui input">
                          <select
                            className="form-control"
                            name="location"
                            onChange={this.handleChange}
                          >
                            {this.state.locationLists.map((option, index) => {
                              return (
                                <option
                                  key={index}
                                  disabled={
                                    parseInt(option.salesforceId) > 0 &&
                                    this.state.selectedDrodown !== option.key
                                      ? true
                                      : false
                                  }
                                  style={{
                                    background:
                                      parseInt(option.salesforceId) > 0 &&
                                      this.state.selectedDrodown !== option.key
                                        ? "#cccaca"
                                        : "",
                                  }}
                                  value={option.key}
                                  selected={
                                    this.state.selectedDrodown === option.key
                                      ? true
                                      : false
                                  }
                                >
                                  {option.text}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">User Name :</label>
                  <div className="col-lg-9">
                    <Form.Input
                      type="text"
                      autoComplete="off"
                      name="userName"
                      value={this.state.userName}
                      placeholder="User Name"
                      onChange={this.handleChange}
                      validations="isRequired"
                      validationErrors={{
                        isRequired: "This field is required.",
                      }}
                      errorLabel={errorLabel}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Password :</label>
                  <div className="col-lg-9">
                    <Form.Input
                      type="password"
                      autoComplete="off"
                      name="password"
                      value={this.state.password}
                      placeholder="Password"
                      onChange={this.handleChange}
                      validations="isRequired"
                      validationErrors={{
                        isRequired: "This field is required.",
                      }}
                      errorLabel={errorLabel}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Security Token :
                  </label>
                  <div className="col-lg-9">
                    <Form.Input
                      type="text"
                      autoComplete="off"
                      name="token"
                      value={this.state.token}
                      placeholder="Security Token"
                      onChange={this.handleChange}
                      validations="isRequired"
                      validationErrors={{
                        isRequired: "This field is required.",
                      }}
                      errorLabel={errorLabel}
                    />
                  </div>
                </div>
                {this.state.locationLists.length > 0 && (
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">
                      Include the location data for default salesforce account?
                    </label>
                    <div className="col-lg-9" style={{ paddingTop: "30px" }}>
                      <Switch
                        onChange={this.handleSwitch}
                        checked={this.state.deafultLocation}
                        disabled={this.state.disableDefaultLocation}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        margin={20}
                        className="react-switch"
                        id="material-switch"
                      />
                    </div>
                  </div>
                )}
              </Form>
            </div>
            <div className="modal-footer">
              <button
                onClick={this.submitForm}
                type="button"
                className="btn linear-gradient yr-submitbtn btn-sm"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  toggleConfirmModal = () =>
    this.setState({ confirmDialog: false, salesForceId: 0 });
  render() {
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="kt-portlet__body kt-portlet__body--fit">
            {this.state.modal && this.createCredentials()}
            {this.state.successMessage && (
              <Message color={"green"}>{this.state.successMessage}</Message>
            )}
            {this.state.errorMessage && (
              <Message color={"red"}>{this.state.errorMessage}</Message>
            )}
            {this.state.loading && <div className="ui loading form"></div>}

            {this.state.confirmDialog && (
              <ConfirmDialog
                show={this.state.confirmDialog}
                toggleModal={this.deleteSalesforceCredentials}
                closeAction={this.toggleConfirmModal}
                title="Confirm your action"
                message={"Do you really want to delete this credential ?"}
              />
            )}
            <div className="yr-manageUser-table ">
              <div className="salesforce-datatable">
                <Segment>
                  <GridList
                    dataSource={(params) => this.getDataSource(params)}
                    dataColumns={this.columnLists}
                    refreshGrid={this.state.refreshGrid}
                  >
                    <div className="col-md-8 kt-margin-b-20-tablet-and-mobile">
                      <button
                        style={{ marging: "0 0 15px 0", float: "right" }}
                        type="button"
                        className="btn linear-gradient yr-submitbtn"
                        onClick={this.createNew}
                      >
                        Create New
                      </button>
                    </div>
                  </GridList>
                </Segment>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SalesForce;
