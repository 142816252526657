import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// import DesignRules from './DesignRules';

import { Message } from "semantic-ui-react";
import {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setBreadCrum,
} from "../../../store/Page";

import DatatableList from "./HippaLog/DatatableList";
const queryString = require("query-string");

class HippaLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activetab: "design",
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
    };
  }
  componentDidMount() {
    const {
      setPageTitle,
      toggleNavigation,
      location,
      PageMessage,
      setMessage,
      setBreadCrum,
    } = this.props;
    setPageTitle({ title: "Hippa Log",subHeaderTitle:'Hippa log activity' });
    

    toggleNavigation({ toggle: false });
    // setBreadCrum({ breadcrum: null });
    // const params = queryString.parse(location.search);

    // if (params && params.tab !== undefined) {
    //   this.setState({
    //     activetab: params.tab,
    //   });
    // }
  }

  render() {

    return (

      <div className="kt-container kt-body  kt-grid kt-grid--ver" id="kt_body">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-content kt-grid__item kt-grid__item--fluid">
            <div className="yr-body-p">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div
                  className="kt-portlet kt-portlet--mobile"
                  style={{ boxShadow: "none" }}
                >
                  <div className="kt-portlet__body kt-portlet__body--fit">
                  <button className = "btn linear-gradient zy-save-btn" style = {{width:200,marginTop:12}} onClick={() => window.history.back()}>Back</button>
                    <DatatableList userData = {this.props.user}  locationData = {this.props.location} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PageMessage: state.page.message,
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setBreadCrum,
})(HippaLog);
