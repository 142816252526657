import React from 'react'
import classes from './ArchiveChat.module.css';

const generateMask = (phoneNumberValue) => {
  phoneNumberValue = "(" + phoneNumberValue;
  let l30 = phoneNumberValue.length;
  let p30 = phoneNumberValue.substring(0, 4);
  p30 = p30 + ")";

  let p31 = phoneNumberValue.substring(4, l30);
  let pp = p30 + p31;

  let l40 = pp.length;
  let p40 = pp.substring(0, 8);
  p40 = p40 + "-";

  let p41 = pp.substring(8, l40);
  let ppp = p40 + p41;
  let maxphonelength = 13;
  return ppp.substring(0, maxphonelength);
}

const getAvtar = (name) => { 
	let newa = name.match(/(\b\S)?/g).join("")
	.match(/(^\S|\S$)?/g)
	.join("").toUpperCase();
	if (newa.length === 1) {
		newa += name.slice(-1).toUpperCase();
	}
	return newa;
}

const ArchiveChat = (props) => {
  return (
    <div className={classes.archivechat_container}>
      {props.smsArchiveList?.length > 0 ?
        props.smsArchiveList.map((item, index) => (
          <div key={index} className={classes.archivechat_container_item} onClick={() => props.viewArchiveChat(item.id)}>
            <div className={classes.archivechat_container_item_1}>
              <div className={classes.archivechat_item_avtar}>
                {item.name ? getAvtar(item.name) : 'SV'}
              </div>
              <div className={classes.archivechat_item_description}>
                <div className={classes.archivechat_item_description_name}>
                  {item.name ? item.name : 'Site Visitor'}
                </div>
                <div className={classes.archivechat_item_description_number}>
                  {generateMask(item.to_number)}
                </div>
              </div>
              <div className={classes.archivechat_item_messagetime}>
                {item.created_date}
              </div>
              {/* <div className={classes.archivechat_item_action}>
								<i title='Archive' style={{ color: '#01cca0' }} class="fa fa-archive" aria-hidden="true"></i>
								<i title='Edit name' style={{ color: '#5a9c12' }} class="fa fa-pencil" aria-hidden="true" onClick={props.onArchive(item.id)}></i>
							</div> */}
            </div>
          </div>
        ))
        :
        <div className={classes.archivechat_container_item}>
          <div className={classes.archivechat_container_item_1}>
            No record found
          </div>
        </div>
      }
    </div>
  )
}

export default ArchiveChat