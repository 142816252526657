import React from "react";
import { Link, Redirect } from "react-router-dom";
import api from "../../../../../api";
import "../../../../Grid/grid.css";
import { Segment, Table, Pagination, Message } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";
import ChatFilter from "./ChatFilter";
import { SMSDatatableRow } from "./SMSDatatableRow";
import { DatatablePageSizeSelect } from "./DatatablePageSizeSelect";
import Modal from "../../../ChatSetup/components/Modal";
import CustomErrorField from "../../../../Form/CustomErrorField";
import utilFunc from "../../../../../util_funs";

const tableHeader = [
	{
		title: "",
		width: "1",
		sort: "",
	},
	{
		title: "Number",
		width: "4",
		sort: "",
	},
	{
		title: "Status",
		width: "3",
		sort: "",
	},
	{
		title: "Other Action",
		width: "3",
		sort: "",
	},
]

const Json = [
	{
		Number: 1234556666,
		status: "active"
	},
	{
		Number: 12345564343,
		status: "unverified"
	},
	{
		Number: 12345512345,
		status: "pending"
	},
]

const queryParams = [
	"_limit",
	"_order",
	"_sort",
	"q",
	"_page",
	"title",
	"added_datetime",
];

export default class SMSDatatableList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lists: [],
			_sort: "auto_id",
			_page: 1,
			_order: "desc",
			_limit: 10,
			q: "",
			title: "",
			added_datetime: "",
			startdaterange: "",
			enddaterange: "",
			totalCount: 0,
			loading: false,
			modalstatus: false,
			modalbody: "",
			modalfooter: "",
			datatableHeader: null,
			datatableRows: null,
			auto_id: "",
			csvdata: [],
			rename: "",
			modal_id: "",
			errors: [],
			status: "all",
			userToaster: false,
			userToasterMsg: "",
			userToasterColor: "",
			dialogTitle: '',
			dialogMessage: '',
			dialogLoading: false,
			dialogShow: false,
			dialogData: '',
			areacode: '',
			fromEmail: '',
			dialogLoadingE: false,
			dialogShowE: false,
			fromDomain: '',
			editid: ''
		};
		this.csvLink = React.createRef();
	}

	componentDidMount() {
		this.loadData({});
	}

	static directionConverter(order) {
		if (order === "asc") {
			return "ascending";
		} else if (order === "desc") {
			return "descending";
		} else {
			return null;
		}
	}

	handleSort = (clickedColumn) => {
		const { _sort, _order } = this.state;

		let newOrder = _order === "asc" ? "desc" : "asc";
		if (_sort !== clickedColumn) {
			newOrder = "asc";
		}

		this.loadData({
			_sort: clickedColumn,
			_page: 1,
			_order: newOrder,
		});
	};

	onChangeLimit = (event, data) => {
		if (data.value !== this.state._limit) {
			this.loadData({ _limit: data.value, _page: 1 });
		}
	};

	onSubmitFilter = (filter, val) => {
		this.loadData({ q: filter, _page: 1 });

		// this.loadData({ [filter]: val, _page: 1 });
	};

	onChangePage = (event, data) => {
		const { activePage } = data;
		if (activePage !== this.state._page) {
			this.loadData({ _page: activePage });
		}
	};

	// loadData = (params) => {
	//   let datatableRowstemp;
	//   datatableRowstemp = <DatatableRow />;

	//   this.setState({
	//     datatableRows: datatableRowstemp,
	//     totalCount: 5,
	//     // lists: data.data.list,
	//   });
	// };

	loadData = (params) => {
		const newState = Object.assign({}, this.state, params, { loading: false });
		console.log(newState);
		queryParams.forEach(function (element) {
			if (!(element in params)) {
				params[element] = newState[element];
			}
		});

		let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
		let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
		let time_zone = clientTimeZone;

		params["time_zone"] = time_zone;
		params["status"] = this.state.status;

		const esc = encodeURIComponent;
		const query = Object.keys(params)
			.map((k) => esc(k) + "=" + esc(params[k]))
			.join("&");

		let totalCountQuery = "";
		if (params.q !== "") {
			totalCountQuery = `q=${params.q}`;
		}
		this.setState(newState, () => {
			this.setState({ loading: true });
			api.ConfigSetting.listSmsNumber(query).then((data) => {
				console.log('add sms data', data.data);
				try {
					let datatableRowstemp;
					//temporaryList
					this.setState({ lists: data.data.list });
					if (data.data.totalrecord) {
						console.log(data.data.list);
						datatableRowstemp = this.state.lists.map((list, index) => (
							<SMSDatatableRow
								key={index}
								list={list}
								deleteDomain={this.deleteDomain}
								editDomain={this.editDomain}
							/>
						));
					} else {
						datatableRowstemp = (
							<Table.Row>
								<Table.Cell colSpan={6} textAlign="center">
									No record found.
								</Table.Cell>
							</Table.Row>
						);
					}
					this.setState({
						datatableRows: datatableRowstemp,
						totalCount: data.data.totalrecord,
						lists: data.data.list,
					});
				} catch (err) {
					console.log(err);
				}
				const newState = Object.assign({}, this.state, params, {
					loading: false,
				});
				this.setState(newState);
			});
		});
	};

	checkAll = (e) => {
		let datalist = this.state.lists;
		datalist.map(function (list, index) {
			list["checked"] = e.target.checked;
		});
		this.setState({
			datatableRows: datalist.map((list, index) => (
				<SMSDatatableRow
					channgeEvent={this.checkboxChangeEvent}
					openContactList={this.openContactList}
					key={index}
					list={list}
					bucketAction={this.bucketAction}
					createPlaybook={this.props.createPlaybook}
					deleteDomain={this.deleteDomain}
					editDomain={this.editDomain}
				/>
			)),
			lists: datalist,
		});
	};

	checkboxChangeEvent = (auto_id) => {
		let datalist = this.state.lists;
		let recordlist = datalist.findIndex((list) => list.auto_id === auto_id);

		if (datalist[recordlist]["checked"]) {
			datalist[recordlist]["checked"] = false;
		} else {
			datalist[recordlist]["checked"] = true;
		}

		this.setState({
			datatableRows: datalist.map((list, index) => (
				<SMSDatatableRow
					channgeEvent={this.checkboxChangeEvent}
					openContactList={this.openContactList}
					key={index}
					list={list}
					createPlaybook={this.props.createPlaybook}
					deleteDomain={this.deleteDomain}
					editDomain={this.editDomain}
				/>
			)),
			lists: datalist,
		});
	};

	bucketAction = (tab, id) => {
		if (tab == "edit") {
			let list_data = this.state.lists;
			let recordid = list_data.findIndex((list_data) => list_data.bid == id);

			this.setState(
				{
					errors: [],
					rename: list_data[recordid]["title"],
					modaltitle: "Rename Bucket",
					modalstatus: true,
					modal_id: id,
					modalfooter: this.modalFooter("", "edit"),
				},
				function () {
					let modalbody = this.modalBody("edit");

					this.setState({ modalbody: modalbody });
				}
			);
		}

		if (tab == "delete") {
			this.setState({
				errors: [],
				modalstatus: true,
				modal_id: id,
				modaltitle: "Confirm your action",
				modalfooter: this.modalFooter("", "delete"),
				modalbody: "Are you sure you want to delete it?",
			});
		}
	};

	modalClose = () => {
		this.setState({
			modalstatus: false,
		});
	};

	modalFooter = (id, type) => {
		if (type == "edit") {
			return (
				<div className="modal-footer">
					<button
						type="button"
						onClick={() => this.modalClose()}
						className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
						data-dismiss="modal"
					>
						<span>Cancel</span>
					</button>
					<button
						type="button"
						onClick={() => this.updateBucketName(this.state.modal_id)}
						className="btn linear-gradient yr-submitbtn btn-sm"
					>
						<span>Save</span>
					</button>
				</div>
			);
		}
		if (type == "delete") {
			return (
				<div className="modal-footer">
					<button
						type="button"
						onClick={() => this.modalClose()}
						className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
						data-dismiss="modal"
					>
						<span>No</span>
					</button>
					<button
						type="button"
						onClick={() => this.deleteBucketName(this.state.modal_id)}
						className="btn linear-gradient yr-submitbtn btn-sm"
					>
						<span>Yes</span>
					</button>
				</div>
			);
		}
	};

	modalBody = (tab) => {
		if (tab == "edit") {
			//alert( this.state.rename)
			let time = Date.now();
			//this.setState({rename:})
			const { rename } = this.state;
			return (
				<div className="col-lg-12 col-md-12 col-sm-12">
					<label className="form-control-label">
						Title <span>*</span>
					</label>
					<input
						type="text"
						className={
							Object.keys(this.state.errors).length > 0 &&
								typeof this.state.errors["rename_data"] != "undefined"
								? "form-control is-invalid focusError"
								: "form-control"
						}
						key={time}
						placeholder="Enter Summary"
						defaultValue={rename}
						onChange={(e) => this.onChangeInput("rename", e)}
					/>
					{Object.keys(this.state.errors).length > 0 &&
						typeof this.state.errors["rename_data"] != "undefined" && (
							<CustomErrorField
								message={this.state.errors["rename_data"]}
								id="help-error"
								className="error invalid-feedback"
							/>
						)}
				</div>
			);
		}
		if (tab == "delete") {
			return "Are you sure";
		}
	};

	onChangeInput = (type, data) => {
		this.setState({ rename: data.target.value });
	};
	updateBucketName = (id) => {
		let error = [];
		if (this.state.rename == "") {
			error["rename_data"] = "This field is required";
		}
		if (Object.keys(error).length > 0) {
			this.setState({ errors: error }, function () {
				let modalbody = this.modalBody("edit");
				this.setState({ modalbody: modalbody });
			});
		} else {
			this.setState({ loading: true });

			api.marketing
				.updateBucketName({ bid: id, title: this.state.rename })
				.then((data) => {
					try {
						if (data.status == false) {
							let error = [];
							error["rename_data"] = data.message;
							this.setState({ errors: error, loading: false }, function () {
								let modalbody = this.modalBody("edit");
								this.setState({ modalbody: modalbody });
							});
						} else {
							let list_data = this.state.lists;
							let recordid = list_data.findIndex(
								(list_data) => list_data.bid == id
							);
							list_data[recordid].title = this.state.rename;

							this.setState(
								{
									loading: false,
									lists: list_data,
									modalstatus: false,
									modal_id: "",
								},
								function () {
									let datatableRowstemp = this.state.lists.map(
										(list, index) => (
											<SMSDatatableRow
												channgeEvent={this.checkboxChangeEvent}
												openContactList={this.openContactList}
												key={index}
												list={list}
												bucketAction={this.bucketAction}
												createPlaybook={this.props.createPlaybook}
												deleteDomain={this.deleteDomain}
												editDomain={this.editDomain}
											/>
										)
									);
									this.setState({ datatableRows: datatableRowstemp });
								}
							);
						}
					} catch (err) {
						console.log(err);
					}
				});
		}
	};

	deleteBucketName = (id) => {
		api.marketing.deleteBucketName({ bid: id }).then((data) => {
			try {
				let list_data = this.state.lists;
				let recordid = list_data.findIndex((list_data) => list_data.bid == id);
				list_data.splice(recordid, 1);
				this.setState(
					{
						loading: false,
						lists: list_data,
						modalstatus: false,
						modal_id: "",
					},
					function () {
						let datatableRowstemp = this.state.lists.map((list, index) => (
							<SMSDatatableRow
								channgeEvent={this.checkboxChangeEvent}
								openContactList={this.openContactList}
								key={index}
								list={list}
								bucketAction={this.bucketAction}
								createPlaybook={this.props.createPlaybook}
								deleteDomain={this.deleteDomain}
								editDomain={this.editDomain}
							/>
						));
						this.setState({ datatableRows: datatableRowstemp });
					}
				);
			} catch (err) {
				console.log(err);
			}
		});
	};

	getListByStatus = (e) => {
		if (this.state.status != e) {
			document.getElementById("kt_form_status").value = "title";
			document.getElementById("playBookSearchBar").value = "";
			this.setState(
				{
					loading: true,
					status: e,
				},
				() => {
					this.getListByStatusNow();
				}
			);
		}
	};

	getListByStatusNow = () => {
		this.loadData({
			_limit: 10,
			_order: "desc",
			_sort: "id",
			q: "",
			_page: 1,
			title: "",
		});
	};

	deleteDomain = (action, id = null) => {

		if (action == 'Open') {
			this.setState({
				dialogTitle: '',
				dialogMessage: 'Are you sure you want to delete it?',
				dialogLoading: false,
				dialogShow: true,
				errors: {},
				dialogData: id
			});
		}
		if (action == 'Close') {
			this.setState({
				dialogTitle: '',
				dialogMessage: '',
				dialogLoading: false,
				dialogShow: false,
				errors: {},
				dialogData: ''
			});
		}
		if (action == 'Yes') {
			this.deleteDomainAction()
		}

	}


	deleteDomainAction = () => {
		this.setState({ dialogLoading: true });
		api.ConfigSetting.deleteSmsNumber({ id: this.state.dialogData }).then((data) => {
			try {
				this.setState({ dialogLoading: false });
				if (data.status) {
					this.deleteDomain('Close');
					this.loadData({});
					this.showToaster("green", "Number has been deleted successfully.");
					utilFunc.scrollTop(800);
				} else {
					this.showToaster("red", data.data);
					utilFunc.scrollTop(800);
				}
			} catch (err) {
				console.log(err);
			}
		});
	}

	showToaster = (color, msg) => {
		this.setState({
			userToaster: true,
			userToasterMsg: msg,
			userToasterColor: color,
		});
		setTimeout(
			function () {
				this.setState({ userToaster: false });
			}.bind(this),
			3000
		);
	};





	onchange = (e) => {
		const inputValue = e.target.value.replace(/[^\d]*/gi, "");
		this.setState({ [e.target.name]: inputValue });
	};

	addSmsNumber = () => {
		this.setState({
			areacode: '',
			dialogLoadingE: false,
			dialogShowE: true,
			addButtonDisabled: false,
			errors: {},
		});
	}

	addAreaCode = (action, id = null) => {
		if (action == 'Close') {
			this.setState({
				areacode: '',
				dialogLoadingE: false,
				dialogShowE: false,
				addButtonDisabled: false,
				errors: {},
			});
		}
		if (action === 'Add') {
			let obj = this;
			this.validateAddForm(this.state.areacode, function(errors){
				obj.setState({
					errors
				});
				console.log(errors);
				console.log(Object.keys(errors));
				if (Object.keys(errors).length === 0) {
					obj.setState({addButtonDisabled: true, dialogLoadingE: true });
					api.ConfigSetting.addAreaCode({'areacode' : obj.state.areacode})
					.then((data) => {
						console.log(data);
						if (data.status === true) {
							obj.showToaster('green', data.message);
						}else{
							obj.showToaster('red', data.message);
						}
					}).catch((err) => {
						console.log(err);
					}).finally(() => {
						obj.setState({
							addButtonDisabled: false, 
							dialogLoadingE: false 
						});
						obj.addAreaCode('Close');
            			obj.loadData({});
					})
				}
			})
		}
	}

	validateAddForm = (inputValue, callback) => {
		const errors = {}; 
		if (inputValue.trim() === '') {
			errors['areacode'] = 'This is required';
		}
		callback(errors);
	}

	render() {
		const { errors } = this.state;
		const { listType } = this.props
		return (
			<div>

				<Message
					color={
						this.state.userToasterColor
							? this.state.userToasterColor
							: "teal"
					}
					style={{
						display: this.state.userToaster
							? "block"
							: "none",
						marginTop: 30,
					}}
				>
					{this.state.userToasterMsg}
				</Message>
				{this.state.loading && <div className="ui loading form"></div>}
				<div class="kt-form kt-form--label-right kt-margin-b-30 yr-manageUser-table">
					<div class="row align-items-center">
						<div class="col-xl-12 order-2 order-xl-1">
							<div class="row align-items-center">
								<div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
									<div style={{ paddingTop: "10px" }}>
										<div style={{cursor:'pointer'}}
											className="btn linear-gradient yr-add-new kt-font-bold float-right"
											onClick={this.addSmsNumber}
										>
											<i className="fa fa-plus"></i>
											Request Number
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Segment
					style={{
						width: "100%",
						minHeight: "300px",
						margin: "0px",
						padding: 0,
						paddingTop: "0px",
					}}
				>
					<DatatableTable
						lists={this.state.lists}
						datatableHeader={tableHeader}
						datatableRows={this.state.datatableRows}
						totalCount={this.state.totalCount}
						totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
						currentPage={this.state._page}
						onChangePage={this.onChangePage}
						column={this.state._sort}
						// direction={DatatableList.directionConverter(this.state._order)}
						handleSort={this.handleSort}
						onChangeLimit={this.onChangeLimit}
						limit={this.state._limit.toString()}
						checkAll={this.checkAll}
					/>
				</Segment>

				<Modal
					show={this.state.modalstatus}
					footer={this.state.modalfooter}
					onclose={this.modalClose}
					title={this.state.modaltitle}
					body={this.state.modalbody}
					modaltranscript={this.state.modalTranscript}
				/>

				<div
					className={this.state.dialogShow ? "modal fade show" : "modal fade"}
					data-backdrop="static"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="staticBackdrop"
					aria-hidden="true"
					style={{ display: this.state.dialogShow ? "block" : "none" }}
				>
					<div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 600 }}>
						{this.state.dialogLoading && <div className="ui loading form"></div>}
						<div className="modal-content">
							<button
								type="button"
								className="close linear-gradient"
								data-dismiss="modal"
								aria-label="Close"
								onClick={(e) => this.deleteDomain('Close')}
							>
								X
							</button>
							<div className="modal-header">
								<h5 className="modal-title">Confirm your action</h5>
							</div>
							<div className="modal-body">
								<div className="px-4">
									{this.state.dialogMessage}
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={(e) => this.deleteDomain('Close')} data-dismiss="modal">
									<span>No</span>
								</button>
								<button type="button" className="btn linear-gradient yr-submitbtn btn-sm" onClick={(e) => this.deleteDomain('Yes')} >
									Yes
								</button>
							</div>

						</div>
					</div>
				</div>



				<div
					className={this.state.dialogShowE ? "modal fade show" : "modal fade"}
					data-backdrop="static"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="staticBackdrop"
					aria-hidden="true"
					style={{ display: this.state.dialogShowE ? "block" : "none" }}
				>
					<div className="modal-dialog modal-dialog-centered" role="document" >
						{this.state.dialogLoadingE && <div className="ui loading form"></div>}
						<div className="modal-content focusError">
							<button
								type="button"
								className="close linear-gradient"
								data-dismiss="modal"
								aria-label="Close"
								onClick={(e) => this.addAreaCode('Close')}
							>
								X
							</button>

							<div className="modal-header">
								<h5 className="modal-title">Add Area Code</h5>
							</div>
							<div className="modal-body">
								<div className="px-4">
									<div className="form-group row">
										<label className="col-lg-3 col-form-label">Area Code</label>
										<div className="col-lg-9">
											<div className="field">
												<div className="ui fluid input">
													<input maxLength={3} autocomplete="off" name="areacode" placeholder="" type="text"
														style={{ maxWidth: '50%' }}
														value={this.state.areacode}
														onChange={(e) => this.onchange(e)}
													/>
												</div>
												{errors && errors.hasOwnProperty('areacode') && (
													<div className="red">{errors['areacode']}</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={(e) => this.addAreaCode('Close')} data-dismiss="modal">
									<span>Cancel</span>
								</button>
								<button disabled={this.state.addButtonDisabled} type="button" className="btn linear-gradient yr-submitbtn btn-sm" onClick={(e) => this.addAreaCode('Add')} >
									Add
								</button>
							</div>

						</div>
					</div>
				</div>

			</div>
		);
	}
}
