import React from "react";
import { connect } from "react-redux";
import { Segment } from "semantic-ui-react";

import { Message } from "semantic-ui-react";
import {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setBreadCrum,
} from "../../store/Page";

class NotAccess extends React.Component {
  componentDidMount() {
    const {
      setPageTitle,
      toggleNavigation,
      location,
      PageMessage,
      setMessage,
      setBreadCrum,
    } = this.props;
    setPageTitle({ title: "", subHeaderTitle: "Not Access" });

    toggleNavigation({ toggle: false });
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="kt-portlet__body kt-portlet__body--fit">
            <Segment>
              <h2>You have no permission to access this page</h2>
            </Segment>
          </div>
        </div>
      </div>
    );
  }
}

//export default NotAccess;

const mapStateToProps = (state) => {
  return {
    PageMessage: state.page.message,
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setBreadCrum,
})(NotAccess);
