import React from "react";
import constants from "../../../../util_funs/constant";

class DuplicatePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedtype: "",
      duplicateloading: false,
      disabledBt : false
    };
  }

  updateConvType = (type) => {
    this.setState({ selectedtype: type });
  };

  componentDidMount() {
    // console.log('notes');
    this.setState({ selectedtype: this.props.type });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.auto_id !== this.props.auto_id) {
      this.setState({ selectedtype: this.props.type });
    }
  }

  duplicateworkspace = (auto_id, type) => {
    this.setState({ disabledBt:true, duplicateloading: true });
    this.props.duplicateWorkspace(auto_id, type, () => {
      this.setState({ duplicateloading: false, disabledBt: false });
    });
  };

  render() {
    return (
      <div>
        <div
          className="yr-scroller"
          data-always-visible="1"
          data-rail-visible1="1"
        >
          <p>
            <strong>
              You can change conversation type by selecting any one option given
              below:{" "}
            </strong>
          </p>

          {this.state.duplicateloading && (
            <div className="ui loading form"></div>
          )}
          <div className="row">
            <div className="col-md-12">
              <div
                className="form-group yr-popBox-1 duplicatepopup"
                style={{ padding: "10px" }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group" style={{ marginBottom: 20 }}>
                      <label
                        className={
                          this.state.selectedtype == "WEB"
                            ? "form-control active"
                            : "form-control"
                        }
                        // onClick={() => this.updateConvType("WEB")}
                        type="WEB"
                      >
                        Web
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group" style={{ marginBottom: 20 }}>
                      <label
                        className={
                          this.state.selectedtype == "FACEBOOK"
                            ? "form-control active"
                            : "form-control"
                        }
                        // onClick={() => this.updateConvType("FACEBOOK")}
                        type="SMS"
                      >
                        Facebook enabled
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group" style={{ marginBottom: 20 }}>
                      <label
                        className={
                          this.state.selectedtype == "SMS"
                            ? "form-control active"
                            : "form-control"
                        }
                        // onClick={() => this.updateConvType("SMS")}
                        type="SMS"
                      >
                        Inbound SMS automation
                      </label>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                                        <div className="form-group" style={{ marginBottom: 20 }}>
                                        <label className={this.state.selectedtype=='OUTBOUNDSMS' ? 'form-control active' : 'form-control'} onClick={()=>this.updateConvType('OUTBOUNDSMS')} type="OUTBOUNDSMS">Outbound SMS automation</label>
                                        </div>
                                    </div> */}
                  <div className="col-md-6">
                    <div className="form-group" style={{ marginBottom: 20 }}>
                      <label
                        className={
                          this.state.selectedtype == "GDF"
                            ? "form-control active"
                            : "form-control"
                        }
                        // onClick={() => this.updateConvType("GDF")}
                        type="GDF"
                      >
                        Google My Business enabled
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group" style={{ marginBottom: 20 }}>
                      <label
                        className={
                          this.state.selectedtype == "Voice"
                            ? "form-control active"
                            : "form-control"
                        }
                        // onClick={() => this.updateConvType("GDF")}
                        type="Voice"
                      >
                        Voice
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group" style={{ marginBottom: 20 }}>
                      <label
                        className={
                          this.state.selectedtype == "Voice_Turbo"
                            ? "form-control active"
                            : "form-control"
                        }
                        // onClick={() => this.updateConvType("GDF")}
                        type="Voice_Turbo"
                      >
                        Voice Turbo
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group" style={{ marginBottom: 20 }}>
                      <label
                        className={
                          this.state.selectedtype == "GEN_WEB"
                            ? "form-control active"
                            : "form-control"
                        }
                        // onClick={() => this.updateConvType("GDF")}
                        type="GEN_WEB"
                      >
                        {constants.web_general}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group" style={{ marginBottom: 20 }}>
                      <label
                        className={
                          this.state.selectedtype == "GEN_VOICE"
                            ? "form-control active"
                            : "form-control"
                        }
                        // onClick={() => this.updateConvType("GDF")}
                        type="GEN_VOICE"
                      >
                        {constants.voice_general}
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group" style={{ marginBottom: 20 }}>
                                        <label className={this.state.selectedtype=='EMAIL' ? 'form-control active' : 'form-control'} onClick={()=>this.updateConvType('EMAIL')} type="EMAIL">Email automation</label>
                                        </div>
                                    </div>
                                </div> */}

                {/* <ul className="yr-checkbox-area">
                         <li onClick={()=>this.updateConvType('WEB')} type="WEB"  className={this.state.selectedtype=='WEB' ? 'webbased yr-wksSelected' :'webbased'}><label className="form-control">WEB</label></li>
                         <li onClick={()=>this.updateConvType('SMS')} type="SMS"  className={this.state.selectedtype=='SMS' ? 'smsbased yr-wksSelected' :'smsbased'}>SMS text enabled</li>
                         <li onClick={()=>this.updateConvType('FACEBOOK')} type="FACEBOOK"  className={this.state.selectedtype=='FACEBOOK' ? 'fbbased yr-wksSelected' :'fbbased'}>Facebook enabled</li>
                        </ul> */}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.props.modalClose()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>Cancel </span>
          </button>

          <button
            type="button"
            disabled={this.state.disabledBt}
            onClick={() =>
              this.duplicateworkspace(
                this.props.auto_id,
                this.state.selectedtype
              )
            }
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Save</span>
          </button>
        </div>
      </div>
    );
  }
}
export default DuplicatePopup;
