import React from "react";
import CustomLabel from "./CustomLabel";
import CustomErrorField from "./CustomErrorField";

const CustomInput = ({ labelInfo, name, errors, ...rest }) => {
	return (
		<React.Fragment>
			<CustomLabel labelInfo={labelInfo} />
			<input {...rest} name={name} />

			{errors && errors.hasOwnProperty(name) && (
				<CustomErrorField
					message={errors[name]}
					id="help-error"
					className="error invalid-feedback"
				/>
			)}
		</React.Fragment>
	);
};

export default CustomInput;
