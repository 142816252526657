import { Table } from 'semantic-ui-react';
import React from 'react';

export function DatatableTableHeader(props) {
  const tableHeader = props.header.map((head, index) => {
    if (head.sort != '') {
      return <Table.HeaderCell key={index} width={head.width} sorted={props.column === head.sort ? props.direction : null}
    onClick={() => props.handleSort(head.sort)}    >{typeof head.checkbox=='undefined' ? head.title :<label className="kt-checkbox kt-checkbox--single "><input type="checkbox" onClick={(e) => props.checkAll(e)} ></input>&nbsp;<span></span></label>}</Table.HeaderCell> ;
    } else {
      return <Table.HeaderCell key={index}  >{typeof head.checkbox=='undefined' ? head.title :<label className="kt-checkbox kt-checkbox--single "><input type="checkbox" onClick={(e) => props.checkAll(e)} ></input>&nbsp;<span></span></label>}</Table.HeaderCell>;
    }
  });
  return (
    <Table.Header>
      <Table.Row>
        {tableHeader}
      </Table.Row>
    </Table.Header>
  );
}
