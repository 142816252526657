import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link, Redirect } from "react-router-dom";
import util_funs from '../../../../../util_funs';
let follow;
let needtocall;
let archive;
let teamSelected;
export const DatatableRow = props => {
  const b_id = util_funs.encrypt_url(props.list.b_id)
  const pid = util_funs.encrypt_url(props.pid)  
   //@Rohit
   return(
  <Table.Row>
    <Table.Cell>{props.list.title}</Table.Cell>
    {/* <Table.Cell>{props.list.email_subject}</Table.Cell> */}
    <Table.Cell ><div dangerouslySetInnerHTML={{ __html: props.list.email_subject }}></div></Table.Cell>
    <Table.Cell>{props.list.total_send_email}</Table.Cell>
    <Table.Cell>{props.list.total_open_email}</Table.Cell>
    <Table.Cell>{props.list.total_send_clicks}</Table.Cell>
    <Table.Cell>
        <Link to={`../PlayBookAnalytics/${b_id}/${pid}`}>Analytics Detail</Link>
     </Table.Cell>
  </Table.Row>
)};
DatatableRow.propTypes = {
  list: PropTypes.object.isRequired, 
  onSubmitFilter: PropTypes.func.isRequired,  
  
};


