import React from "react";
import { connect } from "react-redux";
import './YourReviews.css';
import config from '../../../config/'
import { Rating, Dropdown, Icon } from 'semantic-ui-react';
import ReviewSourceFilter from './components/ReviewSourceFilter';
import TimePeriodFilter from './components/TimePeriodFilter';
import TeamMemberFilter from './components/TeamMemberFilter';
import ModalReplyEditReview from './components/modalReplyEditReview';
import ReviewsDetails from './components/ReviewsDetails';
import DialogBoxDetail from './components/DialogBoxDetail';
import ReviewsDetailsFromBackend from './components/ReviewsDetailsFromBackend';
import ReviewsDetails2 from './components/ReviewsDetails2';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { CSVLink } from "react-csv";


import {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setSubHeaderTitle,
  setNavigationName,
  toggleBreadcrum
} from "../../../store/Page";
import api from "../../../api";
import { Link } from "react-router-dom";
const queryString = require("query-string");
const changeByOtherFilteroption = [
  { value: '1', text: 'Most recent', icon: 'angle right' },
  { value: '2', text: 'Oldest', icon: 'angle right' },
  { value: '3', text: 'Best Rating', icon: 'angle right' },
  { value: '4', text: 'Worst Rating', icon: 'angle right' },
]

const TimeDuration = [
  { value: 'all', text: 'All' },
  { value: 'pastsevendays', text: 'Last 7 days' },
  { value: 'pastmonth', text: 'Last Month' },
  { value: 'pastyear', text: 'Last Year' },
  { value: 'daterange', text: 'Date Range' },

]
const sourceFilter = [
  { value: '4', text: 'All' },
  { value: '1', text: 'Private reviews' },
  { value: '2', text: 'Facebook' },
  { value: '3', text: 'Google' },


]
class YourReviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        initialPage: 1,
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        total: 10,
        currentPage: 1,
        lastPage: 1,
        from: 1,
        to: 1,
        loading: (
          <div style={{ fontSize: 18, display: "initial", position: "absolute", right: "40%" }}><span className="fa fa-spinner fa-spin" /> Loading...</div>),
        responseAdapter: function (resp_data) {
          return { data: resp_data.data, total: resp_data.total }
        }
      },
      reqData: {
        query: '',
        limit: 10,
        page: 1,
        orderBy: '',
        ascending: true,
        otherFilters: 1,
        sourceFilters: 4,
        teamFilters: '0',
        startDate: '',
        endDate: '',
        daterange: '',
      },
      backend_review_current_list: [],
      data: [],
      isLoading: true,
      loading: false,
      modalstatus: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      modalErrorMsg: false,
      modalstatusAddEdit: false,
      modaldata: {},
      modalFormLoader: false,
      review_id: '',
      AvgRating: 0,
      StarCount: 0,
      TeamData: [],
      rangepicker: false,
      selectedtext: 'Choose Date Range ',
      is_float: "",
      star_one: "star_custom",
      star_two: "star_custom",
      star_three: "star_custom",
      star_four: "star_custom",
      star_five: "star_custom",
      ScrLogo: "",
      csvResData: [],
      yourReviewCsvData: '',
    }
    this.handleFetchData();

    this.table_search_input = React.createRef();
    this.teamFilter();
  }

  changeStar() {
    let starCount = this.state.StarCount;
    let AvgRating = this.state.AvgRating;

    for (let index = 1; index <= starCount; index++) {
      if (index == 1) {
        if (index == starCount && !Number.isInteger(AvgRating)) {
          this.setState({
            star_one: "star_custom half"
          });
        } else {
          this.setState({
            star_one: "star_custom on"
          });
        }

      }
      if (index == 2) {
        if (index == starCount && !Number.isInteger(AvgRating)) {
          this.setState({
            star_two: "star_custom half"
          });
        } else {
          this.setState({
            star_two: "star_custom on"
          });
        }
      }
      if (index == 3) {
        if (index == starCount && !Number.isInteger(AvgRating)) {
          this.setState({
            star_three: "star_custom half"
          });
        } else {
          this.setState({
            star_three: "star_custom on"
          });
        }
      }
      if (index == 4) {
        if (index == starCount && !Number.isInteger(AvgRating)) {
          this.setState({
            star_four: "star_custom half"
          });
        } else {
          this.setState({
            star_four: "star_custom on"
          });
        }
      }
      if (index == 5) {
        if (index == starCount && !Number.isInteger(AvgRating)) {
          this.setState({
            star_five: "star_custom half"
          });
        } else {
          this.setState({
            star_five: "star_custom on"
          });
        }
      }

    }
  }

  chatTimeChange = (event, data) => {

    if (data.value == 'daterange') {
      let reqData = Object.assign({}, this.state.reqData);
      reqData.daterange = data.value
      this.setState({
        rangepicker: true,
        reqData: reqData
      }, function () {
        document.querySelector(".showrangepicker").click();
      })
    } else {

      let reqData = Object.assign({}, this.state.reqData);
      reqData.daterange = data.value
      reqData.page = 1;
      this.setState({ reqData: reqData, isLoading: true, rangepicker: false }, () => {
        this.handleFetchData();

      });
    }

  }

  applyDateRange = (event, picker) => {
    this.setState({
      'selectedtext': picker.startDate.format('MM/DD/YYYY') + " - " + picker.endDate.format('MM/DD/YYYY')
    });
    let range = {};
    range['chatfrequency'] = 'daterange'
    range['startdaterange'] = picker.startDate.format('MM/DD/YYYY')
    range['enddaterange'] = picker.endDate.format('MM/DD/YYYY')
    let reqData = Object.assign({}, this.state.reqData);
    reqData.startDate = picker.startDate.format('MM/DD/YYYY');
    reqData.endDate = picker.endDate.format('MM/DD/YYYY');
    reqData.page = 1;
    this.setState({ reqData: reqData, isLoading: true }, () => {
      this.handleFetchData();

    });

  }

  teamFilter() {
    api.manageTeam.getTeam().then(data => {
      try {
        this.setState({
          TeamData: data.data.map((item, index) => (
            { "value": item['id'], "text": item['member_name'] }
          )),
        })
      } catch (err) {
        console.log(err);
      }
    });
  }

  editReviews = (id) => {
    api.reviews.SinglReview({ "id": id }).then(data => {
      try {
        this.setState({
          modalstatusAddEdit: true,
          modaltitle: "Edit Review",
          modalType: "Edit",
          modaldata: Object.assign({}, data.data)
        });
      } catch (err) {
        console.log(err)
      }
    });


  };

  ReplyReviews = (id) => {
    this.setState({
      modalstatusAddEdit: true,
      modaltitle: "Add Reply",
      modalType: "Reply",
      modaldata: '',
      review_id: id,
    });
  };

  AssignReviews = (id, value) => {

    api.reviews.AssignReviews({ "id": id, "value": value }).then((data) => {
      this.setState({ isLoading: true }, () => {
        this.handleFetchData();

      });
    })

  };


  modalClose = (flag, fm) => {
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
    });
  };

  onValidSubmit = (e) => {
    let sendData = {
      reviews: e.reviews,
      reply: e.reply
    };
    sendData["id"] = e.id;
    if (this.state.modalType == "Reply") {
      this.setState({ modalFormLoader: true });
      api.reviews.ReplyReview(sendData).then((data) => {
        this.setState({ modalFormLoader: false });
        try {
          this.setState({
            modalstatusAddEdit: false,
            modaltitle: "",
            modalmessage: "",
            modalbuttonmsg: {},
            modalType: "",
            modalErrorMsg: false,
            modaldata: {},
          });
          this.props.showUserToaster(
            "green",
            "User has been added successfully."
          );
        } catch (err) {
          console.log(err);
        }
      });
    }
    if (this.state.modalType == "Edit") {
      this.setState({ modalFormLoader: true });
      sendData["id"] = e.id;
      api.reviews.EditReview(sendData).then((data) => {
        try {
          this.setState({ modalFormLoader: false });
          this.setState({
            modalstatusAddEdit: false,
            modaltitle: "",
            modalmessage: "",
            modalbuttonmsg: {},
            modalType: "",
            modalErrorMsg: false,
            modaldata: {},
            isLoading: true
          }, () => {
            this.handleFetchData();

          });
          this.showUserToaster(
            "green",
            "Review has been edited successfully."
          );


        } catch (err) {
          console.log(err);
        }
      });
    }

  };

  deleteReview = (id) => {
    this.setState({
      modalstatus: true,
      modaltitle: "Confirm your action",
      modalmessage: "Are you sure you want to delete?",
      modalbuttonmsg: { submit: "Yes", cancel: "No" },
      modalType: "Delete",
      review_id: id,
    });
  };


  modalbuttofun = (data) => {
    if (data.modalType == "Delete") {
      this.onDeleteAction();
    }
  };

  onDeleteAction = () => {
    this.setState({ modalFormLoader: true });
    api.reviews.DeleteReview({ id: this.state.review_id }).then((data) => {
      this.setState({ modalFormLoader: false });
      try {
        this.setState({
          modalstatus: false,
          modaltitle: "",
          modalmessage: "",
          modalbuttonmsg: {},
          modalType: "",
          modalErrorMsg: false,
          review_id: '',
        }, () => {
          this.handleFetchData();

        });
        this.props.showUserToaster(
          "green",
          "User has been deleted successfully."
        );
      } catch (err) {
        console.log(err);
      }
    });
  };


  showToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ userToaster: false });
      }.bind(this),
      3000
    );
  };

  Paginationloop(pagelimit) {
    const options1 = this.state.options;
    let paginationloop = [];
    var k = 1;
    var p1 = 1;
    var p2 = 2;
    var p3 = 3;
    for (k == 1; k <= pagelimit; k++) {

      if (k == 1) {

        paginationloop.push(
          <li key={p1} className={'page-item ' + (options1.currentPage === p1 ? 'active' : '')}>
            <a className="page-link" href="#" onClick={() => this.handlePageChange(p1)}>{p1}</a>
          </li>
        );

      }
      if (k == 2) {

        paginationloop.push(
          <li key={p2} className={'page-item ' + (options1.currentPage === p2 ? 'active' : '')}>
            <a className="page-link" href="#" onClick={() => this.handlePageChange(p2)}>{p2}</a>
          </li>
        );

      }
      if (k == 3) {

        paginationloop.push(
          <li key={p3} className={'page-item ' + (options1.currentPage === p3 ? 'active' : '')}>
            <a className="page-link" href="#" onClick={() => this.handlePageChange(p3)}>{p3}</a>
          </li>
        );

      }


    }

    return paginationloop;

  }

  renderPagination() {
    const options = this.state.options;
    var i = 1;
    var j = 4;
    var pagelimit = '';
    if (options.lastPage < 3) {
      pagelimit = options.lastPage;
    } else {
      pagelimit = 3;
    }
    var pagination_loop = [];
    //alert(pagelimit)

    let pagination = [];
    if (options.lastPage != 0) {

      pagination.push(
        <li key="first"
          className={'page-item ' + (options.currentPage === 1 || options.currentPage === 0 ? 'disabled' : '')}>
          <a className="page-link" href="#" onClick={() => this.handlePageChange(options.currentPage - 1)}>&laquo;</a>
        </li>
      );

      pagination_loop = this.Paginationloop(pagelimit);
      pagination.push(pagination_loop);

      if (options.currentPage > 3 && options.lastPage > 3 && options.currentPage < options.lastPage) {
        pagination.push(
          <li className='page-item' >
            <a className="page-link" href="">...</a>
          </li>
        );
        pagination.push(
          <li key={options.currentPage} className={'page-item ' + (options.currentPage === options.currentPage ? 'active' : '')}>
            <a className="page-link" href="#" onClick={() => this.handlePageChange(options.currentPage)}>{options.currentPage}</a>
          </li >
        );
      }
      if (options.lastPage > 3) {
        pagination.push(
          <li className='page-item' >
            <a className="page-link" href="">...</a>
          </li>
        );

        pagination.push(
          <li key={options.lastPage} className={'page-item ' + (options.currentPage === options.lastPage ? 'active' : '')}>
            <a className="page-link" href="#" onClick={() => this.handlePageChange(options.lastPage)}>{options.lastPage}</a>
          </li>
        );
      }
      pagination.push(
        <li key="last" className={'page-item ' + (options.currentPage === options.lastPage ? 'disabled' : '')}>
          <a className="page-link" href="#" onClick={() => this.handlePageChange(options.currentPage + 1)}>&raquo;</a>
        </li>
      );
    }

    return pagination;

  }

  handleFetchData(res = 0) {

    //alert(res);
    const url = this.props.url;
    let options = Object.assign({}, this.state.options);
    let reqData = Object.assign({}, this.state.reqData);
    let self = this;
    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;
    reqData["time_zone"] = time_zone;

    if (res != 1) {

      api.reviews.custReviewsdataFromBackend(reqData).then(response => {
        try {
          //console.log(response)
          let response_data = response;
          let out_adapter = self.state.options.responseAdapter(response_data);
          if (out_adapter === undefined || !out_adapter ||
            typeof out_adapter !== 'object' || out_adapter.constructor !== Object ||
            !out_adapter.hasOwnProperty('data') || !out_adapter.hasOwnProperty('total')) {
            throw "You must return 'object' contains 'data' and 'total' attributes"
          } else if (out_adapter.data === undefined || out_adapter.total === undefined) {
            throw "Please check from returned data or your 'responseAdapter'. \n response must have 'data' and 'total' attributes.";
          }
          options.total = out_adapter.total;
          if (out_adapter.total === 0) {
            options.currentPage = 0;
            options.lastPage = 0;
            options.from = 0;
            options.to = 0;
          } else {
            options.currentPage = reqData.page;
            options.lastPage = Math.ceil(out_adapter.total / reqData.limit);
            options.from = reqData.limit * (reqData.page - 1) + 1;

            options.to = options.lastPage === options.currentPage ? options.total : reqData.limit * (reqData.page);
          }
          self.setState({
            data: out_adapter.data, options: options,
            isLoading: false,
            AvgRating: response.AvgRating,
            StarCount: response.Star_count,
            is_float: response.is_float,
            star_one: "star_custom",
            star_two: "star_custom",
            star_three: "star_custom",
            star_four: "star_custom",
            star_five: "star_custom"
          }, () => {
            this.changeStar();

          });
          if (this.state.reqData.sourceFilters == 1) {
            self.setState({
              ScrLogo: this.props.user.logoUrl
            });
          }
          else if (this.state.reqData.sourceFilters == 2) {
            self.setState({
              ScrLogo: "/assets/images/facebook.png"
            });
          } else if (this.state.reqData.sourceFilters == 3) {
            self.setState({
              ScrLogo: "/assets/images/google.png"
            });
          }


        } catch (err) {
          console.log(err)
        }
      })
    } else {

      this.setState({ isLoading: true });
      api.reviews.exportYourreviewCSVdata(reqData).then(resp => {
        try {
          //console.log(csvResponse);
          this.setState({ csvResData: resp.list }, () => {
            setTimeout(() => {
              this.setState({ isLoading: false });
              document.getElementById("csvYourReview").click();
            }, 2000);
          });

        } catch (err) {
          console.log(err)
        }
      })


    }

  }

  handlePerPageChange() {
    let pagination = [];
    var e = document.getElementById("ddlViewBy");
    var event = e.options[e.selectedIndex].value;
    let options = Object.assign({}, this.state.options);
    let reqData = Object.assign({}, this.state.reqData);

    options.perPage = event;
    reqData.limit = event;
    reqData.page = 1;

    this.setState({ reqData: reqData, options: options, isLoading: true }, () => {
      this.handleFetchData();

    });
  }

  handlePageChange(page) {
    //alert(page);
    let reqData = Object.assign({}, this.state.reqData);
    reqData.page = page;

    this.setState({ reqData: reqData, isLoading: true }, () => {
      this.handleFetchData();

    });
  }


  handleSearchClick() {
    let query = this.table_search_input.current.value;
    let reqData = Object.assign({}, this.state.reqData);
    reqData.query = query;
    reqData.page = 1;

    this.setState({ reqData: reqData, isLoading: true }, () => {
      this.handleFetchData();
    });
  }

  changeByOtherFilter = (event, data) => {
    let reqData = Object.assign({}, this.state.reqData);
    reqData.otherFilters = data.value;
    reqData.page = 1;
    this.setState({ reqData: reqData, isLoading: true }, () => {
      this.handleFetchData();

    });


  }

  changeByFilterSource = (event, data) => {
    let reqData = Object.assign({}, this.state.reqData);
    reqData.sourceFilters = data.value;
    reqData.teamFilters = '0';
    reqData.page = 1;
    this.setState({ reqData: reqData, isLoading: true }, () => {
      this.handleFetchData();
    });


  }

  changeByFilterTeam = (event, data) => {
    let reqData = Object.assign({}, this.state.reqData);
    reqData.teamFilters = data.value;
    reqData.page = 1;
    this.setState({ reqData: reqData, isLoading: true }, () => {
      this.handleFetchData();

    });


  }

  // Added by Rohit
  exportCSVData = () => {
    //let reqData = Object.assign({}, this.state.reqData);
    //alert('dg');
    this.handleFetchData(1);

  }

  FilterClear = (event, data) => {
    let reqData = Object.assign({}, this.state.reqData);
    reqData.teamFilters = '0';
    reqData.sourceFilters = 4;
    reqData.daterange = '';
    reqData.endDate = '';
    reqData.startDate = '';
    reqData.page = 1;
    this.setState({ reqData: reqData, isLoading: true, rangepicker: false, selectedtext: 'Choose Date Range' }, () => {
      this.handleFetchData();


    });


  }
  getReviews = (accountID, locationID, reviews, accessToken) => {
    if (reviews.length > 0) {
      for (let i = 0; i < reviews.length; i++) {
        this.getSpacificReview(accountID, locationID, reviews[i], accessToken);
      }
    }
  }

  getSpacificReview = (accountID, locationID, reviewInfo, accessToken) => {
    if (reviewInfo) {
      var http = new XMLHttpRequest();
      let reviewId = reviewInfo.reviewId;
      if (reviewId && reviewInfo.comment) {
        let url = "https://mybusiness.googleapis.com/v4/accounts/" + accountID + "/locations/" + locationID + "/reviews/" + reviewId + "?access_token=" + accessToken;
        http.open('GET', url, true);

        http.onreadystatechange = function () {//Call a function when the state changes.

          if (http.readyState == 4 && http.status == 200) {
            let resp = JSON.parse(http.responseText);
            if (resp && resp.comment) {
              api.googleReview.saveReviews(resp);
            }
          }
        }
        http.send();
      }



    }

  }
  updateData = (e) => {
    if (typeof e == "undefined")
      return;
    var ref = this;
    try {
      api.googleReview.getLocationList().then((resp) => {
        if (resp && resp.list && resp.list.length > 0) {
          let obj = this;
          resp.list.map((location) => {

            if (location.account_id && location.location_id && location.access_token) {

              let formData = new FormData();
              formData.append('refresh_token', location.refresh_token);
              formData.append('client_id', config.googleClientId);
              formData.append('client_secret', config.googleClientSecret);
              formData.append('redirect_uri', config.googleRedirectUrl);
              formData.append('grant_type', 'refresh_token');


              var http2 = new XMLHttpRequest();
              http2.open('POST', 'https://www.googleapis.com/oauth2/v4/token', true);

              http2.onreadystatechange = function () {//Call a function when the state changes.
                if (http2.readyState == 4 && http2.status == 200) {
                  let resp2 = JSON.parse(http2.responseText);
                  // console.log("Abbasi",resp2)
                  var http = new XMLHttpRequest();
                  if (e == "")
                    var url = "https://mybusiness.googleapis.com/v4/accounts/" + location.account_id + "/locations/" + location.location_id + "/reviews?access_token=" + resp2.access_token;
                  else
                    var url = "https://mybusiness.googleapis.com/v4/accounts/" + location.account_id + "/locations/" + location.location_id + "/reviews?access_token=" + resp2.access_token + "&pageToken=" + e;

                  http.open('GET', url, true);
                  http.onreadystatechange = function () {//Call a function when the state changes.
                    if (http.readyState == 4 && http.status == 200) {
                      let resp = JSON.parse(http.responseText);
                      // console.log("hilal",resp)
                      obj.getReviews(location.account_id, location.location_id, resp.reviews ? resp.reviews : [], resp2.access_token);
                      ref.updateData(resp.nextPageToken);
                    }
                  }
                  http.send();
                }
              }
              http2.send(formData);
            }

          })


        }
      })
    } catch (ex) {
      console.log(ex);
    }

    api.reviews.getFbPages().then(data => {
      try {
        let sendData = { "data": data.data }
        api.socialLogin.facebookLogin(sendData).then(data => {
          try {
            console.log(data);
            let data_object = Object.assign({}, data.data.data);
            this.setState({
              fb_review_current_list: data.data.data
            }, () => {

            })

          } catch (err) {
            console.log(err)
          }

        })
      } catch (err) {
        console.log(err);
      }
    })
  }


  componentDidMount() {
    const {
      setPageTitle,
      toggleNavigation,
      location,
      PageMessage,
      setMessage,
      setSubHeaderTitle,
      setNavigationName,
      toggleBreadcrum

    } = this.props;
    setPageTitle({ title: "Your Review" });
    setSubHeaderTitle({ subHeaderTitle: "Review Management" });
    setNavigationName({ navigationName: "reviewManagement" })
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    const params = queryString.parse(location.search);
    this.updateData("");
  }


  render() {
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" >
        <div className="kt-container kt-content kt-grid__item kt-grid__item--fluid mb-0">
          <div className="yr-body-p paddingRight p-r0">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="kt-portlet shadow-none">
                  <div className="kt-portlet__body yr-pd0-lr py-0">
                    <div className="row">
                      <div className="col-lg-9 border-right pt-5">
                        <div className="row pb-lg-0">
                          <div className="col-lg-6 d-flex align-items-start pb-3 pt-2">
                            <h4 className="mr-4"
                              style={{ fontWeight: 500, marginBottom: '.5rem', lineHeight: 1.2, fontSize: "1.5rem", wordBreak: 'break-word' }}>You have {this.state.options.total} reviews</h4>
                            <div className="ml-5 mr-3">{this.state.AvgRating}</div>
                            {/* <Rating maxRating={5} rating={this.state.AvgRating} icon='star' /> */}

                            <div className="stars" style={{ minWidth: 110 }}>

                              <span className={this.state.star_one}></span>
                              <span className={this.state.star_two}></span>
                              <span className={this.state.star_three}></span>
                              <span className={this.state.star_four}></span>
                              <span className={this.state.star_five}></span>

                            </div>

                          </div>
                          <div className="col-lg-6 pb-lg-3 text-right">
                            <div className="form-group row mb-lg-0 mb-4 form-wrapper mx-0 justify-content-between">
                              <div className="kt-input-icon kt-input-icon--left yr-search col-12 col-sm-5  form-search">
                                <input type="text" className="form-control" ref={this.table_search_input} id="generalSearch"
                                  onKeyUp={() => this.handleSearchClick()}
                                />
                                <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                  <span><i className="search icon"></i></span>
                                </span>
                                <span className="yr-searchPlace-holder">{/*Search*/}</span>
                              </div>
                              <div className="col-sm-5 col-12">
                                <Dropdown
                                  placeholder='Most recent'
                                  name="subject"
                                  onChange={this.changeByOtherFilter}
                                  selection
                                  className="dropdownMenu  filter- form-filter"
                                  options={changeByOtherFilteroption}
                                  value={this.state.otherFilters}
                                  icon='angle down'
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-4 mt-2 mt-lg-4  ml-auto">
                                <CSVLink
                                  id="csvYourReview"
                                  data={this.state.csvResData}
                                  style={{ display: "none", float: 'right' }}
                                  filename="your-review.csv"
                                >
                                  Export CSV
                                </CSVLink>
                                <button type="button"
                                  className="btn linear-gradient fix-btn-size yr-submitbtn btn-sm csv-btn"
                                  style={{ float: 'right', minWidth: 108 }}
                                  onClick={this.exportCSVData}>Export CSV</button>
                              </div>


                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="float-left">
                              <span>Show</span>
                              <label>
                                <select id="ddlViewBy" className="form-control form-control-sm"
                                  onChange={() => { this.handlePerPageChange() }}>
                                  {this.state.options.perPageValues.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                  ))}
                                </select>
                              </label>
                              <span> entries</span>
                            </div>

                            {/* {this.state.isLoading && (this.state.options.loading)} */}
                            <div>
                              <ul className="pagination m-0 float-right">
                                {this.renderPagination()}
                              </ul></div>
                          </div>
                        </div>
                        <div className="row max-height-510">
                          {this.state.options.total > 0 ?
                            <ReviewsDetailsFromBackend data={this.state}
                              editReviews={this.editReviews}
                              deleteReview={this.deleteReview}
                              ReplyReviews={this.ReplyReviews}
                              AssignReviews={this.AssignReviews}
                              userImageUrl={this.props.user.logoUrl}
                            />
                            :
                            <div className="col-md-12" style={{ color: '#000000', fontSize: 13, fontWeight: 500, marginTop: '1rem' }}>
                              No data? Click here to visit your <a href='settings/settings-customization' className="mx-2">Settings</a> section and connect your <img src="assets/images/Facebook-Logo.png" alt="fb" style={{ marginTop: '-10px', width: 100 }} className="mx-2" /> and<img src="assets/images/glogo.png" alt="google" style={{ marginTop: '-8px', width: 100 }} className="mx-2" />account.
                            </div>
                          }

                          {/* {this.renderData()} */}
                          {/* <ReviewsDetails data = {this.state}></ReviewsDetails> */}

                          {this.state.isLoading && <div className="ui loading form"></div>}
                        </div>
                      </div>
                      <div className="col-lg-3 py-5 px-5">
                        <div className="review-filter-header">
                          <strong>Filter by</strong>
                          <button className="btn btn-clean" onClick={this.FilterClear}>Clear</button>
                        </div>
                        <div className="mb-4">
                          <Dropdown
                            icon='angle down'
                            fluid
                            search
                            id="sourece_id"
                            selection
                            options={sourceFilter}
                            defaultValue={this.state.reqData.sourceFilters}
                            value={this.state.reqData.sourceFilters}
                            onChange={this.changeByFilterSource}
                            placeholder='All'
                          />
                        </div>
                        <div className="mb-4">
                          <Dropdown
                            icon='angle down'
                            fluid
                            search
                            onChange={this.chatTimeChange}
                            id="kt_form_type"
                            selection
                            options={TimeDuration}
                            value={this.state.reqData.daterange}
                            placeholder='All'
                          />
                        </div>
                        {this.state.rangepicker &&
                          <div className="mb-4">
                            <DateRangePicker

                              onApply={(event, picker) => this.applyDateRange(event, picker)}
                            >
                              <div className="showrangepicker">
                                <div className="form-control arrow-color mat-style" style={{ cursor: "pointer" }} > {this.state.selectedtext}</div>
                              </div>
                            </DateRangePicker>
                          </div>
                        }

                        <div className="mb-4">
                          <Dropdown
                            icon='angle down'
                            fluid
                            search
                            onChange={this.changeByFilterTeam}
                            selection
                            id="team_id"
                            options={this.state.TeamData}
                            defaultValue={this.state.reqData.teamFilters}
                            value={this.state.reqData.teamFilters}
                            placeholder='Team Member'

                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalReplyEditReview
          obj={this.state}
          modalClose={this.modalClose}
          onValidSubmit={this.onValidSubmit}
        />

        <DialogBoxDetail
          show={this.state.modalstatus}
          title={this.state.modaltitle}
          message={this.state.modalmessage}
          modalType={this.state.modalType}
          modalClose={this.modalClose}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          loading={this.state.loading}
        />
      </div>

    );
  }


}
const mapStateToProps = (state) => {
  return {
    PageMessage: state.page.message,
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setSubHeaderTitle,
  setNavigationName,
  toggleBreadcrum
})(YourReviews);




