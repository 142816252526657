import React from 'react';
import PropTypes from 'prop-types';
import { Table, Pagination } from 'semantic-ui-react';

import { DatatablePageSizeSelect } from './DatatablePageSizeSelect';
import { DatatableTableHeader } from './DatatableTableHeader';
import './OpenQuickText.css';


export const DatatableTable = props => {
  if (!props.lists) {
    return <React.Fragment />;
  }
  return (
    <React.Fragment>
 
      <Table celled selectable sortable id={"QuickTextTable"} className={"yr-modalDatatable"}>
        <DatatableTableHeader
          column={props.column}
          direction={props.direction}
          handleSort={props.handleSort}
          header={props.datatableHeader}
          checkAll={props.checkAll}

        />
        <Table.Body>{props.datatableRows}</Table.Body>
         
        {/* <Table.Footer>
          <Table.Row>
          <Table.HeaderCell colSpan="3" >
            <DatatablePageSizeSelect  limit={props.limit} onChangeLimit={props.onChangeLimit} /> Showing 1 - {props.limit} of {props.totalCount} 
            </Table.HeaderCell>
           
            <Table.HeaderCell colSpan="1" >
              <Pagination
                totalPages={props.totalPages}
                activePage={props.currentPage}
                onPageChange={props.onChangePage}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer> */}
      </Table>
      <div className="row">
				<div className="col-lg-6 col-md-6 col-sm-6">
        <DatatablePageSizeSelect  limit={props.limit} onChangeLimit={props.onChangeLimit} /> Showing 1 - {props.limit} of {props.totalCount} 

				</div>
        <div className="col-lg-6 col-md-6 col-sm-6">
        <Pagination
                totalPages={props.totalPages}
                activePage={props.currentPage}
                onPageChange={props.onChangePage}
              />
          </div>
			</div> 
    </React.Fragment>
  );
};

DatatableTable.propTypes = {
  totalCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeLimit: PropTypes.func.isRequired,
  limit: PropTypes.string.isRequired,
};
