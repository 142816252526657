import React from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import {
  Form,
  Label,
  Input,
  TextArea,
  Checkbox,
  Radio,
  RadioGroup,
  Dropdown,
  Select,
} from "formsy-semantic-ui-react";

import api from "../../../../api";
import CustomErrorField from "../../../Form/CustomErrorField";
import { Message } from "semantic-ui-react";
import utilFunc from "../../../../util_funs";
import { Icon } from "semantic-ui-react";
class WorkHours extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listdata: [],
      errors: null,
      loading: false,
      errors: { timezoneerror: false, errors: [] },
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    api.chatsetup.getWorkingHoursData().then((data) => {
      const timezone = data.data.timezone;
      try {
        if (data.data.working_hour_finish != "") {
          this.setState({
            listdata: data.data,
            templDataText: data.data.working_hour_finish,
            currenttime: data.data.currenttime,
          });
        } else {
          this.setState({
            listdata: data.data,
            templDataText:
              "Thanks! We're closed at the moment, but you will still hear from us as soon we are back in the office.",
            currenttime: data.data.currenttime,
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  renderTimeZone = (list, selectedindex) => {
    if (typeof list != "undefined") {
      return Object.keys(list).map(function (key, index) {
        return (
          <option key={index} value={key}>
            {list[key]}
          </option>
        );
      });
    }
  };

  handleSelectChange = (e) => {
    let list = this.state.listdata;
    let currentTimeZone = e.target.value;
    let default_val = e.target.value;
    let error = this.state.errors;
    if (e.target.value == "") {
      currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    api.chatsetup.getCurrentTime({ timezone: currentTimeZone }).then((data) => {
      try {
        list.selectedtimezone = default_val;

        if (
          typeof error != "undefined" &&
          error != null &&
          error.hasOwnProperty("timezoneerror") &&
          default_val != ""
        ) {
          error["timezoneerror"] = false;
        }

        this.setState({
          listdata: list,
          currenttime: data.data.currenttime,
          errors: error,
        });
      } catch (err) {
        console.log(err);
      }
    });
  };
  handleTimeChange = (index, tab, e) => {
    let list = this.state.listdata;
    let error = this.state.errors;

    if (index == 0) {
      list.list.forEach(function (item, i, arr) {
        if (
          (arr[i].working == null || arr[i].working == "") &&
          (arr[i].off == null || arr[i].off == "")
        ) {
          arr[i][tab] = e.target.value;

          if (
            typeof error != undefined &&
            error != null &&
            error.errors.hasOwnProperty(i) &&
            e.target.value != ""
          ) {
            if (tab == "open") {
              error.errors[i]["openerror"] = false;
            }
            if (tab == "close") {
              error.errors[i]["closeerror"] = false;
              error.errors[i]["timediff"] = false;
            }
          }
        }
      });
    } else {
      list.list[index][tab] = e.target.value;
      if (
        typeof error != undefined &&
        error != null &&
        error.errors.hasOwnProperty(index) &&
        e.target.value != ""
      ) {
        if (tab == "open") {
          error.errors[index]["openerror"] = false;
        }
        if (tab == "close") {
          error.errors[index]["closeerror"] = false;
          error.errors[index]["timediff"] = false;
        }
      }
    }

    this.setState({
      listdata: list,
      errors: error,
    });
  };
  handleOnOff = (index, tab) => {
    let list = this.state.listdata;
    let error = this.state.errors;

    list.list[index][tab] = list.list[index][tab] == "Y" ? null : "Y";

    if (list.list[index][tab] == "Y") {
      list.list[index]["open"] = "";
      list.list[index]["close"] = "";
      if (tab == "working") {
        list.list[index]["off"] = "";
      }
      if (tab == "off") {
        list.list[index]["working"] = "";
      }
    }

    if (
      typeof error != "undefined" &&
      error != null &&
      error.errors.hasOwnProperty(index) &&
      list.list[index][tab] == "Y"
    ) {
      error.errors[index]["openerror"] = false;
      error.errors[index]["closeerror"] = false;
      error.errors[index]["timediff"] = false;
    }

    this.setState(
      {
        listdata: list,
      },
      function () {}
    );
  };

  onchangeCustom = (e) => {
    this.setState({ templDataText: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validateForm(this.state.listdata);

    if (errors.timezoneerror || errors.errors.length > 0) {
      this.setState({
        errors: errors,
      });

      this.scroll("#workinghours");
    } else {
      this.setState({
        errors: null,
      });
      let datatosend = {
        selectedtimezone: this.state.listdata.selectedtimezone,
        working_hour_finish: this.state.templDataText,
        list: this.state.listdata.list,
      };
      this.setState({ loading: true });
      api.chatsetup.saveWorkingHoursData(datatosend).then((data) => {
        try {
          if (data.hasOwnProperty("access_token")) {
          } else {
            this.setState({ loading: false });
            this.setState({
              userToaster: true,
              userToasterMsg: data.message,
              userToasterColor: "green",
            });
            this.scroll("#workinghours");
            setTimeout(
              function () {
                this.setState({ userToaster: false });
              }.bind(this),
              3000
            );
          }
        } catch (err) {
          console.log(err);
        }
      });
    }
  };

  scroll = (element) => {
    let scrolltop =
      document.querySelector(element).offsetTop - document.body.scrollTop;
    window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
  };

  validateForm = (list) => {
    const errors = [];
    const reterror = {};
    let timezoneerror = false;
    if (list.selectedtimezone == "" || list.selectedtimezone == null) {
      timezoneerror = true;
    }
    reterror.timezoneerror = timezoneerror;
    list.list.map((x, y) => {
      if (
        (x.working == "" || x.working == null) &&
        (x.off == "" || x.off == null)
      ) {
        let openerror = false;
        let closeerror = false;
        let timediff = false;

        if (x.open == "" || x.open == null) {
          openerror = true;
        }
        if (x.close == "" || x.close == null) {
          closeerror = true;
        }

        if (this.calculateTime(x.open, x.close)) {
          timediff = true;
        }
        if (openerror || closeerror || timediff)
          errors[y] = {
            openerror: openerror,
            closeerror: closeerror,
            timediff: timediff,
          };
      }
    });
    reterror.errors = errors;
    return reterror;
  };

  calculateTime = (start, end) => {
    var day = "1/1/1970 ", // 1st January 1970
      diff_in_min =
        (Date.parse(day + end) - Date.parse(day + start)) / 1000 / 60;
    var diff = false;
    if (diff_in_min < 0) {
      var diff = true;
    }
    return diff;
  };

  render() {
    const { listdata, currenttime, errors, loading } = this.state;

    return (
      <div>
        <Message
          color={
            this.state.userToasterColor ? this.state.userToasterColor : "teal"
          }
          style={{
            display: this.state.userToaster ? "block" : "none",
            marginTop: 30,
          }}
        >
          {this.state.userToasterMsg}
        </Message>
        <div className="yr-createTicket chatbox-padd yr-chat-setup">
          <form
            id="workinghours"
            onSubmit={(e) => this.handleSubmit(e)}
            method="post"
            ref={this.myRef}
          >
            {loading && <div className="ui loading form"></div>}
            <div className="row">
              <div className="col-lg-6 pr-5">
                <div className="box-title">
                  Please set your hours of operation
                </div>
                <p className="py-3">
                  When customers chat outside your hours of operation, We'll let
                  them know you will be reaching out as soon as you open back
                  up! Feel free to customize your response below.
                </p>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <label htmlFor="exampleSelectd">
                        Select Time Zone<span className="required">*</span>
                      </label>

                      <select
                        className={
                          errors && errors.timezoneerror
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        id="exampleSelectd"
                        value={listdata.selectedtimezone}
                        onChange={(e) => this.handleSelectChange(e)}
                      >
                        <option value="">Select Time Zone</option>
                        {this.renderTimeZone(
                          listdata.timezone,
                          listdata.selectedtimezone
                        )}
                      </select>
                      {currenttime && (
                        <span className="form-text text-muted">
                          The local time is currently {currenttime}{" "}
                        </span>
                      )}

                      {errors && errors.timezoneerror && (
                        <CustomErrorField
                          message="This field is required"
                          id="timezne"
                          className="error invalid-feedback"
                        />
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="exampleSelectd">
                        How should we end a conversation when a customer chats
                        outside of working hours?
                      </label>

                      <textarea
                        className="form-control"
                        rows="4"
                        name="working_hour_finish"
                        onChange={(e) => this.onchangeCustom(e)}
                        value={this.state.templDataText}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group row px-4 mb-0 yr-scrll-x">
                  {listdata.list &&
                    listdata.list.map((obj, index) => {
                      return (
                        <ul className="yr-time-ShowHide" key={index}>
                          <li>
                            <label className="label-color">{obj.day}</label>
                          </li>
                          <li>
                            <div className="form-group">
                              <label className="drop-label">Open</label>
                              <span className="custom-arrow">
                                <select
                                  className={
                                    errors &&
                                    errors.errors.length > 0 &&
                                    errors.errors.hasOwnProperty(index) &&
                                    errors.errors[index].openerror
                                      ? "form-control is-invalid mat-style"
                                      : "form-control mat-style"
                                  }
                                  name=""
                                  value={obj.open}
                                  onChange={(e) =>
                                    this.handleTimeChange(index, "open", e)
                                  }
                                  disabled={
                                    obj.working == "Y" || obj.off == "Y"
                                      ? true
                                      : null
                                  }
                                >
                                  <option value="">Select</option>
                                  {Object.keys(listdata.hours).map(function (
                                    key,
                                    index
                                  ) {
                                    return (
                                      <option key={index} value={key}>
                                        {listdata.hours[key]}
                                      </option>
                                    );
                                  })}
                                </select>

                                {errors &&
                                  errors.errors.length > 0 &&
                                  errors.errors.hasOwnProperty(index) &&
                                  errors.errors[index].openerror && (
                                    <CustomErrorField
                                      message="This field is required"
                                      id="help-error"
                                      className="error invalid-feedback"
                                    />
                                  )}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="form-group">
                              <label className="drop-label">Closed</label>
                              <span className="custom-arrow">
                                <select
                                  className={
                                    errors &&
                                    errors.errors.length > 0 &&
                                    errors.errors.hasOwnProperty(index) &&
                                    (errors.errors[index].closeerror ||
                                      errors.errors[index].timediff)
                                      ? "form-control is-invalid mat-style"
                                      : "form-control mat-style"
                                  }
                                  name=""
                                  value={obj.close}
                                  onChange={(e) =>
                                    this.handleTimeChange(index, "close", e)
                                  }
                                  disabled={
                                    obj.working == "Y" || obj.off == "Y"
                                      ? true
                                      : null
                                  }
                                >
                                  <option value="">Select</option>
                                  {typeof listdata.hours != "undefined" &&
                                    Object.keys(listdata.hours).map(function (
                                      key,
                                      index
                                    ) {
                                      return (
                                        <option key={index} value={key}>
                                          {listdata.hours[key]}
                                        </option>
                                      );
                                    })}

                                  <option value="11:59 PM">11:59 PM</option>
                                </select>
                                {errors &&
                                  errors.errors.length > 0 &&
                                  errors.errors.hasOwnProperty(index) &&
                                  (errors.errors[index].closeerror ||
                                    errors.errors[index].timediff) && (
                                    <CustomErrorField
                                      message={
                                        errors.errors[index].timediff
                                          ? "Closed time will be greater than open time"
                                          : "This field is required"
                                      }
                                      id="help-error"
                                      className="error invalid-feedback"
                                    />
                                  )}
                              </span>
                            </div>
                          </li>
                          <li>
                            <label className="kt-checkbox chat-checkbox">
                              <input
                                type="checkbox"
                                name="hide"
                                checked={obj["off"] == "Y" ? true : false}
                                onChange={() => this.handleOnOff(index, "off")}
                              />{" "}
                              Closed
                              <span />
                            </label>
                          </li>
                          <li>
                            <label className="kt-checkbox chat-checkbox">
                              <input
                                type="checkbox"
                                name="show"
                                checked={obj["working"] == "Y" ? true : false}
                                onChange={() =>
                                  this.handleOnOff(index, "working")
                                }
                              />{" "}
                              Open 24/7
                              <span />
                            </label>
                          </li>
                        </ul>
                      );
                    })}
                </div>
                <div className="row">
                  <div
                    className="col-sm-12 d-flex justify-content-end yr-mt-20"
                    style={{ paddingRight: 0 }}
                  >
                    <button
                      className="btn linear-gradient zy-save-btn width-100"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default WorkHours;
