import React,{useState} from 'react';
import Datatable from './InvoiceDatatable/index';
import InvoiceDetail from './InvoiceDetail';
import DialogBox from '../Modal/DialogBox';
function Invoices( props ){
    const [tableElements,setTableElement] = useState({
        columns : [
            { text: "S.no", key: "number", sort: false,
                event: {
                    key: "invoiceModalOpen",
                    params: ["invoiceid", "ticketId"],
                    func: (invoiceid, ticketId) =>
                    invoiceModalOpen(invoiceid, ticketId)
                }
            },
            { text: "Invoice", key: "number", sort: true},
            { text: "Date", key: "invoice_date", sort: true },
            { text: "Amount", key: "total", sort: true },
            { text: "Status", key: "status", sort: true }
        ],
    });

    const [ incoiceDetailState,setincoiceDetailState] = useState({
        invoiceModalStatus:false,
        invoiceModalTitle:"",
        invoiceId : "",
        customer_id:"",
        invoiceHistory:[],
        invoiceLoading:true,
        refreshInvoice:false
        
    });

    const invoiceModalToggle = () => {
        setincoiceDetailState({
            ...incoiceDetailState,
            invoiceModalStatus:false,
            refreshInvoice:false
        });

    }

    const invoiceModalOpen = ( invoiceid,customer_id ) => {
        if(invoiceid){
            setincoiceDetailState({...incoiceDetailState,invoiceModalStatus:true,invoiceId:invoiceid,customer_id:customer_id,refreshInvoice:true});
        }
    }

    const incoiceDetail = (invoiceId,customer_id) => {
      
        if(!invoiceId || !customer_id){
            return false;
        }else{
         
            return <InvoiceDetail invoiceId = {invoiceId} customer_id = {customer_id} 
            user_id = {props.userId } />;
        }
    }

    return(
        <React.Fragment>
            <Datatable 
                dataColumns={tableElements.columns}
                userid = {props.userId}
                invoiceDetail = {invoiceModalOpen}
            />
            <DialogBox
                show={incoiceDetailState.invoiceModalStatus}
                title={incoiceDetailState.invoiceModalTitle}
                children={incoiceDetail(incoiceDetailState.invoiceId,incoiceDetailState.customer_id,props.userId)}
                width="70%"
                height="90%"
                message=""
                buttonText="Close"
                toggleModal = {invoiceModalToggle}
            />
        </React.Fragment>
    );
}
export default Invoices;