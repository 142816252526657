import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import {Message } from 'semantic-ui-react';

import { counter } from "@fortawesome/fontawesome-svg-core";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Multiselect } from 'multiselect-react-dropdown';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
const options = [];
const regex = new RegExp("^[a-zA-Z0-9 ]+$");
export class DatatableFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      yourteamdata:[],
      team_id: "",
      team_name: "",
      teamlist: "",
      //team_name:'cc',
      filterValid: true,
      
    };
    this.getyourcallteam();
    
  }
  
  componentDidMount() {
    this.getyourcallteam();
}
componentDidUpdate(prevProps) {
   if(this.props.indextime!=prevProps.indextime){
            this.getyourcallteam();
  }
}
handleOnChange = (event, { name, value }) => {
    if (value !== "" && !regex.test(value)) {
      this.setState({ [name]: value, filterValid: false });
    } else {
      this.setState({ [name]: value, filterValid: true });
    }
    if (value.trim() == "") {
      this.props.onSubmitFilter("q", value);
    }
  };

  handleOnClick = () => {
    //alert('Test By Rohit..');
    let value = this.state.filter;
    if (value.trim() !== "") {
      this.props.onSubmitFilter("q", value);
    }
  };

  chatTimeChange = (e) => {
    this.setState({
      chatstatus: e.target.value,
    });
    if (e.target.value == "daterange") {
      this.setState(
        {
          rangepicker: true,
          daterange: e.target.value,
        },
        function () {
          document.querySelector(".showrangepicker").click();
        }
      );
    } else {
      this.setState({ rangepicker: false, daterange: e.target.value });
      this.props.onSubmitFilter("chatfrequency", e.target.value);
    }
  };

  applyDateRange = (event, picker) => {
    this.setState({
      selectedtext:
        picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY"),
    });
    let range = {};
    range["chatfrequency"] = "daterange";
    range["startdaterange"] = picker.startDate.format("MM/DD/YYYY");
    range["enddaterange"] = picker.endDate.format("MM/DD/YYYY");
    this.props.onSubmitFilterDateReange(range);
  };
  handleClick = (e) => {
    this.datepicker.click();
  };

  //@Rohit
  getyourcallteam = () => {
    
    this.setState({
      loading: false, 
      yourteamdata:this.props.yourteamdata,
  },()=>{
    //document.querySelector('.newtestttt > div > button').addEventListener("click", this.addclick);
  });
  
  };
  
  onChange(value, event) {
    this.props.onSubmitFilter("team",value)
  }
 
  //@Ankit
  teamChange = (value, event) => {
    const TeamMember = value;
    const TeamMemberData = TeamMember.map((TeamMember) => TeamMember.value);
    this.setState({
      team: TeamMemberData,
    });
    this.props.onSubmitFilter("team", TeamMemberData);
    
  };

render() {
    const { filter } = this.state;
    const tmlist = this.state.yourteamdata;
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let final = month + "/" + date + "/" + year;

    return (
     <div className="col-md-12">
       <div className="kt-form kt-form--label-right kt-margin-t-30 kt-margin-b-30 yr-manageUser-table">
        <div className="row align-items-center">
          <div className="col-xl-12 order-2 order-xl-1">
            <div className="row align-items-center"> 
              <div className={(this.props.activetabDelay==3)?"col-md-5 kt-margin-b-20-tablet-and-mobile":"col-md-3 kt-margin-b-20-tablet-and-mobile"}>
                <div className="row align-items-center" style={{justifyContent: "flex-end"}}>
                  <div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile">
                <div className="kt-input-icon kt-input-icon--left">
                  <Form onSubmit={this.handleOnClick}>
                    <Form.Input
                      style={{ marginLeft: 4 }}
                      name="filter"
                      className="search-input-mu"
                      value={filter}
                      onChange={this.handleOnChange}
                    />
                  </Form>
                  <span
                    className="kt-input-icon__icon kt-input-icon__icon--left"
                    style={{ cursor: "pointer" }}
                    onClick={this.handleOnClick}
                  >
                    <span>
                      <i className="la la-search"></i>
                    </span>
                  </span>
                  <span className="yr-searchPlace-holder">Search</span>
                </div>
                </div>
                <div className="kt-margin-b-20-tablet-and-mobile">
                    <button
                      type="button"
                      className="btn linear-gradient btn-outline-info btn-icon-sm zy-outline yr-submitbtn fix-btn-size"
                      onClick={() => this.props.downloadCSV(this.props.tabval)} title="Export CSV"
                    >Export CSV 
                    </button>
                  </div>
                  {(this.props.activetabDelay==3)?<div className="kt-margin-b-20-tablet-and-mobile">
                    <button
                      type="button" style={{color:"white",background:"#2786fb"}}
                      className="btn linear-gradient btn-outline-info btn-icon-sm zy-outline"
                      onClick={() => this.props.subscribeCandidate()} title="Subscribe"
                    >Subscribe
                    </button>
                    <button
                      type="button" style={{color:"white",backgroundColor:"#ef6767", border:"solid 1px #ef6767"}}
                      className="btn linear-gradient btn-outline-info btn-icon-sm zy-outline"
                      onClick={() => this.props.unSubscribeCandidate()} title="Unsubscribe"
                    >Unsubscribe
                    </button>
                  </div>:""
                  }
                </div>
              </div>
            </div>
            <Message color='red' style={{ display: this.props.selectAtleastOne==true ? "block" : "none" }}>Please select atleast one email.</Message>
                <Message color='green' style={{ display: this.props.subscribedUnSubscribedMsgOpen==true ? "block" : "none" }}>{this.props.subscribedUnSubscribedMsg}</Message>

          </div>
        </div>
      </div>
     </div> 
    );
  }
}

DatatableFilter.propTypes = {
  onSubmitFilter: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  teamlist: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
};
