import { createSlice } from "@reduxjs/toolkit";

const incompleteChat = createSlice({
  name: "incompleteChat",
  initialState: {
    data: {},
    headers:[
        {
            title: "Actions",
            width: "1",
            sort: "",
        },
        {
            title: "Name",
            width: "2",
            sort: "",
        },
        {
            title: "Email",
            width: "3",
            sort: "email",
        }
    ],
    params:{
      _sort: "user_id",
      _page: 1,
      _order: "desc",
      _limit: 10,
      q: "",
      totalCount: 8,
    }
    
  },
  reducers: {
    setTableData: (state, action) => {
        state.data = action.payload.data
    },
    setParams : (state,action) => {
      let payload = action.payload;
      let keys = Object.keys(action.payload)
      if(keys[0] == '_limit'){
        state.params._limit = action.payload[keys[0]]
      }
      if(keys[0] == '_page'){
        state.params._page = action.payload[keys[0]]
      }
    }
  },
});

export const {
    setTableData,
    setParams
} = incompleteChat.actions;
export default incompleteChat.reducer;
