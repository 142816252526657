import React from "react";
import TopHeader from "./TopHeader/TopHeader";
import Navigation from "./Navigation/Navigation";
import Breadcrum from "./Breadcrum/Breadcrum";

class Header extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      pageTitle,
      pageMainTitle,
      toggleNavigation,
      toggleBreadcrum,
      navigationName,
      subHeaderTitle,
      selectedBreadCrum,
      user,
      customizedDashboard,
    } = this.props;

    //console.log(user);

    return (
      <React.Fragment>
        <TopHeader subHeaderTitle={subHeaderTitle} user={user} />
        {navigationName && (
          <Navigation
            toggleNavigation={toggleNavigation}
            navigationName={navigationName}
            user={this.props.user}
          />
        )}
        <Breadcrum
          pageTitle={pageTitle}
          subHeaderTitle={subHeaderTitle}
          toggleBreadcrum={toggleBreadcrum}
          breadcrum={selectedBreadCrum}
        />
      </React.Fragment>
    );
  }
}
export default Header;
