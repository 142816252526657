import React from "react";
import {
  toggleBreadcrum,
  setPageTitle,

} from "../../store/Page";

import { connect } from "react-redux";

import Widgets from "./Widgets";
import Facebook from "./Facebook";
import SalesForce from "./Salesforce";
import Webhook from "./Webhook";
import Keep from "./Keep";
import MarketSharp from "./MarketSharp";
import Zapier from "./Zapier";
import Instructions from "./Instructions";
import ActCrm from "./ActCrm";
import './ActCrm.css';
import ServiceTitan from "./ServiceTitan";

class InstallationAndIntegration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: "widget",
    };
    this.props.toggleBreadcrum({ toggle: false });
    this.props.setPageTitle({
      title: "Installation",
      subHeaderTitle: "Conversations",
      navigationName: null,
    });

  }

  componentDidMount() { }
  handleClick = (selectedMenu) => this.setState({ activeMenu: selectedMenu });
  render() {
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content kt-grid__item kt-grid__item--fluid">
          <div className="yr-body-p">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div>
                  <div className="kt-portlet shadow-none">
                    <div className="kt-portlet__body yr-pd0-lr yr-mt-20 yr-pd0-mob">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="box-title mx-lg-3">
                            Let's get you up and running
                          </div>
                          <div className="tab-content">
                            <div className="tab-pane active" id="a">
                              <Widgets user={this.props.user} />
                            </div>
                            <div className="tab-pane" id="b">
                              <div className="yr-chat-round-box">
                                <Facebook user={this.props.user} />
                              </div>
                            </div>
                            {/* <div className="tab-pane" id="c">
                              <div className="yr-chat-round-box">
                                Sms Texting Integration
                              </div>
                            </div> */}
                            <div className="tab-pane" id="d">
                              <div className="yr-chat-round-box">
                                <SalesForce />
                              </div>
                            </div>
                            <div className="tab-pane" id="e">
                              <div className="yr-chat-round-box">
                                <Keep />
                              </div>
                            </div>
                            <div
                              className="tab-pane"
                              id="marketsharp-integration"
                            >
                              <div className="yr-chat-round-box">
                                <MarketSharp />
                              </div>
                            </div>

                            <div className="tab-pane" id="f">
                              <div className="yr-chat-round-box">
                                <Zapier />
                              </div>
                            </div>
                            <div className="tab-pane" id="actcrm">
                              <div className="yr-chat-round-box">
                                <ActCrm />
                              </div>
                            </div>
                            <div className="tab-pane" id="servicetitan">
                              <div className="yr-chat-round-box">
                                <ServiceTitan />
                              </div>
                            </div>
                            <div className="tab-pane" id="g">
                              <div className="yr-chat-round-box">
                                <Webhook />
                              </div>
                            </div>
                            <div className="tab-pane" id="h">
                              <div className="yr-chat-round-box">
                                Google Analytics / Tag manager Setup
                              </div>
                            </div>
                            <div className="tab-pane" id="i">

                              <Instructions />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  toggleBreadcrum,
  setPageTitle,

})(InstallationAndIntegration);
