import React, { useState } from "react";
import {
	Form,Dropdown
} from 'formsy-semantic-ui-react';
import {Message } from 'semantic-ui-react';
// import CheckboxTree from "./PrevilegesTree.js";

import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdChevronRight,
  MdKeyboardArrowDown,
  MdAddBox,
  MdIndeterminateCheckBox,
  MdFolder,
  MdFolderOpen,
  MdInsertDriveFile
} from "react-icons/md";



const subscribeUnSubscribe = ({ obj, modalSubscribeUnSubscibeClose, onValidSubmit,  currentChecked, currentExpanded, setCurrentCheck, setCurrentExpand, selectAtleastOneInPopup }) => {
	let show = obj.modalstatusSubscribeUnSubscribe;
	let title = obj.modalSubscribeUnSubscribeTitle;
	let btTitle = obj.modalSubscribeUnSubscribeBtTitle;
	
	let modalErrorMsg = obj.modalErrorMsg;
	let modalErrorMsgLegacy = obj.modalErrorMsgLegacy;
	let modaldata = obj.modaldata;
	let modalFormLoader = obj.modalFormLoader;
	let selectedOption = obj.selectedOptions;
	//let domainList = obj.domainList;
	let domainList = obj.domainList.map(el => ({ label: el.email, value: el.email }));
	//let currentChecked = obj.currentChecked;

	//console.log('Rohit-currentChecked');
	//console.log(currentChecked);
	// const [check,setCheck] = useState(checked)
	// const [expand,setExpand] = useState(expanded)
	// alert(checked)
	// let options = [];
	// optionsPre.forEach((item2, index2) => {
	// 	Object.keys(item2).forEach(function (key) {
	// 		options.push({ "value": key, "text": item2[key] });
	// 	});
	// });
	const errorLabel = <div className="red"/>
	let passwordField = '';
	if (modaldata.id) {
		passwordField = <Form.Input fluid autoComplete="off" type='password' name="password" value='' placeholder='' validations='minCustomLength' validationErrors={{minCustomLength: "Please enter at least 6 characters." }} errorLabel={errorLabel}/>
	} else {
		passwordField = <Form.Input fluid autoComplete="off" type='password' name="password" value='' placeholder='' validations='isRequired,minCustomLength' validationErrors={{ isRequired: 'This field is required.', minCustomLength: "Please enter at least 6 characters." }} errorLabel={errorLabel} />
	}
	const renderTree = () =>{
		const icons = {
			check: <MdCheckBox className="rct-icon rct-icon-check" />,
			uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
			halfCheck: (
			  <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
			),
			expandClose: (
			  <MdChevronRight className="rct-icon rct-icon-expand-close" />
			),
			expandOpen: (
			  <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
			),
			expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
			collapseAll: (
			  <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
			),
			parentClose: <MdFolder className="rct-icon rct-icon-parent-close" />,
			parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" />,
			leaf: <MdInsertDriveFile className="" />
		  };
		  console.log(this);
		  return (
			<CheckboxTree
			  nodes={domainList}
			  checked={currentChecked}
			  expanded={currentExpanded}
			  onCheck={checked => onCheck(checked)}
			  onExpand={checked => onExpand(checked)}
			  icons={icons}
			/>
		  );
	}

	const onCheck = (checked)=>{
		setCurrentCheck(checked)
	}

	const onExpand = (checked)=>{
		console.log(checked)
		setCurrentExpand(checked)
	}

	const modalCloseHere = (t,fid) =>{
		modalSubscribeUnSubscibeClose()
	}

	


	return (
		<React.Fragment>
			<div
				className={show ? "modal fade show" : "modal fade"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalCloseHere()}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
						</div>
						<Form  noValidate autoComplete="off" id="formManageUser" onValidSubmit={onValidSubmit}>
						    {modalFormLoader && <div className="ui loading form"></div>}
							<div className="modal-body">
								<div className="kt-portlet__body py-0">
									<div className="kt-section kt-section--first">
										<div className="kt-section__body">
											
											<div className="form-group row" style={{paddingLeft:20,paddingRight:20}}>
												<label className="col-lg-3 col-form-label">Domain List  :</label>
												<div className="col-lg-9 col-form-label" style={{overflow:'auto',maxHeight:'135px',minHeight:'48px',marginTop:'6px',paddingLeft: '0px'}}>

												 {modalFormLoader==false ? renderTree():""}
												
												</div>
											</div>
											<div className="form-group row accessModule">
												<label className="col-lg-4 col-form-label">  </label>
												<div className="col-lg-8" style={{paddingLeft:0}}>
												<Form.Input name="domains" type="hidden" value={currentChecked}
														/>
												<div style={{display:selectAtleastOneInPopup}} class="red">Please select atleast one domain.</div>
												</div>
											</div>
											
										</div>
									</div></div></div>
							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<div className="row">
										<div className="col-lg-12 d-flex justify-content-end">
											<Form.Input name="subaccount_id" type="hidden" value={modaldata.id} />
											<button type="reset" onClick={() => modalCloseHere()} className="btn linear-gradient-cancel yr-cancelbtn mg-r"><span> Cancel </span></button>
													<button type="submit" className="btn linear-gradient yr-submitbtn">{btTitle}</button>
											

										</div>
									</div>
								</div>
							</div>
						</Form>

					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default subscribeUnSubscribe;
