import axios from "../config/axios";

const ConfigSetting = {

  adddomain: (params) =>
    axios
      .post(`/marketing/ConfigSetting/adddomain`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  addSMSNumber: (params) =>
    axios
      .post(`/marketing/ConfigSetting/addSMSNumber`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),


  getDomainDetail: (params) =>
    axios
      .post(`/marketing/ConfigSetting/getDomainDetail`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  listDomain: (params) =>
    axios
      .get(`/marketing/ConfigSetting/listDomain?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  listSmsNumber: (params) =>
    axios
      .get(`/marketing/ConfigSetting/listSmsNumber?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),


  validateDomain: (params) =>
    axios
      .post(`/marketing/ConfigSetting/validateDomain`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteDomain: (params) =>
    axios
      .post(`/marketing/ConfigSetting/deleteDomain`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deleteSmsNumber: (params) =>
    axios
      .post(`/marketing/ConfigSetting/deleteSmsNumber`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  addAreaCode: (params) =>
    axios
      .post(`/marketing/ConfigSetting/addAreaCode`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  editDomain: (params) =>
    axios
      .post(`/marketing/ConfigSetting/editDomain`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

};

export default ConfigSetting;
