import React from "react";

const CustomLabel = ({ labelInfo }) => {
	const { label, labelRequired, text, ...rest } = labelInfo;
	return (
		<React.Fragment>
			{label && (
				<label {...rest}>
					{text} {labelRequired && <span>*</span>}
				</label>
			)}
		</React.Fragment>
	);
};

export default CustomLabel;
