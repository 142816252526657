import React from "react";
import CustomErrorField from "../../Form/CustomErrorField";

class CreateBranch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      social_link: "",
      social_account: "",
      link_selected:0,
      errors: {},
    };
  }

  toggleModal = () => this.props.onclose();
  setValue = (e) => {
    this.setState({ [e.target.name]: e.target.value, loading: false });
  };

  componentDidMount() {
    this.loadData();
    this.setState({ loading: false, link_selected:0, });
  }

  componentDidUpdate(prevProps) {
    if (this.props.show != prevProps.show) {
      this.loadData();
    }
  }

  loadData = () => {
    this.setState({ social_link: "", social_account: 0, loading: false, link_selected:0,  });
  };

  changeBranch = (e) => {
    this.setState({ social_account: e.target.value, link_selected: e.target.value, });
  };

  createLink = () => {
    //alert(this.state.social_account);

    let errors = [];
    var isUrlfb = "";
    if (this.state.social_link == "") {
      errors.social_link = "This field is required";
    }

    // if (this.state.social_link != "") {
    //   var isUrlfb = this.state.social_link.match(
    //     /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    //   );
    //   //alert( isUrlfb );
    // } else {
    //   var isUrlfb = "";
    // }

    // if (isUrlfb == null) {
    //   errors.social_link = "Please fill valid url.";
    // }

    if (this.state.social_account == "0") {
      errors.social_account = "This field is required";
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors: errors });
    } else {
      this.setState({ errors: {}, loading: true }, function () {
        let data_set = {
          social_link: this.state.social_link,
          social_account: this.state.social_account,
          link_selected:"selected",
        };

        this.props.saveNewLink(
          data_set,
          this.props.show,
          () => {},
          function () {
            this.setState({ errors: {}, loading: false,link_selected:"selected", });
          }
        );
      });
    }
  };
  render() {
    let { show, title, children, width, height, message } = this.props;
    if (!width) width = "500px";
    if (!height) height = "300px";
    const { errors } = this.state;
    return (
      <React.Fragment>
        <div
          className={show != "" ? "modal fade show " : "modal fade"}
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdrop"
          aria-hidden="true"
          style={{ display: show != "" ? "block" : "none" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: width }}
          >
            <div className="modal-content">
              <button
                type="button"
                className="close linear-gradient"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.toggleModal}
              >
                X
              </button>
              <div className="modal-header">
                <h5 className="modal-title">Add Link</h5>
              </div>
              {this.state.loading && <div className="ui loading form"></div>}
              <div
                className="modal-body"
                style={{ maxHeight: height, overflowX: "auto" }}
              >
                <div class="form-group">
                  <select
                    name="social_account"
                    id="social_account"
                    value={this.state.link_selected}
                    className={
                      Object.keys(errors).length > 0 &&
                      errors.hasOwnProperty("social_account") &&
                      typeof errors.social_account != "undefined"
                        ? "form-control is-invalid focusError"
                        : "form-control focusError"
                    }
                    onChange={(e) => this.changeBranch(e)}
                  >
                    <option value="0" selected={this.state.link_selected}>Select business account </option>
                    <option value="1">Connect facebook</option>
                    <option value="2">Connect google</option>
                  </select>
                  {Object.keys(errors).length > 0 &&
                    errors.hasOwnProperty("social_account") &&
                    typeof errors.social_account != "undefined" && (
                      <CustomErrorField
                        message={errors.social_account}
                        className="error invalid-feedback"
                      />
                    )}
                </div>
                <div class="form-group mt-20">
                  {" "}
                  <input
                    value={this.state.social_link}
                    className={
                      Object.keys(errors).length > 0 &&
                      errors.hasOwnProperty("social_link") &&
                      typeof errors.social_link != "undefined"
                        ? "form-control is-invalid focusError"
                        : "form-control focusError"
                    }
                    name="social_link"
                    placeholder="Link"
                    onChange={(e) => this.setValue(e)}
                  />
                  {Object.keys(errors).length > 0 &&
                    errors.hasOwnProperty("social_link") &&
                    typeof errors.social_link != "undefined" && (
                      <CustomErrorField
                        message={errors.social_link}
                        className="error invalid-feedback"
                      />
                    )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.toggleModal}
                >
                  <span>Cancel</span>
                </button>
                <button
                  type="button"
                  onClick={() => this.createLink()}
                  className="btn linear-gradient yr-submitbtn btn-sm"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateBranch;
