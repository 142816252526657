import React from "react";
import { Form, Message,Input,Checkbox } from "semantic-ui-react";
import Validator, { blacklist } from "validator";
import Popup from "react-popup";
// import 'reactjs-popup/dist/index.css';
import api from "../../api";
import Switch from "react-switch";
import utilFunc from "../../util_funs";

import ConfirmDialog from "../Modal/ConfirmDialog";
import webhook from "../../api/installation-and-integration/webhook";

class CustomIntegration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      webhook: "",
      webhookStatus: false,
      // workspacelist:[],
      // workspace_id:'',
      successMessage: null,
      errors:{},
      blankField:[],
      modalIsOpen:false,
      showPopup:false,
      modal2:false,
      fields:[],
      is_required:[],
      delModal:false,
      delKey:"",
      key:999,
      search:[],
      btn_name:"",
      editmode:false,
      editModel:false,
      newone:""
    };
  }

  handleChange = (e) => {
    if (this.state.errors && this.state.errors.webhookAuthToken) {
      this.setState({ errors: {}, webhookAuthToken: e.target.value });
    } else {
      this.setState({ webhookAuthToken: e.target.value });
    }
  };
  handleSearch=(e) => {
     if(this.state.fields.length && this.state.webhook.length) {
      this.setState({loading:true})
      api.dialogflow.searchCustomWebhoook().then((res)=>{
         if(res.status) {
          let uparr={ successMessage: res.message};
          this.setState(uparr, () => {
            // utilFunc.scrollTop(800);
            setTimeout(() => {
              this.setState({ successMessage: null });
            }, 5000);
          });
              this.loadData();
         }

      }).catch(err=>{
        this.setState({loading:false});
        console.log(err);
      });
     }
    
  }


  handleSubmit = () => {
    let errs=this.validateForm();
    let webhookStatus=false;
     let blankField=Object.keys(errs)?.length>0 ? errs.blankField:[];
     let berrs=Object.values(blankField).filter((f)=>f.trim()!=="");
    if (Object.keys(errs.err)?.length === 0 &&  berrs.length === 0) {
      this.setState({loading:true,modalIsOpen:false,editmode:false,newone:""});
      api.dialogflow.saveCustomWebhoook({ webhookStatus:this.state.webhookStatus,webhook:this.state.webhook,fields:this.state.fields,button:this.state.btn_name,is_required:this.state.is_required}).then((resp) => {
       
        if (resp && resp.message) {
          let uparr={ successMessage: resp.message };
            uparr={ successMessage: resp.message};
            if(this.state.webhookStatus){
              setTimeout(() => {
                this.setState({loading:false})
                window.scrollTo(0, 0);
                this.loadData();
              }, 5000);
             
            }else{
              this.setState({loading:false})
            }
         
          this.setState(uparr, () => {
            // utilFunc.scrollTop(800);
            setTimeout(() => {
              this.setState({ successMessage: null });
            }, 5000);
          });
        }
      });
    }
  };

  validateForm() {
    const errors = {};
    let blankField=[];
    let fieleds=this.state.fields;
      if (this.state.webhook.trim().length>0){
        if (!this.isValidHttpUrl(this.state.webhook)) {
          errors.webhook = "Invalid webhook url.";
        }
        if(this.state.fields.length==0){
          errors.webhook = "At least one search criteria is required.";
        }
      
      } 
      
    
    if(this.state.btn_name===""){
      errors.btn_name = "Please enter button text."
    }
    if(this.state.btn_name.length>100){
      errors.btn_name = "Button text is too long."
    }
        if(this.state.fields.length>0){
         
     if(fieleds.length>0){
      for(let i=0;i<fieleds.length;i++){
        if( fieleds[i].trim().length==0){
           blankField[i]="Please enter search criteria value.";
           document.getElementById("row_"+i).scrollIntoView({
            behavior: 'smooth'
          });
        }else{
          blankField[i]="";
        }
         
      }
     }
     
   
        }
   

     
     
   
   this.setState({errors:errors,blankField:blankField});
   setTimeout(() => {
    this.setState({errors:{},blankField:[]});
   }, 5000);
  
    return {err:errors,blankField:blankField};
  }

  modalBeforeEnable = ()=>{
    // const errors = this.validateForm();
    
  }

  handleSwitch = (checked) => {
    this.setState(prevState=>({
      webhookStatus:!prevState.webhookStatus
    }),()=>{
        // if(this.state.webhookStatus===false &&(!(this.state.workspace_id==="" ||this.state.workspace_id==="0")) )
        if(this.state.webhookStatus===false){
          this.openModal()
        }
    })
  }

  disableDialogflow = ()=>{
    this.setState({
      webhookStatus:false,
      // workspace_id:'',
      modalIsOpen:false,
      loading:true,
      errors:{}
    })

    // if(!(this.state.workspace_id==="" ||this.state.workspace_id==="0")){
      // api.dialogflow.createWebhook({ webhookStatus:false, webhook:this.state.webhook, workspace_id:null}).then((resp) => {
        api.dialogflow.saveCustomWebhoook({ 
        webhookStatus:false,
        webhook:this.state.webhook,
        fields:this.state.fields,
        }).then((resp) => {
        this.setState({loading:false})
        if (resp && resp.message) {
          this.setState({ successMessage: resp.message }, () => {
            // utilFunc.scrollTop(800);
            setTimeout(() => {
              this.setState({ successMessage: null });
            }, 5000);
          });
        }
      });
    // }
  }

  closeeditModal=()=>{
    this.setState({editModel:false,editmode:false});
  }
  closeModal = ()=>{
    this.setState({modalIsOpen:false,webhookStatus:true})
  }
  openModal = ()=>{
    this.setState({modalIsOpen:true})
  }
  componentDidMount() {
    this.loadData();
  }
  loadData=()=>{
    this.setState({loading:true})
    api.dialogflow.getcustomWebhook({}).then((resp) => {
      this.setState({loading:false})
      try{
      if (resp.status) {
        const webhookInfo = resp.data;
        // const workspacelist = resp.workspace_list;
        let required_fields=webhookInfo.is_required ? webhookInfo.is_required:[];
        if(required_fields.length==0 && webhookInfo.fields.length>0){
          for(let i=0; i<=webhookInfo.fields.length;i++) {
            required_fields[i]=false;
          }
        }
        
        if(webhookInfo.fields.length==0){
          required_fields=[false];
        }
        this.setState({
          webhook: webhookInfo.webhook_url && typeof webhookInfo.webhook_url!="undefined"  ? webhookInfo.webhook_url:"",
          webhookStatus: webhookInfo.status === "ON" ? true : false,
          search:resp.search ? resp.search:[],
          fields: webhookInfo.fields ? webhookInfo.fields:[""],
          btn_name: webhookInfo.button_name!=null  && typeof webhookInfo.button_name!="undefined"  ? webhookInfo.button_name :"",
          editmode:false,
          newone:"",
          is_required:required_fields
        });
      }} catch(e){}
    });
  }

   copyURL=(inputId)=> {
    /* Get the text field */
    var copyText = document.getElementById(inputId);
  
    copyText.select();
    copyText.setSelectionRange(0, 99999); 
  

    navigator.clipboard.writeText(copyText.value);
  
    this.setState({showPopup:true},()=>{
      setTimeout(()=>{
        this.setState({showPopup:false})
      },3000)
    })

  }
  

  handleWebhookChange=(e,type,i)=>{
    let update={editmode:true};
    let value=e.target.value;
    switch (type) {
      case 1:
        update={webhook:value,editmode:true};
        break;
        case 2:
          let fields=this.state.fields;
          fields[i]=value;
        update={fields:[...fields],editmode:true};
        break;
        case 3:
          update={btn_name:value,editmode:true};
      default:
        break;
    }
    this.setState(update);
  }
  addsearchField=(e)=>{
    let fields=this.state.fields;
    let is_required=this.state.is_required;
    let nf=fields.length;
    this.setState({fields:[...fields,""],newone:nf,is_required:[...is_required,false]});
  }
  deleteField=(e,n)=>{
    this.setState({delModal:true,delKey:n});
  }
  confirmdeleteField=()=>{
    let n=this.state.delKey;
    let fields=this.state.fields;
    let reqs=this.state.is_required;
    fields.splice(n,1);
    if(typeof reqs[n]=="undefined"){
       reqs.splice(n,1);
    }
    this.setState({fields:fields,delKey:"",delModal:false,is_required:reqs});
  }
  closeDelModal=()=>{
    this.setState({delModal:false,delKey:""});
  }
  enableEdit=()=>{
     this.setState({editmode:true,editModel:false}); 
  } 
  openeditModal=()=>{
     this.setState({editModel:true});
  }  

  isValidHttpUrl=(string) =>{
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }
  markrequired=(val,i)=>{   
     let reqs=this.state.is_required;
     if(typeof reqs[i] !== "undefined") {
      
      reqs[i]=!reqs[i];
     }else{
       reqs[i]=true;
     }
     this.setState({is_required:reqs});  

  } 
  getoptionslist=(e,list,name,i)=>{
    e.preventDefault();
     let liststr="";
     if(list.length){
      name = name.split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
      liststr+=name;
      for(let i=0;i<list.length;i++){
        if(i==0){
          liststr+=" [";
        }
        liststr+=list[i].data_name+" ("+list[i].data_id+"), ";
        if(i==list.length-1){
          liststr = liststr.replace(/,\s*$/, "");
          liststr+="]";
        }
      }
     }
    if(document.getElementById("spanstr_"+i)){
      document.getElementById("spanstr_"+i).textContent=liststr;
      navigator.clipboard.writeText(liststr);
      //document.getElementById("copy_"+i).style.display="inline";
      document.getElementById("sucmsg_"+i).style.display="inline";
      setTimeout(() => {
        document.getElementById("sucmsg_"+i).style.display="none";
      }, 4000);
      
    }
  } 
  copythis=(e,list,name,i)=>{
    if(document.getElementById("spanstr_"+i)){
    const linktocopy = document.getElementById("spanstr_"+i).innerText;
    navigator.clipboard.writeText(linktocopy);
    document.getElementById("sucmsg_"+i).style.display="inline";
      setTimeout(() => {
        document.getElementById("sucmsg_"+i).style.display="none";
      }, 4000);
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.loading && <div className="ui loading form"></div>}
        {/* Modal  */}
        { this.state.modalIsOpen && (this.state.webhookStatus===false) && <ConfirmDialog
                show={this.state.modalIsOpen }
                toggleModal={this.disableDialogflow}
                closeAction={this.closeModal}
                title="Confirm your action"
                message={"Do you really want to disable configure manual data push?"}
              />
        }
        {this.state.modalIsOpen && (this.state.webhookStatus===true) && <ConfirmDialog
                show={this.state.modalIsOpen }
                toggleModal={this.handleSubmit}
                closeAction={this.closeModal}
                title="Confirm your action"
                message={"Do you want to enable configure manual data push?"}
              />}


{this.state.editModel && (this.state.webhookStatus===true) && <ConfirmDialog
                show={this.state.editModel }
                toggleModal={this.enableEdit}
                closeAction={this.closeeditModal}
                title="Confirm your action"
                message={"If you change seach fields, zap may stop working. Do you want to edit search criteria?"}
              />}
        
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            {this.state.successMessage && (
              <Message color={"green"}>{this.state.successMessage}</Message>
            )}
            <div className="kt-portlet__body kt-portlet__body--fit ">

              <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6 form-group-sub d-flex flex-row ">
                  <label class="form-control-label">Configure manual data push button</label>
                  <div style={{ margin: "3px 100px 0 10px" }}>
                    <Switch
                      onChange={this.handleSwitch}
                      checked={this.state.webhookStatus}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className="react-switch"
                      id="material-switch"
                    />
                     
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 form-group-sub d-flex">
                  {this.state.webhookStatus && <input
                        type="text"
                        name="btnmae"
                        id="myInput"
                        value={this.state.btn_name!=null ? this.state.btn_name : ""}
                        className="form-control"
                        style={{width:'100%',marginLeft:"30px;",cursor:this.state.btn_name.length==0 || this.state.editmode  ? "":'not-allowed'}}
                        aria-describedby="basic-addon"
                        disabled={(this.state.btn_name.trim().length==0) || (this.state.editmode)  ? false:true}
                        placeholder="button name"
                        onChange={(e)=>this.handleWebhookChange(e,3,'')}
                      /> }
                      {typeof this.state.errors?.btn_name!='undefined' && (
                    <div className="red" pointing={"true"}>
                      {this.state.errors?.btn_name}
                    </div>
                  )}
                  </div>
               
              </div>
              {this.state.webhookStatus &&  <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub">
                     <button
                            type="button"
                            style={{ float: "right",marginLeft: "350px" }}
                            className="btn linear-gradient yr-submitbtn btn-sm"
                            onClick={this.handleSearch}
                            disabled={this.state.fields.length && this.state.webhook.length && !this.state.editmode ? false : true}
                          >
                          Search Data
                          </button>
                    </div>} 
              <Form autoComplete="off" onSubmit={this.handleSubmit} key={this.state.key}>
              

                 <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub ">
                   {typeof this.state.errors?.fieldCount!='undefined' && (
                    <div className="red" pointing={"true"}>
                      {this.state.errors.fieldCount}
                    </div>
                  )}
                    </div>
                {this.state.webhookStatus && (
                  
                  <div class="form-group row">
                       <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mt-2">
                       
                       </div>
                   
                    <div class="col-lg-10 col-md-10 col-sm-10 form-group-sub mt-2">
                      <label class="form-control-label">
                        Zapier Webhook Url
                      </label>
                      {/* <div className="input-group input-group-sm mb-3"> */}
                      <div className="input-group mb-3">
                      <input
                        type="text"
                        name="webhook"
                        id="myInput"
                        disabled={(this.state.webhook.length==0) || (this.state.editmode) ? false:true}
                        style={{cursor:this.state.webhook.length==0 || this.state.editmode  ? "":'not-allowed'}}
                        value={this.state.webhook}
                        className="form-control"
                        aria-describedby="basic-addon"
                        onChange={(e)=>this.handleWebhookChange(e,1,"")}
                      />
                      
                      </div>
                      {typeof this.state.errors?.webhook!=="undefined" && (
                    <span className="red" pointing={"true"}>
                      {this.state.errors?.webhook}
                    </span>
                  )}
                    </div>

                    {/* field1 */}
                    {this.state.fields.length > 0 && <>
                       {this.state.fields.map((field,i)=>{
                        let searchres=Object.keys(this.state.search).length && typeof this.state.search[field]!='undefined' ? this.state.search[field]:false;
                       
                        if(this.state.editmode){
                          searchres=false;
                        }
                        
                          return (<><div class="col-lg-8 col-md-8 col-sm-8form-group-sub mt-2" key={i+1} id={`row_${i}`}>
                             
                            {searchres ? <label class="form-control-label">
                              Search Criteria &nbsp;({searchres ? field:''})
                            </label> :  
                            <label class="form-control-label">
                            Search Criteria
                          </label>}
                            <div className="input-group mb-3">
                            {searchres==false ?
                            <input
                              type="text"
                              name={`field_${i}`}
                              id={`field_${i}`}
                              value={field}
                              disabled={(field.trim().length==0) || (this.state.editmode) || this.state.newone==i ? false:true}
                              style={{cursor:field.trim().length==0 || this.state.editmode || this.state.newone==i  ? "":'not-allowed'}}
                              className="form-control"
                              aria-describedby="basic-addon"
                              onChange={(e)=>this.handleWebhookChange(e,2,i)}
                              key={i}
                            /> :
                            <select name={field} key={field}>
                               {searchres.map((seachop)=>(<option value={seachop.data_id}>{seachop.data_name}&nbsp;&nbsp;({seachop.data_id})</option>))}
                            </select>
                            }

{typeof this.state.blankField[i]!="undefined" && (
                    <p style={{float:'left',marginLeft:"-7px"}} className="red" pointing={"true"}>
                      {this.state.blankField[i]}
                    </p>
                  )}


                              </div>
                             
                         </div> 
                              <div class="col-lg-2 col-md-2 col-sm-2" style={{marginTop:'41px'}}>
                                  <Checkbox
                                label='Is required'
                                onChange={(e) => this.markrequired(field,i)}
                                checked={this.state.is_required[i]}
                                />
                        </div>
                               <div class="col-lg-1 col-md-1 col-sm-2" style={{marginTop:'29px'}}> <i onClick={(e)=>this.deleteField(e,i)} title="Delete this search criteria" class="la la-trash pull-right btn red" style={{cursor:'pointer'}}></i> </div>
                               {searchres.length>0 && <div className="col-lg-8"><a className="text-info" onClick={(e)=>this.getoptionslist(e,searchres,field,i)} style={{cursor:'pointer'}}>Click here to view and copy {field} list</a>&nbsp;&nbsp;<p><span id={`spanstr_${i}`}></span></p> <p style={{display:"none",fontWeight:'600'}}  class="text-success" id={`sucmsg_${i}`}>Data copied successfully.</p></div>}
                               </> );
                       })} 
                      </> 
                   }
                     {this.state.webhook.length>0 && <div className="col-md-12"><span onClick={(e)=>this.addsearchField(e)} style={{fontWeight: '500', cursor: 'pointer'}}>+Add a search Criteria</span></div>}
                     
                          {/* field1 */}
                                      
                  </div>
                  
                )}
                
                 <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mt-5">
                  { this.state.webhookStatus && <div style={{float:"right"}}>
                  <button
                          type="button"
                          className="btn linear-gradient yr-submitbtn btn-sm"
                          onClick={(e)=>this.handleSubmit()}
                        >
                          Save
                        </button> &nbsp;&nbsp;
                        <button
                          type="button"
                          disabled={this.state.editmode}
                          className="btn linear-gradient yr-submitbtn btn-sm"
                          onClick={(e)=>this.openeditModal()} 
                        >
                          Edit
                        </button>
                  </div>}
                       
                      </div>
              </Form>
            </div>
          </div>
          { this.state.delModal && <ConfirmDialog
                show={this.state.delModal }
                toggleModal={this.confirmdeleteField}
                closeAction={this.closeDelModal}
                title="Confirm your action"
                message={"Do you really want to delete this field?"}
              />
        }
        </div>
      </React.Fragment>
    );
  }
}

export default CustomIntegration;
