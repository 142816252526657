import React from "react";
import {
    Form, Dropdown, TextArea
} from 'formsy-semantic-ui-react';
import { Message } from 'semantic-ui-react';
import validationRule from "../../../../validation";
import "../../../Modal/dialog.css";
import './Popup.css';
import 'semantic-ui-css/semantic.min.css';
import { addValidationRule } from "formsy-react";
import axios from "../../../../config/axios";
import api from "../../../../api";

validationRule.isRequired();
validationRule.usPhoneLength();
class DialogBoxSoundNotificationSetting extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: props.obj.modalstatusNotiAandMisc,
            title: props.modaltitle,
            modalFormLoader: props.modalFormLoader,
            message: "none",
            message2: "none",
            switchValue1:"",
            switchValue2:"",
            loading:"none"
            
        }
    }

   

    
    changeSwitch = (e) => {
        // var val = e.target.value;
        // alert(e.target.value)
        // alert(e.target.value)
        var val = "";
        if(e.target.value=='ON'){
            val = "OFF";
        } else{
            val = "ON";
        }
        if(e.target.name=="first_time_sound_notification"){
            this.setState({
                switchValue1: val,
            })
        }
        else if(e.target.name=="sound_notification"){
            this.setState({
                switchValue2: val,
            })
        }
        
        
        this.onValidSubmitSwitch(e.target.name,val)
    }
    
    
    componentDidMount = () => {
    }

    onValidSubmitSwitch = (which,value) => {
        this.setState({
            loading: "block",
            message: 'none',
            message2: 'none'
        })
        let id = this.props.obj.soundNotiAndSettingmodalData.id;
        api.liveChat
            .soundNotificationSettings({which:which,user_id:this.props.obj.user.user_id,value:value})
            .then((resp) => {
                if(value=='ON')
                    value = "1";
                else
                    value = "0";                
                this.props.onValidSubmitSoundNotification(which,value)
                var ref = this;

                    this.setState({
                        loading: "none",
                        message:"block"
                    })
                    var timeOut = "";
                    clearTimeout(timeOut)
                    timeOut = setTimeout(function(){
                        ref.setState({
                            message:"none"
                        })
                    },5000)
             
                
                
            })
            .catch((e) => {
                console.log(e);
            });

    }

     
    notiAndMiscmodalClose = () => {
        this.setState({
            imgError: "none",
            message: "none",
            loading: "none",
            switchValue1:"",
            switchValue2:"",
        })
        this.props.soundSettingModalClose(false, 'manageNotification')
    }
    random = () => {
        return (
            Math.random()
        )
    }

    

    render() {
        const errorLabel = <div className="red" />
        return (
            // (this.props.loadNow)?
            
            <React.Fragment>
                {<div style={{ display: this.state.loading }} class="ui loading form mh-loading-custom"></div>}
                <div
                    id="notificationSetingModalPopup"
                    className={this.props.obj.modalstatusNotiAandMisc ? "modal fade show" : "modal fade"}
                    data-backdrop="static"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="staticBackdrop"
                    aria-hidden="true"
                    style={{ display: this.props.obj.showSoundNotiDialog ? "block" : "none" }}
                >
                    <div className="modal-dialog modal-dialog-centered" style={{maxWidth:"500px"}}  role="document">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="close linear-gradient"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => this.props.soundSettingModalClose()}
                            >
                                X
						</button>


                            {this.state.modalFormLoader && <div className="ui loading form"></div>}
                            <div className="modal-body">
                                <div className="row" style={{ width: 100 + '%' }}>
                                    <div className="col-lg-12 yr-chat-box-row">
                                        
                                        <div className="">
                                            <Form noValidate autoComplete="off" className="manageNotification" id="manageSOundNotificationSecond" onValidSubmit={this.onValidSubmitSecondCol}>
                                            
                                                <div className="yr-popBox-2">
                                                    <div className="box-title">Sound Notification Settings</div>
                                                    <Message
                                                        color={
                                                            "green"
                                                        }
                                                        style={{
                                                            display: this.state.message,
                                                        }}
                                                    >Setting has been saved successully</Message>
                                                    <div className="form-group row" style={{ marginBottom: 0 }}>
                                                        <label className="col-10 col-form-label">First Time Sound Notification</label>
                                                        <div className="col-2">
                                                            <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                                                                <label>
                                                                    <input type="checkbox" name="first_time_sound_notification" value={this.props.obj.soundNotiAndSettingmodalData.first_time_sound_notification=="1"?"ON":"OFF"} onClick={(e) => this.changeSwitch(e)} defaultChecked={this.props.obj.soundNotiAndSettingmodalData.first_time_sound_notificationChecked} />
                                                                    <span />
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row" style={{ marginBottom: 10 }}>
                                                        <label className="col-10 col-form-label">Sound Notification</label>
                                                        <div className="col-2">
                                                            <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                                                                <label>
                                                                    <input  type="checkbox" name="sound_notification" value={this.props.obj.soundNotiAndSettingmodalData.sound_notification=="1"?"ON":"OFF"} onClick={(e) => this.changeSwitch(e)} defaultChecked={this.props.obj.soundNotiAndSettingmodalData.sound_notificationChecked} />
                                                                    <span />
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>


                                                </div>
                                            </Form>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
}

export default DialogBoxSoundNotificationSetting;
