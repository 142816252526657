import React from "react";
import { connect } from "react-redux";
import ReactDOM from 'react-dom' 
import { Link } from "react-router-dom";
// import DesignRules from './DesignRules';
import api from "../../../../../api";
import '../../../TranscriptChat/TranScriptChat.css';


import { Message } from "semantic-ui-react";
import {
    setPageTitle,
    toggleNavigation,
    setMessage,
    setBreadCrum,
} from "../../../../../store/Page"; 
const queryString = require("query-string");

class ChatFilter extends React.Component {

constructor(props) {
    super(props);
   
    this.state = {
        chatstatus: 'all',
        daterange: 'alltime',
        selectedtext:'Choose Date Range ',
        rangepicker:false,
        csvdata:[],
        serach:'',
        filter:'',
        isOpen: false,
        btype:'all'

        

    };
    this.openModal = this.openModal.bind(this)

    }

    openModal= ()=> {
        this.setState({isOpen: true})
      }

    orderStatusChange = (e) => {
        this.setState({
            order_status: e.target.value
        })
        
         this.props.handleSort(e.target.value,'desc');
      

    }
    filterBucketType = (e) => {
        let v=e.target.value;
        this.setState({
            btype: v
        });
        console.log(e.target.value);
         this.props.onSubmitFilter('btype',e.target.value);
      

    }


chatStatusChange=(e)=>{
    this.setState({
        chatstatus:e.target.value
    })
    
        this.props.onSubmitFilter('chatstatus',e.target.value);
    
    
}

handleChange=(e)=>{
    console.log(e.target.value)
    this.setState({
        serach:e.target.value
    })
    
}
handleSubmit=(e)=>{
    e.preventDefault();
    console.log("---",this.state.serach);
    this.props.onSubmitFilter('q',this.state.serach);
}
chatTimeChange=(e)=>{
    this.setState({
        chatstatus:e.target.value
    })
    if(e.target.value=='daterange'){
       
        this.setState({
            rangepicker:true,
            daterange:e.target.value
        },function(){
            document.querySelector(".showrangepicker").click();
        })
        
        
    }else{
        this.setState({rangepicker:false,daterange:e.target.value});
        this.props.onSubmitFilter('chatfrequency',e.target.value);
    }
    
} 
handleClick = (e) => {
    this.datepicker.click();
  }



render() {
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let final=month+"/"+date+"/"+year;
    
    // console.log("newupdate",this.props.csvdata);
    return (
        <div class="kt-form kt-form--label-right kt-margin-t-30  yr-manageUser-table">
        <div class="row">
            <div class="col-md-3">
                <h3 class="sm-b-title">My Buckets</h3>
            </div>
            <div class="col-md-3">
                <div>
                <Link
                 className="btn linear-gradient yr-add-new float-right cursorpointer"
                 to={"/marketing/contacts/datasource"}
                >
                  View / Create Segmenting Rules
                </Link>
                {/* <a onClick={this.props.openSegmenting} href="#" class="btn linear-gradient yr-add-new float-right cursorpointer" data-toggle="modal" data-target="#kt_modal_6">View / Create Segmenting Rules</a> */}
                </div>
            </div>
            <div className="col-md-2">
                <a onClick={this.props.createBucket} href="#" class="btn linear-gradient yr-add-new float-right cursorpointer" data-toggle="modal" data-target="#kt_modal_6">Create New Bucket</a>
            </div>
                {
                    (this.props.isST) ?
                        <div className="col-md-3">
                            <a onClick={this.props.createServiceTitanBucket} href="#" class="btn linear-gradient yr-add-new float-right cursorpointer ml-2" data-toggle="modal" data-target="#kt_modal_6">Create Bucket for Service Titan</a>
                        </div> : ''
                }
            <div class="col-xl-8 order-2 order-xl-1 mt-3">
                <div class="row">       
                    <div class="col-md-12 kt-margin-b-20-tablet-and-mobile ">
                        <div class="kt-form__group kt-form__group--inline">
                            <div class="kt-form__label">
                                <label class="yr-nowrap kt-font-bolder">Sort by</label>
                            </div>
                            <div class="kt-form__control">
                                <select class="form-control" id="kt_form_status" style={{width: "auto"}} onChange={(e)=>this.orderStatusChange(e)}>
                                    <option value="title">Title</option>
                                    <option value="added_datetime">Date</option>
                                </select>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>


);
    }
}
export default ChatFilter