import React, {useState} from 'react'

const ZipcodeInputField = (props)=> {

    console.log(props.index)

return (
    <div className='row' key={props.index}>
    <label className="col-md-4 pd10botbuilder">
        Zip Code
    </label>
    <div className={`col-md-8`}>
        <div className="form-group" style={{ marginBottom: 10 }}>
            <input type="text" className={`form-control`}
                value={props.zipcodesData[props.index]}
            onChange={(e) => props.zipcodeHandler(e, props.index, 'zipcode')} 
            
            />
        </div>
    </div>
    <div className="col-md-4 pd10botbuilder">
        Send Lead Within
    </div>
    <div className="col-md-8">
        <div className="form-group" style={{ marginBottom: 10 }}>
            <select
                className="form-control"
                value={props.leadsWithinData[props.index]}
                onChange={(e) => props.sendLeadsWithinHandler(e.target.value,props.index) }
            >
                <option value="">Select</option>
                {[5, 10, 15, 20, 25, 30, 35, 40].map((x, y) => {
                    return (
                        <option value={x}>{x} Miles</option>
                    )
                })}

            </select>
        </div>
    </div>
    </div>
)}

export default ZipcodeInputField