import React from "react";
//import MultiImageInput from "react-multiple-image-input";

//import "./CustomImage.css";

class CustomImageUploadField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
    this.extractFormData = this.extractFormData.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);

    this.imgPreviewImag = React.createRef();
  }

  extractFormData = function (form) {
    const formData = new FormData(document.querySelector(form));
    let values = {};

    for (var pair of formData.entries()) {
      if (values[pair[0]]) {
        if (!(values[pair[0]] instanceof Array)) {
          values[pair[0]] = new Array(values[pair[0]]);
        }
        values[pair[0]].push(pair[1]);
      } else {
        values[pair[0]] = pair[1];
      }
    }
    return values;
  };

  generatePreviewData = (file) => {
    const fr = new FileReader();
    return new Promise((resolve, reject) => {
      fr.addEventListener("load", (e) => {
        const div = document.createElement("div");

        const img = document.createElement("img");
        img.src = fr.result;
        img.setAttribute("class", "border rounded img-preview");
        img.setAttribute("width", "200px");
        img.setAttribute("height", "200px");

        div.appendChild(img);
        resolve(div);
      });
      fr.addEventListener("error", (e) => {
        reject();
      });
      fr.readAsDataURL(file);
    });
  };

  removeAllChildren = (el) => {
    while (el.childElementCount) {
      el.removeChild(el.children[0]);
    }
  };

  renderCollection = (collection, container) => {
    Promise.all(collection.map(this.generatePreviewData)).then((imgs) =>
      imgs.map((img, i) => {
        img.setAttribute("index", i);
        img.addEventListener("click", (e) => {
          collection.splice(i, 1);
          this.renderCollection(collection, container);
        });
        return container.appendChild(img);
      })
    );
  };

  onChangeFile = (e) => {
    let fileCollection = [];

    while (fileCollection.length) {
      fileCollection.pop();
    }

    Array.from(e.target.files).map((f) => {
      if (f.type !== "" && f.type.search("image") !== -1) {
        if (f.type !== "image/webp") {
          const fsize = f.size;
          const file = Math.round(fsize / 1024);
          // The size of the file.
          if (file >= 4096) {
            this.props.addFiles(f);
          } else {
            this.props.addFiles(f);
            return fileCollection.push(f);
          }
        }
      }
    });
    this.renderCollection(fileCollection, this.imgPreviewImag.current);
  };

  render() {
    return (
      <React.Fragment>
        <input
          name="images[]"
          type="file"
          id="pictures"
          accept="image/*"
          multiple
          onChange={this.onChangeFile}
        />
        <div ref={this.imgPreviewImag} />
      </React.Fragment>
    );
  }
}

export default CustomImageUploadField;
