import React from 'react'

const ContactEngagement = ({ analyticsKey, analyticsValue, totalEmail, totalDelivered, onBoxClick }) => {
    const totalDeliver = totalDelivered ? parseInt(totalDelivered) : 0;
    const analyticsValueCal = analyticsValue ? parseInt(analyticsValue) : 0;
    const openPer = (totalDeliver > 0) ? (analyticsValueCal * 100) / totalDeliver : 0;
    const newString = analyticsKey === 'complained' ? 'Marked as spam' : `${analyticsKey[0].toUpperCase() }${analyticsKey.slice(1)}`;
    const newValueString = (analyticsKey === 'complained') || (analyticsKey === 'Unsubscribed') ? newString : '';
    return (
        <li className='pointer' onClick={() => onBoxClick(newValueString, analyticsValue, newString)}>
            <div className="yr-dr-box">
                <h3>{openPer.toFixed(2)}%</h3>
                <strong> 
                    {newString} <span className="info-icon">
                    <i className="fas fa-question-circle"></i>
                    <span className="clicktoshow"><b> {analyticsValue} </b>  
                        emails {analyticsKey} so far 
                        <b> {totalDeliver} </b> total
                    </span>
                    </span>
                </strong>
                <p><b>{analyticsValue}</b> emails</p>
            </div>
        </li>
    )
}

export default ContactEngagement;