import React from "react";
import { Form, Message,Input } from "semantic-ui-react";
import Validator from "validator";
import Popup from "react-popup";
// import 'reactjs-popup/dist/index.css';
import api from "../../api";
import Switch from "react-switch";
import utilFunc from "../../util_funs";

import ConfirmDialog from "../Modal/ConfirmDialog";

class Dialogflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      webhook: "",
      webhookAuthURL:"",
      webhookAuthToken:"",
      webhookStatus: false,
      // workspacelist:[],
      // workspace_id:'',
      successMessage: null,
      errors:{},
      modalIsOpen:false,
      showPopup:false,
      modal2:false,
      videoURL:"https://www.loom.com/embed/4ccfe4cb325748ad9f94c8fd8a69c0f6"
    };
  }

  handleChange = (e) => {
    if (this.state.errors && this.state.errors.webhookAuthToken) {
      this.setState({ errors: {}, webhookAuthToken: e.target.value });
    } else {
      this.setState({ webhookAuthToken: e.target.value });
    }
  };

  handleSubmit = () => {
    
    if (Object.keys(this.state.errors)?.length === 0) {
      this.setState({loading:true,modalIsOpen:false})
      // const workspace_id = this.state.workspace_id
      let { webhookStatus, webhook,webhookAuthURL,webhookAuthToken } = this.state;
      if (!webhookStatus) webhook = "";
      // api.dialogflow.createWebhook({ webhookStatus, webhook,workspace_id }).then((resp) => {
      api.dialogflow.createWebhook({ webhookStatus, webhook,webhookAuthURL,webhookAuthToken }).then((resp) => {
        this.setState({loading:false})
        if (resp && resp.message) {
          let uparr={ successMessage: resp.message };
           if(typeof resp?.token!=='undefined' && resp.token.length!=''){
            uparr={ successMessage: resp.message, webhookAuthToken:resp?.token,key:Math.random()};
           }
           console.log(uparr,'arr');
          this.setState(uparr, () => {
            // utilFunc.scrollTop(800);
            setTimeout(() => {
              this.setState({ successMessage: null });
            }, 5000);
          });
        }
      });
    }
  };

  validateForm() {
    const errors = {};
    // if (this.state.webhookStatus ) {
    //   if (!this.state.webhook) errors.webhook = "This field is required.";
    //   else if (!Validator.isURL(this.state.webhook)) {
    //     errors.webhook = "Invalid webhook url.";
    //   }
    // }
    if(this.state.webhookAuthToken===""){
      errors.webhookAuthToken = "Please enter auth token."
    }
    return errors;
  }

  modalBeforeEnable = ()=>{
    const errors = this.validateForm();
    if(errors.hasOwnProperty('webhookAuthToken')){
      this.setState({ errors });
    }else{
      this.openModal()
    }
  }

  handleSwitch = (checked) => {
    this.setState(prevState=>({
      webhookStatus:!prevState.webhookStatus
    }),()=>{
        // if(this.state.webhookStatus===false &&(!(this.state.workspace_id==="" ||this.state.workspace_id==="0")) )
        if(this.state.webhookStatus===false){
          this.openModal()
        }
    })
  }

  disableDialogflow = ()=>{
    this.setState({
      webhookStatus:false,
      // workspace_id:'',
      modalIsOpen:false,
      loading:true,
      errors:{}
    })

    // if(!(this.state.workspace_id==="" ||this.state.workspace_id==="0")){
      // api.dialogflow.createWebhook({ webhookStatus:false, webhook:this.state.webhook, workspace_id:null}).then((resp) => {
      api.dialogflow.createWebhook({ 
        webhookStatus:false,
        webhook:this.state.webhook,
        webhookAuthURL:this.state.webhookAuthURL,
        webhookAuthToken:this.state.webhookAuthToken
        }).then((resp) => {
        this.setState({loading:false})
        if (resp && resp.message) {
          this.setState({ successMessage: resp.message }, () => {
            // utilFunc.scrollTop(800);
            setTimeout(() => {
              this.setState({ successMessage: null });
            }, 5000);
          });
        }
      });
    // }
  }

  closeModal = ()=>{
    this.setState({modalIsOpen:false,webhookStatus:true})
  }
  openModal = ()=>{
    this.setState({modalIsOpen:true})
  }
  componentDidMount() {
    this.setState({loading:true})
    api.dialogflow.loadDialogFlow({}).then((resp) => {
      console.log(resp,"webhook resp")
      this.setState({loading:false})
      try{
      if (resp.webhook) {
        const webhookInfo = resp.webhook;
        // const workspacelist = resp.workspace_list;
        this.setState({
          webhook: webhookInfo.webhook_url,
          webhookAuthToken:webhookInfo.webhook_auth_token,
          webhookAuthURL:webhookInfo.webhook_auth_url,
          webhookStatus: webhookInfo.status === "active" ? true : false,
        });
      }} catch(e){}
    });
  }

   copyURL=(inputId)=> {
    /* Get the text field */
    var copyText = document.getElementById(inputId);
  
    copyText.select();
    copyText.setSelectionRange(0, 99999); 
  

    navigator.clipboard.writeText(copyText.value);
  
    this.setState({showPopup:true},()=>{
      setTimeout(()=>{
        this.setState({showPopup:false})
      },3000)
    })

  }
  
  openInstructionModal = ()=>{
    this.setState({modal2:true})
    this.setState({videoURL:"https://www.loom.com/embed/4ccfe4cb325748ad9f94c8fd8a69c0f6"})
  }

  closeInstructionModal = (e) => {
    this.setState({modal2:!this.state.modal2})
    console.log("iframeGMB",document.getElementsByClassName('gmb-instruction'))
    this.setState({videoURL:""})
}
  // openPage() {
  //   window.open(
  //     "https://docs.google.com/document/d/1S0lzB7wsoTVpjsEhlnoAEIlPtY_XWnNer1zBmhcnax0/edit?usp=sharing"
  //   );
  // }
  render() {
    return (
      <React.Fragment>
        {this.state.loading && <div className="ui loading form"></div>}
        {this.state.showPopup &&<Popup  position="top center">Webhook URL Copied!</Popup>}
        {/* Modal  */}
        { this.state.modalIsOpen && (this.state.webhookStatus===false) && <ConfirmDialog
                show={this.state.modalIsOpen }
                toggleModal={this.disableDialogflow}
                closeAction={this.closeModal}
                title="Confirm your action"
                message={"Do you really want to disable Google My Business?"}
              />
        }
        {this.state.modalIsOpen && (this.state.webhookStatus===true) && <ConfirmDialog
                show={this.state.modalIsOpen }
                toggleModal={this.handleSubmit}
                closeAction={this.closeModal}
                title="Confirm your action"
                message={"Do you want to enable Google My Business?"}
              />}
        <div
            className={this.state.modal2 ? "modal fade show " : "modal fade"}
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="staticBackdrop"
            aria-hidden="true"
            style={{ display: this.state.modal2 ? "block" : "none" }}
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button
                        type="button"
                        className="close linear-gradient"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={(e)=>this.closeInstructionModal(e)}
                    >
                        X
                    </button>
                    <div className="modal-body">
                    <div style={{position: "relative", paddingBottom: "56.25%", height: 0}}>
                      <iframe src={this.state.videoURL} class="gmb-instruction" title="GoogleMyBusiness Integration" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{position: "absolute", top: 0, left: 0, width: "100%",height: "100%"}}></iframe>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Modal  */}
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            {this.state.successMessage && (
              <Message color={"green"}>{this.state.successMessage}</Message>
            )}
            <div className="kt-portlet__body kt-portlet__body--fit ">
              {/* <div class="form-group row">
                <div class="col-lg-6 col-md-6 col-sm-6 form-group-sub  ">
                  <button
                    type="submit"
                    style={{ float: "right" }}
                    className="btn linear-gradient yr-submitbtn btn-sm"
                    onClick={this.openPage}
                  >
                    Dialogflow Tutorial Docs
                  </button>
                </div>
              </div> */}

              <div class="form-group row">
                <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub d-flex flex-row ">
                  <label class="form-control-label">Enable Google My Business</label>
                  <div style={{ margin: "3px 175px 0 10px" }}>
                    <Switch
                      onChange={this.handleSwitch}
                      checked={this.state.webhookStatus}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className="react-switch"
                      id="material-switch"
                    />
                  </div>
                  <button
                      style={{ marging: "0 0 15px 0", float: "right" }}
                      type="button"
                      className="btn linear-gradient yr-submitbtn"
                      onClick={this.openInstructionModal}
                  >
                      Watch Tutorial
                  </button>
                </div>
              </div>
              <Form autoComplete="off" onSubmit={this.modalBeforeEnable} key={this.state.key}>
                {this.state.webhookStatus && (
                  
                  <div class="form-group row">
                    {/* <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub ">
                        <label class="form-control-label">
                            Select Workspace <span>*</span>
                        </label>
                        <Form.Field control='select' onChange={this.handleChange} name="workspace_id" value={this.state.workspace_id}>
                          <option value=''>Select</option>
                          {this.state.workspacelist.map((workspace)=>{
                            return <option value={workspace.auto_id} key={workspace.auto_id}>{workspace.name}</option>
                          })}
                        </Form.Field> 
                        {this.state.errors && (
                        <div className="red" pointing={"true"}>
                          {this.state.errors.workspace}
                        </div>
                      )}
                    </div> */}
                    <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mt-2">
                      <label class="form-control-label">
                        Webhook Url
                      </label>
                      {/* <div className="input-group input-group-sm mb-3"> */}
                      <div className="input-group mb-3 flex-nowrap">
                      <input
                        type="text"
                        name="webhook"
                        id="myInput"
                        disabled
                        value={this.state.webhook}
                        className="form-control"
                        style={{cursor:"not-allowed"}}
                        aria-describedby="basic-addon"
                      />
                        <div className="input-group-append input-group-text" data-toggle="tooltip" title="Copy URL">
                        <i className="fa fa-copy " onClick={() => this.copyURL('myInput')} id="basic-addon"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mt-2">
                      <label class="form-control-label">
                        Authentication Url
                      </label>
                      {/* <div className="input-group input-group-sm mb-3"> */}
                      <div className="input-group mb-3 flex-nowrap">
                      <input
                        type="text"
                        name="webhook"
                        id="myInput2"
                        disabled
                        value={this.state.webhookAuthURL}
                        className="form-control"
                        style={{cursor:"not-allowed"}}
                        aria-describedby="basic-addon2"
                      /> 
                        <div className="input-group-append input-group-text" data-toggle="tooltip" title="Copy URL">
                        <i className="fa fa-copy " onClick={() => this.copyURL('myInput2')} id="basic-addon2"></i>
                        </div>
                      </div>
                      <div class="form-group-sub">
                        <label class="form-control-label">
                          Webhook Auth Token
                        </label>
                        <Form.Field control='Input' onChange={this.handleChange} name="webhook_auth_token" value={this.state.webhookAuthToken}>
                          {/* <option value=''>Select</option>
                          {this.state.workspacelist.map((workspace)=>{
                            return <option value={workspace.auto_id} key={workspace.auto_id}>{workspace.name}</option>
                          })} */}
                        </Form.Field> 
                        {this.state.errors && (
                        <div className="red" pointing={"true"}>
                          {this.state.errors.webhookAuthToken}
                        </div>
                      )}
                    </div>
                    </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mt-5">
                        <button
                          type="submit"
                          style={{ float: "right" }}
                          className="btn linear-gradient yr-submitbtn btn-sm"
                        >
                          Save
                        </button>
                      </div>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Dialogflow;
