import React from "react";
import { Link, withRouter } from "react-router-dom";

class SubNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLink: "profile",
    };
  }
  redirectUser = (link) => {
    if (link === "support") {
      this.setState({ activeLink: "support" });
      this.props.history.push("/redirect?tab=support");
    }
    if (link === "manageuser") {
      this.setState({ activeLink: "manageuser" });
      this.props.history.push("/redirect?tab=manageuser");
    }
    if (link === "profile") {
      this.setState({ activeLink: "profile" });
      this.props.history.push("/redirect?tab=profile");
    }

    if (link === "sensitive") {
      this.setState({ activeLink: "sensitive" });
      this.props.history.push("/redirect?tab=sensitive");
    }

    
  };
  render() {
    const { display } = this.props;
    return (
      <div
        style={{ display: display ? "block" : "none" }}
        className="kt-menu__submenu kt-menu__submenu--classic kt-menu__submenu--left"
      >
        <ul className="kt-menu__subnav">
          <li
            className={`kt-menu__item  ${
              this.state.activeLink === "profile"
                ? " kt-menu__item--active"
                : ""
            }`}
            aria-haspopup="true"
          >
            {" "}
            <a to="#" className="kt-menu__link "
            onClick={(link) => this.redirectUser("profile")}
            >
              <i className="kt-menu__hor-arrow la la-angle-right" />
              <span />
              <span className="kt-menu__link-text">Profile</span>
            </a>
          </li>
          <li 
          className={`kt-menu__item  ${
            this.state.activeLink === "manageuser"
              ? " kt-menu__item--active"
              : ""
          }`}
          aria-haspopup="true"
          >
            {" "}
            <a to="#" className="kt-menu__link "
            onClick={(link) => this.redirectUser("manageuser")}
            >
              <i className="kt-menu__hor-arrow la la-angle-right" />
              <span />
              <span className="kt-menu__link-text">Manage Users</span>
            </a>
          </li>
          {/* <li className="kt-menu__item " aria-haspopup="true">
            {" "}
            <a to="#"className={`kt-menu__item  ${
              this.state.activeLink === "sensitive"
                ? " kt-menu__item--active"
                : ""
            }`}
                onClick={(link) => this.redirectUser("sensitive")}
            >
              <i className="kt-menu__hor-arrow la la-angle-right" />
              <span />
              <span className="kt-menu__link-text">
                Sensitive Data Activity Log1
              </span>
            </a>
          </li> */}
          <li
            className={`kt-menu__item  ${
              this.state.activeLink === "sensitive"
                ? " kt-menu__item--active"
                : ""
            }`}
            aria-haspopup="true"
          >
            {" "}
            <a
              className="kt-menu__link "
              onClick={(link) => this.redirectUser("sensitive")}
            >
              <i className="kt-menu__hor-arrow la la-angle-right" />
              <span />
              <span className="kt-menu__link-text">Sensitive Data Activity Log</span>
            </a>
          </li>
          <li
            className={`kt-menu__item  ${
              this.state.activeLink === "support"
                ? " kt-menu__item--active"
                : ""
            }`}
            aria-haspopup="true"
          >
            {" "}
            <a
              className="kt-menu__link "
              onClick={(link) => this.redirectUser("support")}
            >
              <i className="kt-menu__hor-arrow la la-angle-right" />
              <span />
              <span className="kt-menu__link-text">Support Ticket</span>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(SubNavigation);
