import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { propTypes } from 'formsy-react'


const QuickSelect = (props) => {
  const Options = props.state.quickTextOption;
  const QuickSelectChangeHere = (event, { value}) => {
    props.QuickSelectChange(value, props.anonymous_user_id)
  }
  return (
    (props.state.join_into_conversation==1 && props.state.join_into_conversation_fromID==props.user_id && props.state.closeConversation=="n")?
  <Dropdown
    placeholder='Quick Text'
    fluid
    search
    selection
    options={Options}
    onChange = {QuickSelectChangeHere}
    value = {props.state.quickSelectVal}
  />:""
  )
}


export default QuickSelect

