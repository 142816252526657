import React from "react";
import { connect } from "react-redux";
import { toggleSubMenuSidebar } from "../../../store/Page";
class Hamburger extends React.Component {
  loadMenuSidebar = () => {
    let body = document.body;

    body.classList.add("kt-aside--on");
    this.props.toggleSubMenuSidebar({ toggle: true });
  };
  render() {
    const { pageTitle } = this.props;
    const { user } = this.props;
    var sidebar_icon_color = user.customizedDashboard[0].sidebar_icon_color;
    if (sidebar_icon_color == '') {
      sidebar_icon_color = '#FFFFFF';
    }
    return (
      <React.Fragment>
        <button
          className="kt-header-menu-wrapper-close"
          id="kt_header_menu_mobile_close_btn"
        >
          <i className="la la-close" />
        </button>
        <div className="kt-header-menu-wrapper kt-grid__item kt-grid__item--fluid">
          {/* <button onClick={this.loadMenuSidebar} > */}
          <span>
            <i class="fa fa-th mr-2" aria-hidden="true" onClick={this.loadMenuSidebar} style={{ color: sidebar_icon_color }}>
            </i>
          </span>
          {/* </button>  */}
          <span>
            <div className="yr-desktoppagetitle" style={{ color: sidebar_icon_color }}>
              {pageTitle}
              {/* <span>
                  <i className="yr-faicon yr-navicon-conversation" />
                </span> */}
            </div>
          </span>
        </div>
      </React.Fragment>
    );
  }
}
export default connect(null, { toggleSubMenuSidebar })(Hamburger);
