import React from "react";

import { Filters } from "./Filters";
import { Table, Segment, Divider } from "semantic-ui-react";
import DataColumns from "./DataColumns";
import DataRow from "./DataRow";
import Paginate from "./Paginate";
import "./grid.css";
import config from "../../config";
import util from "../../util";
class GridList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLists: [],
      totalPages: null,
      totalRecord: null,
      currentPage: 1,
      loading: false,
      params: {
        _limit: config.dataTableSettings.pageLimit,
        _order: null,
        _sort: null,
        _query: null,
        _page: 1,
        timeZone: util.getTimeZone(),
        otherParams: null,
      },
    };
  }

  componentDidMount() {
    const { dataSource, dataColumns } = this.props;
    if (dataSource) {
      this.loadData();
    }
    if (dataColumns && dataColumns.length > 0) {
      this.setColumnEvents(dataColumns);
    }
  }

  setColumnEvents = (dataColumns) => {
    if (dataColumns && dataColumns.length > 0) {
      dataColumns.forEach((column) => {
        if (column.event) {
          if (Array.isArray(column.event)) {
            column.event.forEach((item) => {
              let functionParams = item.params;
              if (functionParams) {
                window[item.key] = (...functionParams) =>
                  item.func(...functionParams);
              } else {
                window[item.key] = () => item.func();
              }
            });
          } else if (column.event.key && column.event.func) {
            let functionParams = column.event.params;
            if (functionParams) {
              window[column.event.key] = (...functionParams) =>
                column.event.func(...functionParams);
            } else {
              window[column.event.key] = () => column.event.func();
            }
          }
        }
      });
    }
  };

  getTotalPages = (totalRecord) =>
    Math.ceil(totalRecord / this.state.params._limit);

  loadData = () => {
    this.setState({ loading: true });
    if (this.props.dataSource) {
      try {
        this.props.dataSource(this.state.params).then((resp) => {
          let listData = [];
          if (resp && resp.list) {
            listData = resp.list;
            this.setState({
              dataLists: listData,
              totalRecord: resp.totalCount,
              totalPages: this.getTotalPages(resp.totalCount),
              loading: false,
            });
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  sortElement = (column, order) => {
    let query = this.state.params;
    query._sort = column;
    query._order = order;
    query._page = 1;

    this.setState({ params: query, currentPage: 1 }, () => {
      this.loadData();
    });
  };
  searchData = (searchKeyword) => {
    if (searchKeyword !== undefined) {
      let params = this.state.params;
      params._query = searchKeyword;
      params._sort = null;
      params._order = null;
      params._page = 1;
      this.setState({ params }, () => {
        this.loadData();
      });
    }
  };

  changePageLimit = (pageLimit) => {
    let params = this.state.params;
    params._limit = pageLimit;
    params._page = 1;

    this.setState({ params, currentPage: 1 }, () => this.loadData());
  };
  pagination = (page) => {
    if (page > 0 && page !== this.state.currentPage) {
      let query = this.state.params;
      query._page = page;

      this.setState({ params: query, currentPage: page }, () => {
        this.loadData();
      });
    }
  };
  componentWillReceiveProps = () => {
    if (this.props.refreshGrid === true) {
      this.loadData();
    } else if (this.props.otherParams) {
      let params = this.state.params;
      params._sort = null;
      params._order = null;
      params._page = 1;

      params.otherParams = this.props.otherParams;

      this.setState({ params }, () => {
        this.loadData();
      });
    }
  };
  render() {
    const { dataColumns, dataLoading } = this.props;
    return (
      <React.Fragment>
        <Filters
          search={(keyword) => this.searchData(keyword)}
          otherProps={this.props.children}
        />
        <Divider />
        {(this.state.loading || dataLoading) && (
          <div className="ui loading form"></div>
        )}
        <div className="table__wrapper">
          <Table celled selectable sortable>
            <DataColumns
              dataColumns={dataColumns}
              sortElement={(column, order) => this.sortElement(column, order)}
            />
            {this.state.dataLists && (
              <DataRow
                columnLength={dataColumns.length}
                dataRow={this.state.dataLists}
              />
            )}
            {this.state.totalRecord > 0 ? (
              <Paginate
                totalPages={this.state.totalPages}
                changePage={this.pagination}
                currentPage={this.state.currentPage}
                totalRecord={this.state.totalRecord}
                pageLimit={this.state.params._limit}
                changePageSize={(pageLimit) => this.changePageLimit(pageLimit)}
                columnLength={dataColumns.length}
              />
            ) : (
              <Paginate columnLength={dataColumns.length} />
            )}
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

export default GridList;
