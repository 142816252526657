import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { propTypes } from 'formsy-react'
import './NotificationMember.css';



const NotificationMemberList = (props) => {
  const Options = props.state.NotificationMemberListOption;
  const NotificationMemberChangeHere = (event, { value}) => {
    props.NotificationMemberChange(value)
  }
  return (
    (props.state.join_into_conversation==10 && props.state.join_into_conversation_fromID==props.user_id)?
  <Dropdown
    placeholder='Select Member'
    fluid
    search
    selection
    options={Options}
    onChange = {NotificationMemberChangeHere}
    value = {props.state.NotificationMemberSelectVal}
  />:""
  )
}


export default NotificationMemberList

