import axios from "../../config/axios";

const salesforce = {
  list: (params) =>
    axios
      .post("/integration/salesforce/list", params)
      .then((resp) => (resp ? resp.data : null)),

  locationList: (params) =>
    axios
      .post("/integration/salesforce/locations", params)
      .then((resp) => (resp ? resp.data : null)),

  createNew: (params) =>
    axios
      .post("/integration/salesforce/create_new", params)
      .then((resp) => (resp ? resp.data : null))
      .catch((err) => err),

  getSalesForceInfo: (params) =>
    axios
      .post("/integration/salesforce/salesforce_info", params)
      .then((resp) => (resp ? resp.data : null)),

  deleteSalesforceCredential: (params) =>
    axios
      .post("/integration/salesforce/delete_credentials", params)
      .then((resp) => (resp ? resp.data : null)),
};

export default salesforce;
