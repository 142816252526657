import React from 'react'

const ButtonClick = (props) => {
    const buttonClickHtml=()=>{
        const dataCount = props.data;
    // console.log(dataCount,"beeetnn f",props.button_ids);
    // console.log(dataCount);
    let buttonClickHtml = '';
    if (Object.keys(dataCount).length === 0) {
        return (
            <ul class="popularUrlList">
                <li>
                    <span>Records not found.</span>
                </li>
            </ul>
            
        )
    } else {
        let sortByTopClick = null;
        if (Object.keys(dataCount).length > 1) {
            sortByTopClick = Object.fromEntries(
                Object.entries(dataCount).sort(([, a], [, b]) => b - a)
            );
        } else {
            sortByTopClick = dataCount;
        }
        // console.log(sortByTopClick,"beeetnn");
        // for(const btVl in sortByTopClick){
        //     buttonClickHtml += `<li><p onClick={() => handleClick()}>${btVl}</p><div><b>${sortByTopClick[btVl]}</b></div></li>`;
        // }
        return(<>
              {Object.entries(sortByTopClick).map((btn,i)=>{
                return(<li style={{cursor:'pointer'}} key={i} onClick={(e) => handleClick(e,props.button_ids[btn[0]],btn[0])}><p>{btn[0]}</p><div><b>{sortByTopClick[btn[0]]}</b></div></li>);
              })}
             </>);
        
    }
    }
    const handleClick=(e,id,name)=>{
        if(id && typeof id !=="undefined" && id!=''){
            props.showModal(id,name);
        }
      
    }
    
    return (
        <ul className="zy-clickCounts">
            {/* <div dangerouslySetInnerHTML={{__html: buttonClickHtml }} /> */}
            <div>{buttonClickHtml()}</div>
        </ul>
    )
}

export default ButtonClick