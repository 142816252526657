import React,{useState,useEffect} from 'react'
import classes from './CompanyInformation.module.css';
const CompanyInformation = (props) => {
    const [companyData,setCompanyData] = useState( {});
    // const [dynamicNo, setDynamicNo] = useState(false);
    const [phoneNo, setPhoneNo]=useState('')
    const [prevNo, setPrevNo] = useState('')


    
    useEffect(() =>{
        setCompanyData(props.companyInfo);
        },[props.companyInfo]);
        
        useEffect(()=>{
            setPrevNo(props.companyInfo.phoneNumber)
        },[])

    const handleCompanyInfoInput  = ( e ) => {
        if(e.target.name == 'limit'){
            e.target.value = e.target.value > 0 ? e.target.value : ''
        }
        let tempInfo = {...companyData};
        tempInfo[e.target.name] = e.target.value;
        props.setValue(tempInfo,'companyInfo');
    }
   
    
const handleDynamicNo = () => { 
    if(companyData.dynamicInsertNumber=='ON'){
        let phoneToggleInfo = {...companyData};
      phoneToggleInfo['dynamicInsertNumber']='OFF';
      props.setValue(phoneToggleInfo,'companyInfo')
      phoneToggleInfo['phoneNumber']=props.companyInfo.prevNumber;
  }

  if(companyData.dynamicInsertNumber=='OFF' || companyData.dynamicInsertNumber==undefined){
    //   setDynamicNo(true)
      let phoneToggleInfo = {...companyData};
      phoneToggleInfo['dynamicInsertNumber']='ON';
      phoneToggleInfo['phoneNumber']='';
      props.setValue(phoneToggleInfo,'companyInfo')
  }
  console.log(companyData.dynamicInsertNumber)
}
  


const handlePhoneNo = (e) =>{
        setPhoneNo(e.target.value)
        let phoneInfo = {...companyData};
        phoneInfo['phoneNumber']=e.target.value;
        phoneInfo['prevNumber']=e.target.value;
        props.setValue(phoneInfo,'companyInfo')
        console.log(phoneInfo)
}


   
    const renderComapnyInfo = () => {
        return (
            <div className="row" un_key="0">
                <label className="col-md-4 pd10botbuilder">Company name</label>
                <div className="col-md-8">
                    <div className="form-group mb-2">
                        <input type="text" className="form-control" name='companyName' 
                        defaultValue={props.companyInfo.companyName}  
                        value={props.companyInfo.companyName} 
                        onChange={(event) => handleCompanyInfoInput(event)}
                        />
                    </div>
                </div>
                <label className="col-md-4 pd10botbuilder">Phone number</label>
                <div className="col-md-8">
                    <div className="form-group mb-2">
                        <input 
                        disabled={companyData.dynamicInsertNumber == 'ON' ? true : false}
                        type="text" className="form-control" name='phoneNumber' 
                        defaultValue={props.companyInfo.phoneNumber}  
                        value={props.companyInfo.phoneNumber}
                        onChange={(event) => handlePhoneNo(event)}
                        placeholder='Phone Number'
                         />
                    </div>
                </div>
                <label className="col-md-4 pd10botbuilder">Use dynamic number insertion</label>
                <div className="col-md-8">
                <div>
                    <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                        <label>
                            <input checked={companyData.dynamicInsertNumber == 'ON' ? true : false} onChange={(e)=>handleDynamicNo()} type="checkbox" />
                            <span />
                        </label>
                    </span>
                </div>
                </div>
                <label className="col-md-4 pd10botbuilder">Company website</label>
                <div className="col-md-8">
                    <div className="form-group mb-2">
                        <input type="text" className="form-control" name='website' 
                        defaultValue={props.companyInfo.website}  
                        value={props.companyInfo.website}
                        onChange={(event) => handleCompanyInfoInput(event)}
                        />
                    </div>
                </div>
                <label className="col-md-4 pd10botbuilder">Maximum number of words per response<br/><strong>(We suggest 50 words)</strong></label>
                <div className="col-md-8">
                    <div className="form-group mb-2">
                        <input type="number" min="0" className="form-control" name='limit' 
                        defaultValue={props.companyInfo.limit ? props.companyInfo.limit : ''}  
                        value={props.companyInfo.limit ? props.companyInfo.limit : ''}
                        onChange={(event) => handleCompanyInfoInput(event)}
                        pattern='[0-9]'
                        onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="box-title">Basic Info</div>
            {renderComapnyInfo()}
        </>
    )
}

export default CompanyInformation