import React from 'react'
import classes from "./ConfusedBranchDetails.module.css";
import { Checkbox } from 'semantic-ui-react';
import { useState,useEffect } from 'react';
import api from "../../../../../../api";
import Modal from "../../../../ChatSetup/components/Modal";



export default function Integration(props) {
   const [zapstatus, setzapstatus] = useState("OFF");
   const [webhookStatus, setWebhookStatus] = useState("OFF");
   const [webhookPhone, setWebhookPhone] = useState('OFF');
   const [webhookEmail, setWebhookEmail] = useState('OFF');
   const [ststatus, setststatus] = useState("OFF");
   const [zapPhoneLookup, setZAPPhoneLookup] = useState("OFF");
   const [zapEmailLookup, setZAPEmailLookup] = useState("OFF");
   const [zapcustomSearch, setZapcustomSearch] = useState("OFF");
   const [zapSearchData, setZapSearchData] = useState([]);
   const [zapSearchErrorData, setZapSearchErrorData] = useState([]);
   const [webhookPhoneInput,setWebhookPhoneInput]=useState('')
   const [webhookEmailInput,setWebhookEmailInput]=useState('')
   //st 
   const [stPhoneLookup, setSTPhoneLookup] = useState("OFF");
   const [stcustomSearch, setSTcustomSearch] = useState("OFF");
   const [stSearchData, setSTSearchData] = useState([]);
   const [stSearchErrorData, setSTSearchErrorData] = useState([]);
   const  [loading, setloading] = useState(false);
   const [modalstatus, setmodalstatus] = useState(false);
   const [modalfooter, setmodelfooter] = useState("");
   const [modaltitle, setmodaltitle] = useState("");
   const [modalbody, setmodalbody] = useState("");
   const [webhookUrlEmailValue, setWebhookUrlEmailValue] = useState("")
   const [webhookUrlPhoneValue, setWebhookUrlPhoneValue] = useState("")
   const [errmsg, seterrmsg] = useState("");
   const [sucmsg, setsucmsg] = useState("");
   const [saveZap, setsaveZap] = useState(false);
   const [saveST, setsaveST] = useState(false);
   const [zapbutton, setzapbutton] = useState(true);
   const [zapWebhookButton, setZapWebhookButton]=useState(true)
   const [stbutton, setstbutton] = useState(false);
   const [nameerror, setnameerror] = useState([]);
   const [ziperror, setziperror] = useState([]);
   const [zapapperr, setzapapperr] = useState('');
   const [stapperr, setstapperr] = useState('');
   const [phoneWebhookAlert, setPhoneWebhookAlert]=useState('OFF')
   const [emailWebhookAlert, setEmailWebhookAlert]=useState('OFF')
   const [webhookSearchRuleAlert, setWebhookSearchRuleAlert]=useState('OFF')
   const [webhookSearchRuleFieldAlert, setWebhookSearchRuleFieldAlert]=useState('OFF')
   const [stSearchRuleAlert, setStSearchRuleAlert]=useState('OFF')
   const [allFieldsError, setAllFieldsError]=useState(false)
   
   const INCLUDES=['Only Numbers','Only Letters','Number and Letters','Number Or Letters'];
   
   useEffect(() => {
    console.log(props.integration_branch_id && props.idata.workspaceId)
        setloading(true);
        if(props.integration_branch_id || props.idata.branch_id){
          loadData(true,0);
        }else{
            setloading(false);
        }
   }, [props]);

    useEffect(()=>{
        setzapbutton(true);
    },[zapstatus])
    useEffect(()=>{
        setstbutton(true);
    },[ststatus]);

   const loadData=(onoff,zaporst)=>{
	setziperror([]);
	setnameerror([]);
	setZapSearchErrorData([]);
	setSTSearchErrorData([]);
    api.botbuilder
    .getintegrationlist({branch_id:props.integration_branch_id,workspace_id:props.idata.workspaceId})
    .then((data) => {
        try {
            if (data.status) {
                console.log(props.idata.branch_id)
                let listdata=JSON.parse(data.data);
                let zpdata=[];
                 let stdata=[];
                 let zapemail=false;
                 let zapphone=false;
                 let stphone=false;
                 let zapapp=false;
                 let stapp=false;
                 let zapierOn=data.zp_integration;
                 let serviceTitanOn=data.st_integration;
                 if(zapierOn=='OFF'){
                    setWebhookStatus("OFF");
                    setWebhookPhone('OFF')
                    setWebhookEmail('OFF')
                    setWebhookEmailInput('')
                    setWebhookPhoneInput('')
                 }
                 console.log("----------------->>>>>>>>>>>>>>>>>>>>>> data fetched successfully",zapierOn,onoff)
                 if(listdata.length>0){
                    for(let i=0;i<listdata.length;i++){
                        if(listdata[i].crmType=="ZAPIER"){
                            zapapp=true;
                            if((listdata[i].captureField!=null && listdata[i].captureField!='email' && listdata[i].captureField!='phone' ) && listdata[i].characters!=null && listdata[i].includes!=null){
                                zpdata.push({id:listdata[i].id,captureField:listdata[i].captureField,characters:listdata[i].characters,includes:listdata[i].includes,webhook_url:listdata[i].webhook_url});
                            }else {
                                if(listdata[i].captureField=="email"){
                                    zapemail=true;
                                    setWebhookEmailInput(listdata[i].webhook_url)
                                }
                                if(listdata[i].captureField=="phone"){
                                    zapphone=true;
                                    setWebhookPhoneInput(listdata[i].webhook_url)
                                 }
                               
                            }
                        }else if(listdata[i].crmType=="SERVICETITAN"){
                            stapp=true;
                            if((listdata[i].captureField!=null && listdata[i].captureField!='email' && listdata[i].captureField!='phone' ) && listdata[i].characters!=null && listdata[i].includes!=null){
                                stdata.push({id:listdata[i].id,captureField:listdata[i].captureField,characters:listdata[i].characters,includes:listdata[i].includes});
                            }else {
                                if(listdata[i].captureField=="phone"){
                                    stphone=true;
                                 }
                               
                            } 
                        }
                    }
                    if(onoff==true){
                        if(zapierOn=='ON'){
                            setzapstatus("ON");
                            setWebhookStatus("ON");
                        }
                        if(zapierOn=='OFF'){
                            setWebhookStatus("OFF");
                            setzapstatus("OFF");
							setZAPPhoneLookup("OFF");
							setZAPEmailLookup("OFF");
							setZapcustomSearch("OFF")
                        }
                        if(serviceTitanOn=='ON'){
                            setststatus("ON")
                        }else{
                            setststatus("OFF");
							setSTPhoneLookup("OFF");
							setSTcustomSearch("OFF");
                        }
                    }
                    console.log(zapphone,zapemail,stphone,'phone')
                    if(zaporst==0 || zaporst==1 || zaporst==3 || zaporst==5){
                        if(zpdata.length){
                            setZapcustomSearch("ON");
                            
                            
                        }else{
                            setZapcustomSearch("OFF"); 
                        }
                        if(zapemail==true){
                            setZAPEmailLookup("ON");
                            setWebhookEmail('ON')
                        }else{
                            setZAPEmailLookup("OFF");
                            setWebhookEmail('OFF')
                            setWebhookEmailInput('')
                        }
                        if(zapphone==true){
                            setZAPPhoneLookup("ON");
                            setWebhookPhone('ON')
                        }else{
                            setZAPPhoneLookup("OFF");
                            setWebhookPhone('OFF')
                            setWebhookPhoneInput('')
                        }
                        setZapSearchData(zpdata);
                    }
                    if(zaporst==0 || zaporst==2 || zaporst==4 || zaporst==6){
                        if(stphone==true){
                            setSTPhoneLookup("ON");
                            setststatus("ON")
                        }else{
                            setSTPhoneLookup("OFF");
                        }
                        if(stdata.length){
                            setSTcustomSearch("ON");
                        }else{
                            setSTcustomSearch("OFF"); 
                        }
                        setSTSearchData(stdata);
                    } 
                 }else{
                    if(zaporst==0 || zaporst==1 || zaporst==3 || zaporst==5){
                        setZAPPhoneLookup("OFF");
                        setZAPEmailLookup("OFF");
                        setZapcustomSearch("OFF");
                        setZapSearchData([]);
                    }
                    if(zaporst==0 || zaporst==2 || zaporst==4 || zaporst==6){
                        setSTPhoneLookup("OFF");
                        setSTcustomSearch("OFF");
                        setSTSearchData([]);
                    }
                    if(onoff===true){
                        setzapstatus('OFF')
                        setststatus('OFF')
                    }
                 }
            }
            setloading(false);
         } catch (err) {
            setloading(false);
            console.log(err);
        }
        
    });       
   }

   const switchZap=(e,field="",type)=>{
       let name=field;
       if(name=="zapier_div"){
        setzapapperr("");
        if(zapstatus=="ON"){
            // setzapstatus("OFF");
            let footer=modalFooterDelete("OFF",ststatus,3);
            setmodelfooter(footer);
            setmodalstatus(true);
            setmodalbody("This action will delete all data. Are you sure want to proceed?");
            setmodaltitle("Confirm your action")
            setmodalstatus(true);
         }else{
            setzapstatus("ON");
            //toggleApp("ON",ststatus,false);
         }
         
    }
    else if(name=="webhook_div"){
        setZapWebhookButton(false)
        setzapapperr("");
        if(webhookStatus=="ON"){
            let footer=modalFooterDelete("OFF",ststatus,5);
            setmodelfooter(footer);
            setmodalstatus(true);
            setmodalbody("This action will delete all data. Are you sure want to proceed?");
            setmodaltitle("Confirm your action")
            setmodalstatus(true);
         }else{
            setWebhookStatus("ON");
            setZapWebhookButton(true)
            // toggleApp(zapstatus,'OFF',false);
         }
    }else if(name=="st_div"){
        setstbutton(false)
        setstapperr("");
        if(ststatus=="ON"){
            //setststatus("OFF");
            let footer=modalFooterDelete(zapstatus,"OFF",6);
            setmodelfooter(footer);
            setmodalstatus(true);
            setmodalbody("This action will delete all data. Are you sure want to proceed?");
            setmodaltitle("Confirm your action")
            setmodalstatus(true);
         }else{
            setststatus("ON");
            setstbutton(false)
            //toggleApp(zapstatus,'ON',false);
         }
            
    }else if(name=="phonelookup"){
        setzapbutton(false);
        if(zapPhoneLookup=="ON"){
            setZAPPhoneLookup("OFF");
         }else{
            setzapapperr("");
            setZAPPhoneLookup("ON");
         }
    }else if(name=="emaillookup"){
        setzapbutton(false);
        if(zapEmailLookup=="ON"){
            setZAPEmailLookup("OFF");
         }else{
            setzapapperr("");
            setZAPEmailLookup("ON");
         }
    }else if(name=="zapcustomSearch"){
        setZapWebhookButton(false)
        setzapbutton(false);
        if(zapcustomSearch=="ON"){
            //setZapcustomSearch("OFF");
            let footer=modalFooterDelete(zapstatus,"OFF",3);
            setmodelfooter(footer);
            setmodalstatus(true);
            setmodalbody("This action will delete all custom search data. Are you sure want to proceed?");
            setmodaltitle("Confirm your action")
            setmodalstatus(true);
         }else{
            setzapapperr("");
            setZapcustomSearch("ON");
            if(zapSearchData.length==0){
            let idd = "id" + Math.random().toString(16).slice(2)
             setZapSearchData([{id:idd,captureField:"",characters:'',includes:'',webhook_url:''}])
            }
         }
    }else if(name=="stcustomSearch"){
        setstbutton(false);
        if(stcustomSearch=="ON"){
            let footer=modalFooterDelete(zapstatus,"OFF",4);
            setmodelfooter(footer);
            setmodalstatus(true);
            setmodalbody("This action will delete all custom search data. Are you sure want to proceed?");
            setmodaltitle("Confirm your action")
            setmodalstatus(true);
         }else{
            setstapperr("")
            setSTcustomSearch("ON");
            if(stSearchData.length==0){
                let id = "id" + Math.random().toString(16).slice(2)
                setSTSearchData([{id:id,captureField:"",characters:'',includes:''}])
            }
         }
    }
    else if(name=="stPhoneLookup"){
        setstbutton(false);
        if(stPhoneLookup=="ON"){
            setSTPhoneLookup("OFF");
         }else{
            setstapperr("")
            setSTPhoneLookup("ON");
         }
        
    }
     
   }
   const updatezapvalue=(e,name,index,type)=>{
    if(type==1){
        setZapWebhookButton(false);
    }
    setZapSearchErrorData([]);
    setzapapperr("");
    let zapdata=[...zapSearchData];
      let v=e.target.value.trim() !="" ? e.target.value:'';
      if(typeof zapdata[index]==='undefined'){
         let dta={[name]:v};
         zapdata[index]=dta;
         setZapSearchData(zapdata);
      }else{
       let zapfiled=zapdata[index];
        zapfiled[name]=v;
        zapdata[index]=zapfiled;
        setZapSearchData(zapdata);
        console.log(zapdata)
      }
   }

// st
const updatestvalue=(e,name,index)=>{
    setstbutton(false);
    setSTSearchErrorData([]);
    setziperror([])
    setnameerror([]);
    setstapperr("");
    let zapdata=[...stSearchData];
      let v=e.target.value.trim() !="" ? e.target.value:'';
      if(typeof zapdata[index]==='undefined'){
         let dta={[name]:v};
        
         if(name=='captureField'){
            if(v=='name'){
                dta['includes']='Only Letters';
                dta['characters']=3;
            }else{
                dta['includes']='Only Numbers';
                dta['characters']=5;
            }
       }
        zapdata[index]=dta;
         setSTSearchData(zapdata);
      }else{
       let zapfiled=zapdata[index];
        zapfiled[name]=v;
        zapdata[index]=zapfiled;
        if(name==='captureField'){
             if(v=='name'){
                console.log(name,v,1)
                zapfiled['includes']='Only Letters';
                zapfiled['characters']=3;
                zapdata[index]=zapfiled;
                
             }else{
                console.log(name,v,2,zapfiled)
                zapfiled['includes']='Only Numbers';
                zapfiled['characters']=5;
                console.log(name,v,2,zapfiled)
                zapdata[index]=zapfiled;
             }
        }
        setSTSearchData(zapdata);
      }
   }



   const addzapfields=(e)=>{
    setZapWebhookButton(false)
    setzapbutton(false);
    let zapdata=[...zapSearchData];
    let id = "id" + Math.random().toString(16).slice(2)
    zapdata.push({id:id,captureField:'',characters:'',includes:'',webhook_url:''
      });
       setZapSearchData(zapdata);
       setZapcustomSearch("ON")
   }

   const addstfields=(e)=>{
    setstbutton(false);
    let zapdata=[...stSearchData];
    let id = "id" + Math.random().toString(16).slice(2)

    zapdata.push({id:id,captureField:'',characters:'',includes:''
      });
       setSTSearchData(zapdata);
       setSTcustomSearch("ON")
   }
   
   const confirmDelete=(e,index,type)=>{
        let footer=modalFooterDelete(index,type,1);
        setmodelfooter(footer);
        setmodalstatus(true);
        setmodalbody("Are you sure want to delete it?");
        setmodaltitle("Confirm your action")
        setmodalstatus(true);
        }
        const isInt=(value)=> {
            return !isNaN(value) && 
                parseInt(Number(value)) == value && 
                !isNaN(parseInt(value, 10));
    }
   
   const removeFiled=(index,type)=>{
    setmodalstatus(true);
    setmodalstatus(false);
    let zapdata= type==1 ? [...zapSearchData] : [...stSearchData];
    let deldata=zapdata.filter(item=>item.id==index);
    if(!isInt(index)){
        zapdata = zapdata.filter(item => item.id != index)
        if(type==1){
            setZapSearchData(zapdata);
        }else{
            setSTSearchData(zapdata);
        }
        if(zapdata.length===0){
            if(type==1){
                setZapcustomSearch("OFF");
            }else{
                setSTcustomSearch("OFF");
            }  
        } 
        if(type==1){
            setzapbutton(false);
        }else{
            setstbutton(false);
        }
        return false;
    }else{
        setloading(true);
    api.botbuilder
    .deleteCustomSearchFormIntegration({id:index})
    .then((data) => {
        try {
            if (data.status) {
                setloading(false);
                zapdata = zapdata.filter(item => item.id != index)
                console.log("dell",index,zapdata,deldata)
                console.log(zapdata,'dell');
                if(type==1){
                    setZapSearchData(zapdata);
                }else{
                    setSTSearchData(zapdata);
                }
                if(zapdata.length===0){
                    if(type==1){
                        setZapcustomSearch("OFF");
                    }else{
                        setSTcustomSearch("OFF");
                    }
                }  
               // loadData(false);
                if(type==1){
                    setzapbutton(false);
                }else{
                    setstbutton(false);  
                }
            }
        }catch(err){
            setloading(false);
            if(type==1){
                setzapbutton(false);
            }else{
                setstbutton(false);  
            }
            console.log(err);
        }
    });

    }
    
     
   }
  
   const toggleApp=(zap,st,tab=2)=>{
    console.log(zap,st,tab,'tab..')
    if(tab==3 || tab==4){
        setloading(true);
        setmodalstatus(false);
        let lookphone=0;
        let lookemail=0;
        let stlookphone=0;
        if(tab==3){
            setzapapperr("");
            lookphone=zapPhoneLookup=='ON' ? 1:0;
            lookemail=zapEmailLookup=='ON' ? 1:0;
            setZapcustomSearch("OFF");
            setZapSearchData([]);
        }
        else{
            setstapperr("");
            stlookphone=stPhoneLookup=='ON' ?  1 :null;
            setSTcustomSearch("OFF");
            setSTSearchData([]);
        }

        let postdata;
        if(tab==4){
            postdata={
                workspace_id:props.idata.workspaceId,
                branch_id:props.idata.branch_id,
                user_id:props.idata.user_id,
                crm_type:tab==3 ? "Zapier":'ServiceTitan',
                phone:stlookphone,
                customsearch:[]
             }
        }else{
            postdata={
                workspace_id:props.idata.workspaceId,
                branch_id:props.idata.branch_id,
                user_id:props.idata.user_id,
                crm_type:tab==3 ? "Zapier":'ServiceTitan',
                phone:lookphone,
                email:lookemail,
                webhook_url_phone:webhookPhoneInput,
                webhook_url_email:webhookEmailInput,
                customsearch:[]
             }
        }
            
        api.botbuilder
        .createChatGptIntegrationValue(postdata)
        .then((data) => {
            try{
                if (data.status) {
                setTimeout(()=>{
                    setloading(false)
                },1000)
                loadData(false,tab);
                }
            }catch(err){
                setloading(false);
                 console.log(err)
                 if(tab==3){
                    setzapbutton(false);
                }else{
                    setstbutton(true);  
                }
         
            }
        });   
    }else{
       
        setststatus(st);
            let appdata={
                workspace_id:props.idata.workspaceId,
                branch_id:props.idata.branch_id,
                user_id:props.idata.user_id
            }
            if(tab==5){
                setzapapperr("")
                setzapstatus('OFF');
                appdata['integration_type']='ZAPIER';
                appdata['status']="OFF";
            }else if(tab==6){
                setstapperr("");
                setststatus("OFF");
                appdata['integration_type']='SERVICETITAN'; 
                appdata['status']='OFF';
            }else if(tab==7){
                setstapperr("");
                setWebhookStatus("OFF");
                // appdata['integration_type']='SERVICETITAN'; 
                appdata['status']='OFF';
            }
            setloading(true);
            setmodalstatus(false);
            api.botbuilder
            .createChatGptIntegration(appdata)
            .then((data) => {
                if (data.status) {
                    setloading(false);
                    loadData(false,tab);
                    if(tab==5){
                        setZAPEmailLookup("OFF");
                        setZAPPhoneLookup("OFF");
                        setZapcustomSearch("OFF");
                        setZapSearchData([]);
                        setWebhookStatus("OFF");
                        setWebhookPhoneInput('')
                        setWebhookEmailInput('')
                        setWebhookPhone('OFF')
                        setWebhookEmail('OFF')
                    }
                    if(tab==6){
                        setSTPhoneLookup("OFF");
                        setSTcustomSearch("OFF");
                        setSTSearchData([]);
                    }
                        setzapbutton(false); 
                }
            });
    }
    
   
   }
   const saveButton=(e,type)=>{
    setnameerror([]);
    setziperror([]);
    setzapapperr("");
    setstapperr("");
    if(type==1 && (zapPhoneLookup=='OFF' && zapEmailLookup=='OFF' && zapcustomSearch=='OFF')){
        setzapapperr("You need to check at least one checkbox. If you want to save all unchecked checkboxes, you can off Zapier.")
       return false;
    }
    if(type==2 && (stPhoneLookup=='OFF' && stcustomSearch=='OFF')){
        setstapperr("You need to check at least one checkbox. If you want to save all unchecked checkboxes, you can off Service Titan.");
        setstbutton(true);
        setTimeout(()=>{
            setstapperr("");
        },3500)
        return false;
     }
    let appdata={
        workspace_id:props.idata.workspaceId,
        branch_id:props.idata.branch_id,
        user_id:props.idata.user_id
    };
    if(type==1){
        appdata['integration_type']='ZAPIER';
        appdata['status']=zapstatus;
    }else{
        appdata['integration_type']='SERVICETITAN';
        appdata['status']=ststatus; 
    }
    if(stcustomSearch=='ON'){
        if(stSearchData.length==0){
            setStSearchRuleAlert('ON')
            setTimeout(()=>{
                    setStSearchRuleAlert('OFF')
                },3000)
                return false
            }
        }
    setloading(true);
    if(type==1){
        setzapbutton(true);
    }else{
        setstbutton(true);
    }
    api.botbuilder
    .createChatGptIntegration(appdata)
    .then((data) => {
        console.log(data)
        if (data.status) {
            // save data
             let zaps=[];
             let sdata=type==1 ? [...zapSearchData] : [...stSearchData];
             let errors=[];
                 for(var i=0;i<sdata.length;i++){
                    let err={};
                    if(sdata[i].captureField.length==0){
                       err['captureField']=true;
                    }
                     if(sdata[i].characters.length==0 || sdata[i].characters==0 || typeof sdata[i].characters=="undefined"){
                        err['characters']=true;
                    }
                     if(sdata[i].includes=="" || typeof sdata[i].includes=="undefined"){
                        err['includes']=true;
                    }
                    if(type==2 && sdata[i].captureField=="name" && sdata[i].includes=="Only Numbers"){
                        setnameerror([...nameerror,sdata[i].id])
                        setloading(false);
                        return false;
                    }
                    if(type==2 && sdata[i].captureField=="zip" && sdata[i].includes=="Only Letters"){
                        setziperror([...ziperror,sdata[i].id])
                        setloading(false);
                        return false;
                    }
                    if(type==2 && sdata[i].captureField=="zip" && sdata[i].characters!=5){
                        setziperror([...ziperror,sdata[i].id])
                        setloading(false);
                        return false;
                    }
                    
                    if(Object.keys(err).length>0){
                        err['id']=sdata[i].id;
                       errors[i]=err;
                        
                    }else{
                        let idd=!isInt(sdata[i].id) ? 0:sdata[i].id;
                        zaps[i]={id:idd,captureField:sdata[i].captureField,characters:sdata[i].characters,includes:sdata[i].includes}
                    } 
                 }
                 if(errors.length==0 && ziperror.length==0 && nameerror.length==0){
                    let lookphone=0;
                    let lookemail=0
                    if(type==1){
                        lookphone=zapPhoneLookup=='ON' ? 1:0;
                        lookemail=zapEmailLookup=='ON' ? 1:0;
                    }else{
                        lookphone=stPhoneLookup=='ON' ? 1:0
                        
                    }
                    if(type==2 && stcustomSearch=='OFF'){
                        zaps=[];
                        setSTSearchData([]);
                    }
                    let postdata={
                        workspace_id:props.idata.workspaceId,
                        branch_id:props.idata.branch_id,
                        user_id:props.idata.user_id,
                        crm_type:type==1 ? "Zapier":'ServiceTitan',
                        phone:lookphone,
                        email:lookemail,
                        customsearch:zaps
                     }
                     
                                api.botbuilder
                    .createChatGptIntegrationValue(postdata)
                    .then((data) => {
                        try{
                            if (data.status) {
                                setloading(false)
                                setsucmsg("Data saved successfully");
                                setTimeout(()=>{
                                    setsucmsg('');
                                },2000)
                                if(type==1){
                                    setZapSearchErrorData([]);
                                  }else{
                                      setSTSearchErrorData([]);
                                  }
                                setloading(false);
                                    }
                        }catch(errr){
                            setloading(false);
                            if(type==1){
                                setzapbutton(true);
                            }else{
                                setstbutton(true);
                            }
                           
                        }
                        // loadData(false,type);
                       
                        
                    });
                 }else{
                    if(type==1){
                        setZapSearchErrorData(errors);
                      }else{
                          setSTSearchErrorData(errors);
                      }
                      setloading(false);
                      if(type==1){
                        setzapbutton(true);
                    }else{
                        setstbutton(true);
                    }
                      return false;
                 }
                 
                 setloading(false);
                 if(type==1){
                    setzapbutton(true);
                }else{
                    setstbutton(true);
                }
        }
    })
}




const closeModal = (type) => {
        setmodalstatus(false);
        if(type==1 || type==3){
           setzapbutton(false);
        }
        if(type==2 || type==4){
            setzapbutton(false);
         }
        
}
const modalFooterDelete = (index,type,tab) => {
   let typ=(type===1 || type===2) ? type:tab
    return (
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => closeModal(typ)}
          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          data-dismiss="modal"s
        >
          <span> Cancel</span>
        </button>
         {tab==1 ? (<button
            type="button"
            onClick={() => removeFiled(index,type)}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>):(<button
            type="button"
            onClick={() => toggleApp(index,type,tab)}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>)}
      </div>
    );
  };

  const webhookIntegrate=(e,type)=>{
    setZapWebhookButton(false)
    if(type=='WebHookPhoneInput'){
        if(webhookPhone=='OFF'){
            setWebhookPhone('ON')
        }else{
            setWebhookPhone('OFF')
        }
    }
    if(type=='WebHookEmailInput'){
        if(webhookEmail=='OFF'){
            setWebhookEmail('ON')
        }else{
            setWebhookEmail('OFF')
        }
    }
    if(type=='zapCustomSearch'){
        if(zapcustomSearch=='OFF'){
            setZapcustomSearch('ON')
        }else{
            setZapcustomSearch('OFF')
        }
    }
   }

   const serviceTitanIntegration=(e,type)=>{
    setstbutton(false)
    if(type=='stcustomSearch'){
        if(stcustomSearch=='OFF'){
            setSTcustomSearch('ON')
        }else{
            setSTcustomSearch('OFF')
        }
    }
   }





  const saveWebhook=(e,type)=>{
      setloading(true)
      setZapWebhookButton(true)
    if(type=== '1' && (webhookPhone=='OFF' && webhookEmail=='OFF' && zapcustomSearch=='OFF')){
        setloading(false)
        setzapapperr("You need to check at least one checkbox. If you want to save all unchecked checkboxes, you can turn off Zapier.")
        setTimeout(()=>{
            setzapapperr("")
        },3500)
        return false;
    }
    if(type=== '1' && (webhookPhone=='ON' && /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(webhookPhoneInput.trim()) === false)){
        setloading(false)
        setPhoneWebhookAlert('ON')
        setTimeout(()=>{
            setPhoneWebhookAlert('OFF')
        },3500)
        return false;
    }
    if(type=== '1' && (webhookEmail=='ON' &&  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(webhookEmailInput.trim()) === false)){
        setloading(false)
        setEmailWebhookAlert('ON')
        setTimeout(()=>{
            setEmailWebhookAlert('OFF')
        },3500)
        return false;
    }
    if(zapcustomSearch=='ON'){
        if(zapSearchData.length==0){
            setWebhookSearchRuleFieldAlert('ON')
            setloading(false)
            setTimeout(()=>{
                setWebhookSearchRuleFieldAlert('OFF')
            },3000)
            return false;
        }
    }
    let zaps=[];
    let sdata= [...zapSearchData];
    let errors=[];
        for(var i=0;i<sdata.length;i++){
           let err={};
           if(sdata[i].captureField.length==0){
              err['captureField']=true;
           }
            if(sdata[i].characters.length==0 || sdata[i].characters==0 || typeof sdata[i].characters=="undefined"){
               err['characters']=true;
           }
            if(sdata[i].includes=="" || typeof sdata[i].includes==""){
               err['includes']=true;
           }
            if(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(sdata[i].webhook_url.trim()) === false){
                // setzapapperr("Please write a valid URL to save");
                setWebhookSearchRuleAlert('ON')
                setloading(false)
                setTimeout(()=>{
                    setWebhookSearchRuleAlert('OFF')
                },3000)
                return false;
            }
            if(type==1 && sdata[i].captureField=="name" && sdata[i].includes=="Only Numbers"){
                setnameerror([...nameerror,sdata[i].id])
                setloading(false);
                return false;
            }
            if(type==1 && sdata[i].captureField=="zip" && sdata[i].includes=="Only Letters"){
                setziperror([...ziperror,sdata[i].id])
                setloading(false);
                return false;
            }
            if(type==1 && sdata[i].captureField=="zip" && sdata[i].characters != 5){
                setziperror([...ziperror,sdata[i].id])
                setloading(false);
                return false;
            }
            if(Object.keys(err).length>0){
                err['id']=sdata[i].id;
                errors[i]=err;
           }else{
               let id=sdata[i].id;
               zaps[i]={id:id,captureField:sdata[i].captureField,characters:sdata[i].characters,includes:sdata[i].includes,webhook_url:sdata[i].webhook_url}
           }
        }
        if(errors.length==0 && ziperror.length==0 && nameerror.length==0){
            let lookphone=0;
            let lookemail=0;
            lookphone=webhookPhone==='ON' ? 1:0;
            lookemail=webhookEmail==='ON' ? 1:0;
            if(lookphone==0 && webhookPhone=='OFF'){
                setWebhookPhoneInput('')
            }
            if(lookemail==0 && webhookEmail=='OFF'){
                setWebhookEmailInput('')
            }
            if(zapcustomSearch=='OFF'){
                zaps=[];
                setZapSearchData([]);
            }
            let postwebhookdata={
                workspace_id:props.idata.workspaceId,
                branch_id:props.idata.branch_id,
                user_id:props.idata.user_id,
                crm_type:"Zapier",
                phone:lookphone,
                email:lookemail,
                webhook_url_phone:webhookPhoneInput.trim(),
                webhook_url_email:webhookEmailInput.trim(),
                customsearch:zaps
            }
            api.botbuilder
            .createChatGptIntegrationValue(postwebhookdata)
            .then((data) => {
                try{
                    if (data.status) {
                        setZapWebhookButton(true)
                        // loadData(false,type);
                        setloading(false)
                        setsucmsg("Data saved successfully");
                        setTimeout(()=>{
                            setsucmsg('');
                        },2000)
                    }
                }catch(err){
                    console.log(err)
                    }
            }); 
    }else{
        setZapSearchErrorData(errors);
        setloading(false);
        setZapWebhookButton(true)
        return false;
    }
  }

  const webhookURLinput=(e,type)=>{
    setZapWebhookButton(false)
    if(type==1){
        setWebhookPhoneInput(e.target.value)
    }
    if(type==2){
        setWebhookEmailInput(e.target.value)
    }
  }






    return (
        <div className='row'>
            {errmsg && <div class='alert alert-danger'>{errmsg}</div>}
            {sucmsg && <div class='alert alert-success'>{sucmsg}</div>}
             {loading && <div className="ui loading form"></div>}
            <div className='col-md-12'>
                <p><strong>By enabling integrations, we use content from the chat to look up customers and other information in your outside systems. For example, if we detect a phone number, we can use that information to pull the additional customer information into the context of the chat to further improve the experience.
                </strong></p>
       
                {/* <div className="box-title mt-4" style={{marginBottom:'-3rem'}} >
                    <div className="form-group row" style={{display:"flex", alignItems:"center"}}>
                        <div className="col-1">
                            <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                                <label>
                                    <input type="checkbox" name="zapier_div" onChange={(e)=>switchZap(e,'zapier_div')} checked={zapstatus=='ON' ? true :false} />
                                    <span />
                                </label>
                            </span>
                        </div>
                        <label className={classes.integrationlabels}> Enable zap connection</label>
                        <div>
                            <a href="https://zapier.com/apps/zyratalk/integrations" target="_blank" className="btn linear-gradient yr-submitbtn "
                                >Integrate using <img src={`${process.env.PUBLIC_URL}/assets/images/zapier-logo.png`} style={{ maxHeight: 28 }} alt="zapier-logo" className="img-fluid" />
                            </a>
                        </div>
                    </div>
                </div> */}

                {zapstatus=="ON" && <div style={{marginBottom:'5px'}}>
                {/* <div className='row'>
                    <div className='col-1'></div>
                    <div className="col-md-7">
                        <div className="form-group mb-2">
                            <input
                                type="text" className="form-control" name='' placeholder='zyratalk.com/webhook/12345'
                            />
                        </div>
                    </div>
                </div> */}
                {/* <div className='row'>
                    <div className='col-1'></div>
                    <div className={`col-md-9 mb-3`} style={{marginLeft:'10px'}}>
                        <p><strong>You can enable multiple search triggers. Once information is successfully obtained during an outside search, we will not proceed with additional searches.
                        </strong>
                        </p>
                    </div>
                </div> */}
                {/* <div className="row" style={{ marginLeft: '9%' }}>
                    <div className={`col4 ml-3 `}>
                        <Checkbox  checked={zapPhoneLookup=="ON" ? true:false} onChange={(e)=>switchZap(e,'phonelookup')} name="phonelookup"
                        />
                    </div>
                    <div className={`ml-2`}>
                        Enable to lookup when a <b style={{ fontWeight: 700 }}>Phone Number</b> is detected
                    </div>
                </div>
                <div className="row" style={{ marginLeft: '9%' }}>
                    <div className={`col4 ml-3 `}>
                        <Checkbox checked={zapEmailLookup=="ON" ? true:false} onChange={(e)=>switchZap(e,'emaillookup')}
                        name="emaillookup" 
                        />
                    </div>
                    <div className={`ml-2`}>
                        Enable to lookup when an <b style={{ fontWeight: 700 }}>Email</b> is detected
                    </div>
                </div>
                <div className="row" style={{ marginLeft: '9%' }}>
                    <div className={`col4 ml-3 `}>
                        <Checkbox name="zapcustomSearch"  checked={zapcustomSearch=="ON" ? true:false} onChange={(e)=>switchZap(e,'zapcustomSearch')}
                        />
                    </div>
                    <div className={`ml-2 `+classes.clwidth}>
                        <label>
                            Create custom search rules <strong style={{fontWeight:700}}>*An example might be an order number that includes 8 characters that are only numbers.*</strong>
                        </label>
                    </div>
                </div> */}

            
                {/* {(zapSearchData.length>0 && zapcustomSearch=='ON') && <div>
                {zapSearchData.map((item,i,zapSearchData1)=>{
                    let err=zapSearchErrorData.filter(er=>er.id==item.id);
                    return(
                    <div className="row" style={{ marginLeft: '9%' }} key={zapSearchData1[i].id}>
                    <div className={`ml-5`}>
                  
                        Trigger a search for a
                        <input  className= "inputfield" name="captureField" value={zapSearchData1[i].captureField}  onChange={(e)=>updatezapvalue(e,'captureField',i)} />
                       
                        . To trigger, we must detect <input  className="inputfield" type="number" name='characters'  value={zapSearchData1[i].characters} onChange={(e)=>updatezapvalue(e,'characters',i)} /> character that may include
                        <span class="custom-arrow">
                            <select className={classes.selectcss+" bootstrap-select arrow-color mat-style"} name="includes" onChange={(e)=>updatezapvalue(e,'includes',i)} style={{width:'145px'}} id="kt_form_status"  value={zapSearchData1[i].includes} >
                                <option value="">Select</option>
                                <option value="Only Numbers">Only Numbers</option>
                                <option value="Only Letters">Only Letters</option>
                                <option value="Number and Letters">Number and Letters</option>
                                <option value="Number Or Letters">Number Or Letters</option>
                            </select>
                        </span>
                        .     <i style={{cursor:'pointer'}} onClick={(e)=>confirmDelete(e,zapSearchData1[i].id,1)} className="la la-trash pull-right btn red"></i>
                    </div>
                    {Object.keys(err).length>0 && <div className='ulinline' style={{display:'flex',flexDirection:'row',marginLeft:'42px',width:'100%'}}>
                    <span id="help-error" style={{display:'inline-block'}} class="error invalid-feedback errf">All fields are required.</span>
                </div>}
                </div>
                )}
                )}
                </div>} */}
                {/* <div> */}
                {/* {zapcustomSearch=="ON" && 
                <div>
                    <div className="gradientsskill">
                        <a className="add-gradient mb-3" style={{ color: "#087ab6" }} onClick={(e)=>addzapfields(e)}>
                            + Add additional search rule
                        </a>
                    </div>
                </div>
                } */}
                {/* </div> */}
                {/* {zapapperr.length>0 &&<div class="row"><div class="col-1"></div><div class="col-md-9 mb-3" style={{marginLeft: '10px'}}><p className='text-danger'><strong>{zapapperr}</strong></p></div></div>}
                
                <button type="submit" disabled={zapbutton ? true:false} className="btn linear-gradient yr-submitbtn pull-right" onClick={(e) => saveButton(e,1)}>Save</button> */}
                </div>}

                {/* webhook connection starts here */}
                <div className="box-title mt-4" style={{marginBottom:'-3rem'}} >
                    <div className="form-group row" style={{display:"flex", alignItems:"center"}}>
                        <div className="col-1">
                            <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                                <label>
                                    <input type="checkbox" name="webhook_div" onChange={(e)=>switchZap(e,'webhook_div',1)} checked={webhookStatus=='ON' ? true :false} />
                                    <span />
                                </label>
                            </span>
                        </div>
                        <label className={classes.integrationlabels}> Enable webhook connection</label>
                        <label style={{fontSize:"14px", marginRight:"25px"}}> Not a developer? <a style={{color:"#495057", borderBottom:"1px solid #495057"}} className='integrationHyperLink' href='https://zapier.com/apps/webhook/integrations' target="_blank"> Integrate using zapier</a></label>
                         
                    </div>
                </div>
                
                {webhookStatus=="ON" && <div style={{marginBottom:'45px'}}>
                {/* <div className='row'>
                    <div className='col-1'></div>
                    <div className="col-md-7">
                        <div className="form-group mb-2">
                            <input
                                type="text" className="form-control" name='' placeholder='zyratalk.com/webhook/12345'
                            />
                        </div>
                    </div>
                </div> */}
                <div className='row'>
                    <div className='col-1'></div>
                    <div className={`col-md-9 mb-3`} style={{marginLeft:'10px'}}>
                        <p><strong>You can enable multiple search triggers. Once information is successfully obtained during an outside search, we will not proceed with additional searches.
                        </strong>
                        </p>
                    </div>
                </div>
                <div className="row" style={{ marginLeft: '9%' }}>
                    <div className={`col4 ml-3 `}>
                        <Checkbox name="webhookPhone"
                        onChange={(e)=>webhookIntegrate(e,'WebHookPhoneInput')}
                        checked={webhookPhone=='OFF' ? false : true}
                        />
                    </div>
                    <div className={`ml-2`}>
                        Enable to lookup when a <b style={{ fontWeight: 700 }}>Phone Number</b> is detected
                    </div>
                </div>
                <div  style={{marginLeft:"9%", width:"92%"}}>
                    {webhookPhone=='ON' && 
                            <input type='input' value={webhookPhoneInput} onChange={(e)=>webhookURLinput(e,1)}  placeholder='Webhook URL' style={{marginLeft:"2%", marginBottom:"10px", border:"none", borderBottom:"1px solid #cecece", width:"75%",color: '#495057'}}/>
                        }
                        {phoneWebhookAlert=='ON' && <p  style={{marginLeft:"20px",width:"100%", marginBottom:"10px", fontWeight:500, fontSize:"1rem"}} className='text-danger'>Please write a valid URL.</p>}
                </div>
                <div className="row" style={{ marginLeft: '9%' }}>
                    <div className={`col4 ml-3 `}>
                        <Checkbox 
                        name="webhookEmail" 
                        onChange={(e)=>webhookIntegrate(e,'WebHookEmailInput')}
                        checked={webhookEmail=='OFF' ? false : true}

                        />
                    </div>
                    <div className={`ml-2`}>
                        Enable to lookup when an <b style={{ fontWeight: 700 }}>Email</b> is detected
                    </div>
                    <div>
                    </div>
                </div>
                <div style={{marginLeft:"9%", width:"92%"}}>
                    {webhookEmail=='ON' && 
                        <input type='input' value={webhookEmailInput} onChange={(e)=>webhookURLinput(e,2)} placeholder='Webhook URL' style={{marginLeft:"2%", marginBottom:"10px", border:"none", borderBottom:"1px solid #cecece", width:"75%",color: '#495057'}}/>
                        }
                        {emailWebhookAlert=='ON' && <p  style={{marginLeft:"20px",width:"100%", marginBottom:"10px", fontWeight:500, fontSize:"1rem"}} className='text-danger'>Please write a valid URL.</p>}
                
                </div>
                <div className="row" style={{ marginLeft: '9%' }}>
                    <div className={`col4 ml-3 `}>
                        <Checkbox name="zapcustomSearch"  checked={zapcustomSearch=="ON" ? true:false} onChange={(e)=>webhookIntegrate(e,'zapCustomSearch')}
                        />
                    </div>
                    <div className={`ml-2 `+classes.clwidth}>
                        <label>
                            Create custom search rules <strong style={{fontWeight:700}}>*An example might be an order number that includes 8 characters that are only numbers.*</strong>
                        </label>
                    </div>
                </div>

            
                {(zapSearchData.length>0 && zapcustomSearch=='ON') && <div>
                {zapSearchData.map((item,i,zapSearchData1)=>{
                    let err=zapSearchErrorData.filter(er=>er.id==item.id);
                    return(
                    <div className="row" style={{ marginLeft: '9%' }} key={zapSearchData1[i].id}>
                    <div className={`ml-5`}>
                  
                        Trigger a search for a
                        <input  className= "inputfield" style={{borderBottom: "1px solid #cecece", color: "rgb(73, 80, 87)"}} name="captureField" value={zapSearchData1[i].captureField}  onChange={(e)=>updatezapvalue(e,'captureField',i,1)} />
                       
                        . To trigger, we must detect <input style={{ color: "rgb(73, 80, 87)", borderBottom: "1px solid #cecece"}}  className="inputfield" type="number" name='characters'  value={zapSearchData1[i].characters} onChange={(e)=>updatezapvalue(e,'characters',i,1)} /> character that may include
                        <span class="custom-arrow">
                            <select className={classes.selectcss+" bootstrap-select arrow-color mat-style"} name="includes" onChange={(e)=>updatezapvalue(e,'includes',i,1)} style={{width:'145px',color: "rgb(73, 80, 87)"}} id="kt_form_status"  value={zapSearchData1[i].includes} >
                                <option value="">Select</option>
                                <option value="Only Numbers">Only Numbers</option>
                                <option value="Only Letters">Only Letters</option>
                                <option value="Number and Letters">Number and Letters</option>
                                <option value="Number Or Letters">Number Or Letters</option>
                            </select>
                        </span>
                        .     <i style={{cursor:'pointer'}} onClick={(e)=>confirmDelete(e,zapSearchData1[i].id,1)} className="la la-trash pull-right btn red"></i>
                    </div>
                    <div></div>
                            <input value={zapSearchData1[i].webhook_url} onChange={(e)=>updatezapvalue(e,'webhook_url',i,1)} type='input' placeholder='Webhook URL' style={{marginLeft:"40px", marginTop:"5px",marginBottom:"10px", border:"none", borderBottom:"1px solid #cecece", width:"70%",color: "rgb(73, 80, 87"}}/>
                    {Object.keys(err).length>0 && <div className='ulinline' style={{display:'flex',flexDirection:'row',marginLeft:'42px',width:'100%'}}>
                    <span id="help-error" style={{display:'inline-block'}} class="error invalid-feedback errf">All fields are required.</span>
                </div>}
                </div>
                )}
                )}
                </div>}
                <div>
                {zapcustomSearch=="ON" && 
                <div>
                    <div className="gradientsskill">
                        <a className="add-gradient mb-3" style={{ color: "#087ab6" }} onClick={(e)=>addzapfields(e)}>
                            + Add additional search rule
                        </a>
                    {webhookSearchRuleAlert=='ON' && <p style={{marginLeft:"10px",width:"100%", fontWeight:600, marginBottom:"10px"}} className='text-danger'>Please write a valid URL or fill all the inputs.</p>}
                    {webhookSearchRuleFieldAlert=='ON' && <p style={{marginLeft:"10px",width:"100%", fontWeight:600, marginBottom:"10px"}} className='text-danger'>Please add additional search rule.</p>}
                    </div>
                </div>
                }
                </div>
                {zapapperr.length>0 &&<div class="row"><div class="col-1"></div><div class="col-md-9 mb-3" style={{marginLeft: '10px'}}><p className='text-danger'><strong>{zapapperr}</strong></p></div></div>}
                <button type="submit" disabled={zapWebhookButton ? true : false} className="btn linear-gradient yr-submitbtn pull-right" onClick={(e)=>saveWebhook(e,'1')}>Save</button>
                </div>}
                {/* webhook connection ends here */}

                <div className="box-title mt-4" style={{marginBottom:'-3rem'}} >
                    <div className="form-group row" style={{display:"flex", alignItems:"center"}}>
                        <div className="col-1">
                            <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                                <label>
                                    <input type="checkbox" name="st_div" onChange={(e)=>switchZap(e,'st_div')} checked={ststatus=="ON" ? true:false} />
                                    <span />
                                </label>
                            </span>
                        </div>
                        <label className={classes.integrationlabels}> Enable service titan connection</label>
                    </div>
                </div>
                {ststatus=="ON" && <div>        
                <div className='row'>
                    <div className='col-1'></div>
                    <div className={`col-md-9 mb-3`} style={{marginLeft:'10px'}}>
                        <p><strong>You can enable multiple search triggers. Once information is successfully obtained during an outside search, we will not proceed with additional searches.
                        </strong>
                        </p>
                    </div>
                </div>
                <div className="row" style={{ marginLeft: '9%' }}>
                    <div className={`col4 ml-3 `}>
                        <Checkbox checked={stPhoneLookup=="ON" ? true:false} onChange={(e)=>switchZap(e,'stPhoneLookup')} name="stPhoneLookup"
                        />
                    </div>
                    <div className={`ml-2`}>
                        Enable to lookup when a <b style={{ fontWeight: 700 }}>Phone Number</b> is detected
                    </div>
                </div>
                
                <div className="row" style={{ marginLeft: '9%' }}>
                    <div className={`col4 ml-3 `}>
                        <Checkbox name="stcustomSearch" value={stcustomSearch} checked={stcustomSearch==="ON" ? true:false}  onChange={(e)=>serviceTitanIntegration(e,'stcustomSearch')}
                        />
                    </div>
                    <div className={`ml-2 `+classes.clwidth}>
                        <label>
                            Create custom search rules <strong style={{fontWeight:700}}>*An example might be an order number that includes 8 characters that are only numbers.*</strong>
                        </label>
                    </div>
                </div>

            
                {(stSearchData.length>0 && stcustomSearch=='ON') && <div>
                {stSearchData.map((item,i,stSearchData)=>{
                     let err=stSearchErrorData.filter(er=>er.id==item.id);
                    return(
                    <div className="row" style={{ marginLeft: '9%' }} key={stSearchData[i].id}>
                       
                    <div className={`ml-5`}>
                   
                        Trigger a search for a
                        
                        <select className={classes.selectcss+" bootstrap-select arrow-color mat-style"} style={{width:'100px',color: "rgb(73, 80, 87)"}} name="captureField" onChange={(e)=>updatestvalue(e,'captureField',i)} id="kt_form_status"  value={stSearchData[i].captureField}>
                                <option value="">Select</option>
                                <option value="name">Name</option>
                                <option value="zip">Zip</option>
                            </select>    

                        . To trigger, we must detect <input style={{color: "rgb(73, 80, 87)", borderBottom:"1px solid #cecece"}}  className={ typeof err[0]!='undefined' && err[0].hasOwnProperty('characters')
                                                                    ? "inputfield is-invalid"
                                                                    : "inputfield"
                                                            }  type="number" name='characters'    value={stSearchData[i].characters} onChange={(e)=>updatestvalue(e,'characters',i)} /> character that may include
                        <span class="custom-arrow">
                            <select className={classes.selectcss+" bootstrap-select arrow-color mat-style"} name="includes" onChange={(e)=>updatestvalue(e,'includes',i)} style={{width:'120px',color: "rgb(73, 80, 87)"}}  id="kt_form_status" value={stSearchData[i].includes}  >
                                <option value="">Select</option>
                                <option value="Only Numbers">Only Numbers</option>
                                <option value="Only Letters">Only Letters</option>
                            </select>
                        </span>
                        . <i onClick={(e)=>confirmDelete(e,stSearchData[i].id,2)} style={{cursor:'pointer'}} className="la la-trash pull-right btn red"></i>
                    </div>
                    {Object.keys(err).length>0 && <div className='ulinline' style={{display:'flex',flexDirection:'row',marginLeft:'42px',width:'100%'}}>
                     <span id="help-error" style={{display:'inline-block'}} class="error invalid-feedback errf">All fields are required.</span>
                                                      
                </div>}
                {ziperror.includes(stSearchData[i].id) &&  <div id="help-error" style={{display:'flex',flexDirection:'row',marginLeft:'42px',width:'100%'}} class="error invalid-feedback errf">Include must be "Only Numbers" . characters must be 5.</div>}
                {nameerror.includes(stSearchData[i].id) &&  <div id="help-error" style={{display:'flex',flexDirection:'row',marginLeft:'42px',width:'100%'}} class="error invalid-feedback errf">Include must be "Only Letters" .</div>}       
                </div>
                )}
                )}
                </div>}
                <div>
                {(stcustomSearch=="ON" && stSearchData.length<2) && 
                <div>
                    <div className="gradientsskill">
                        <a className="add-gradient mb-3" style={{ color: "#087ab6" }} onClick={(e)=>addstfields(e)}>
                            + Add additional search rule
                        </a>
                        {stSearchRuleAlert=='ON' && <p style={{marginLeft:"10px",width:"100%", fontWeight:600, marginBottom:"10px"}} className='text-danger'>Please add additional search rule.</p>}
                    </div>
                </div>
                }
                </div>
                {stapperr.length>0 &&<div class="row"><div class="col-1"></div><div class="col-md-9 mb-3" style={{marginLeft: '10px'}}><p className='text-danger'><strong>{stapperr}</strong></p></div></div>}
                <button type="submit" disabled={stbutton ? true:false} className="btn linear-gradient yr-submitbtn pull-right" onClick={(e) => saveButton(e,2)}>Save</button>
                </div>}
            </div>
            <Modal
          show={modalstatus}
          footer={modalfooter}
          onclose={closeModal}
          title={modaltitle}
          body={modalbody}
          modaltranscript=""/>
            </div>
    )
}
