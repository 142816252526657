import React from "react";
import { Link, Redirect } from "react-router-dom";
import api from "../../../../../api";
import "../../../../Grid/grid.css";
import { Segment, Table, Pagination,Message } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";
import ChatFilter from "./ChatFilter";
import { DatatableRow } from "./DatatableRow";
// import { DatatablePageSizeSelect } from "./DatatablePageSizeSelect";
// import Modal from "../../../ChatSetup/components/Modal";
// import CustomErrorField from "../../../../Form/CustomErrorField";
import utilFunc from "../../../../../util_funs";
const tableHeader = [
  {
    title: "Action",
    width: "1",
    sort: "",
  },
  {
    title: "Title",
    width: "1",
    sort: "title",
  }, 
  {
    title: "Front Preview",
    width: "4",
    sort: "",
  },
  {
    title: "Back Preview",
    width: "4",
    sort: "",
  },
  {
    title: "Status",
    width: "2",
    sort: "status",
  },

];
const queryParams = [
  "_limit",
  "_order",
  "_sort",
  "q",
  "_page",
  "title",
  "added_datetime",
];

export default class DatatableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: "auto_id",
      _page: 1,
      _order: "desc",
      _limit: 10,
      q: "",
      title: "",
      added_datetime: "",
      startdaterange: "",
      enddaterange: "",
      totalCount: 0,
      loading: false,
      modalstatus: false,
      modalbody: "",
      modalfooter: "",
      datatableHeader: null,
      datatableRows: null,
      auto_id: "",
      csvdata: [],
      rename: "",
      modal_id: "",
      errors: [],
      status: "all",
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      dialogTitle: '',
      dialogMessage: '',
      dialogLoading: false,
      dialogShow: false,
      dialogData:'', 
    };
    this.csvLink = React.createRef();
  }

  componentDidMount() {
    this.loadData({});
  }

  static directionConverter(order) {
    if (order === "asc") {
      return "ascending";
    } else if (order === "desc") {
      return "descending";
    } else {
      return null;
    }
  }

  handleSort = (clickedColumn) => {
    const { _sort, _order } = this.state;

    let newOrder = _order === "asc" ? "desc" : "asc";
    if (_sort !== clickedColumn) {
      newOrder = "asc";
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = (filter, val) => {
    this.loadData({ q: filter, _page: 1 });

    // this.loadData({ [filter]: val, _page: 1 });
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  // loadData = (params) => {
  //   let datatableRowstemp;
  //   datatableRowstemp = <DatatableRow />;

  //   this.setState({
  //     datatableRows: datatableRowstemp,
  //     totalCount: 5,
  //     // lists: data.data.list,
  //   });
  // };

  loadData = (params) => {
    const newState = Object.assign({}, this.state, params, { loading: false });
    console.log(newState);
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });

    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;

    params["time_zone"] = time_zone;
    params["status"] = this.state.status;

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let totalCountQuery = "";
    if (params.q !== "") {
      totalCountQuery = `q=${params.q}`;
    }
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.postcard.list(query).then((data) => {
        console.log(data.data);
        try {
          let datatableRowstemp;
          this.setState({ lists: data.data.list });

          if (data.data.totalrecord) {
            console.log(data.data.list);
            datatableRowstemp = this.state.lists.map((list, index) => (
              <DatatableRow key={index} list={list} deleteTemplate={this.deleteDomain} editTemplate={this.editTemplate} updateBackPostcard={this.updateBackPostcard} />
            ));
          } else {
            datatableRowstemp = (
              <Table.Row>
                <Table.Cell colSpan={6} textAlign="center">
                  No record found.
                </Table.Cell>
              </Table.Row>
            );
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.data.totalrecord,
            lists: data.data.list,
          });
        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });
    });
  };

  checkAll = (e) => {
    let datalist = this.state.lists;
    datalist.map(function (list, index) {
      list["checked"] = e.target.checked;
    });
    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          openContactList={this.openContactList}
          key={index}
          list={list}
          bucketAction={this.bucketAction}
          createPlaybook={this.props.createPlaybook} 
          deleteTemplate={this.deleteDomain} 
          editTemplate={this.editTemplate}
          updateBackPostcard={this.updateBackPostcard}
        />
      )),
      lists: datalist,
    });
  };

  checkboxChangeEvent = (auto_id) => {
    let datalist = this.state.lists;
    let recordlist = datalist.findIndex((list) => list.auto_id === auto_id);

    if (datalist[recordlist]["checked"]) {
      datalist[recordlist]["checked"] = false;
    } else {
      datalist[recordlist]["checked"] = true;
    }

    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          openContactList={this.openContactList}
          key={index}
          list={list}
          createPlaybook={this.props.createPlaybook}
          deleteTemplate={this.deleteDomain} 
          editTemplate={this.editTemplate}
          updateBackPostcard={this.updateBackPostcard}
        />
      )),
      lists: datalist,
    });
  };

  bucketAction = (tab, id) => {
    if (tab == "edit") {
      let list_data = this.state.lists;
      let recordid = list_data.findIndex((list_data) => list_data.bid == id);

      this.setState(
        {
          errors: [],
          rename: list_data[recordid]["title"],
          modaltitle: "Rename Bucket",
          modalstatus: true,
          modal_id: id,
          modalfooter: this.modalFooter("", "edit"),
        },
        function () {
          let modalbody = this.modalBody("edit");

          this.setState({ modalbody: modalbody });
        }
      );
    }

    if (tab == "delete") {
      this.setState({
        errors: [],
        modalstatus: true,
        modal_id: id,
        modaltitle: "Confirm your action",
        modalfooter: this.modalFooter("", "delete"),
        modalbody: "Are you sure you want to delete it?",
      });
    }
  };

  modalClose = () => {
    this.setState({
      modalstatus: false,
    });
  };

  
  deleteDomain = (action,id=null) => {
    
      if (action == 'Open') {
        this.setState({
          dialogTitle: '',
          dialogMessage: 'Are you sure you want to delete it?',
          dialogLoading: false,
          dialogShow: true,
          errors: {},
          dialogData:id
        });
      }
      if (action == 'Close') {
        this.setState({
          dialogTitle: '',
          dialogMessage: '',
          dialogLoading: false,
          dialogShow: false,
          errors: {},
          dialogData:''
        });
      }
      if (action == 'Yes') {
        this.deleteAction()
      }
   
  }


  deleteAction = () =>{ 
    this.setState({ dialogLoading: true });
    api.postcard.deleteTemplate({ id: this.state.dialogData }).then((data) => {
      try {
        this.setState({ dialogLoading: false });
        if (data.status) {
          this.deleteDomain('Close');
          this.loadData({});
          this.showToaster("green", "Template has been deleted successfully.");
          utilFunc.scrollTop(800);
        } else {
          this.showToaster("red", data.data);
          utilFunc.scrollTop(800);
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  showToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ userToaster: false });
      }.bind(this),
      3000
    );
  };

  editTemplate = (id) => { 
    let list_data = this.state.lists;
    let recordid = list_data.findIndex((list_data) => list_data.auto_id == id);
    this.props.action('Edit',this.state.lists[recordid]);
  }



  updateBackPostcard = (data) =>{
      this.setState({ loading: true });
      api.postcard.backurl({'message':data.message,'style':data.style,'colour':data.colour}).then(data => {
        try {
            this.setState({loading: false});  
            //window.open('/marketing/viewback?img='+data.data.backurl.data.handwriting_image); 
            var myWindow = window.open("", "Image", "width=600,height=600");
            myWindow.document.write(" <img src='"+ data.data.backurl.data.handwriting_image +"' width='600px' height='600px' />");
        } catch (err) {
            console.log(err);
        }
      });
  }

  render() {
    return (
      <div>
        <ChatFilter
          filter={{
            chatstatus: this.state.chatstatus,
            chatfrequency: this.state.chatfrequency,
            strsearch: this.state.q,
            startdaterange: this.state.startdaterange,
            enddaterange: this.state.enddaterange,
          }}
          handleSort={this.handleSort}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading}
          onSubmitFilterDateReange={this.onSubmitFilterDateReange}
          
        />
         <Message
            color={
            this.state.userToasterColor
                ? this.state.userToasterColor
                : "teal"
            }
            style={{
            display: this.state.userToaster
                ? "block"
                : "none",
            marginTop: 30,
            }}
        >
            {this.state.userToasterMsg}
        </Message>
        {this.state.loading && <div className="ui loading form"></div>}

        <Segment
          style={{
            // width: "100%",
            // maxWidth: "1280px",
            // margin: "0px",
            // padding: 0,
            // paddingTop: "0px",
            
          }}
        >
          <DatatableTable
            lists={this.state.lists}
            datatableHeader={tableHeader}
            datatableRows={this.state.datatableRows}
            totalCount={this.state.totalCount}
            totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
            currentPage={this.state._page}
            onChangePage={this.onChangePage}
            column={this.state._sort}
            direction={DatatableList.directionConverter(this.state._order)}
            handleSort={this.handleSort}
            onChangeLimit={this.onChangeLimit}
            limit={this.state._limit.toString()}
            checkAll={this.checkAll}
          />
        </Segment>

        {/* <Modal
          show={this.state.modalstatus}
          footer={this.state.modalfooter}
          onclose={this.modalClose}
          title={this.state.modaltitle}
          body={this.state.modalbody}
          modaltranscript={this.state.modalTranscript}
        /> */}

<div
                    className={this.state.dialogShow ? "modal fade show" : "modal fade"}
                    data-backdrop="static"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="staticBackdrop"
                    aria-hidden="true"
                    style={{ display: this.state.dialogShow ? "block" : "none" }}
                  >
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 600 }}>
                      {this.state.dialogLoading && <div className="ui loading form"></div>}
                      <div className="modal-content">
                        <button
                          type="button"
                          className="close linear-gradient"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) => this.deleteDomain('Close')}
                        >
                          X
						</button>
                        <div className="modal-header">
                          <h5 className="modal-title">Confirm your action</h5>
                        </div>
                        <div className="modal-body">
                          <div className="px-4">
                            {this.state.dialogMessage}
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={(e) => this.deleteDomain('Close')} data-dismiss="modal">
                            <span>No</span>
                          </button>
                          <button type="button" className="btn linear-gradient yr-submitbtn btn-sm" onClick={(e) => this.deleteDomain('Yes')} >
                            Yes
            </button>
                        </div>

                      </div>
                    </div>
                  </div>
 
      </div>
    );
  }
}
