import React from "react";
import "../../../../Modal/dialog.css";

const DialogBoxDetail = ({ show, id, title, status, saveData, modalClose, changeTittle, changeStatus, loading }) => {
	return (
		<React.Fragment>
			<div
				className={show ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					{loading && <div className="ui loading form"></div>}
					<div className="modal-content white-bg yr-campaigns-model">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalClose(false, '')}
						>
							X
						</button>
						<div className="modal-header"> 
							<h5 className="modal-title pt-0">Edit Data Source</h5>
						</div>
						<div className="modal-body new-modal-body pb-4">
							<form class="py-4">
								<div class="d-flex align-items-end mb-3">
									<span style={{minWidth: 60, margin: '0'}}>Title :</span>
									<div class="field w-100">
										<div class="ui fluid input ml-0 w-100">
											<input autocomplete="off" name="title" placeholder="" type="text" value={title} onChange={(e) => changeTittle(e)}/>
										</div>
									</div>
								</div>
								<div class="d-flex align-items-end mb-3">
									<span style={{minWidth: 60, margin: '0'}}>Status :</span>
									 <select className="custom-select model-select-dropdown" value={status} onChange={(e) => changeStatus(e)}>
										<option value="Active">Active</option>
										<option value="De-active">Deactive</option>
									</select>
								</div>
								<div className="md-footer d-flex justify-content-end align-items-center mt-5">
									<button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" data-dismiss="modal">
										<span style={{paddingTop: 10, paddingBottom: 10}} onClick={() => modalClose(false, '')}>Cancel</span></button>
									<button type="button" className="ml-2 btn linear-gradient yr-submitbtn btn-sm" onClick={() => saveData(id, title, status)}>Save</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxDetail;