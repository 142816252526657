import React from 'react'
import WaitingForResponse from '../../components/WaitingForResponse';
import classes from './EngageChat.module.css';

const EngageChat = (props) => {
	const renderAutomatedChat = e => {
		return (
			(props.props.user.feature == "freemium") ?
				<div className="card-body d-flex justify-content-center py-4">
					<button className="btn linear-gradient zy-save-btn bigwithshadow">Upgrade to use this feature</button>
				</div> :
				<div>
					<div className="d-flex justify-content-end mh-timeText">
						<p style={{ display: props.state.automatedChatWaitTimeBar }} className="time-waiting">TIME WAITING</p>
					</div>
					<div className="kt-notification-v2 mh-automatedChat">
						<WaitingForResponse
							onChnageName={props.onChnageName}
							onArchive={props.onArchive}
							chatListEngage='engage'
							joinedTheConversation={"0"}
							state={props.state}
							openLiveChatBoxOnClickLeftRow={props.openLiveChatBoxOnClickLeftRow}
						/>
					</div>
				</div>
		)
	}
	const renderFreemiumChat = e => {
		return (
			(props.props.user.feature == "freemium") ?

				<div>
					<div className="d-flex justify-content-end mh-timeText">
						<p style={{ display: props.state.automatedChatWaitTimeBar }} className="time-waiting">TIME WAITING</p>
					</div>
					<div className="kt-notification-v2 mh-automatedChat">
						<WaitingForResponse chatListEngage='engage' joinedTheConversation={"0"} state={props.state} openLiveChatBoxOnClickLeftRow={props.openLiveChatBoxOnClickLeftRow} />
					</div>
				</div> : ""
		)
	}

	return (
		<>
			{renderAutomatedChat()}
		</>
	)
}

export default EngageChat