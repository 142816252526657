import React from 'react'
import classes from './Button.module.css';

const Button = (props) => {

    return (
        <button
            className={`btn linear-gradient yr-add-new ${props.className}`}
            type={props.type}
            onClick={props.handleClick}
            disabled={props.disable}
        >
            {props.children}
        </button>
    )
}

export default Button