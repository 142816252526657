import axios from "../config/axios";

const user = {
  verifyUser: (userToken) =>
    axios.post("/auth/generate_token", { user: userToken }),

  getUserInfo: (data) => axios.post("/auth/get_user_info", data),
  getProfile: () => axios.post("/settings/profile/profileDetails", {}),
  enalbeMFALogin: (formData) =>
  axios.post("/settings/profile/enalbeMFALogin", formData),

  saveProile: (formData) =>
    axios.post("/settings/profile/saveProfile", formData),
  duplicateEMail: (formData) =>
    axios.post("/settings/profile/checkDuplicateEmail", formData),
    getHippaConfBots: (formData) => axios.post("/common/common/getHipaConfBots", formData),
    resetHipaaPin: (formData) => axios.post("/common/common/sendPinRequest", formData),
    validatePin: (formData) => axios.post("/common/common/validatePin", formData),
    addSubAccountOnFYI: (formData) => axios.post("/common/common/addSubAccountOnFYI", formData),
  
    changeToLegacy: (params) =>
     axios
    .post('/settings/profile/changetolegacy', params)
    .then((resp) => resp.data)
    .catch((err) => console.log(err)),
};

export default user;
