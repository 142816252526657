import axios from "../config/axios";

const ticket = {
  saveTicket: (formData) =>
    axios.post("/settings/support/save", { data: formData }),

  getLists: (params) =>
    axios
      .post(`/settings/support/list`, { ...params })
      .then((resp) => (resp ? resp.data : null))
      .catch((err) => console.log(err)),

  changeTicketLevel: (params) =>
    axios
      .post("/settings/support/change_level", params)
      .then((resp) => (resp ? resp.data : null)),

  changeTicketStatus: (params) =>
    axios
      .post("/settings/support/change_status", params)
      .then((resp) => (resp ? resp.data : null)),

  getScreenShots: (params) =>
    axios
      .post("/settings/support/screenshots", params)
      .then((resp) => (resp ? resp.data : null)),

  getCommentList: (params) =>
    axios
      .post("/settings/support/get_comment_details", params)
      .then((resp) => resp.data),

  saveComment: (params) =>
    axios
      .post("/settings/support/save_comment", params)
      .then((resp) => resp.data),
};

export default ticket;
