import React from "react";
import config from "../../config";
import { Link } from "react-router-dom";
import YoutubeModal from "../Modal/YoutubeModal";
import { Form } from "formsy-semantic-ui-react";
import validationRule from "../../validation";
import { Message } from "semantic-ui-react";
import util from "../../util";
import utilFunc from "../../util_funs";
import api from "../../api";
validationRule.isRequired();
validationRule.usPhoneLength();
class Widgets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      fullName: "",
      email: "",
      phone: "",
      messageDialog: false,
      successMessage: "",
      loading: false,
    };
    this.formRef = React.createRef();
  }
  handleChange = (e) => {
    if (e.target.name == "phone" && e.target.value !== "") {
      this.setState({ phone: util.phoneFormat(e.target.value) });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };
  displayChatWidget = (widget) => {
    const { chatBot_id } = this.props.user;
    const { onePlanetChatKey, bitlyUrlForGtm, onePlanetGtmUrl } = config;

    if (widget === "google") {
      if (onePlanetChatKey === chatBot_id) {
        return (
          <div className="yr-script">
            &lt;script id="chatBT" src="
            {onePlanetGtmUrl}
            ?c={chatBot_id}" type="text/javascript"&gt;&lt;/script&gt;
          </div>
        );
      }

      return (
        <div className="yr-script">
          &lt;script id="chatBT" src="{bitlyUrlForGtm}
          ?c={chatBot_id}" type="text/javascript"&gt;&lt;/script&gt;
        </div>
      );
    } else if (widget === "chatBot") {
      if (onePlanetChatKey === chatBot_id) {
        return (
          <div className="yr-script">
            &lt;script id="chatBT" chatKey= "{this.props.user.chatBot_id}" src="
            {config.onePlanetBotUrl}" type="text/javascript"&gt;&lt;/script&gt;
          </div>
        );
      } else {
        return (
          <div className="yr-script">
            &lt;script id="chatBT" chatKey= "{this.props.user.chatBot_id}" src="
            {config.installationUrl}" type="text/javascript"&gt;&lt;/script&gt;
          </div>
        );
      }
    }
  };
  clearFileds = () => {
    this.setState({ messageDialog: false, successMessage: "" });
  };
  handleSubmit = () => {
    const { fullName, email, phone } = this.state;
    this.setState({ loading: true });
    api.widget
      .needHelp({ fullName, email, phone })
      .then((resp) => {
        if (resp && resp.message) {
          this.setState(
            {
              messageDialog: true,
              successMessage: resp.message,
              fullName: "",
              email: "",
              phone: "",
              loading: false,
            },
            () => {
              utilFunc.scrollTop(800);

              this.formRef.current.reset();
              setTimeout(() => {
                this.clearFileds();
              }, 5000);
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const errorLabel = <div className="red" pointing={"true"} />;
    return (
      <div className="yr-chat-round-box">
        {this.state.modal && (
          <YoutubeModal
            show={this.state.modal}
            text="Watch this page tutorial"
            youtubeUrl={config.installationPage}
            toggleModal={this.toggleModal}
          />
        )}
        {this.state.loading && <div className="ui loading form"></div>}

        <div className="row">
          <div className="col-lg-6 px-lg-5">
            <p className="p-title">
              Please click one of the options below to install the chat widget
              onto your website:
            </p>
            <ul className="nav nav-tabs tabs-box ">
              <li className="active">
                <a href="#install-chat" data-toggle="tab" className="active">
                  <div className="div-tab yr-chat-round-box">
                    <p>
                      <img src="assets/media/logos/filebtn.png" alt="" />
                    </p>
                    <p className="text-blue">I'll Install it myself</p>
                    <p>This takes less than 2 mins</p>
                  </div>
                </a>
              </li>
              <li>
                <a href="#install-tag-manager" data-toggle="tab">
                  <div className="div-tab yr-chat-round-box">
                    <p>
                      <img
                        src="assets/media/logos/tag-manager-icon.png"
                        alt=""
                      />
                    </p>
                    <p className="text-blue">Google Tag Manager Installation</p>
                    <p>This takes 2 minutes</p>
                  </div>
                </a>
              </li>
              <li>
                <a href="#install-teammate" data-toggle="tab">
                  <div className="div-tab yr-chat-round-box">
                    <p>
                      <img src="assets/media/logos/email.png" alt="" />
                    </p>
                    <p className="text-blue">Send to a teammate</p>
                    <p>This takes 2 minutes</p>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <div className="tab-content">
              <div className="tab-pane active yr-installChat" id="install-chat">
                <div className="btn linear-gradient fix-btn-size white-font">
                  <Link to="#" onClick={this.toggleModal} style={{ color: '#fff' }}>
                    Watch Page Tutorial
                  </Link>&nbsp;&nbsp;
                  <i class="fa fa-play-circle" aria-hidden="true"></i>
                </div>
                <h3>Install Your chat widget</h3>
                <p>
                  To install, copy the text in the grey box and paste the text
                  at the end of &lt;body&gt; tag of your website's index file.
                  If you only want the chat to appear on a certain pages, only
                  copy and paste the text at the end of &lt;body&gt; tag of
                  those pages. This process should only take a few minutes! Your
                  chat can be customized on your profile.
                </p>
                {this.displayChatWidget("chatBot")}
              </div>
              <div className="tab-pane yr-installChat" id="install-tag-manager">
                <div className="btn linear-gradient fix-btn-size white-font">
                  <Link to="#" onClick={this.toggleModal} style={{ color: '#fff' }}>
                    Watch Page Tutorial
                  </Link>&nbsp;&nbsp;
                  <i class="fa fa-play-circle" aria-hidden="true"></i>
                </div>
                <h3>Install Your chat widget</h3>
                <p>
                  To install, copy the text in the gray box and paste the text
                  into the tag value in GTM. Tag type will be "Custom HTML".
                </p>

                {this.displayChatWidget("google")}
              </div>
              <div className="tab-pane yr-installChat" id="install-teammate">
                {this.state.messageDialog && (
                  <Message color={"green"} style={{ marginTop: 30 }}>
                    {this.state.successMessage}
                  </Message>
                )}

                <div className="btn linear-gradient fix-btn-size white-font">
                  <Link to="#" onClick={this.toggleModal} style={{ color: '#fff' }}>
                    Watch Page Tutorial
                  </Link>
                </div>
                <Form
                  noValidate
                  autoComplete="off"
                  onValidSubmit={this.handleSubmit}
                  ref={this.formRef}
                  id="team-notes"
                >
                  <h3>
                    Need help? Fill out the following information and we'll take
                    care of the rest.
                  </h3>
                  <div className="form-group row">
                    <label className="col-lg-12 col-form-label bold">
                      What is the name of your website manager?
                    </label>
                    <div className="col-lg-12">
                      <Form.Input
                        autoComplete="off"
                        name="fullName"
                        onChange={this.handleChange}
                        value={this.state.fullName}
                        placeholder="Full Name"
                        validations="isRequired"
                        validationErrors={{
                          isRequired: "This field is required.",
                        }}
                        errorLabel={errorLabel}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-12 col-form-label bold">
                      What is their email address?
                    </label>
                    <div className="col-lg-12">
                      <Form.Input
                        autoComplete="off"
                        name="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                        placeholder="Email Address"
                        validations="isEmail,isRequired"
                        validationErrors={{
                          isEmail: "Please enter a valid email address.",
                          isRequired: "This field is required.",
                        }}
                        errorLabel={errorLabel}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-12 col-form-label bold">
                      What is the phone number of your website manager?
                    </label>
                    <div className="col-lg-12">
                      <Form.Input
                        maxLength="13"
                        autoComplete="off"
                        name="phone"
                        onChange={this.handleChange}
                        value={this.state.phone}
                        placeholder="Phone Number"
                        validations="isRequired,usPhoneLength"
                        validationErrors={{
                          isRequired: "This field is required.",
                          usPhoneLength:
                            "Phone number must be at least 10 numbers.",
                        }}
                        errorLabel={errorLabel}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 ">
                      <button
                        disabled={this.state.loading}
                        className="btn linear-gradient zy-save-btn"
                      >
                        Send Request
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Widgets;
