import React from "react";
import { Link } from "react-router-dom";

class SupportNavigation extends React.Component {
  render() {
    return (
      <div className="kt-header__bottom">
        <div className="kt-container" style={{ backgroundColor: "#fff" }}>
          <div
            className="kt-header-menu-wrapper kt-grid__item kt-grid__item--fluid"
            id="kt_header_menu_wrapper"
          >
            <div
              id="kt_header_menu"
              className="kt-header-menu kt-header-menu-mobile "
            >
              <div className="yr-mobpagetitle">Support Ticket :</div>
              <ul className="kt-menu__nav " style={{ padding: 22 }}>
                <li
                  className="kt-menu__item  kt-menu__item--active "
                  aria-haspopup="true"
                >
                  <Link to="/support/new" className="kt-menu__link linear-gradient">
                    <span className="kt-menu__link-text">
                     <i className="fa fa-building-o" aria-hidden="true"></i>
                      Create Ticket
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SupportNavigation;
