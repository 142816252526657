import axios from "../../config/axios";

const googleReview = {
  saveReviews: (params) =>
    axios.post(`/reviewmanagement/reviews/save_google_reviews`, params),
  
  addLocation:(params)=>
    axios.post(`/reviewmanagement/reviews/save_google_location`, params), 
    
  getLocationList :(params)=>
  axios.post(`/reviewmanagement/reviews/get_google_location_list`, params).then((resp)=> resp ? resp.data:null), 
  
    
};

export default googleReview;
