import React from 'react';
import { Table, Pagination,Button, Icon } from "semantic-ui-react";
import Paging from './Paging';
// import './paging.css';

class Paginate extends React.Component {
    constructor( props ){
        super( props );
    }

    setPagination = (event,page) => {
		this.props.changePage(page.activePage,"_page");
	};

	setPageSize = ( pageSize ) => {
		this.props.changePageSize(pageSize,"_limit")
	}

	displatEntries = () => {
		const { totalRecord, pageLimit, currentPage } = this.props;
		if (parseInt(totalRecord) > 0) {
			let number = parseInt(pageLimit * currentPage);
			let text = ` Showing ${
                this.props.startPoint
			} to ${
				number > totalRecord ? totalRecord : number
			} of ${totalRecord} entries`;
			return text;
		}
		return "";
	};
	
    render() {
        let columnLen = Math.ceil(this.props.columnLength / 2);
        return (
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan={columnLen}>
                        <Paging
                            limit={this.props.pageLimit}
                            onPageLimitChange={(pageLimit) =>
                              this.setPageSize(pageLimit)
                            }
                        />
                        {this.displatEntries()}
                        </Table.HeaderCell>

                        <Table.HeaderCell colSpan={columnLen}>
                        {this.props.totalPages && (
                            <Pagination
                            totalPages={this.props.totalPages}
                            onPageChange={this.setPagination}
                            activePage={this.props.currentPage}
                            />
                        )}
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan={3} textAlign="right">
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        );
    }
}
export default Paginate;