import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function createMarkup(html){
  return {__html: html};
}

export const DatatableRow = props => (
  
  <Table.Row >
    <Table.Cell>
    
    <label className="kt-checkbox kt-checkbox--single"><input type="checkbox" checked={props.list.checked ? true : false}  auto_id={props.list.auto_id} onChange={()=>props.channgeEvent(props.list.auto_id)}></input>&nbsp;<span></span></label>
    
    </Table.Cell>
    <Table.Cell><div style={{width:60}}>{props.list.chat_process ? (props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>: <span ><span className={props.list.chat_process=='Completed' ?'kt-badge  kt-badge--success kt-badge--inline kt-badge--pill' : 'kt-badge  kt-badge--error kt-badge--inline kt-badge--pill'}>{props.list.chat_process}</span></span> :'' }</div></Table.Cell>
    <Table.Cell><div style={{width:35}}>{props.list.chat_from ? (props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>: <span ><span className="kt-badge  kt-badge--web kt-badge--inline kt-badge--pill">{props.list.chat_from}</span></span> :'' }</div></Table.Cell>
    <Table.Cell><div style={{width:100}}>{props.list.chat_from ? (props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>: <span ><span className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill" style={{overflow: "hidden" ,display:"block",maxWidth: "70px",textOverflow: "ellipsis",lineHeight: "3px",whiteSpace: "nowrap"}} title={props.list.referrer_page}>{props.list.referrer_page}</span></span> :'' }</div></Table.Cell>
    <Table.Cell style={{whiteSpace: "nowrap",width:"200px"}}><div style={{width:170}}>{(props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:props.list.date_added}</div></Table.Cell>
    <Table.Cell className="cWidth" style={{whiteSpace: "nowrap",textOverflow: "ellipsis",overflow: "hidden",maxWidth:"200px"}}>
      <div style={{display:"inline-block",minWidth:200,whiteSpace: "nowrap",textOverflow: "ellipsis",overflow: "hidden",maxWidth:"200px"}}>{(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:props.list.job_description}</div> </Table.Cell>
      <Table.Cell className="cWidth" style={{whiteSpace: "nowrap",textOverflow: "ellipsis",overflow: "hidden",maxWidth:"200px"}}><div style={{display: "contents",width:50}}>{(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);"></a></span>:<a className="cursorpointer des-link" onClick={()=>props.viewDetails(props.list.auto_id)}>View Details</a>}</div> </Table.Cell>
  
      <Table.Cell id={"trans_chatstatus_" + props.list.auto_id} style={{width: "150px"}}>{props.list.chat_status?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:props.list.chat_status:""}</Table.Cell>
    
    {/* <Table.Cell style={{width: "150px"}}>{props.list.user_name}</Table.Cell>
   
    
    <Table.Cell>{props.list.zip_code}</Table.Cell>
    <Table.Cell>{props.list.phone_number}</Table.Cell>
    <Table.Cell>{props.list.chat_email}</Table.Cell>
    <Table.Cell>{props.list.lender}</Table.Cell>
    
    <Table.Cell style={{whiteSpace: "nowrap"}}><span dangerouslySetInnerHTML={createMarkup(props.list.host)}  ></span></Table.Cell>
    <Table.Cell ><span dangerouslySetInnerHTML={createMarkup(props.list.utm_url)}  ></span></Table.Cell>
    <Table.Cell>{props.list.location_name}</Table.Cell> */}
    {/* <Table.Cell>{props.list.additionl_captured ? <a className="cursorpointer des-link" onClick={()=>props.viewAdditionalCaptured(props.list.auto_id,'additional_captured')}> View </a> : ''}     </Table.Cell> */}
    <Table.Cell><div >{props.list.team_notes ? (props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>: <a className="cursorpointer des-link" onClick={()=>props.viewNotes(props.list.auto_id)}>View Notes</a> :''}</div></Table.Cell>
    {/* <Table.Cell>{props.list.ip_address}</Table.Cell> */}
    
    
  </Table.Row>
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
