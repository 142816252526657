import axios from "../config/axios";

const automation = {
  
  automationinfo: (params) =>
    axios
      .get(`/workspace/automation/automationinfo`, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  welcomebuttontext: (params) =>
    axios
      .post(`/workspace/automation/welcomebuttontext`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)), 
      
  buttontextadd: (params) =>
    axios
      .post(`/workspace/automation/buttontextadd`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),  

  buttontextdelete: (params) =>
    axios
      .post(`/workspace/automation/buttontextdelete`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)), 
    
  buttonadd: (params) =>
    axios
      .post(`/workspace/automation/buttonadd`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)), 

  buttonedit: (params) =>
    axios
      .post(`/workspace/automation/buttonedit`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)), 

  onchangewp: (params) =>
    axios
      .post(`/workspace/automation/onchangewp`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)), 

  editautomationinfo: (params) =>
    axios
      .post(`/workspace/automation/editautomationinfo`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)), 

  saveLocation: (params) =>
    axios
      .post(`/workspace/automation/saveLocation`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)), 

  deletLocation: (params) =>
    axios
      .post(`/workspace/automation/deletLocation`, { ...params })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)), 
      
};

export default automation;
