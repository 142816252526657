import React from "react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../store/Page";

import "./ConfigurationSetting.css";
import "../../../Grid/grid.css";
import { library, icon } from "@fortawesome/fontawesome-svg-core";

import DatatableList from "./ListDomain/DatatableList";
import SMSDatatableList from "./ListDomain/SMSDatatableList";
import { Message } from "semantic-ui-react";
import "../../Marketing/yr-style.css";

import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

class ListDomain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "Contacts",
      navigationName: "marketing",
      subHeaderTitle: "Engage",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
  }

  render() {
    const { errors } = this.state;

    return (
      <div  className="kt-container">
        <div className="kt-header__bottom">
          <div style={{ backgroundColor: "#fff" }}>
            <div className="yr-pagetitle d-flex justify-content-between kt-margin-t-10 kt-padding-b-0">
              <div className="left yr-titleSelect">
                <h2>Sending domains</h2>
              </div>
            </div>

            <div className="kt-margin-b-20" style={{ maxWidth: "750px" }}>
              <p className="kt-font-bold">
                A sending domain allows you to send outbound emails directly
                from our system. If you have a website you have access to
                setting up a sending domain on our system to start sending
                today.
              </p>
            </div>
          </div>
        </div>
        <div
          className="kt-body  kt-grid kt-grid--ver "
          id="kt_body"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-ameplaybooks">
              <div class="kt-form kt-form--label-right kt-margin-b-30 yr-manageUser-table">
                <div class="row align-items-center">
                  <div class="col-xl-12 order-2 order-xl-1">
                    <div class="row align-items-center">
                      <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                        <div style={{ paddingTop: "10px" }}>
                          {/* <a href="javascript:void(0)" class="btn yr-add-new kt-font-bold float-right" data-toggle="modal" data-target="#kt_modal_6"><i class="fa fa-plus" style={{fontSize: "10px"}}></i>Add Domain</a> */}

                          <Link
                            to={"/marketing/adddomain"}
                            className="btn linear-gradient yr-add-new kt-font-bold float-right"
                          >
                            <i className="fa fa-plus"></i>
                            Add Domain
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <DatatableList />
              </div>
            </div>
          </div>
        </div>
        <div className="kt-header__bottom">
          <div style={{ backgroundColor: "#fff" }}>
            <div className="yr-pagetitle d-flex justify-content-between kt-margin-t-10 kt-padding-b-0">
              <div className="left yr-titleSelect">
                <h2>SMS Sending Numbers</h2>
              </div>
            </div>

            <div className="kt-margin-b-20" style={{ maxWidth: "750px" }}>
              <p className="kt-font-bold">
                In order to send SMS Messages, a system administrator will need to add a number to your account.
                Please review your numbers below or request a number to be added to your account.
              </p>
            </div>
          </div>
        </div>
        <div
          className="kt-body  kt-grid kt-grid--ver "
          id="kt_body"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-ameplaybooks">
              
              <SMSDatatableList />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(ListDomain);
