import React from "react";
import "../../../../../Modal/dialog.css";
import api from "../../../../../../api";

const DatatableDeleteBox = ({ show, title, message, yclid,  notesDel , yourNotes, notes_id, deleteNotes, modalType, modalCloseNoteDeleteBox, buttonmsg, modalbuttofun, loading }) => {
	const ycall_list_id = yclid;//@Rohit
	deleteNotes= (notesid)=> {

		//alert(notesid);
		//alert(call_notes_comment);
		// api.marketing.getContactDetails({'call_notes_id':call_notes_id}).then((data) => {
		api.marketing.deleteYourNotes({'notes_id':notesid}).then((data) => {
		console.log(data)
		try {   
		
			modalCloseNoteDeleteBox(false, '');
			notesDel();
			yourNotes(ycall_list_id, buttonmsg.n_team_id, 1);
		} catch (err) {
		console.log(err);
		}
	  });
	  
	  let modalContactViaObject = {
		show: false,
		title: "Notes",
		loading: false
	  }
	  }

return (
	
		<React.Fragment>
			
			<div
				className={show ? "modal fade show" : "modal fade"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:600}}>
				   {loading && <div className="ui loading form"></div>}
					<div className="modal-content">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalCloseNoteDeleteBox(false, '')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
						</div>
						<div className="modal-body"><div className="px-4">{message}</div></div>
						<div className="modal-footer">
							<button type="button" onClick={() => modalCloseNoteDeleteBox(false, '')} style={{ display: buttonmsg.cancel != '' ? "block" : "none" }} className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" data-dismiss="modal">
								<span>{buttonmsg.cancel}</span>
							</button>
							<button type="button" onClick={() => deleteNotes(buttonmsg.call_notes_id )} style={{ display: buttonmsg.submit != '' ? "block" : "none" }} className="btn linear-gradient yr-submitbtn btn-sm">
								{buttonmsg.submit}
							</button>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DatatableDeleteBox;
