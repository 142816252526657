import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import {Message } from 'semantic-ui-react';

const regex = new RegExp('^[a-zA-Z0-9 ]+$');

export class DatatableFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      filterValid: true,
    };
    console.log('table list', this.props.lists);
  }

  

  handleOnChange = (event, { name, value }) => { 
     //alert('hi');
     //console.log(name);
   if (value !== '' && !regex.test(value)) {

      this.setState({ [name]: value, filterValid: false });
    } else {
      this.setState({ [name]: value, filterValid: true });
    }
    if (value.trim() == '') {
        
        this.props.onSubmitFilter(value);
    }
  };

  handleOnClick = () => { 
    let value = this.state.filter;
    if (value.trim() !== '') {
      this.props.onSubmitFilter(value);
    }
  };
// add by rohit
  onChange = (e) => { 
    //alert( e.target.value );
    this.props.onSubmit_is_subscribed( e.target.value );
  
  };

  // add by rohit
  onChangeSource = (e) => { 
    //alert( e.target.value );
    this.props.onSubmit_Source( e.target.value );
  
  };

  

  render() {
     const { filter } = this.state;
   
    return (




      <div class="kt-form kt-form--label-right kt-margin-t-30 kt-margin-b-30 yr-manageUser-table">
      <div class="row">
          <div class="col-md-12">
                  {/* <div class="yr-mc-select">
                      <select class="form-control">
                          <option value="">Manage Contacts</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                      </select>
                  </div> */}
              <a href="javascript:void(0)" onClick={() => this.props.associateCampaigns()} class="kt-font-bold blue" data-toggle="modal" data-target="#kt_modal_6">Associated campaigns</a>

          </div>
      </div>


      <div class="col-md-12">
      <div class="row hr-top">		
          <div class="col-lg-4">
          <div className="kt-form kt-form--label-right kt-margin-t-10 kt-margin-b-10">
        <div className="row align-items-center">
          <div className="col-xl-8 order-2 order-xl-1">
            <div className="row align-items-center">
              <div className="col-md-12 kt-margin-b-20-tablet-and-mobile">
                <div className="kt-input-icon kt-input-icon--left">
                <Form onSubmit={this.handleOnClick}>
                  <Form.Input
                    style={{ marginLeft: 4 }}                    
                    name="filter"
                    placeholder="Search"
                    className="search-input-mu pl--35"
                    value={filter}
                    onChange={this.handleOnChange}
                  />
                  </Form>
                  {/* <span className="kt-input-icon__icon kt-input-icon__icon--left sr--iconBox" style={{cursor:"pointer"}} onClick={this.handleOnClick} >
                    <span>
                      
                    </span>                            
                  </span> */}
                  <span className="yr-searchPlace-holder sr--pHolder" onClick={this.handleOnClick}><i className="la la-search"/></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
          </div>
          <div class="col-lg-8">
              <div class="row">
                      {this.props.type === 1 && <select class="form-control kt-margin-t-10 mr-4" id="kt_form_status " style={{width:"auto"}}  onChange={(e) => this.onChange(e)}>
                          <option value="">Select subscribe status</option>
                          <option value="1">Subscribed</option>
                          <option value="0">Unsubscribed</option>
                      </select> }
                      <select class="form-control kt-margin-t-10" id="kt_form_status " style={{width:"auto"}} onChange={(e) => this.onChangeSource(e)}>
                          <option value="">Select source</option>
                          <option value="static">Static</option>
                          <option value="Zapier">Zapier</option>
                          <option value="CSV">CSV</option>
                          <option value="Service Titan">ServiceTitan</option>
                      </select> 
                      <button type="button" class="btn linear-gradient-diff diff btn-icon-sm zy-outline kt-margin-t-10" onClick={()=>this.props.downloadCSV()}>Export Contacts
                      </button>
                      <button type="button" class="btn linear-gradient-diff diff  btn-icon-sm zy-outline kt-margin-t-10" onClick={() => this.props.addContact(this.props.type)}>Add Contacts
                      </button>
                      {/* <button type="button" class="btn linear-gradient-diff diff  btn-icon-sm zy-outline kt-margin-t-10" onClick={() => this.props.addContactZapier()}>Add Contacts Via Zapier
                      </button> */}
                      <button type="button" class="btn linear-gradient-diff diff btn-icon-sm zy-outline kt-margin-t-10" onClick={() => this.props.associateCampaigns()}>Associated Campaigns
                      </button>
                      {this.props.type === 1 && <button
                      type="button" style={{color:"white",backgroundColor:"#2786fb", border:"solid 1px #2786fb"}}
                      className="btn linear-gradient btn-outline-info btn-icon-sm zy-outline kt-margin-t-10"
                      onClick={() => this.props.subscribeCandidate()} title="Subscribe"
                    >Subscribe
                    </button>}
                    {this.props.type === 1 && <button
                      type="button" style={{color:"white",backgroundColor:"#ef6767", border:"solid 1px #ef6767"}}
                      className="btn btn-outline-info btn-icon-sm zy-outline kt-margin-t-10"
                      onClick={() => this.props.unSubscribeCandidate()} title="Unsubscribe"
                    >Unsubscribe
                    </button>}
                      
                  </div>
                  
              </div>
          </div>
          <Message color='red' style={{ display: this.props.selectAtleastOne==true ? "block" : "none" }}>Please select atleast one email.</Message>
                <Message color='green' style={{ display: this.props.subscribedUnSubscribedMsgOpen==true ? "block" : "none" }}>{this.props.subscribedUnSubscribedMsg}</Message>

  </div>

  
      </div>


    );
  }
}

DatatableFilter.propTypes = {
  onSubmitFilter: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
};
