import React from 'react';
import classes from "./ScheduledBranchCall.module.css"
import { Label, Message } from "semantic-ui-react";
import api from '../../../../../api';
import { Dropdown,Dimmer,Loader,Image,Segment,TextArea} from 'semantic-ui-react'
import Modal from "../../../ChatSetup/components/Modal";

class ScheduledBranchCall extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hourslist:[],
            timezone:[],
            daysTimeschedule:[{name:'Sunday'},{name:'Monday'},{name:'Tuesday'},{name:'Wednesday'},{name:'Thursday'},{name:'Friday'},{name:'Saturday'}],
            selecteDailyTime:[{from:"",to:""},{from:"",to:""},{from:"",to:""},{from:"",to:""},{from:"",to:""},{from:"",to:""},{from:"",to:""}],
            dailyTime:[{from:"",to:""},{from:"",to:""},{from:"",to:""},{from:"",to:""},{from:"",to:""},{from:"",to:""},{from:"",to:""}],
            selectedTimeZone:{value:"",name:""},
            overlappingTime:{from:"",to:""},
            userToasterColor : "",
            userToasterMsg : "",
            occupiedHours:[],
            userToaster:false,
            modalStatus:false,
            modalTitle:'Reset scheduled welcome branch configuration.',
            modalbody: "Are you sure you want to remove it?",
            modalfooter :<>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => this.modalStatuschange()}
                    className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                    data-dismiss="modal"
                >
                    <span>No</span>
                </button>
                <button
                    type="button"
                    className="btn  linear-gradient yr-submitbtn btn-sm"
                    onClick={() => this.resetConfirm()}
                >
                    <span>Yes</span>
                </button>
            </div>
            </>,
            loader:false,
            holidaysList:[],
            selecteHolidaysList:[],
            associatedList:[],
            addDispalyHoidayFeild:false,
            showHolidays:false,
            noteActiveIndex:0
            
        }
        this.targetRef = React.createRef();
        this.messagetargetRef = React.createRef();
    }
    componentDidMount() {
        this.onLoad();
    }

    stateOptions =  () => {let timezoneobje = Object.entries(this.state.timezone).map( (state, index) => ({
        key: index,
        text: state[1],
        value: state[0]
    }))

    return timezoneobje;
    }

    componentDidUpdate(prevProps) {
    }
    onLoad = () => {
        this.setState({loader:true})
        let param =  { branch_id:this.props.branch_id,workspace_id:atob(this.props.workspace_id)};
        api.botbuilder.fetchTimezoneHourLilst(param).then( ( res ) => {
           this.setState({
                selecteDailyTime:res.data.daywise_configration ? res.data.daywise_configration : this.state.selecteDailyTime,
                selectedTimeZone:res.data.timezonevalue ? res.data.timezonevalue : this.state.selectedTimeZone,
                overlappingTime:res.data.overlapping ? res.data.overlapping : this.state.overlappingTime,
                selectedTimeZone:{value:res.data.timezonevalue,name:res.data.timezone}
           })
        });
        api.botbuilder.gettimezoneHourConfigration().then( ( res ) => {
           this.setState({
                ...this.state,
                hourslist:res.hours?res.hours:[],
                timezone:res.timezone?res.timezone:[]
           })
        });
        let param_hours =  { workspace_id:this.props.workspace_id};
        api.botbuilder.getOccupiedHours( param_hours ).then( ( res ) => {
            this.setState({
                occupiedHours:res.data
            },() =>  this.setState({loader:false}))
        });

        let holidaysList = {};
        let paraholidayparams =  { workspace_id:this.props.workspace_id,};
        api.botbuilder.getHolidaysList( paraholidayparams ).then( ( res ) => {
            this.setState({
                holidaysList:res.holidays.data
            },() =>  this.setState({loader:false}))
            let selectedHolidays = [];
            res.holidays.selectedHolidays.map( (holidaym,index) => {
                let dd = {};
                dd = {
                    from:holidaym.from,
                    to:holidaym.to,
                    event:holidaym.event,
                    content:holidaym.content,
                    data:holidaym?.content,
                    id:holidaym.id
                }
                selectedHolidays.push(dd);
            });
            this.setState({associatedList:selectedHolidays})
        });


    };
    validateHour = (checktime,occupiedArray) => {
        if(checktime == undefined || occupiedArray.from ==undefined  ){ return false;}
        let fromValue;
        let toValue;
        let flag = false;
        let chekconverted;
        let halfHourBefore;
        occupiedArray.forEach(element => {
            if(element.from && element.to){
                chekconverted = new Date('2024-01-06 '+checktime);
                fromValue = new Date('2024-01-06 '+element.from);
                toValue = new Date('2024-01-06 '+element.to);
                toValue = new Date(toValue.getTime() - 30 * 60 * 1000); // 30 minutes * 60 seconds * 1000 milliseconds
                if(chekconverted >= fromValue && chekconverted <= toValue){
                    flag = true;
                }
            }
        });
        return flag;
    }

    modalStatuschange = () => {
        this.setState({ modalStatus : !this.state.modalStatus},() => {
        })
    }

    
    renderHours = (type,index) => {
        let daywiseConfigrationOccupied = this.state.occupiedHours?.daywiseConfigration
        let overlappingOccupied = this.state.occupiedHours?.overlapping
        let onlyparticulardatre = [];
        if(type == 'daily' && daywiseConfigrationOccupied){
            Object.values(daywiseConfigrationOccupied).map(element => {
                onlyparticulardatre.push(element[index]); 
            });
        }else if(type == 'holiday' ){
            let holidays = [...this.state.holidaysList];
            onlyparticulardatre.push(holidays[index].schedule); 
        }if(overlappingOccupied){
            Object.values(overlappingOccupied).map(element => {
                onlyparticulardatre.push(element); 
            });
        }
        let hourArray = Object.entries(this.state.hourslist);
        if(type == 'holiday'){
            return (
                <>
                    {hourArray.map(( hour ) => 
                        {   if(hour[0] != undefined && onlyparticulardatre != undefined){
                             return <option value={hour[0]}  disabled = {this.validateHour(hour[0],onlyparticulardatre)}>{hour[1]}</option>
                            }
                        }
                        
                    )}
                </>
            )
        }else{
            return (
                <>
                    {hourArray.map(( hour ) => 
                        <option value={hour[0]}  disabled = {this.validateHour(hour[0],onlyparticulardatre)}>{hour[1]}</option>
                    )}
                </>
            )
        }
        
    }
    renderTimezone = ( selectedhour ) => {
        let timezoneArray = Object.entries(this.state.timezone);
        let stateTemp = {...this.state.selectedTimeZone}
        return (
            <>
                {timezoneArray.map(( timezone ) => <option value={timezone[1]} selected={stateTemp.value == timezone[1] ? "selected" : ""}>{timezone[0]}{timezone[1]}</option>)}
            </>
        )
    }
    
    renderDaywiseData = () => {
        return (
            <>
                {
                    this.state.daysTimeschedule.map( (daySchedule,index) => {
                        return <> 
                            <div className='row mb-3'>
                                <label className="col-lg-3 my-3" >{daySchedule.name}</label>
                                <div className="col-lg-9">
                                    <div className='d-flex justify-content-between'>
                                        <select className={` ${classes.option} form-control mr-3` } 
                                            onChange={(event) => this.selectTime(event,"from","daily",index)}
                                            value = {this.state.selecteDailyTime[index].from ?? '' }
                                            defaultValue = {this.state.selecteDailyTime[index].from ?? '' }
                                        >
                                            <option value="">Select Hour</option>
                                            {this.renderHours( "daily",index) }
                                        </select>
                                        <select className={` ${classes.option} form-control ` }  
                                            onChange={(event) => this.selectTime(event,"to","daily",index)}
                                            value = {this.state.selecteDailyTime[index].to ?? '' }
                                            defaultValue = {this.state.selecteDailyTime[index].to ?? '' }
                                        >
                                            <option value="">Select Hour</option>
                                            {this.renderHours( "daily",index)}
                                        </select>
                                    </div>
                                    
                                </div>
                            </div>
                        </>
                    })
                }
                {this.state.modalStatus && <Modal
                    show={this.state.modalStatus}
                    footer={this.state.modalfooter}
                    onclose={this.modalStatuschange}
                    title={this.state.modalTitle}
                    body={this.state.modalbody}
                />}

                
            </>
        )
    }

    changeWelcomeNotesNAndtext = (event,index) => {
        let holidaysList = [...this.state.holidaysList]
        holidaysList[index].content = event.target.value;
        this.setState({
            holidaysList : holidaysList
        });
    }
    renderHoursOfHoliday = (target,type,index) => {
        let selectedHolidays = [...this.state.selecteHolidaysList];
        console.log( type,index );
        if( type == 'holiday'){
            if( !selectedHolidays[index]){
                selectedHolidays[index]= {from:selectedHolidays[index]?.from,to:selectedHolidays[index]?.to,id:target.value,content:selectedHolidays[index]?.content};
            }else{

                selectedHolidays[index].id= target.value;
            }
        }else{
            if( !selectedHolidays[index]){
                
            }else{
                selectedHolidays[index][type]= target.value;
            }
        }
        this.setState({selecteHolidaysList:selectedHolidays})
    }
    deleteHoliday = (holiday) => {
        if(this.messagetargetRef.current)
            this.messagetargetRef.current.scrollIntoView({ behavior: 'smooth' });
        let paraholidayparams =  { workspace_id:this.props.workspace_id,hoiday_id:holiday.id };
        api.botbuilder.deleteHolidayNode( paraholidayparams ).then( ( res ) => {
            if(res.status){
                let associatedList = this.state.associatedList.filter( (item) => item.id != holiday.id );
                this.setState({
                    associatedList : associatedList
                })
            }

            

            this.setState({
                userToasterColor : res.color,
                userToasterMsg : res.message,
                userToaster:true,
            })
        });    
        setTimeout(() => {
            this.setState({
                userToasterColor : "",
                userToasterMsg : "",
                userToaster:false
            })
        }, 2000);
        
    }

    edtiHoliday = (holiday) => {
        this.setState({addDispalyHoidayFeild:true})
        let selecteHolidaysList = [...this.state.selecteHolidaysList]
        selecteHolidaysList[0] = {from:holiday.from,to:holiday.to,id:"holiday-"+holiday.id,content:holiday.content};
        this.setState({selecteHolidaysList:selecteHolidaysList});
        if(this.targetRef.current)
        this.targetRef.current.scrollIntoView({ behavior: 'smooth' });
        
    }
    
    renderHolidays = () => {
        return (
            <>
                {
                    this.state.associatedList.map( (daySchedule,index) => {
                        return <> 
                            <div className={` ${classes.holidayPicker}`}>
                                <div className="col-lg-12 mr-3">
                                    <label className="col-lg-10">Holiday</label>
                                    <label className="col-lg-1 holidayAction" title="Edit Holiday" onClick={(event) => this.edtiHoliday(daySchedule)}><i className="fas fa-edit" ></i></label>
                                    <label className="col-lg-1 holidayAction" title="Delete Holiday" onClick={ (event) => this.deleteHoliday(daySchedule)}><i className="fas fa-trash-alt" ></i></label>
                                </div>
                                <div className="col-lg-12">
                                    <select
                                        id="holiday-select"
                                        className={` ${classes.option} form-control mr-3` } 
                                        onChange={(event) => this.renderHoursOfHoliday(event.target,"holiday",0)}
                                        disabled="true"
                                    >
                                        <option value="">{daySchedule.event}</option>
                                    </select>
                                    <div className='d-flex justify-content-between mt-3'>
                                        <select className={` ${classes.option} form-control mr-3` } onChange={(event) => this.renderHoursOfHoliday(event.target,"from",0)} disabled="true">
                                            <option value="">{daySchedule.from}</option>
                                        </select>
                                        <select className={` ${classes.option} form-control ` }  onChange={(event) => this.renderHoursOfHoliday(event.target,"to",0)} disabled="true">
                                            <option value="">{daySchedule.to}</option>
                                        </select>
                                    </div>
                                    <div className='d-flex justify-content-between mt-3'>
                                       <textarea className = {`${classes.textareanote}`} value={daySchedule.content} onChange={""} disabled></textarea>
                                    </div>
                                </div>
                            </div>
                        </>
                    })
                }
                
            </>
        )
    }

    selectTimezone = (event,data) => {
        let newselectedtimezone = {...this.state.selectedTimeZone};
        newselectedtimezone.value = data.value;
        newselectedtimezone.name = data.options.find(option => option.value === data.value)?.text;
        this.setState({selectedTimeZone:newselectedtimezone});
    }

    selectTime = (event,from,type,index) => {

        let fromValue;
        let toValue;
        let modifiesvalue;
        let flag = true;
        let newOverlapping = {...this.state.overlappingTime};
        let newDaily = [...this.state.selecteDailyTime];
        let holidays = [...this.state.holidaysList];
        if( event.target.value != ''){
            if( this.state.selectedTimeZone.value == '' || this.state.selectedTimeZone.value == 'Select Time Zone' || this.state.selectedTimeZone.value == undefined){
                this.setState({
                    userToasterColor : "red",
                    userToasterMsg : "Please select timezone first.",
                    userToaster:true,
                })
                let scrolltop = document.querySelector(".branchlistbuilder").offsetTop - document.body.scrollTop;
                window.scroll({ top: scrolltop, left: 0, behavior: 'smooth' });
                setTimeout(() => {
                    this.setState({
                        userToasterColor : "",
                        userToasterMsg : "",
                        userToaster:false,
                    })
                }, 2000);
                return;
            }
        }

        if(type == "overlapping"){
            if(event.target.value == ''){
                newOverlapping[from] = event.target.value;
                this.setState({overlappingTime:newOverlapping})
                return;
            }
            if( from  ==  'from'){
                if(newOverlapping['to'] == ""){
                    newOverlapping[from] = event.target.value;
                    this.setState({overlappingTime:newOverlapping})
                    return;
                }
                fromValue = new Date('2024-01-06 '+event.target.value);
                toValue = new Date('2024-01-06 '+newOverlapping['to']);
            }else{
                toValue = new Date('2024-01-06 '+event.target.value);
                fromValue = new Date('2024-01-06 '+newOverlapping['from']);
            }
            if( fromValue < toValue){
                newOverlapping[from] = event.target.value;
                this.setState({overlappingTime:newOverlapping})
            }else{  
                flag = false;
            }
        }else if(type == "daily"){
            if(event.target.value == ''){
                newDaily[index][from] = event.target.value;
                if(from == 'from'){
                    newDaily[index]["to"] = event.target.value;
                }
                this.setState({selecteDailyTime:newDaily})
                return;
            }
            // Convert array to object
            modifiesvalue = newDaily.map( (daily,index1) => {
                if( from  ==  'from'  ){
                    if(daily['to']  !=  '' ){
                        fromValue = new Date('2024-01-06 '+event.target.value);
                        toValue = new Date('2024-01-06 '+daily['to']);
                    }else{  
                        fromValue = 0;
                        toValue = 10;
                    }
                }else{
                    toValue = new Date('2024-01-06 '+event.target.value);
                    fromValue = new Date('2024-01-06 '+daily['from']);
                }
                if( fromValue < toValue){
                    if( daily[from] == "" ){
                        daily[from] = event.target.value;
                    }
                    if(  index1 == index ){
                        daily[from] = event.target.value;
                    }
                }else{  
                    flag = false
                }
                return daily;
            });
           
            if(flag){
                this.setState({selecteDailyTime:modifiesvalue})
            }
        }else if( type == "holiday"){
            if( from  ==  'from'){
                if(holidays[index].schedule.to == ""){
                    holidays[index].schedule[from] = event.target.value;
                    this.setState({holidaysList:holidays})
                    return;
                }
                fromValue = new Date('2024-01-06 '+event.target.value);
                toValue = new Date('2024-01-06 '+holidays[index].schedule.to);
            }else{
                toValue = new Date('2024-01-06 '+event.target.value);
                fromValue = new Date('2024-01-06 '+holidays[index].schedule.from);
            }
            if( fromValue < toValue){
                holidays[index].schedule[from] = event.target.value;
                this.setState({holidaysList:holidays})
            }else{  
                flag = false;
            }
        } 
        if( !flag){
            this.setState({
                userToasterColor : "red",
                userToasterMsg : "Start time must be less than End time.",
                userToaster:true,
            })
            let scrolltop = document.querySelector(".branchlistbuilder").offsetTop - document.body.scrollTop;
            window.scroll({ top: scrolltop, left: 0, behavior: 'smooth' });
            setTimeout(() => {
                this.setState({
                    userToasterColor : "",
                    userToasterMsg : "",
                    userToaster:false,
                })
            }, 2000);
        }
    }

    saveConfigration = () => {
        if( this.state.selecteHolidaysList.length ){
            let selectedLength = [...this.state.selecteHolidaysList][0];
            if( selectedLength.content == undefined || selectedLength.from == undefined || selectedLength.to == undefined || selectedLength.content == '' || selectedLength.from == '' || selectedLength.to == ''){
                let scrolltop = document.querySelector(".branchlistbuilder").offsetTop - document.body.scrollTop;
                window.scroll({ top: scrolltop, left: 0, behavior: 'smooth' });
                this.setState({
                    userToasterColor : "red",
                    userToasterMsg : "You must fill all appropriate data.",
                    userToaster:true,
                    selecteHolidaysList:[]
                })
                return;
            }
        }
        let dataForSubmit = {
            branch_id:this.props.branch_id,
            workspace_id:atob(this.props.workspace_id),
            selectedTimeZone:this.state.selectedTimeZone,
            selecteDailyTime:this.state.selecteDailyTime,
            overlappingTime:this.state.overlappingTime,
            holidays:this.state.selecteHolidaysList
        };
        let flag = true;
        Object.values( this.state.selecteDailyTime ).forEach(element => {
            if(element.from != ''){
                if( element.to == ''){
                    flag = false;
                }
            }else if(element.to != '' ){
                if( element.from == ''){
                    flag = false;
                }
            }
        });
        if(this.state.overlappingTime.from != ''){
            if( this.state.overlappingTime.to == ''){
                flag = false;
            }
        }else if(this.state.overlappingTime.to != '' ){
            if( this.state.overlappingTime.from == ''){
                flag = false;
            }
        }
        if( flag ){
             api.botbuilder.savetimezoneHourConfigration( dataForSubmit ).then( ( res ) => {
                 this.setState({
                     userToasterColor : res.data.color,
                     userToasterMsg : res.data.message,
                     userToaster:true
                 })
                 let scrolltop = document.querySelector(".branchlistbuilder").offsetTop - document.body.scrollTop;
                 window.scroll({ top: scrolltop, left: 0, behavior: 'smooth' });
                 this.onLoad();
                 setTimeout(() => {
                     this.setState({
                         userToasterColor : "",
                         userToasterMsg : "",
                         userToaster:false,
                         selecteHolidaysList:[]
                     })
                 }, 3000);
             });
        }else{
            this.setState({
                userToasterColor : "red",
                userToasterMsg : "You must select from and to both.",
                userToaster:true,
                selecteHolidaysList:[]
            })
            let scrolltop = document.querySelector(".branchlistbuilder").offsetTop - document.body.scrollTop;
            window.scroll({ top: scrolltop, left: 0, behavior: 'smooth' });
            setTimeout(() => {
                this.setState({
                    userToasterColor : "",
                    userToasterMsg : "",
                    userToaster:false,
                    loader:false
                })
            }, 2000);
        }
    }
    resetConfigration = () => {
        this.modalStatuschange()
    }

    resetConfirm = () =>{
        this.setState({
            selecteDailyTime:this.state.dailyTime,
            selectedTimeZone:{value:"",name:""},
            overlappingTime:{from:"",to:""}
        },() => {
            this.saveConfigration()
            this.modalStatuschange()
        })
    }

    showHideHolidays = () => {
        this.setState( {
            ...this.state,
            showHolidays:!this.state.showHolidays
        })
    }

    setNoteIndex = (index = 1) => {
        this.setState({noteActiveIndex:index})
    }
    
    render() {
        return (
            <div>
                 <Segment>
                 {this.state.loader &&
                    <Dimmer active inverted>
                    <Loader size='large'>Loading</Loader>
                    </Dimmer>
                 }
                    <div className="yr-chat-round-box yr-closebtn" ref={this.messagetargetRef}>
                        <button type="button" class="close linear-gradient" onClick={() => this.props.popupClose()}>&#10006;</button>
                        <h2  >Set my time on calendar.</h2>
                        <Message
                            color={
                                this.state.userToasterColor
                                    ? this.state.userToasterColor
                                    : "teal"
                            }
                            style={{
                                display: this.state.userToaster ? "block" : "none",
                                marginTop: 30,
                            }}
                        >
                            {this.state.userToasterMsg}
                        </Message>
                        <div className="form-group row mb-0">
                            {/* Select time zone for scheduling calender */}
                            
                            <div className="col-lg-12"> 
                                <div className='row'>
                                        <label className="col-lg-3 my-3" >Time Zone :</label>
                                        <div className="col-lg-9 mb-3">
                                            <Dropdown placeholder='Select Timezone Here' search selection options={this.stateOptions()}
                                                onChange={this.selectTimezone}
                                                value={this.state.selectedTimeZone.value??this.state.selectedTimeZone.value}
                                            />
                                        </div>
                                </div>
                            </div>

                            {/* Select calender for overlapping time */}
                            <div className="col-lg-12 py-3">
                                <h3>All day rule </h3>
                                <div className='row'>
                                    <label className="col-lg-3 my-3" >Every day on</label>
                                    <div className="col-lg-9">
                                    <div className='d-flex justify-content-between'>
                                        <select className={` ${classes.option} form-control mr-3` } 
                                            onChange={(event) => this.selectTime(event,"from","overlapping")}
                                            value={this.state.overlappingTime.from ?? this.state.overlappingTime.from }
                                            defaultValue={this.state.overlappingTime.from ?? this.state.overlappingTime.from }
                                        >
                                            <option value="">Select Hour</option>
                                            { this.renderHours( )}
                                        </select>
                                        <select className={` ${classes.option} form-control` } onChange={(event) => this.selectTime(event,"to","overlapping")}
                                        value={this.state.overlappingTime.to ?? this.state.overlappingTime.to }
                                        defaultValue={this.state.overlappingTime.to ?? this.state.overlappingTime.to }>
                                            <option value="">Select Hour</option>
                                            {this.renderHours('overlapping',0)}
                                        </select>
                                    </div>

                                    </div>
                                    
                                </div>
                            </div>
                            {/* Select  calender on day basis */}
                            <div className="col-lg-12">
                                <h3 className='my-3'> Daily schedule on calendar </h3>
                                {this.renderDaywiseData()}
                            </div>

                            {/* Holidays List  */}
                            {this.props.bot_type === 'Voice_Turbo' &&
                            <div className="col-lg-12">
                                <h3 className='my-3' >Schedule's for holidays</h3>
                                {
                                    this.state.associatedList.length > 0 &&  
                                    <div className={` ${classes.cursor}` } onClick={() => this.showHideHolidays()} title='Click to show/hide holiday list'>
                                    <label className={` ${classes.cursor}` } > {this.state.showHolidays ? <span><i className={`fa fa-minus`}></i> Hide holiday List</span> : <span><i className={`fa fa-plus`}></i> Show holiday List</span> } </label>
                                    </div>
                                }
                               

                                {this.state.showHolidays && this.renderHolidays()}
                                {/* this is drop down with the list of holidays starts*/}
                                <div className={` ${classes.cursor}` } onClick={() => this.setState({addDispalyHoidayFeild:!this.state.addDispalyHoidayFeild})} title='Click to add/hide holiday schedule.'>
                                    <span><i className={`fa fa-plus ${classes.cursor}`}></i></span>
                                    <label className={` ${classes.cursor}` } > {this.state.addDispalyHoidayFeild ? " Hide holiday schedule " : " Add holiday Schedule"} </label>
                                </div>
                                {this.state.addDispalyHoidayFeild && <div className="holiday-picker" ref={this.targetRef}>
                                    <div className="col-lg-12"  >
                                        <select
                                            id="holiday-select"
                                            className={` ${classes.option} form-control mr-3` } 
                                            onChange={(event) => this.renderHoursOfHoliday(event.target,"holiday",0)}
                                            value={this.state.selecteHolidaysList.length ? this.state.selecteHolidaysList[0].id :0 }
                                        >
                                            <option value="">-- Select Holiday --</option>
                                            {this.state.holidaysList.map((holiday,tabIndex) => (
                                            <option key={holiday.tabIndex} value={holiday.id} >
                                                {holiday.event} ({holiday.data})
                                            </option>
                                            ))}
                                        </select>
                                        <div className='d-flex justify-content-between mt-3'>
                                            <select className={` ${classes.option} form-control mr-3` } 
                                            onChange={(event) => this.renderHoursOfHoliday(event.target,"from",0)}
                                            value={this.state.selecteHolidaysList.length ? this.state.selecteHolidaysList[0].from :0 }
                                            >
                                                <option value="">Select Hour</option>
                                                {this.renderHours( "holiday",0) }
                                            </select>
                                            <select className={` ${classes.option} form-control ` } 
                                            value={this.state.selecteHolidaysList.length ? this.state.selecteHolidaysList[0].to :0 }
                                            onChange={(event) => this.renderHoursOfHoliday(event.target,"to",0)} >
                                                <option value="">Select Hour</option>
                                                {this.renderHours( "daily",0)}
                                            </select>
                                        </div>
                                        <div className='d-flex justify-content-between mt-3'>
                                            <textarea className = {`${classes.textareanote}`} default="" 
                                            onChange={(event) => this.renderHoursOfHoliday(event.target,"content",0)}
                                            value={this.state.selecteHolidaysList.length ? this.state.selecteHolidaysList[0].content :"" }
                                            ></textarea>
                                        </div>

                                    </div>
                                </div>}
                                </div>
                            }  
                            {/* this is drop down with the list of holidays ends*/}
                            <div className="row w-100 mt-2">
                                <div className="col-lg-12">
                                    <div className='d-flex justify-content-end'>
                                    <button  className="btn linear-gradient yr-submitbtn float-right mr-3" onClick={() => this.resetConfigration()}> Reset </button>
                                    <button type="submit"  className="btn linear-gradient yr-submitbtn float-right" onClick={() => this.saveConfigration()}> Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Segment>
            </div>
        )
    }
}


export default ScheduledBranchCall;
