import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react'
import WaitingForResponse from './WaitingForResponse';

const LeftSideAccordion = props => {
    const handleClick = (type) => {
        props.openLeftAccordion(type)
    }


    const renderAutomatedChat = e => {
        return (
            (props.props.user.feature == "freemium") ?
                <div className="card-body d-flex justify-content-center py-4">
                    <button className="btn linear-gradient zy-save-btn bigwithshadow">Upgrade to use this feature</button>
                </div> :
                <div>
                    <div className="d-flex justify-content-end mh-timeText">
                        <p style={{ display: props.state.automatedChatWaitTimeBar }} className="time-waiting">TIME WAITING</p>
                    </div>
                    <div className="kt-notification-v2 mh-automatedChat">

                        <WaitingForResponse showDeleteConfimationModal={props.showDeleteConfimationModal} chatListEngage='live' joinedTheConversation={"0"} state={props.state} openLiveChatBoxOnClickLeftRow={props.openLiveChatBoxOnClickLeftRow} />
                    </div> 
                </div>
        )
    }
    const renderFreemiumChat = e => {
        return (
            (props.props.user.feature == "freemium") ?
               
                <div>
                    <div className="d-flex justify-content-end mh-timeText">
                        <p style={{ display: props.state.automatedChatWaitTimeBar }} className="time-waiting">TIME WAITING</p>
                    </div>
                    <div className="kt-notification-v2 mh-automatedChat">

                        <WaitingForResponse showDeleteConfimationModal={props.showDeleteConfimationModal} chatListEngage='live' joinedTheConversation={"0"} state={props.state} openLiveChatBoxOnClickLeftRow={props.openLiveChatBoxOnClickLeftRow} />
                    </div> 
                </div>:""
        )
    }
    return (
        <Accordion>
            <Accordion.Title
                active={props.state.LeftSideAccordionWaitActive}
                index={0}
                onClick={() => handleClick("w")}
            >
                <Icon name='angle right' />
                Automated Chats
            </Accordion.Title>
            <Accordion.Content active={props.state.LeftSideAccordionWaitActive}>
                {renderAutomatedChat()}
            </Accordion.Content>
            <Accordion.Title
                active={props.state.LeftSideAccordionWaitActive}
                index={1}
                onClick={() => handleClick("w")}
                className={"mh-userType-" + props.props.user.feature}
            >
                <Icon name='angle right' className={"mh-userType-" + props.props.user.feature} />
                Currently waiting for Response
            </Accordion.Title>
            <Accordion.Content className={"mh-userType-" + props.props.user.feature} active={props.state.LeftSideAccordionWaitActive}>
            {renderFreemiumChat()}
            </Accordion.Content>
            <Accordion.Title
                active={props.state.LeftSideAccordionCurrentlyChattingActive}
                index={2}
                onClick={() => handleClick("cc")}
            >
                <Icon name='angle right' />
                Currently Chatting
            </Accordion.Title>
            <Accordion.Content active={props.state.LeftSideAccordionCurrentlyChattingActive}>
                <div>
                    <div className="d-flex justify-content-end mh-timeText">
                        <p style={{ display: props.state.automatedChatWaitTimeBar }} className="time-waiting">LAST RESPONDED AT</p>
                    </div>
                    <div className="kt-notification-v2 mh-automatedChat">

                        <WaitingForResponse showDeleteConfimationModal={props.showDeleteConfimationModal} joinedTheConversation={"1"} chatListEngage="live" state={props.state} openLiveChatBoxOnClickLeftRow={props.openLiveChatBoxOnClickLeftRow} />
                    </div> </div>
            </Accordion.Content>
        </Accordion>
    )
}


export default LeftSideAccordion;