import React from "react";
import { Table, TableCell } from "semantic-ui-react";
import PropTypes from "prop-types";
import ReactTooltip from 'react-tooltip';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export const DatatableRow = (props) => {
  let rowcid= props.list.cid;
  console.log("rowid",props);
  return(<Table.Row>
    <Table.Cell> 
        {<label className="kt-checkbox kt-checkbox--single"><input type="checkbox" checked={props.list.checked ? true : false}  cid={rowcid} onChange={()=>props.channgeEvent(rowcid)}></input>&nbsp;<span></span></label>}
       </Table.Cell>
    <Table.Cell style={{ whiteSpace: "nowrap" }}>
      <a
        id={rowcid}
        className="btn btn-icon"
        onClick={() => props.viewContactDetail(rowcid,props.bid)}
        title="View"
      >
        <i className="la la-eye blue"></i>
      </a>
      <a
        id={props.list.cid}
        className="btn btn-icon"
        onClick={() => props.editContact(rowcid)}
        title="Edit"
      >
        <i className="la la-edit blue"></i>
      </a>
      <a
        onClick={() => props.deleteContact(rowcid)}
        title="Delete"
        className="btn btn-icon gray"
      >
        <i className="la la-trash red"></i>
      </a>
    </Table.Cell>
    {props.type==3?<TableCell>
      <span>{props.list['First Name']}</span>
    </TableCell>:null}
    {props.type==3?<TableCell>
      <span>{props.list['Last Name']}</span>
    </TableCell>:null}
    
    <Table.Cell>
      <span title={(props.type == 1) ? props.list.email : 
        (props.type == 3) ? props.list['Phone_Number'] ? props.list['Phone_Number'] : props.list['Phone Number'] : (props.list['First_Name'] ? props.list['First_Name']: props.list['First Name'])}
      >{(props.type == 1 || props.type == 4 || props.type == 5 || props.type == 7 || props.type == '' ) ? props.list.Email ? props.list.Email : props.list.email : (props.type == 3) ? props.list['Phone_Number'] ? props.list['Phone_Number'] : props.list['Phone Number'] : (props.list['First_Name'] ? props.list['First_Name']: props.list['First Name'])}</span>
    </Table.Cell>
    {(props.type == 2 || props.type == 6) && <Table.Cell><span title={props.list['Last_Name'] ? props.list['Last_Name'] : props.list['Last Name']}>{props.list['Last_Name'] ? props.list['Last_Name'] : props.list['Last Name']}</span></Table.Cell>}
    {(props.type == 1 || props.type == 4 || props.type == 5 || props.type == 7 || props.type == '' ) && <Table.Cell style={{textAlign:"center"}}>
    {(props.list.toolTipText)?<ReactTooltip className="custom--tooltip" 
            place={"top"}
            type={"dark"}
            effect={"float"}
           multiline={true}
             />:""}
      {(props.list.subscribedText == 'Unsubscribed' )?
      <span style={{backgroundColor:"#ef6767"}} data-tip={props.list.toolTipText} class="kt-badge kt-badge--info kt-badge--inline kt-badge--pill" onClick={() => props.seeSubscribeStatus(rowcid)}>
        {props.list.subscribedText}
      </span>
        : <span class="kt-badge kt-badge--info kt-badge--inline kt-badge--pill" onClick={() => props.seeSubscribeStatus(rowcid)} >
        {props.list.subscribedText}
    </span>
    
    }
    </Table.Cell>}
    {props.type!==3 && <Table.Cell></Table.Cell>}
    <Table.Cell>{props.list.source}</Table.Cell>
    
  </Table.Row>)
  
  };

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
