import axios from "../config/axios";

// const sensitiveData = {
//   sensitiveDataList: (params) =>
//   axios
//       .get(`/settings/Sensitivedataactivitylog/getchatlist?` + params, {})
//       .then((resp) => resp.data)
//       .catch((err) => console.log(err)),
// };

const sensitiveData = {
  sensitiveDataList: (params) =>
  axios
      .get(`/settings/Sensitivedataactivitylog/getUserReport?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

      getLists: (params) =>
      axios
        .get(`/settings/UserActivityLog/list?` + params, {})
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),

};





export default sensitiveData;
