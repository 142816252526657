import React, { useState } from 'react'
import api from '../../../api';
import Button from '../../UI/button/Button'
import classes from './UrlShortner.module.css';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

const UrlShortner = (props) => {

    const [inputUrl, setinputUrl] = useState('');
    const [outputUrl, setoutputUrl] = useState('');
    const [buttonDisable, setbuttonDisable] = useState(false);
    const [resMessage, setresMessage] = useState(null);
    const [urlshortnerloading, seturlshortnerloading] = useState(false);
    const [copyDisabled, setcopyDisabled] = useState(true);
    const closeBox = () => {
        props.OnCloseShortner(true);
    }

    const generateShortUrl = () => {
        if (inputUrl) {
            seturlshortnerloading(true);
            setbuttonDisable(true);
            api.liveChat.generateShortUrl({ inputurl: inputUrl })
                .finally(() => {
                    setbuttonDisable(false);
                    seturlshortnerloading(false);
                })
                .then((data) => {
                    console.log(data);
                    if (data.status && data.message === 'success') {
                        setoutputUrl(data.data.shortenurl);
                        setresMessage([data.message, 'green']);
                        setcopyDisabled(false);
                        setTimeout(() => {
                            setresMessage(null);
                        }, 2000);
                    } else {
                        setresMessage([data.message, 'red']);
                        setTimeout(() => {
                            setresMessage(null);
                        }, 2000);
                    }
                }).catch((err) => {
                    console.log(err);
                    setresMessage([err, 'red']);
                    setTimeout(() => {
                        setresMessage(null);
                    }, 2000);
                })
        } else {
            setresMessage(['Please write URL in input field.', 'red']);
            setTimeout(() => {
                setresMessage(null);
            }, 3000);
        }

    }

    const handleInputShort = (e) => {
        setinputUrl(e.target.value);
    }

    const copyUrl = () => {
        if (outputUrl) {
            navigator.clipboard.writeText(outputUrl);
            setresMessage(["Copied", 'green']);
            setTimeout(() => {
                setresMessage(null);
            }, 2000);
        }

    }

    return (
        <Segment>
            <Dimmer active={urlshortnerloading}>
                <Loader indeterminate>Loading...</Loader>
            </Dimmer>
            <div className={classes.urlshortner__box}>
                <div className={classes.urlshortner__box__closebutton}>
                    <i className='fa fa-times' style={{ color: 'red' }} onClick={closeBox}></i>
                </div>
                <div className='form-group'>
                    <label>URL</label>
                    <div className={classes.urlshortner__box_input}>
                        <input type='text' className='form-contorl' name='inputurl' value={inputUrl} onChange={handleInputShort} />
                        <Button handleClick={generateShortUrl} disable={buttonDisable}>Generate</Button>
                    </div>
                    {resMessage && <div className='' style={{ color: resMessage[1], display: 'flex', alignSelf: 'center' }}>{resMessage[0]}</div>}

                </div>
                <div className='form-group'>
                    <label>Shorten URL</label>
                    <div className={classes.urlshortner__box_input}>
                        <input type='text' className='form-control' readOnly name='outputurl' value={outputUrl} />
                        <Button handleClick={copyUrl} disable={copyDisabled}>Copy</Button>
                    </div>
                </div>
            </div>
        </Segment>
    )
}

export default UrlShortner