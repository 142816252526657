import React from "react"; 

class PreviewWorkspace extends React.Component {
 

    constructor(props) {
        super(props);
        this.state = this.props.automationinfo;
        this.state.prevSidebar = false;
    }



    prevSidebar (status) {
        this.setState({
            prevSidebar: status,
        });
    }


	render() {  
        let hColorMultiArr = [];
        let hColorMulti = '';
        if(this.state.buttonHeaderColor.length > 1){
            this.state.buttonHeaderColor.map((x, y) => {
                hColorMultiArr.push(x);
            });
            hColorMulti = hColorMultiArr.join();
        }


        let bColorMultiArr = [];
        let bColorMulti = '';
        if(this.state.buttonColor.length > 1){
            this.state.buttonColor.map((x, y) => {
                bColorMultiArr.push(x);
            });
            bColorMulti = bColorMultiArr.join();
        }

		return (
			<div className="yr-chat-round-box width-35 yr-mt-20 box-bg yr-max-height">
                        <div className="box-title">Use this area to test!</div>
                        <div className="yr-preview-box">
                            <div className="yr-preview">
                            { this.state.prevSidebar && 
                                    <div className="yr-preview-menu">
                                        <span className="closebtn" onClick={() => this.prevSidebar(false)}>×</span>
                                        <a target="_blank"  href={this.state.privacyPolicy}>Privacy Policy</a>
                                        <p dangerouslySetInnerHTML={{__html:this.state.footerText}}  className="chat-footer-text"></p>
                                    </div>
                                }
                            <header
                             style={this.state.buttonHeaderColor.length == 1 ? { background: this.state.buttonHeaderColor[0]} : { backgroundImage: 'linear-gradient(45deg,'+ hColorMulti +')'}}
                            >
                                <div className="yr-logo brand-left">
                                <span className='small-img-logo'>
                                {this.state.companyLogo=='' ? (<button className="yr-bgcolor-btn"  aria-haspopup="true" style={{backgroundColor:this.state.primaryColor}}></button>) : (<img src={this.state.companyLogo} alt="" style={{maxWidth: 40,maxHeight: 35}} />) }    
                                </span>
                                <div className="brand-name">{this.state.company}</div>
                                
                                </div>
                                <div className="yr-button">
                                <span className="yr-nav" onClick={() => this.prevSidebar(true)}><i className="flaticon-more" /></span>
                                <span className="yr-close"><i className="flaticon2-delete" /></span>
                                </div>                                            
                                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '40%', width: '100%'}}><path d="M0.00,49.98 C149.99,150.00 347.35,-22.20 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: '#fff'}} /></svg>
                            </header>
                            <div className="yr-body">
                                <div className="yr-body-top">

                                {this.state.companyLogo=='' ? (<button className="yr-bgcolor-btn-mini"  aria-haspopup="true" style={{backgroundColor:this.state.primaryColor}}></button>) : (<span class="yr-chatlogo-mini"><img src={this.state.companyLogo} alt="" /></span>) }    
                                  <span className="welcome-text">{this.state.openMessage}</span>
                                </div>

                                <div className="yr-body-bottom">
                                { this.state.buttonList && this.state.buttonList.map((x, y) => {
                                    if(x.text!='') {
                                        return (
                                            <div class="yr-btnHover">
                                            <span key={y} className="btn yrbtn" 
                                            style={this.state.buttonColor.length == 1 ? { background: this.state.buttonColor[0], color: this.state.buttonTextColor} : { backgroundImage: 'linear-gradient(45deg,'+ bColorMulti +')', color: this.state.buttonTextColor}}
                                            dangerouslySetInnerHTML={{__html:x.text}} ></span>
                                            <div className='yreditbtn'>
                                            <a title="Edit" className="btn btn-icon gray"  onClick={() => this.props.editButton(x,y)}><i className="la la-pencil" style={{color:'rgb(202 194 194)'}}></i></a>
                                            { x.auto_id!='' && (<a title="Delete" className="btn btn-icon gray" onClick={() => this.props.deleteButton(x.text)}><i className="la la-trash" style={{color:'rgb(202 194 194)'}}></i></a>) }
                                            </div>
                                            </div>
                                        )
                                    }
                                })}
                                </div>
                                <a className="add-gradient form-group" onClick={() => this.props.addButton()} style={{marginLeft: 35}}>+Click to add additional buttons</a>
                                <div className="yr-footer">
                                  <p dangerouslySetInnerHTML={{__html:this.state.footerText}}  className="text-center mb-0"></p>
                                </div>
                            </div>
                            </div>
                            <div className="yr-chat-logo-row">
                                <label 
                                    className="image-checkbox"  
                                    style={this.state.customizeLogo == '' ? { backgroundImage: 'linear-gradient(45deg,'+ this.state.primaryColor +','+ this.state.secondaryColor +')'} : { background: 'transparent !important', border: 0, height:'auto'}}
                                >
                                <img className="img-responsive" id='preViewIcon'  src= {this.state.customizeLogo == '' ? "assets/media/logos/"+ this.state.selectedTheme : this.state.customizeLogo} alt="" style={this.state.customizeLogo == '' ?{maxWidth: 52, maxHeight: 52} : {maxWidth: 120, maxHeight: 120, width: 52, height: 52 }} />
                            </label>
                            </div>
                        </div>
                    </div>
		);
	};
}

export default PreviewWorkspace;
