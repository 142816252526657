import React from "react";
import { Form, Dropdown, TextArea } from "formsy-semantic-ui-react";
import { Message } from "semantic-ui-react";
import validationRule from "../../../../validation";
import "../../../Modal/dialog.css";
import "./Popup.css";
import "semantic-ui-css/semantic.min.css";
import { addValidationRule } from "formsy-react";
import axios from "../../../../config/axios";
import api from "../../../../api";
import utilFunc from "../../../../util_funs";
import DialogBoxLocationSettings from "./DialogBoxLocationSettings";

validationRule.isRequired();
validationRule.usPhoneLength();
validationRule.newPinAndOldPinNotSame();

class DialogBoxCallNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.obj.modalstatusCallNoti,
      title: props.modaltitle,
      modalFormLoader: props.modalFormLoader,
      modalstatusCallNotiData: props.modalstatusCallNotiData,
      message1: "none",
      color1: "green",
      msg1: "Setting has been saved successfully.",
      message2: "none",
      message3: "none",
      memberLogo: "",
      imgError: "none",
      loading: "none",
      isd_code: "",
      jumpinMsg: "",
      switchValue1: "",
      switchValue2: "",
      pin: "",
      cpin: "",
      pinMessage: "Pin Setting has been saved successfully.",
      pinMessageState: "none",
      locationSettingModalShow: false,
      locationSettingModalTitle: "",
      id: "",
      sessionUser: {},
      locationDataResult: [],
      locationDataGmap: [],
      locationState: [],
      userState: [],
      recieve_all_chat_if_not_in_current_location: "0",
      recieve_all_chat_if_not_in_current_locationChecked: true,
    };

    let modalstatusCallNotiData = this.props.modalstatusCallNotiData;
  }

  // componentDidMount() {
  //   let phone1 = this.props.obj.CallNotiData.phone;
  //   let p = this.phoneFormat(phone1);
  //   this.setState({
  //     EditModePhone: p,
  //   });
  // }

  notiAndCallClose = () => {
    this.setState({
      memberLogo: "",
      imgError: "none",
      message1: "none",
      color1: "green",
      msg1: "Setting has been saved successfully.",
      message2: "none",
      message3: "none",
      loading: "none",
      isd_code: "",
      jumpinMsg: "",
      switchValue1: "",
      switchValue2: "",
    });
    this.props.notiAndCallClose(false, "manageCallNotification");
  };
  random = () => {
    return Math.random();
  };

  renderDropDownDefault = (defaultCountryCodeArr, countryId = null) => {
    return defaultCountryCodeArr.map((option, index) => {
      return (
        <option
          key={index}
          value={option.country_code}
          selected={countryId == option.country_code ? "selected" : ""}
        >
          {option.country_name}
        </option>
      );
    });
  };

  renderDropDownRemaining = (remailCountryCodeArr, countryId = null) => {
    return remailCountryCodeArr.map((option, index) => {
      return (
        <option
          key={index}
          value={option.country_code}
          selected={countryId == option.country_code ? "selected" : "qqq"}
        >
          {option.country_name}
        </option>
      );
    });
  };

  handleChangeISD = (e) => {
    this.setState({
      isd_code: e.target.value,
    });
    // setIsd(e.target.value)
  };

  phoneFormat = (p) => {
    p = p.replace(/[^\d]*/gi, "");
    if (p.length <= 3) {
      return p;
    } else if (p.length > 3 && p.length < 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;
      return pp;
    } else if (p.length >= 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      let l40 = pp.length;
      let p40 = pp.substring(0, 8);
      p40 = p40 + "-";

      let p41 = pp.substring(8, l40);
      let ppp = p40 + p41;
      let maxphonelength = 13;
      let finalPhone = ppp.substring(0, maxphonelength);
      return finalPhone;
    }
  };
  handlePhoneChange = (e) => {
    let phone1 = e.target.value;
    let p = this.phoneFormat(phone1);
    setTimeout(function () {
      document.getElementById("phoneNumberCallNoti").value = p;
    }, 100);
  };

  onValidSubmitFirstColIndiviNoti = (e) => {
    this.setState({
      loading: "block",
    });

    var myform = document.getElementById("manageCallNotification");
    var formData = new FormData(myform);
    formData.append("user_id", this.props.sessionUser.user_id);
    formData.append("chatBot_id", this.props.sessionUser.chatBot_id);
    //console.log(formData);

    api.manageUser
      .CallNotification(formData)
      .then((resp) => {
        document.getElementById("PhonecallModalPopup").scroll({
          top: 0,
          behavior: "smooth", // 👈
        });
        if (resp.data.status == true) {
          this.setState({
            loading: "none",
            message1: "block",
            color1: "green",
            msg1: resp.data.message,
          });
          this.props.UpdateCallNotification(
            resp.data.data.callNotiData,
            this.state
          );
          var ref = this;
          setTimeout(function () {
            ref.setState({
              message1: "none",
            });
          }, 5000);
          this.notiAndCallClose();
        } else if (resp.data.status == false) {
          this.setState({
            loading: "none",
            message1: "block",
            color1: "red",
            msg1: resp.data.message,
          });
          var ref = this;
          setTimeout(function () {
            ref.setState({
              message1: "none",
            });
          }, 5000);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    const errorLabel = <div className="red" />;
    return (
      <React.Fragment>
        {
          <div
            style={{ display: this.state.loading }}
            class="ui loading form mh-loading-custom"
          ></div>
        }
        <div
          id="PhonecallModalPopup"
          className={
            this.props.obj.modalstatusCallNoti
              ? "modal fade show"
              : "modal fade"
          }
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdrop"
          aria-hidden="true"
          style={{
            display: this.props.obj.modalstatusCallNoti ? "block" : "none",
          }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close linear-gradient"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.notiAndCallClose()}
              >
                X
              </button>
              {this.state.modalFormLoader && (
                <div className="ui loading form"></div>
              )}
              <div className="modal-body">
                <div className="row" style={{ width: 100 + "%" }}>
                  <div className="col-lg-12">
                    <div>
                      <Form
                        noValidate
                        autoComplete="off"
                        className="manageCallNotification"
                        id="manageCallNotification"
                        onValidSubmit={this.onValidSubmitFirstColIndiviNoti}
                      >
                        <div className="yr-popBox-1">
                          <div className="box-title mb-1">
                            Call Notifications:
                          </div>
                          <Message
                            color={this.state.color1}
                            style={{
                              display: this.state.message1,
                            }}
                          >
                            {this.state.msg1}
                          </Message>

                          <div
                            className="form-group"
                            style={{ marginBottom: 10 }}
                          >
                            <label>Phone Number</label>
                            <div className="d-flex">
                              <select
                                class="form-control"
                                name="isd_code"
                                id="isd_code"
                                onChange={this.handleChangeISD}
                                id="isd_code_id"
                                style={{ maxWidth: 166, marginRight: "1rem" }}
                              >
                                {this.renderDropDownDefault(
                                  this.props.obj.ISDrenderOption1,
                                  this.props.obj.CallNotiData.isd
                                )}
                                <optgroup label="Other countries">
                                  {this.renderDropDownRemaining(
                                    this.props.obj.ISDrenderOption2,
                                    this.props.obj.CallNotiData.isd
                                  )}
                                </optgroup>
                              </select>

                              <Form.Input
                                fluid
                                autoComplete="off"
                                name="phone"
                                maxLength="13"
                                onChange={this.handlePhoneChange}
                                placeholder="Phone Number"
                                validations="isRequired,usPhoneLength"
                                validationErrors={{
                                  isRequired: "This field is required.",
                                  usPhoneLength:
                                    "Phone number must be at least 10 numbers.",
                                }}
                                value={
                                  this.props.obj.CallNotiData
                                    .output_phone_number
                                }
                                id="phoneNumberCallNoti"
                                className="w-100"
                                errorLabel={errorLabel}
                              />
                            </div>
                            <input
                              type="hidden"
                              id="tw_id"
                              name="tw_id"
                              value={this.props.obj.CallNotiData.tw_id}
                            />
                          </div>

                          <div className="row">
                            <div
                              className="col-lg-12 text-center"
                              style={{ marginTop: 33 }}
                            >
                              <button
                                type="submit"
                                class="btn linear-gradient yr-submitbtn"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DialogBoxCallNotifications;
