import React from "react";
import { connect } from "react-redux";
import { Table } from 'semantic-ui-react';
// import DesignRules from './DesignRules';

import { Message } from "semantic-ui-react";
import {
    setPageTitle,
    toggleNavigation,
    setMessage,
    setBreadCrum,
} from "../../../store/Page";
const queryString = require("query-string");

class AdditionalCaptured extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
       
        const captured=this.props.additional_captured;
        return (
            <Table id="additional_captured">

                <Table.Body>
                   
                    {Object.keys(captured).map(function(key,index){
                        let captured2=key;
                        captured2=captured2.replace(/_/g, " ");
                        return (
                    <Table.Row>
                        <Table.Cell style={{textTransform:"capitalize"}}>{captured2}</Table.Cell>
                        <Table.Cell>{captured[key]}</Table.Cell>
                    </Table.Row>
                    )
                        }) 
                    }
                        
                   
                </Table.Body>

            </Table>
        );
    }
}
export default AdditionalCaptured