import React from 'react';
import CustomErrorField from "../../../../Form/CustomErrorField";
import api from "../../../../../api";
import { Message } from "semantic-ui-react";
import Modal from '../../../ChatSetup/components/Modal';
class TestView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            display: false,
            chat_data: [],
            input_text: '',
            last_branch_id: '',
            watson_arr: [],
            chat_input_data: [],
            choice_info: [],
            input_disable: false,
            modalstatus: false,

            modaltitle: "",
            modalbody: "",
            intent_data: [],
            validation: '',
            checkzipcaptured: false,
            candidateName: '',
            capturedas: '',
            questionAnswer: [],
            showfooter:false,
            showreportbutton:false,
            isgpt:false,
            transcript:[],
            showmodal:false,
            notemodalbody:'',
            reportcommnet:'',
            reportstatus:'',
            reporterror:false,
            bodywidth:'',
            chaterr:''

        };

    }

    componentDidMount() {

        this.onLoad();
    }

    // componentDidUpdate(prevProps) {

    //     if(this.props.indextime!=prevProps.indextime){

    //        this.onLoad();
    //     }

    //   }

    onLoad = () => {
        this.setState({transcript:[]});
        api.botbuilder.getTestingView({ 'workspace_id': this.props.workspace_id,bot_type:this.props.bot_type }).then(data => {
            try {
                // console.log('data.', data);
                let arr = this.state.chat_data
                let qalist = this.state.questionAnswer;
                 let transcript=[]
                data.details.branch_questions.forEach((datad, i) => {
                    let qtd = datad.question;
                    qtd = qtd.replace("COMPANY_NAME", data.details.userinfo.company);
                    qtd = qtd.replace("PHONE_NUMBER", data.details.userinfo.phone);
                    if (i == 0) {
                        qalist.push({ answer: qtd });
                        transcript.push({from:"u",msgVal:qtd})
                    } else {
                        const qlastEle = qalist.slice(-1);
                        if (qlastEle && qlastEle.keys()) {
                            qlastEle.answer = `${qlastEle.answer} ${qtd}`;
                        }
                        qalist[qalist.length - 1] = { answer: qlastEle };
                       // transcript.push({from:"u",msgVal:qlastEle})
                    }
                })
                arr.push(data.details)
                let disableinput = false
                let show_footer=true;
                if(data.details?.branch_buttons?.length>0){
                    disableinput = true;
                    show_footer=false;
                }
                if (data.details.multiple_choice_options.confirmation_button != '') {
                    disableinput = true;
                    show_footer=false;
                }

                this.setState({
                    chat_data: arr, input_text: '', last_branch_id: data.details.branch_id,
                    watson_data: [], choice_info: [],
                    input_disable: disableinput,
                    showfooter:show_footer,
                    intent_data: [],
                    questionAnswer: qalist,
                    capturedas: data.details.details.capture_as
                  //  transcript
                })

            } catch (err) {
                console.log(err);
            }
        });
    }

    inputChange = (data) => {

        if (this.state.validation == 'phonenumber') {

            this.setState({ input_text: this.phoneFormat(data.target.value) });
        } else {
            this.setState({ input_text: data.target.value });
        }

    }

    chatBotFlow = (e) => {
        this.setState({ loading: true })
        e.preventDefault();
        let parameter = {
            workspace_id: this.props.workspace_id, branch_id: this.state.last_branch_id, msg: this.state.input_text, branch_type: "text", checkzipcaptured: this.state.checkzipcaptured, capturedas: this.state.capturedas, candidateName: this.state.candidateName
        }
        if (this.state.input_text != '') {
            this.loadChatData(parameter);
            // api.botbuilder.getWatsonData(parameter).then(data => {
            //     try {
            //        let arr=this.state.watson_arr
            //         arr.push(data.data)
            //         this.setState({watson_arr:arr,input_text:'',last_branch_id:data.data.branch_id,loading:false},function(){

            //             var scrollingElement = document.getElementById('popuptestview');
            //             scrollingElement.scrollTop = scrollingElement.scrollHeight;


            //         })

            //     } catch (err) {
            //         console.log(err);
            //     }
            //    });
        }
    }

    loadChatData = (parameter) => {
        const qss = [...this.state.questionAnswer];
        qss[qss.length - 1].question = parameter.msg;
        parameter.questionAnswer = JSON.stringify(qss);
        this.setState({ questionAnswer: qss })
        api.botbuilder.getWatsonData(parameter).then(data => {
            try {
                let arr = this.state.watson_arr
                let qalist = this.state.questionAnswer;
                arr.push(data.data)
                // update question answer
                const genericRes = data.data.watson_data.generic;
                
                // console.log('gene', genericRes);
                let trans=this.state.transcript;
                if (genericRes[0].response_type == 'text') {
                    let str = genericRes[0].text;
                    if (str) {
                        qalist.push({answer: str});
                       
                        trans.push({From:'u',msgVal:parameter.msg})
                        trans.push({From:'a',msgVal:str})
                    }
                }

                var capturedas = data.data.watson_data.capturedas;
                if (data.data.name == '' && this.state.candidateName != '') {
                    var name = this.state.candidateName
                } else {
                    var name = data.data.name
                }

                // var candidate_name=''

                //      if(obj.name!=''){

                //         candidate_name= obj.name;
                //      }
                  let showreportbutton=false;
                  let gpt_status=typeof data.data.watson_data?.chatgptstatus?.status!='undefined' ? data.data.watson_data.chatgptstatus.status : false;
                     if(gpt_status){
                        this.setState({isgpt:gpt_status})
                     }
                   let bwidth='';  
                 if(parameter.msg!="" && parameter.branch_type=='text' && this.state.isgpt){
                    showreportbutton=true;
                    bwidth='calc(100% - 110px)';
                 }
                this.setState({
                    watson_arr: arr, input_text: '', last_branch_id: data.data.branch_id, loading: false, validation: data.data.validation, checkzipcaptured: data.data.watson_data.checkzipcaptured, candidateName: name,
                    capturedas: capturedas,
                    questionAnswer: qalist,
                    showreportbutton:showreportbutton,
                    transcript:trans,
                    bodywidth:bwidth

                }, function () {
                  
                    // let d=''
                    if (data.data.watson_data.branch_detail?.branch_buttons?.length > 0) {
                        this.setState({ input_disable: true,showfooter:false })
                    } 
                    else if (data.data.multiple_choice_options.confirmation_button != '') {
                        this.setState({ input_disable: true,showfooter:false })
                    } else {
                        this.setState({ input_disable: false,showfooter:true })
                    }
                     

                     
                    // this.setState({chat_input_data:d})
                    var scrollingElement = document.getElementById('popuptestview');
                    scrollingElement.scrollTop = scrollingElement.scrollHeight;


                })

            } catch (err) {
                console.log(err);
            }
        });
    }

    chatSendOnClick = (msg, branch_id) => {
        if(msg.trim()=='IS_REQUIRED_TEXT'){
            msg="";
            this.setState({chaterr:'Please select this option'});
            setTimeout(()=>{
                this.setState({chaterr:''});
            },3000)
            return false;
        }

        let parameter = {
            workspace_id: this.props.workspace_id, branch_id: branch_id, msg: msg, branch_type: "button"
        }
        this.loadChatData(parameter);
    }


    showPhone = (phone, class_name) => {
        document.querySelector('.' + class_name).innerHTML = phone
        window.open("tel:" + phone, "_self");
    }

    showSMS = (phone, class_name) => {

        document.querySelector('.' + class_name).innerHTML = phone

        window.open("sms:" + phone, "_self");
    }

    onChangeCheck = (e, index) => {

        let st = this.state.choice_info
        if (e.target.checked) {
            st[index] = e.target.value
            //st.push({[index]:e.target.value})

        } else {
            if (typeof st[index] != 'undefined') {
                delete st[index]
            }
        }
        this.setState({ choice_info: st });
    }
    chatSendOnconfirmation = (branch_id) => {
        console.log("choice",this.state.chat_data[0].multiple_choice_options,this.state.chat_data)
        let choices=this.state.chat_data[0].multiple_choice_options;
        let required=this.state.chat_data[0].multiple_choice_options.is_required
        let is_req=0;
        if(Object.keys(this.state.chat_data[0].multiple_choice_options.option).length==1 && required==1){
            is_req=1;
        }
        new Promise(resolve => {
            let data = [];
            Object.keys(this.state.choice_info).forEach((obj, index) => {

                data.push(this.state.choice_info[obj])
            })
            // console.log(data)
            resolve(data);
        }).then((data) => {
            let str = ''
            if (data.length > 0) {
                str = data.join();
            } else {
               if(is_req==1){
                 str='IS_REQUIRED_TEXT'
                 
               }else{
                str = "No option Selected";
            }

            }

            this.chatSendOnClick(str, branch_id);
        })


    }

    jumpBranch = (branch_id,trainAi) => {
        console.log(trainAi)
        let branchType;
        if(trainAi=='1'){
            branchType='confused'
        }else{
            branchType=null
        }
        this.props.jumpToBranch(branch_id,branchType)
    }



    addSuggestedIntent = (index) => {
        let intent_data = this.state.intent_data
        let intent_id = '';
        let example = '';
        if (typeof intent_data[index] != 'undefined') {
            intent_id = intent_data[index].intent_id
            example = intent_data[index].example
        }


        let error = false;
        let errormsg = '';
        let parameter = {
            workspace_id: this.props.workspace_id, intent_id: intent_id, example: example
        }


        if (intent_id == '') {
            error = true;
            errormsg = 'Pleae select Intent';

        }

        else if (example.trim() == '') {

            error = true;
            errormsg = 'No example is found';

        }
        if (error) {
            this.setState({
                modalstatus: true,
                modaltitle: "",
                modalbody: errormsg,
            })
        }

        if (!error) {
            api.botbuilder.addSuggestedInetentExample(parameter).then(data => {
                try {
                    let msg_success = ''
                    if (data.status) {
                        msg_success = 'Intent Correction submitted successfully';

                    } else {
                        msg_success = 'Error Unable to change the intent. Error: Unique Violation: The value "' + example + '" already exists.';

                    }
                    this.setState({
                        modalstatus: true,
                        modaltitle: "",
                        modalbody: msg_success,
                    })

                } catch (err) {
                    console.log(err);
                }
            });
        }
    }


    modalClose = () => {
        this.setState({
            modalstatus: false,
            modaltitle: "",
            modalbody: "",
            modalindex: "",

        });
    }


    modalFooter = () => {
        return (
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => this.modalClose()}
                    className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                    data-dismiss="modal"
                >
                    <span>OK</span>
                </button>

            </div>
        );

    };

   
    selectIntentChange = (e, index) => {
        let watson_intent = this.state.watson_arr

        let intent_data = this.state.intent_data;
        intent_data[index] = { "intent_id": e.target.value, 'example': watson_intent[index].msg }
        this.setState({ intent_data: intent_data })
        // console.log(this.state.intent_data)
    }

    showLink = (url,b,tabType) => {
        const tab=tabType.button_respond_on_url_tab
        console.log(tabType)
        var prefix = 'https://';
        if (url.substr(0, prefix.length) !== prefix) {
            url = prefix + url;
        }
        if(tab=='sametab'){
            window.open(url, '_top')
        }else{
        window.open(url, '_blank')
    }
    }

    phoneFormat = (p) => {
        p = p.replace(/[^\d]*/gi, "");
        if (p.length <= 3) {
            return p;
        } else if (p.length > 3 && p.length < 7) {
            p = "(" + p;
            let l30 = p.length;
            let p30 = p.substring(0, 4);
            p30 = p30 + ")";

            let p31 = p.substring(4, l30);
            let pp = p30 + p31;
            return pp;
        } else if (p.length >= 7) {
            p = "(" + p;
            let l30 = p.length;
            let p30 = p.substring(0, 4);
            p30 = p30 + ")";

            let p31 = p.substring(4, l30);
            let pp = p30 + p31;

            let l40 = pp.length;
            let p40 = pp.substring(0, 8);
            p40 = p40 + "-";

            let p41 = pp.substring(8, l40);
            let ppp = p40 + p41;
            let maxphonelength = 13;
            let finalPhone = ppp.substring(0, maxphonelength);
            return finalPhone;
        }
    };


    loadReviewData = () => {

    }
    reportChat=(e)=>{
       this.props.reportChat(this.state.transcript,this.props.workspace_id,this.props.bot_type)
       //this.setState({transcript:[]});
    }


    render() {
        // console.log(this.state.watson_arr)
        const { show, width, height, errors, currenttime, listdata } = this.state
        return (
            <React.Fragment>

                <div className="yr-testChat" style={{ display: this.state.display,postion:'fixed',bottom:0,right:0,padding:0,height:'calc(100vh-25%)' }}>

                    <div className="yr-testHead">
                        <ul>
                            <li className="yr-title">Try It Out</li>
                            <li className="text-center"><a href="javascript:void(0)" className="yr-clear" onClick={() => this.props.loadTestView('load')}>Clear</a></li>
                            <li className="text-right"><a className="yr-close cursorponiter" onClick={() => this.props.loadTestView('close')}>&#10005;</a></li>
                        </ul>
                    </div>

                    <div className="yr-conTentArea" style={this.state.isgpt ? {height:this.state.bodywidth}: {}} id="popuptestview">

                        {this.state.chat_data.length > 0 && this.state.chat_data.map((obj_test, index) => {
                            return (
                                <div style={{ position: 'relative' }}>
                                    {
                                        obj_test.branch_questions.map((question, question_id) => {
                                        let qtd = question.question
                                        qtd = qtd.replace("COMPANY_NAME", obj_test.userinfo.company);
                                        qtd = qtd.replace("PHONE_NUMBER", obj_test.userinfo.phone);
                                        return (
                                            <div className={question_id == 0 ? "yr-TextArea mapiconRight" : 'yr-TextArea'}>

                                                <div className="yr-textSec" dangerouslySetInnerHTML={{ __html: qtd }}>
                                                </div>

                                                    <a onClick={() => this.jumpBranch(obj_test.branch_id,'null')} className="cursorpointer"><i class="la la-map-marker yr-faMapIcon" aria-hidden="true"></i></a>

                                            </div>
                                        )

                                    })}


                                    <ul style={{padding:"5px 25px 5px 15px",marginLeft:'10px'}}>
                                        {obj_test.branch_buttons.length > 0 && obj_test.branch_buttons.map((button, button_index) => {

                                            //  return (
                                            //      <li>
                                            //          <a>{button.button_title}</a>
                                            //      </li>
                                            //  )

                                            let btntype = button.button_respond_on;

                                            let msgval = button.button_title;
                                            let class_name = "link " + btntype + obj_test.branch_id + button_index

                                            return (
                                                <div>
                                                    {btntype == 'Branch' &&
                                                        <li style={{paddingTop:'7px'}} className={class_name + " cursorpointer"} onClick={() => this.chatSendOnClick(msgval, button.button_respond_on_branch)}>{msgval}</li>
                                                    }
                                                    {btntype == 'PhoneCall' &&
                                                        <li style={{paddingTop:'7px'}} className={class_name + " cursorpointer"} onClick={() => this.showPhone(button.button_respond_on_phonecall_country_code + button.button_respond_on_phonecall, class_name)}>{msgval}</li>
                                                    }
                                                    {btntype == 'URL' &&
                                                        <li style={{paddingTop:'7px'}} className="cursorpointer" onClick={() => this.showLink(button.button_respond_on_url, class_name,obj_test.branch_buttons[button_index])}>{msgval}</li>
                                                    }
                                                    {btntype == 'SMS' &&
                                                        <li className={class_name + " cursorpointer"} onClick={() => this.showSMS(button.button_respond_on_phonecall_country_code + button.button_respond_on_sms, class_name)}>{msgval}</li>

                                                    }
                                                    {btntype == 'REVIEW' && this.props.review_links.length > 0 &&
                                                        <li style={{paddingTop:'7px'}} className={class_name + " cursorpointer"} onClick={() => this.loadReviewData(button.button_respond_on_review, msgval)}>{msgval}</li>

                                                    }
                                                </div>
                                            )




                                        })}
                                    </ul>
                                    {obj_test.multiple_choice_options.confirmation_button != '' &&
                                        <div class="yr-checkboxnewdesign">

                                            {Object.keys(obj_test.multiple_choice_options.option).length > 0 && Object.keys(obj_test.multiple_choice_options.option).map((button, button_index) => {
                                                return (
                                                    <div class="yr-checkBtn">
                                                        <label class="kt-checkbox kt-checkbox--single chat-checkbox">
                                                            <input type="checkbox" value="Option2" name="test" onChange={(e) => this.onChangeCheck(e, button_index)} /> {obj_test.multiple_choice_options.option[button]} <span></span>
                                                        </label>
                                                    </div>
                                                )
                                            })}



                                            <div class="yr-confirmBtn">
                                                <button type="button" onClick={() => this.chatSendOnconfirmation(obj_test.multiple_choice_options.target_branch_id)} className="btn yr-round-btn">{obj_test.multiple_choice_options.confirmation_button}</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )

                        })}

                        {this.state.watson_arr.length > 0 && this.state.watson_arr.map((obj, index) => {
                            return (
                                <div style={{ position: 'relative' }}>

                                    <div class="yr-TextBox ">
                                        <div class="yr-texttitle "> {obj.msg}</div>
                                        <div class="eyeiconRight">
                                            <select onChange={(e) => this.selectIntentChange(e, index)}>
                                                <option value=''>Select Intent</option>
                                                {obj.lists.length > 0 && obj.lists.map((list, listindex) => {
                                                    return (
                                                        <option value={list.id} selected={list.id == obj.selected_inetent_id}>{list.name}</option>
                                                    )
                                                })

                                                }

                                            </select>
                                            <p>Question Detected = {obj.question_detected}</p>
                                            <a class="yr-faEyeIcon cursorpointer" onClick={() => this.addSuggestedIntent(index)}><i class="la la-eye" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                    {obj.watson_data.generic.length > 0 && obj.watson_data.generic.map((generic, genindec) => {
                                        let mapicon = false;
                                        if (generic.response_type == 'text') {
                                            let question_to_do = generic.text;
                                            question_to_do = question_to_do.replace("COMPANY_NAME", obj.userinfo.company);
                                            question_to_do = question_to_do.replace("PHONE_NUMBER", obj.userinfo.phone);
                                            let emailRegex = /[\w\.-]+@[\w\.-]+\.\w+/g;
                                            let  foundEmails = generic.text.match(emailRegex);
                                            if(foundEmails !== null){
                                                question_to_do=foundEmails;
                                                let text = generic.text;
                                                let finalText = text.replace(foundEmails,`<a href=mailto:${foundEmails}>${foundEmails}</a>`);
                                                question_to_do=finalText
                                            }
                                            return (
                                                <div className="yr-TextArea mapiconRight">
                                                    <div className="yr-textSec" dangerouslySetInnerHTML={{ __html: question_to_do }}></div>
                                                    {genindec == 0 &&
                                                        <a className="cursorpointer" onClick={() => this.jumpBranch(obj.branch_id,obj.watson_data.branch_detail.trainyourAI)}><i class="la la-map-marker yr-faMapIcon" aria-hidden="true"></i></a>
                                                    }
                                                </div>
                                            )
                                        } else if (generic.response_type == 'option') {
                                            let title = generic.title;

                                            let question_to_title = title;
                                            question_to_title = question_to_title.replace("COMPANY_NAME", obj.userinfo.company);
                                            question_to_title = question_to_title.replace("PHONE_NUMBER", obj.userinfo.phone);

                                            return (
                                                <div >
                                                    <div className="yr-TextArea mapiconRight">
                                                        <div className="yr-textSec" dangerouslySetInnerHTML={{ __html: question_to_title }}></div>
                                                        {genindec == 0 &&
                                                            <a className="cursorpointer" onclick="jumpToBracnh('.$branch_id.')"><i class="la la-map-marker yr-faMapIcon" aria-hidden="true"></i></a>
                                                        }

                                                    </div>
                                                    <ul>
                                                        {generic.options.length > 0 && generic.options.map((option, optinde) => {

                                                            let msg = option.label;
                                                            let btntype = option.type;
                                                            let btntvalue = option.btnvalue;
                                                            let msgval = option.value.input.text;
                                                            let class_name = "link" + btntype + obj.branch_id + optinde
                                                            let btnTab=obj.watson_data.branch_detail.branch_buttons[optinde]

                                                            return (
                                                                <div>
                                                                    {btntype == 'Branch' &&
                                                                        <li className={class_name + " cursorpointer"} onClick={() => this.chatSendOnClick(msgval, btntvalue)}>{msgval}</li>
                                                                    }
                                                                    {btntype == 'PhoneCall' &&
                                                                        <li className={class_name + " cursorpointer"} onClick={() => this.showPhone(btntvalue, class_name)}>{msgval}</li>
                                                                    }
                                                                    {btntype == 'URL' &&
                                                                        <li className="cursorpointer" onClick={() => this.showLink(btntvalue, class_name,option)}>{msgval}</li>
                                                                    }
                                                                    {btntype == 'SMS' &&
                                                                        <li className={class_name + " cursorpointer"} onClick={() => this.showSMS(btntvalue, class_name)}>{msgval}</li>

                                                                    }
                                                                    {btntype == 'REVIEW' && this.props.review_links.length > 0 &&
                                                                        <li className={class_name + " cursorpointer"} onClick={() => this.loadReviewData(btntvalue, msgval)}>{msgval}</li>

                                                                    }
                                                                </div>)
                                                        })}
                                                    </ul>
                                                </div>

                                            )


                                        }

                                    })}


                                    {obj.multiple_choice_options.confirmation_button != '' &&
                                        <div class="yr-checkboxnewdesign">

                                            {Object.keys(obj.multiple_choice_options.option).length > 0 && Object.keys(obj.multiple_choice_options.option).map((button, button_index) => {
                                                return (
                                                    <div class="yr-checkBtn">
                                                        <label class="kt-checkbox kt-checkbox--single chat-checkbox">
                                                            <input type="checkbox" value="Option2" name="test" value={obj.multiple_choice_options.option[button]} onChange={(e) => this.onChangeCheck(e, button_index)} /> {obj.multiple_choice_options.option[button]} <span></span>
                                                        </label>
                                                    </div>
                                                )
                                            })}



                                            <div class="yr-confirmBtn">
                                                <button type="button" onClick={() => this.chatSendOnconfirmation(obj.multiple_choice_options.target_branch_id)} className="btn yr-round-btn">{obj.multiple_choice_options.confirmation_button}</button>
                                            </div>
                                        </div>
                                    }



                                </div>



                            )
                        })}




                    </div>
                {this.state.chaterr!='' && <span className='text-danger'>{this.state.chaterr}</span>}
                    {(this.state.showreportbutton && this.state.isgpt) && <ul style={{background:'#efefef',paddingTop:'4px',paddingBottom:'2px'}}><li class="linkBranch1252960 cursorpointer" style={{color:'red','fontWeight':'bold',textDecoration:'underline' ,paddingTop: '3px',
    paddingBottom: '3px',marginLeft:'12px'}} onClick={(e)=>this.reportChat(e)}>Report This Chat for Training</li></ul>}
                    <div className="yr-textFooter" style={ this.state.showfooter==true ? {background:'#f4f4ff',marginTop:(this.state.showreportbutton && this.state.isgpt)? '-13px':'0px'} : {height:'100%',background:'#f4f4ff'}}>
                        {this.state.loading && <div className="ui loading form"></div>}
                        <form onSubmit={(e) => this.chatBotFlow(e)} >
                           {this.state.showfooter==true && 
                            <input type="text" disabled={this.state.input_disable == true ? true : false} value={this.state.input_text} placeholder="Enter Something to test your workflow" onChange={(e) => this.inputChange(e)} autocomplete="off" id="testflow" /> }
                        </form>
                    </div>
                </div>
                <Modal
                    show={this.state.modalstatus}
                    footer={this.modalFooter()}
                    onclose={this.modalClose}
                    title='ALert'
                    body={this.state.modalbody}
                />



            </React.Fragment>
        )
    }
}


export default TestView;