import React from 'react';
import { ReactSortable } from "react-sortablejs";
import api from "../../../../../api"; 
import Modal from "../../../ChatSetup/components/Modal";
class BranchList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list:  ['GEN_WEB','GEB_VOICE'].includes(this.props.bot_type) ? this.props.branchList : this.props.branchList.slice(1), 
            contextBranchesList:[],
            dropbranch:'',
            selectedlocation:'',
            loadingbranch: false,
            dialogShow:false,
            dialogLoading:false,
            dialogMessage:'',
            dialogId:'',
            finalWorkspaceSaveModalFlag: false,
            editBranchNameInput:[],
            contextBranchValue:[],
            editBranchNameShow:false,
            inputValueBranch:'',
            inputValueBranchId:'',
            branchchangenameError:'',
            branchchangenameErr:'',
            finalList:[],
            backupbot_types:['GEN_WEB','GEN_VOICE'],
            disableBtn:false
        }; 
      }

      componentDidMount(){
           this.assignList();
      }

      componentDidUpdate(prevProps){
        //  console.log(prevProps)
        //  console.log('-----------------------------------')
        //  console.log(prevProps)
          
        if (this.props.indextime != prevProps.indextime) {
            this.assignList();
        }
    }
    changeBranchNameFnc = ( event ) => {
        
        if(this.state.inputValueBranch.trim().length===0){
            let errr="This field is required."
            this.setState({branchchangenameErr:errr});
            setTimeout(() => {
                this.setState({
                    branchchangenameErr:''
                });
            },2000);
        }else{
            let valll=this.state.inputValueBranch.trim();
            const payload = {
                "branch_id" : this.state.inputValueBranchId,
                "name"      : valll
            }
            this.setState({disableBtn:true});
            api.botbuilder.changeContextBranchName(payload).then( res =>{
                let error = '';
                if(res.status === true){
                    error = <div class="alert alert-success" role="alert">{res.message}</div>;
                }else{
                    error = <span class="alert alert-danger">{res.message}</span>;
                }
                this.setState({branchchangenameError:error});
                this.props.refeshData();
                setTimeout(() => {
                    this.setState({
                        editBranchNameShow:false,
                        inputValueBranch:'',
                        inputValueBranchId:'',
                        branchchangenameError:'',
                        disableBtn:false
                    });
                },2000)
            });
        }
        
    }
    assignList=()=>{
        let result=this.props.branchList;
        if(!this.state.backupbot_types.includes(this.props.bot_type)){
             result=this.props.branchList.slice(1).filter((item) => {
                if(item.trainyourAI==null){
                    return (item)
                }
            })
        }
        
       
            let contextBranch=this.props.branchList.slice(1).filter((item)=>{
                if(item.trainyourAI=='1'){
                    return (item)
                }
            })
             
        this.setState({ 
            list:result,
            contextBranchesList:contextBranch
        })
    }
    onchangeDropbranch = (e) =>{
       this.setState({ [e.target.name]: e.target.name });
       if(e.target.value!=''){
            this.orderBranchList(e.target.value,(callback)=>{
                var elems = document.querySelectorAll('.borderset');
                for (var index = 0 ; index < elems.length; index++) {
                    elems[index].style.border = "none"; 
                }
                document.querySelector('.direction').disabled = true;
                document.querySelector('.branchcount').disabled = true;
                this.setState({ [e.target.name]: '' });

                api.botbuilder.updateposition({'btList':this.state.list}).then(data => {
                    //console.log(data);
                })

            });
        }
    }
    
    finalWorkspaceSaveModal = () => {
        this.setState({
            finalWorkspaceSaveModalFlag : false
        })
    }
    onSelectBranch = (e,key,branch_id) =>{
        // console.log(this.props.contextChangedFlag)
        if(this.props.contextChangedFlag){
            this.setState({
                finalWorkspaceSaveModalFlag : true
            });
            return;
        }
        document.querySelector('.contextbranch').style.border = "";
        var elems = document.querySelectorAll('.borderset');
        for (var index = 0 ; index < elems.length; index++) {
            elems[index].style.border = "none"; 
            if(index+1==elems.length){
             elems[key].style.border = '1px solid rgb(15, 98, 254)';
             this.setState({ selectedlocation: key });
            }
        }
        this.props.editBranch(branch_id)
        var elems2 = document.querySelector('.branchcount').options;
        for (var index2 = 0 ; index2 < elems2.length; index2++) {
            elems2[index2].disabled = false; 
            elems2[index2].style.backgroundColor = "#fff"; 
            if(index2+1==elems2.length){ 
                elems2[key+1].disabled = true;
                elems2[key+1].style.backgroundColor  = '#cccccc';
                document.querySelector('.direction').disabled = false;
                document.querySelector('.branchcount').disabled = false;
            }
        }
          
    }

    orderBranchList = (movelocation,callback) =>{
        var order = document.querySelector('.direction').value;
        var selectedlocation = this.state.selectedlocation; 
        if(order=='up' && selectedlocation==movelocation-1){ 
            callback(0);
        }else if(order=='down' && selectedlocation==movelocation+1){ 
            callback(0);
        } else {   
            let tempVal = this.state.list[selectedlocation];
            this.state.list.splice(selectedlocation, 1);
            if(order=='up'){ 
                if(movelocation>selectedlocation)
                this.state.list.splice(parseInt(movelocation)-1, 0, tempVal);
                else
                this.state.list.splice(movelocation, 0, tempVal);
            }
            if(order=='down'){ 
                if(movelocation>selectedlocation){  
                 this.state.list.splice(movelocation, 0, tempVal);
                }else{    
                 this.state.list.splice(parseInt(movelocation)+1, 0, tempVal);
                }
            }
            callback(1);
        }
    }
    onEndOrder = () =>{ 
        api.botbuilder.updateposition({'btList':this.state.list}).then(data => {
            this.props.updateBranchList()
            //console.log(data);
        }) 
    }  
    copyBranch = (e,id) =>{
        e.stopPropagation()
        this.setState({ loadingbranch: true });
            api.botbuilder.copybranch({'bid':id}).then(data => {
                this.props.updateList(data.data);
                this.setState({ loadingbranch: false});
                this.assignList()
             })
    }  
    actionBranchDelete = (e,action,id='') =>{
        e.stopPropagation()
        if(action=='Open'){
            this.setState({ dialogShow: true, dialogLoading:false});
            api.botbuilder.targetedbranchdetail({'bid':id}).then(data => { 
                try{
                    if(data.status){
                        this.setState({  
                            dialogLoading:false,
                            dialogMessage:data.data,
                            dialogId:id
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            })
        }
        if(action=='Close'){
            this.setState({ 
                dialogShow:false,
                dialogLoading:false,
                dialogMessage:'',
                dialogId:''
            });
        }
        if(action=='Delete'){
            this.setState({ dialogLoading:true});
            this.setState({ dialogShow:false})
            api.botbuilder.deletebranch({'bid':this.state.dialogId}).then(data => { 
                try{
                    if(data.status){
                        this.props.updateList(data.data,this.state.dialogId);
                        this.setState({  
                            dialogShow:false,
                            dialogLoading:false,
                            dialogMessage:'',
                            dialogId:'',
                        });
                        this.assignList()
                    }
                } catch (err) {
                    console.log(err);
                }
            })
        }
    }

    editBranchName = (event,Title,index,branchId) => {
        console.log('rename',event.detail,Title,index,branchId);
        if(event.detail == 2){
        this.setState({
            inputValueBranch:Title,
            editBranchNameShow:true,
            inputValueBranchId:branchId
        });
		}
        if(event.detail == 1){
            if(this.props.bot_type=='GEN_WEB' || this.props.bot_type=='GEN_VOICE'){
                this.props.editBranch(branchId, '')
            }else{
                this.props.editBranch(branchId, 'confused')
            }
           
        }
    }

    closeEditBranchName = () => {
        this.setState({
            editBranchNameShow:false,
        })
    }

    setNameContextBranch = (e) => {
        this.setState({
            inputValueBranch:e.target.value
        })
    }
    copyContextBranch = (e,id) =>{
        e.stopPropagation()
        this.setState({ loadingbranch: true });
            api.botbuilder.copybranch({'bid':id}).then(data => {
                // console.log(data)
                this.props.updateList(data.data);
                this.setState({ loadingbranch: false});
                this.assignList()
                // console.log(data);
             })
    }
    handlechangebranchname=(e)=>{
            this.setState({inputValueBranch:e.target.value});
    }
    branchnameModalBody = () =>{
        return (
            <div>
                {this.state.branchchangenameError}
              
                <div className='row'>
                    <div className='col-md-12'>
                         <div className='form-group'>
                            <label>Title*</label>
                            <input type="text" key={this.state.inputValueBranchId} value={this.state.inputValueBranch} onChange={(e)=>this.handlechangebranchname(e)} className='form-control' />
                            {this.state.branchchangenameErr && <span className='text-danger'>{this.state.branchchangenameErr}</span>}
                         </div>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        let tyai =  this.props.branchList[0].trainyourAI ? 'confused' : null;
        return (
            
            <div className="yr-chat-round-box box-bg" style={{maxHeight:'865px',overflow:'auto',paddingTop: "0px"}}>
                 {this.state.loadingbranch && <div className="ui loading form"></div>}
                 {!this.state.loadingbranch && ( <div>
                <div className="row yr-sticky-header">
                    <div className="col-md-2">
                        <button type="button" title="Add Branch" className="btn btn-sm btn-clean btn-icon" onClick={()=>this.props.addBranch()}>
                            <img src="../../assets/media/icons/plus.svg" alt="" width={25} />
                        </button>
                    </div>
                    <div className="col-md-10 d-flex flex-wrap justify-content-end">
                        <button type="button" className="btn btn-clean btn-bold move-btn">
                            Move
                        </button>
                        <div className="inner-wrapper">
                            <select className="form-control ml-2 direction" name='dropdirection' disabled='true'>
                                <option value='down'>Below</option>
                                <option value='up'>Above</option>
                            </select>
                            <select 
                            name='dropbranch'
                            className="form-control ml-2 branchcount"
                            value={this.state.dropbranch}
                            onChange={(e) => this.onchangeDropbranch(e)} 
                            disabled='true'
                            >
                                <option value=''>Branch #</option>
                                {this.state.list && this.state.list.map((value, index) => {
                                    return(
                                    <option value={index} key={index}>{index+1}</option>
                                    )
                                })} 
                            </select>
                        </div>
                    </div>
                </div>
                
                    {!this.state.backupbot_types.includes(this.props.bot_type) && <div data-branch={this.props.branchList[0].branch_id} className="yr-chat-round-box inner-box borderset contextbranch">
                        <div className="right-action-btns" style={{ textAlign: "right" }}>
                            {
                                tyai && <button type="button" className="btn btn-icon" title="Copy" onClick={(e) => this.copyContextBranch(e,this.props.branchList[0].branch_id)}><i className="la la-clone red" /></button>

                            }

                            <button type="button" className="btn btn-icon"><i className="la la-edit blue" title="Edit" onClick={(e) => this.props.editBranch(this.props.branchList[0].branch_id, (this.props.bot_type=='GEN_WEB'|| this.props.bot_type=='GEN_VOICE') ? null :tyai)} /></button>

                            {/* <button type="button" className="btn btn-icon" onClick={(e) => this.actionBranchDelete('Open',this.props.branchList[0].branch_id)}><i className="flaticon-delete-1" /></button> */}

                        </div>
                        <header className="box-head mb-2">
                            <div className="small-title cursorpointer" onClick={(e) => this.props.editBranch(this.props.branchList[0].branch_id, (this.props.bot_type=='GEN_WEB'|| this.props.bot_type=='GEN_VOICE') ? null :tyai)}>
                                {tyai  ? "Context branch" : this.props.branchList[0].branch_name}
                            </div>

                        </header>

                        {!tyai && this.props.branchList[0].branch_questions && this.props.branchList[0].branch_questions.map((obj2, index) => {
                            return (
                                <section className="body-text mb-3 question_map_image" style={{ wordBreak: "break-all" }} dangerouslySetInnerHTML={{ __html: obj2.question }}>
                                </section>
                            )
                        })}
                        {tyai && "This branch includes company generated context to help train your ai model."}

                        <footer>
                            {this.props.branchList[0].branch_buttons && this.props.branchList[0].branch_buttons.map && !tyai && ((buttonobj, index) => {
                                return (
                                    <button style={{height:"auto", minHeight:"32px"}} className="btn yr-round-btn">{buttonobj.button_title}</button>
                                )
                            })}
                        </footer>
                    </div>}
                    {this.state.contextBranchesList.map((contextBranch,index,arr)=> {
                        let checkBranchType;
                        if(contextBranch.trainyourAI=='1'){
                            checkBranchType='confused';
                        }else{
                            checkBranchType=null;
                        }
                        if(this.props.bot_type=='GEN_WEB' || this.props.bot_type=='GEN_VOICE'){
                            checkBranchType=null;
                        }
                        const capitalHeaderLetter=contextBranch.branch_name.charAt(0).toUpperCase();
                        const remainHeaderLetter=contextBranch.branch_name.slice(1)
                        const finalHeaderTitle=capitalHeaderLetter+remainHeaderLetter;
                        return (
                            <div onClick={(e)=>this.editBranchName(e,finalHeaderTitle,index,contextBranch.branch_id,)} key={index} className="yr-chat-round-box inner-box borderset cursorpointer" data-branch={contextBranch.branch_id} >
                                <div className="right-action-btns" style={{textAlign:"right"}}>
                                    <button type="button" className="btn btn-icon" title="Copy" onClick={(e) => this.copyBranch(e,contextBranch.branch_id)}><i className="la la-clone" /></button>
                                    <button type="button" className="btn btn-icon" title="Edit" onClick={(e)=>this.props.editBranch(contextBranch.branch_id, checkBranchType)}><i className="la la-edit" /></button>    
                                        {index > -1 && 
                                        <button type="button" className="btn btn-icon" title="Delete" onClick={(e) => this.actionBranchDelete(e,'Open',contextBranch.branch_id)}><i className="la la-trash" /></button>
                                        }
                                        {/* <button type="button" className="btn btn-icon"><i className="yr-edit blue" /></button> */}
                                    </div>
                                <header className="box-head mb-2">
                                    <div className="small-title cursorpointer"  data-tooltip={checkBranchType=='confused' ? "Double click to edit name." : 'Click to edit branch'}>
                                    <span className="boxhastitle">{finalHeaderTitle}</span> 
                                    </div>
                                    {/* <button type="button" onClick={()=>this.editBranchName(finalHeaderTitle,index)} className="btn btn-icon" ><i className="la la-edit" /></button> */}
                                </header>
    
                                {contextBranch.branch_questions && contextBranch.branch_questions.map((contextBranch2, index) => {
                                    return (
                                        <section className="body-text mb-3 question_map_image" dangerouslySetInnerHTML={{ __html: contextBranch2.question }}>
                                        </section>
                                    )
                                })}
                                <footer>
                                    {contextBranch.branch_buttons && contextBranch.branch_buttons.map((buttonobj, index) => {
                                        return (
                                            <button className="btn yr-round-btn" style={{display:buttonobj.button_respond_on=='REVIEW' && this.props.review_links.length==0 ?'none':'block', height:"auto", minHeight:"32px"}}>{buttonobj.button_title}</button>
                                        )
                                    })}
                                </footer>
                            </div>)
                    })}
                <ReactSortable
                    animation={400}
                    list={this.state.list}
                    setList={(newState) => {
                        this.setState({ list: newState})
                    }}
                    onEnd={()=>this.onEndOrder()}
                    disabled={false}
                >
                {this.state.list && this.state.list.map((obj, index) => { console.log("this.state.list",this.state.list)
                    let checkBranchType;
                    if(obj.trainyourAI=='1'){
                        checkBranchType='confused';
                    }else{
                        checkBranchType=null;
                    }
                    if(this.props.bot_type=='GEN_WEB' || this.props.bot_type=='GEN_VOICE'){
                        checkBranchType=null;
                    }
                    this.state.editBranchNameInput.push(false);
                    const capitalHeaderLetter=obj.branch_name.charAt(0).toUpperCase();
                    const remainHeaderLetter=obj.branch_name.slice(1)
                    const finalHeaderTitle=capitalHeaderLetter+remainHeaderLetter;
                    return (
                        <div onClick={checkBranchType=='confused' ? (e)=>this.editBranchName(e,finalHeaderTitle,index,obj.branch_id) : (e)=>this.props.editBranch(obj.branch_id, checkBranchType)} key={index} className="yr-chat-round-box inner-box borderset cursorpointer" data-branch={obj.branch_id} >
                            <div className="right-action-btns" style={{textAlign:"right"}}>
                                <button type="button" className="btn btn-icon" title="Copy" onClick={(e) => this.copyBranch(e,obj.branch_id)}><i className="la la-clone" /></button>
                                <button type="button" className="btn btn-icon" title="Edit" onClick={(e)=>this.props.editBranch(obj.branch_id, checkBranchType)}><i className="la la-edit" /></button>    
                                    {index > 0 && 
                                    <button type="button" className="btn btn-icon" title="Delete" onClick={(e) => this.actionBranchDelete(e,'Open',obj.branch_id)}><i className="la la-trash" /></button>
                                    }
                                    
                                   
                                    {/* <button type="button" className="btn btn-icon"><i className="yr-edit blue" /></button> */}
                                </div>
                            <header className="box-head mb-2">
                                    <div className="small-title cursorpointer"  data-tooltip={checkBranchType=='confused' ? "Double click to edit name." : 'Click to edit branch'}>
                                    <span className="kt-badge kt-badge--success kt-badge--square pre-number-box">{index+1}</span>
                                    <span className="boxhastitle">{finalHeaderTitle}</span> 
                                    </div>
                                {/* <button type="button" onClick={()=>this.editBranchName(finalHeaderTitle,index)} className="btn btn-icon" ><i className="la la-edit" /></button> */}
                            </header>

                            {obj.branch_questions && obj.branch_questions.map((obj2, index) => {
                                return (
                                    <section className="body-text mb-3 question_map_image" dangerouslySetInnerHTML={{ __html: obj2.question }}>
                                    </section>
                                )
                            })}
                            <footer>
                                {obj.branch_buttons && obj.branch_buttons.map((buttonobj, index) => {
                                    return (
                                        <button className="btn yr-round-btn" style={{display:buttonobj.button_respond_on=='REVIEW' && this.props.review_links.length==0 ?'none':'block', minHeight:"32px", height:"auto",maxWidth:"280px",overflow:"hidden"}}>{buttonobj.button_title}</button>
                                    )
                                })}
                            </footer>
                        </div>)
                })}
                </ReactSortable>
            </div>)}
            <div
				className={this.state.dialogShow ? "modal fade show" : "modal fade"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: this.state.dialogShow ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:600}}>
				   {this.state.dialogLoading && <div className="ui loading form"></div>}
					<div className="modal-content">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={(e) => this.actionBranchDelete(e,'Close')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">Confirm your action</h5>
						</div>
						<div className="modal-body">
							<div className="px-4">
                             {this.state.dialogMessage && (<p dangerouslySetInnerHTML={{ __html: this.state.dialogMessage }}></p>)}
							 <p>Are you sure you want to delete it?</p>
							</div>
						</div>
						<div className="modal-footer">
								<button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={(e) => this.actionBranchDelete(e,'Close')} data-dismiss="modal">
									<span>No</span>
								</button>
								<button type="button" className="btn linear-gradient yr-submitbtn btn-sm" style={{marginRight:"25px"}} onClick={(e) => this.actionBranchDelete(e,'Delete')}>
									Yes
								</button>
							</div>
					</div>
				</div>
			</div>
                <Modal
                    show={this.state.finalWorkspaceSaveModalFlag}
                    footer={(<div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => this.finalWorkspaceSaveModal()}
                            className="btn  linear-gradient yr-submitbtn btn-sm"
                        >
                            <span>Close</span>
                        </button>
                    </div>)}
                    onclose={this.finalWorkspaceSaveModal}
                    title={"Context Branch"}
                    body={"Please save your context branch changes before saving the workspace."}
                />
                <Modal
                    show={this.state.editBranchNameShow}
                    style={"none"}
                    footer={(
                    <div style={{marginTop:"15px"}}>
                            <div style={{float:"right", marginTop:"5px", padding:"10px", marginRight:"25px"}}>
                                <button onClick={(e)=>this.closeEditBranchName()} className='btn linear-gradient-cancel yr-cancelbtn mg-r'>
                                    <span>Cancel</span>
                                </button>
                                <button  disabled={this.state.disableBtn} className='btn linear-gradient yr-submitbtn btn-sm' onClick={(e)=>this.changeBranchNameFnc(e)}>Save</button>
                            </div>
                    </div>)}
                    onclose={(e)=>this.closeEditBranchName()}
                    title={"Edit Context Branch Name"}
                    body={this.branchnameModalBody()}
                />
            </div>
        )
    }
}


export default BranchList;
