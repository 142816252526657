import React from "react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../../store/Page";
import "./callList.css";
import "../../../../Modal/dialog.css";
import "../../../../Modal/DialogBox.js";
import api from "../../../../../api";
import config from "../../../../../config";
import util from "../../../../../util";
import utilFunc from "../../../../../util_funs";
import DatatableList from "./dataTables/DatatableList";
import { Link, Redirect } from "react-router-dom";

import { connect } from "react-redux";

class CallList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: {},
      yourCallList: [],
      tab: "tab1",
      bid: "",
      subscriber: 0,
      email: "",
      contacts: 0,
    };
  }

  componentDidMount() {
    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "CallList",
      navigationName: "marketing",
      subHeaderTitle: "Engage",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });

    this.setState({ bid: this.props.match.params.id }, function () {
      this.loadData();
    });
  }

  loadData = () => {
    this.setState({
      activetab: 0,
    });
  };

  getCallListStatus = (tab) => {
    this.setState({
      activetab: tab,
    });
  };

  render() {
    const { errors, email, email_subject, action, added_date } = this.state;

    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="mh-CallList">
        <div className="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-call-list">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 px-5">
              <div className="kt-portlet--tabs yr-mt-10">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-toolbar">
                    <ul
                      className="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-line-3x"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className={
                            this.state.activetab == 0
                              ? "nav-link active cursorpointer"
                              : "nav-link cursorpointer"
                          }
                          onClick={() => this.getCallListStatus("0")}
                        >
                          All
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            this.state.activetab == 1
                              ? "nav-link active cursorpointer"
                              : "nav-link cursorpointer"
                          }
                          onClick={() => this.getCallListStatus("1")}
                        >
                          Need to Call
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            this.state.activetab == 2
                              ? "nav-link active cursorpointer"
                              : "nav-link cursorpointer"
                          }
                          onClick={() => this.getCallListStatus("2")}
                        >
                          Follow ups
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            this.state.activetab == 3
                              ? "nav-link active cursorpointer"
                              : "nav-link cursorpointer"
                          }
                          onClick={() => this.getCallListStatus("3")}
                        >
                          Archived Contacts
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clearfix"></div>

                <div className="kt-portlet__body">
                  <DatatableList
                    activetab={this.state.activetab}
                    indextime={new Date()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(CallList);
