import {
  isRequired,
  isRequiredOldPass,
  isRequiredNewPass,
  validNewPassword,
  isRequiredNewConPass,
  newPasswordAndConfirmPasswordEqulaity,
  isSelected,
  usPhoneLength,
  usPhoneLengthTM,
  minCustomLength,
  isValidDomain,
  newPinAndOldPinNotSame
} from "./Validation";

export default {
  isRequired,
  isRequiredOldPass,
  isRequiredNewPass,
  isRequiredNewConPass,
  newPasswordAndConfirmPasswordEqulaity,
  isSelected,
  validNewPassword,
  usPhoneLength,
  usPhoneLengthTM,
  minCustomLength,
  isValidDomain,
  newPinAndOldPinNotSame
};
