import React,{useState,useEffect} from 'react'
import { Checkbox } from 'semantic-ui-react';
import classes from "./BusinessCategories.module.css";
const BusinessCategories = (props) => {
    
    const [buissnessCatDetail,setbuissnessCatDetail] = useState({})
    const [categoryDefaultData,setCategoryDefaultData]  = useState({checkedCategory:[],checkedServiceCategory:[],intent_data:props.intent_data});
    useEffect(() => {
        setbuissnessCatDetail(props.BuisnessCat);
        loadData();
    },[props.rands]);

    function loadData(){
        if(buissnessCatDetail.mainCatSelected){
            let selectedCat = buissnessCatDetail.mainCatSelected.map( ( cat ) => {
                return cat.id
            })
            let tempState = {
                checkedCategory:selectedCat.length ? selectedCat : [],
                checkedServiceCategory:props.BuisnessCat.subCatDeata.length ? props.BuisnessCat.subCatDeata : [],
                intent_data:props.intent_data
            };
            setCategoryDefaultData(tempState);
        }
    }

    const handleCategoryCheck = (e,categoryId) => {
        let data = categoryDefaultData.checkedCategory;
        let serviceData = categoryDefaultData.checkedServiceCategory;
        let intentData = categoryDefaultData.intent_data;
        const checkIndex = data.findIndex((v,i) => v == categoryId);
        if (checkIndex === -1) {
            data.push(categoryId);
        }else{
            data.splice(checkIndex, 1);
        }

        let categoryBuisness = {...buissnessCatDetail};
        const checkIndexm = props.intent_data.categories.findIndex((v,i) => v.id == categoryId);
        if(checkIndexm !== -1 ){
            categoryBuisness.mainCatSelected = categoryBuisness.mainCatSelected ? categoryBuisness.mainCatSelected : []
            const chekexistingIndexM = categoryBuisness.mainCatSelected.findIndex((v,i) => v.id == categoryId);
            if( chekexistingIndexM === -1){
                categoryBuisness.mainCatSelected.push(props.intent_data.categories[checkIndexm]);
            }else{
                categoryBuisness.mainCatSelected.splice(chekexistingIndexM,1);
            }
        }
        if (data.length === 0) {
            serviceData = [];
        }else{
            serviceData = intentData.subcategories.filter((item) => data.includes(item.cat_id));
        }
        const tempCategoryData = {...categoryDefaultData};
        tempCategoryData.checkedCategory = data;
        tempCategoryData.checkedServiceCategory = serviceData;

        let tempbuinessness = {...buissnessCatDetail};
        tempbuinessness.subCatDeata=tempCategoryData.checkedServiceCategory;
        props.setValue(tempbuinessness,"BuisnessCat");

    }

    const handleServiceCategoryCheck = (e, serviceCatId) => {
        let serviceCatData = categoryDefaultData.checkedServiceCategory;

        let itemIndex = serviceCatData.findIndex((item) => item.id === serviceCatId);
        serviceCatData[itemIndex].checked = !serviceCatData[itemIndex].checked
        const tempCategoryData = {...categoryDefaultData};
        tempCategoryData.checkedServiceCategory = serviceCatData;
        setCategoryDefaultData(tempCategoryData);

        let tempbuinessness = {...buissnessCatDetail};
        tempbuinessness.subCatDeata=categoryDefaultData.checkedServiceCategory;
        props.setValue(tempbuinessness,"BuisnessCat");
    }
    
    // input validation pending
    const handleAdditionalcategory = ( e ) => {
        console.log(e.target.value);
        let tempstate = {...buissnessCatDetail};
        tempstate.additionCategories = e.target.value;
        setbuissnessCatDetail(tempstate);
        props.setValue(tempstate,"BuisnessCat");

    }
    return (
        <>
            <div className="row mb-4">
                <div className='col-md-12'>
                    <div className={`box-title ${classes.business_box_title}`}>Which category does your company fall into?</div>
                </div>
                <div className={`col-md-12 ${classes.business_checkbox}`}>
                    {categoryDefaultData.intent_data && categoryDefaultData.intent_data?.categories.map((intentData, index) => (
                        <Checkbox
                            checked={categoryDefaultData.checkedCategory.indexOf(intentData.id) !== -1}
                            key={intentData.id}
                            label={intentData.name}
                            className='my-2'
                            onChange={(e) => handleCategoryCheck(e, intentData.id)}
                        />
                    ))}

                </div>
            </div>
                <div className='row mb-4'>
                    {(categoryDefaultData.checkedServiceCategory && categoryDefaultData.checkedServiceCategory.length) ?
                    <div className='col-md-12'>
                        <div className={`box-title ${classes.business_box_title}`}> Which action you like to exclude ? </div>
                    </div>:""}
                    <div className={`col-md-12 ${classes.business_checkbox}`}>
                        {categoryDefaultData.checkedServiceCategory && categoryDefaultData.checkedServiceCategory.map((serviceCat, index) => (
                            <div className={`${classes.business_checkbox__group}`} key={serviceCat.id}>
                                <Checkbox
                                    checked={serviceCat.checked ?? false}
                                    label={serviceCat.name}
                                    className='my-2'
                                    onChange={(e) => handleServiceCategoryCheck(e, serviceCat.id)}
                                />
                            </div>
                        ))}
                    </div>
                    <div className='col-md-12'>
                        <label>Any other action to exclude ? <br/>
                        Make sure to start with "Don't..." for example "Don't ask for a customer's full address." </label>
                        <div className='form-group'>
                            <textarea onChange={handleAdditionalcategory}  className={ `form-group ${classes.inputTextare}`}
                            value= {buissnessCatDetail.additionCategories} >
                            </textarea>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default BusinessCategories