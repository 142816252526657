import React from 'react'
import '../../Automation/Automation.css';
import CustomErrorField from "../../../../Form/CustomErrorField";

import { Tab } from 'semantic-ui-react'
import { clearConfig } from 'dompurify';
let selectedTabValue = ['Branch', 'URL', 'PhoneCall', 'SMS', 'REVIEW'];
class AddButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            buttontitle: '',
            button_respond_on: '',
            button_respond_on_branch: '',
            button_respond_on_url: '',
            button_respond_on_url_tab:'newtab',
            button_respond_same_tab:false,
            button_respond_new_tab:true,
            button_respond_on_phonecall: '',
            button_respond_on_sms: '',
            button_url_tab_error:false,
            button_respond_on_phonecall_country_code: '+1',
            button_respond_on_sms_country_code: '',
            button_phone_call_use_phone_var: 0,
            button_phone_call_use_dynamic_call: 0,
            button_phone_call_use_input_method: 0,
            button_respond_on_review: '',
            button_review_location: '',
            branchList: [],
            activeIndex: 0,
            selectedTab: 'Branch',
            errors: [],
            button_action_id: '',
            is_exclude: 0,
            include_zip: 0,
            auto_id: null,
        };
        selectedTabValue = (this.props.bot_type === 'Voice' || this.props.bot_type === 'GEN_VOICE') ? ['Branch', 'PhoneCall'] : selectedTabValue;
    }




    componentDidMount() {

        this.setState({ branchList: this.props.branchList })
        const county_list = this.props.countryCodeLists;
        const default_list = this.props.defaultCountryCodeLists;

        this.onLoad();
    }

    componentDidUpdate(prevProps) {
        //console.log(this.props.button_action_id+"============"+prevProps.button_action_id)

        if (this.props.button_action_id != prevProps.button_action_id) {

            this.onLoad();
        }
        // if(this.props.button_action_id=='0'){
        //     this.onLoad();
        // }
        //this.onLoad();
    }

    onLoad = () => {
        // let scrolltop=document.querySelector('.addbutton').offsetTop - document.body.scrollTop;
        // window.scroll({top: document.querySelector('.addbutton').offsetTop, left: 0, behavior: 'smooth' });
        if (this.props.button_action_id == 'add') {
            this.setState({
                buttontitle: '',
                button_respond_on: 'Branch',
                button_respond_on_branch: '',
                button_respond_on_url: '',
                button_respond_on_phonecall: '',
                button_respond_on_sms: '',
                button_respond_on_phonecall_country_code: '+1',
                button_respond_on_sms_country_code: '',
                button_phone_call_use_phone_var: 0,
                button_phone_call_use_dynamic_call: 0,
                button_phone_call_use_input_method: 0,
                button_respond_on_review: '',
                button_review_location: '',
                activeIndex: 0,
                selectedTab: 'Branch',
                is_exclude: 0,
                include_zip: 0,
                auto_id: null,
            })
        } else if (this.props.button_action_id == 'create_branch') {
            this.setState({ button_respond_on_branch: this.props.branch_new_id })
        }
        else {

            let buttoninfo = this.props.buttondata[this.props.button_action_id];
            console.log('------------------------------------->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', buttoninfo)

            let activeindex = selectedTabValue.findIndex(
                (list) => list === buttoninfo.button_respond_on
            );

            this.setState({
                buttontitle: buttoninfo.button_title,
                button_respond_on: selectedTabValue[activeindex],
                button_respond_on_branch: buttoninfo.button_respond_on_branch,
                button_respond_on_url: buttoninfo.button_respond_on_url,
                button_respond_on_url_tab:buttoninfo.button_respond_on_url_tab,
                button_respond_on_phonecall: this.phoneFormat(buttoninfo.button_respond_on_phonecall),
                button_respond_on_sms: this.phoneFormat(buttoninfo.button_respond_on_sms),
                button_respond_on_phonecall_country_code: buttoninfo.button_respond_on_phonecall_country_code,
                button_respond_on_sms_country_code: buttoninfo.button_respond_on_phonecall_country_code,
                button_phone_call_use_phone_var: buttoninfo.button_phone_call_use_phone_var,
                button_phone_call_use_dynamic_call: buttoninfo.button_phone_call_use_dynamic_call,
                button_phone_call_use_input_method: buttoninfo.button_phone_call_use_input_method,
                button_respond_on_review: buttoninfo.button_respond_on_review,
                button_review_location: buttoninfo.button_review_location,
                selectedTab: selectedTabValue[activeindex],
                activeIndex: activeindex,
                is_exclude: buttoninfo.is_exclude,
                include_zip: buttoninfo.include_zip,
                auto_id: buttoninfo.auto_id,
            })

            if(buttoninfo.button_respond_on_url_tab=='sametab'){
                this.setState({
                    button_respond_same_tab:true,
                    button_respond_new_tab:false
                })
            }
            if(buttoninfo.button_respond_on_url_tab=='newtab'){
                this.setState({
                    button_respond_same_tab:false,
                    button_respond_new_tab:true
                })
            }
        }

    }
    saveButton = (type_id) => {
        const errors = {};

        if (this.state.buttontitle == '') {

            errors['buttontitle'] = "This field is required.";
        }

        if (this.state.selectedTab == 'Branch' && this.state.button_respond_on_branch == '') {
            errors['Branch'] = "This field is required.";
        }

        let validUrl=this.state.button_respond_on_url
        let validateUrl=/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(validUrl) == true;
        console.log(validateUrl)
        if (this.state.selectedTab == 'URL') {
            if(validateUrl==false){
                errors['URL'] = "Please write a valid URL.";
            }
        }

        if(this.state.button_respond_same_tab==false && this.state.button_respond_new_tab == false ){
            this.setState({button_url_tab_error:true})
            return;
        }

        if (this.state.selectedTab == 'PhoneCall') {

            if ((!['Voice','GEN_VOICE'].includes(this.props.bot_type)) &&(this.state.button_phone_call_use_phone_var == 0 || this.state.button_phone_call_use_phone_var == '')
                && (this.state.button_phone_call_use_dynamic_call == 0 || this.state.button_phone_call_use_dynamic_call == '')
                && (this.state.button_phone_call_use_input_method == 0 || this.state.button_phone_call_use_input_method == '')
            ) {
                errors['PhoneCall'] = "Please select one option.";
            } else {

                if (this.state.button_respond_on_phonecall.trim() == '' && this.state.button_phone_call_use_input_method == 1) {
                    errors['PhoneCallNumber'] = "This field is required.";
                }

                if (this.state.button_respond_on_phonecall.trim() != '' && this.state.button_phone_call_use_input_method == 1 && this.state.button_respond_on_phonecall.match(/\d/g).length != 10) {
                    errors['PhoneCallNumber'] = "Please Enter Valid Number.";
                }
            }

        }

        if (this.state.selectedTab == 'SMS' && this.state.button_respond_on_sms == '') {
            errors['SMS'] = "This field is required.";
        }

        if (this.state.selectedTab == 'SMS' && this.state.button_respond_on_sms.trim() != '' && this.state.button_respond_on_sms.match(/\d/g).length != 10) {
            errors['SMS'] = "Please Enter Valid Number.";
        }



        if (this.state.selectedTab == 'REVIEW') {

            if ((this.state.button_respond_on_review == 0 || this.state.button_respond_on_review == '')

            ) {
                errors['REVIEW'] = "Please select one option.";
            } else {


                if (this.state.button_respond_on_review.trim() == 2) {

                    if (this.state.button_review_location.trim() == '') {

                        errors['REVIEWERR'] = "This field is required.";
                    }

                }


            }

        }




        this.setState({ errors: errors });
        if (Object.keys(errors).length == 0) {
            let data = {
                button_title: this.state.buttontitle,
                button_respond_on: this.state.button_respond_on,
                button_respond_on_branch: this.state.button_respond_on_branch,
                button_respond_on_url: this.state.button_respond_on_url,
                button_respond_on_url_tab:this.state.button_respond_on_url_tab,
                button_respond_on_phonecall: this.state.button_respond_on_phonecall,
                button_respond_on_sms: this.state.button_respond_on_sms,
                button_respond_on_phonecall_country_code: this.state.button_respond_on_phonecall_country_code,
                button_phone_call_use_phone_var: this.state.button_phone_call_use_phone_var,
                button_phone_call_use_dynamic_call: this.state.button_phone_call_use_dynamic_call,
                button_phone_call_use_input_method: (this.props.bot_type === 'Voice' || this.props.bot_type === 'GEN_VOICE') ? 1 : this.state.button_phone_call_use_input_method,
                button_respond_on_review: this.state.button_respond_on_review,
                button_review_location: this.state.button_review_location,
                is_exclude: this.state.is_exclude,
                auto_id: this.state.auto_id,
                include_zip: this.state.include_zip,
                enable_transfer_call: false
            }
            console.log(data)
            
            this.props.saveButton(data, type_id);
        }


    }


    onchange = (e) => {
        if (e.target.name === 'is_exclude') {
            if (e.target.checked) {
                this.setState({ is_exclude: 1 });
            } else {
                this.setState({ is_exclude: 0 });
            }
        } else if (e.target.name === 'include_zip') {
            if (e.target.checked) {
                this.setState({ include_zip: 1 });
            } else {
                this.setState({ include_zip: 0 });
            }
        } else {
            if(e.target.value.length<61){
                console.log(e.target.value.length)
                this.setState({ buttontitle: e.target.value });
            }
        }


    }

    changeBranch = (e) => {
        if (this.state.selectedTab == 'Branch') {
            this.setState({ button_respond_on_branch: e.target.value });
        }
    }

    onChangeUrlTab = (e) => {
        if (this.state.selectedTab == 'URL') {
            this.setState({ button_respond_on_url: e.target.value });
        }
    }

    onChangeUrlTabType = (e) => {
        if (this.state.selectedTab == 'URL') {
            if(e.target.value=='newtab'){
                this.setState({
                    button_respond_new_tab:true,
                    button_respond_same_tab:false,
                    button_respond_on_url_tab:e.target.value,
                    button_url_tab_error:false
                })
            }
            if(e.target.value=='sametab'){
                this.setState({
                    button_respond_new_tab:false,
                    button_respond_same_tab:true,
                    button_respond_on_url_tab:e.target.value,
                    button_url_tab_error:false
                })
            }
        }
    }

    onSelectPhoneVar = (e, value) => {

        if (this.state.selectedTab == 'PhoneCall') {
            let button_phone_call_use_phone_var = '';
            let button_phone_call_use_dynamic_call = '';
            let button_phone_call_use_input_method = '';
            //    button_phone_call_use_input_method:'',
            if (value == 'button_phone_call_use_phone_var') {
                button_phone_call_use_phone_var = 1;
                button_phone_call_use_dynamic_call = 0;
                button_phone_call_use_input_method = 0;
            }
            if (value == 'button_phone_call_use_dynamic_call') {
                button_phone_call_use_phone_var = 0;
                button_phone_call_use_dynamic_call = 1;
                button_phone_call_use_input_method = 0;
            }
            if (value == 'button_phone_call_use_input_method') {
                button_phone_call_use_phone_var = 0;
                button_phone_call_use_dynamic_call = 0;
                button_phone_call_use_input_method = 1;
            }
            this.setState({
                button_phone_call_use_phone_var: button_phone_call_use_phone_var,
                button_phone_call_use_dynamic_call: button_phone_call_use_dynamic_call,
                button_phone_call_use_input_method: button_phone_call_use_input_method
            }, function () {

            })
        }
    }

    onTabChange = (e, data) => {
        console.log(data);
        this.setState({
            selectedTab: selectedTabValue[data.activeIndex],
            activeIndex: data.activeIndex,
            button_respond_on: selectedTabValue[data.activeIndex],

        });
    }


    onInputChange = (e) => {


        let inputValue;
        if (e.target.name == 'button_respond_on_phonecall' || e.target.name == 'button_respond_on_sms') {
            inputValue = this.phoneFormat(e.target.value);

        }

        else
            inputValue = e.target.value;
        if (e.target.name == 'button_respond_on_review') {


            this.setState({ [e.target.name]: inputValue, button_review_location: '' });
        } else {
            this.setState({ [e.target.name]: inputValue });
        }


        // let errList = this.state.errors;
        // delete errList[e.target.name];
        // this.setState({ errors: errList });
    }



    phoneFormat = (p) => {
        p = p.replace(/[^\d]*/gi, "");
        if (p.length <= 3) {
            return p;
        } else if (p.length > 3 && p.length < 7) {
            p = "(" + p;
            let l30 = p.length;
            let p30 = p.substring(0, 4);
            p30 = p30 + ")";

            let p31 = p.substring(4, l30);
            let pp = p30 + p31;
            return pp;
        } else if (p.length >= 7) {
            p = "(" + p;
            let l30 = p.length;
            let p30 = p.substring(0, 4);
            p30 = p30 + ")";

            let p31 = p.substring(4, l30);
            let pp = p30 + p31;

            let l40 = pp.length;
            let p40 = pp.substring(0, 8);
            p40 = p40 + "-";

            let p41 = pp.substring(8, l40);
            let ppp = p40 + p41;
            let maxphonelength = 13;
            let finalPhone = ppp.substring(0, maxphonelength);
            return finalPhone;
        }
    };



    render() {
        const { errors } = this.state;



        let panes = this.props.bot_type != 'GDF' ?
            [{
                menuItem: 'Branch',
                render: () => <Tab.Pane attached={false} >
                    <div className="form-group">
                        {!['Voice','GEN_VOICE'].includes(this.props.bot_type) &&
                            <div style={{ marginLeft: '17px' }}>
                                <input name='include_zip' checked={this.state.include_zip == 1 ? true : false} value={this.state.include_zip} onChange={(e) => this.onchange(e)} style={{ marginTop: '3px' }} class="form-check-input kt-checkbox--single chat-checkbox" type="checkbox" id="flexCheckDefault" />
                                <label style={{ marginTop: 0 }} class="form-check-label" for="flexCheckDefault">
                                    Include Zipcode
                                </label>
                            </div>
                        }
                        <label>Go to branch</label>
                        <select className={
                            errors && errors['Branch']
                                ? "form-control is-invalid"
                                : "form-control"
                        } onChange={(e) => this.changeBranch(e)} value={this.state.button_respond_on_branch}>
                            <option value="">Select Branch</option>
                            {this.state.branchList && this.state.branchList.map((value, index) => {
                                return (
                                    <option value={value.branch_id}>{value.branch_name}</option>
                                )
                            })}
                        </select>
                        {errors && errors.hasOwnProperty('Branch') && (
                            <CustomErrorField
                                message={errors['Branch']}
                                className="error invalid-feedback"
                            />
                        )}
                        <a nohref="" class="addTextArea cursorpointer" onClick={() => this.props.createNewBranch('button')}><br />
                            <i class="la la-plus"></i> Create new branch</a>
                    </div>
                </Tab.Pane>,
            },

            {
                menuItem: 'URL',
                render: () => <Tab.Pane attached={false}>
                    <div className="form-group">
                        <label>Open URL</label>
                        <input type="text" className={
                            errors && errors['URL']
                                ? "form-control is-invalid"
                                : "form-control"
                        } defaultValue={this.state.button_respond_on_url} placeholder="Add URL" onChange={(e) => this.onChangeUrlTab(e)} />
                        {errors && errors.hasOwnProperty('URL') && (
                            <CustomErrorField
                                message={errors['URL']}
                                className="error invalid-feedback"
                            />
                        )}
                        <label style={{width:"170px"}}>Open URL in new tab</label>
                        <input value={'newtab'} checked={this.state.button_respond_new_tab} onChange={(e)=>this.onChangeUrlTabType(e)} style={{cursor:"pointer"}} type='radio'/>
                        <br/>
                        <label style={{width:"170px"}}>Open URL in same tab</label>
                        <input value={'sametab'} checked={this.state.button_respond_same_tab} onChange={(e)=>this.onChangeUrlTabType(e)} style={{cursor:"pointer"}} type='radio'/>
                        <br/>
                        {this.state.button_url_tab_error && <span style={{
                            fontWeight: 400,
                            fontSize: '80%',
                            color: "red"
                        }}>please select any one of the checkboxes.</span>}
                        
                    </div>
                </Tab.Pane>,
            },
            {
                menuItem: 'Phonecall',
                render: () => <Tab.Pane attached={false}>
                    {!['Voice','GEN_VOICE'].includes(this.props.bot_type) &&
                        <div className="form-group">
                            <label>Add Contact</label>
                            <div className="row">
                                <div className="col-md-7 col-xs-10"> <div> Use PHONE_NUMBER variable </div></div>
                                <div className="col-md-5 col-xs-2">

                                    <input type="radio" className="phone_var" name="button_phone_call_use_input_method" phone_tex_val="(955)465-254" onChange={(e) => this.onSelectPhoneVar(e, 'button_phone_call_use_phone_var')} checked={this.state.button_phone_call_use_phone_var > 0 ? true : false} />
                                    <span></span>

                                </div>
                            </div>

                        <div className="row">
                            <div className="col-md-7 col-xs-10"> <div> Use dynamic number insertion </div></div>
                            <div className="col-md-5 col-xs-2">

                                <input type="radio" className="phone_var" name="button_phone_call_use_input_method" phone_tex_val="" onChange={(e) => this.onSelectPhoneVar(e, 'button_phone_call_use_dynamic_call')} checked={this.state.button_phone_call_use_dynamic_call > 0 ? true : false} />
                                <span></span>

                            </div>
                        </div>

                        <div className="row marginbt5">
                            <div className="col-md-7 col-xs-10"> <div> Enter manual phone number </div></div>
                            <div className="col-md-5 col-xs-2">

                                <input type="radio" className="phone_var" id="button_phone_call_use_input_method" name="button_phone_call_use_input_method" phone_tex_val="" onChange={(e) => this.onSelectPhoneVar(e, 'button_phone_call_use_input_method')} checked={this.state.button_phone_call_use_input_method > 0 ? true : false} />
                                <span></span>

                            </div>
                        </div>
                        <div className={
                            errors && errors['PhoneCall']
                                ? "form-control is-invalid"
                                : "form-control"
                        } style={{ display: "none" }}></div>
                        {errors && errors.hasOwnProperty('PhoneCall') && (
                            <CustomErrorField
                                message={errors['PhoneCall']}
                                className="error invalid-feedback"
                            />
                        )}
                        {this.state.button_phone_call_use_input_method > 0 &&
                            <div className="select_simple_call" style={{ display: "block" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <select name="button_respond_on_phonecall_country_code" style={{ float: "left", width: "114px;" }} value={this.state.button_respond_on_phonecall_country_code} className="form-control" onChange={(e) => { this.onInputChange(e) }}>

                                            {this.props.defaultCountryCodeLists && this.props.defaultCountryCodeLists.map((value, index) => {
                                                return (
                                                    <option value={value.country_code} >{value.country_name}</option>

                                                )
                                            })}

                                            <optgroup label="Other countries">

                                                {this.props.countryCodeLists && this.props.countryCodeLists.map((value, index) => {
                                                    return (
                                                        <option value={value.country_code}  >{value.country_name}</option>

                                                    )
                                                })} </optgroup>
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <input className={
                                            errors && errors['PhoneCallNumber']
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        } autocomplete="off" type="text" id="phonecall" name="button_respond_on_phonecall" placeholder="Add Call No." value={this.state.button_respond_on_phonecall} onChange={(e) => this.onInputChange(e)} />
                                        {errors && errors.hasOwnProperty('PhoneCallNumber') && (
                                            <CustomErrorField
                                                message={errors['PhoneCallNumber']}
                                                className="error invalid-feedback"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        }



                        </div>
                    }
                    {(this.props.bot_type === 'Voice' || this.props.bot_type=='GEN_VOICE') &&
                        <div className='form-group'>
                        <input type="hidden" name="button_phone_call_use_input_method" phone_tex_val="(955)465-254" value={1} />
                            <div className="select_simple_call" style={{ display: "block" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <select name="button_respond_on_phonecall_country_code" style={{ float: "left", width: "114px;" }} value={this.state.button_respond_on_phonecall_country_code} className="form-control" onChange={(e) => { this.onInputChange(e) }}>
                    
                                            {this.props.defaultCountryCodeLists && this.props.defaultCountryCodeLists.map((value, index) => {
                                                return (
                                                    <option value={value.country_code} >{value.country_name}</option>

                                                )
                                            })}

                                            <optgroup label="Other countries">

                                                {this.props.countryCodeLists && this.props.countryCodeLists.map((value, index) => {
                                                    return (
                                                        <option value={value.country_code}  >{value.country_name}</option>

                                                    )
                                                })} </optgroup>
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <input className={
                                            errors && errors['PhoneCallNumber']
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        } autocomplete="off" type="text" id="phonecall" name="button_respond_on_phonecall" placeholder="Add Call No." value={this.state.button_respond_on_phonecall} onChange={(e) => this.onInputChange(e)} />
                                        {errors && errors.hasOwnProperty('PhoneCallNumber') && (
                                            <CustomErrorField
                                                message={errors['PhoneCallNumber']}
                                                className="error invalid-feedback"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Tab.Pane>,
            },
            {
                menuItem: 'SMS',
                render: () => <Tab.Pane attached={false}>
                    <div className="form-group">
                        <label>Add Contact</label>
                        <div className="row">
                            <div className="col-md-6">
                                <select name="button_respond_on_sms_country_code" id="isd_code_for_phone_call" value={this.state.button_respond_on_sms_country_code} style={{ float: "left", width: "114px;" }} className="form-control" onChange={(e) => { this.onInputChange(e) }}>
                                    {this.props.defaultCountryCodeLists && this.props.defaultCountryCodeLists.map((value, index) => {
                                        return (
                                            <option value={value.country_code}>{value.country_name}</option>

                                        )
                                    })}

                                    <optgroup label="Other countries">

                                        {this.props.countryCodeLists && this.props.countryCodeLists.map((value, index) => {
                                            return (
                                                <option value={value.country_code}>{value.country_name}</option>

                                            )
                                        })} </optgroup>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <input type="text" className={
                                    errors && errors['SMS']
                                        ? "form-control is-invalid"
                                        : "form-control"
                                } name="button_respond_on_sms" placeholder="Phone Number" onChange={(e) => this.onInputChange(e)} value={this.state.button_respond_on_sms} />
                                {errors && errors.hasOwnProperty('SMS') && (
                                    <CustomErrorField
                                        message={errors['SMS']}
                                        className="error invalid-feedback"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </Tab.Pane>,
            },

            {
                menuItem: 'Review Capture',
                render: () => <Tab.Pane attached={false}>
                    <div className="form-group mt-10">
                        <div className="row">

                            <div className="col-md-12 col-xs-12">
                                <input type="radio" className="review_capture" id="review_type_first" name="button_respond_on_review" value="1" onChange={(e) => this.onInputChange(e)} checked={this.state.button_respond_on_review == 1 ? true : false} />
                                <span className="review_tb_content marginbt5"> This button will use the review functionality from the review tab.</span>


                            </div>

                        </div>

                        <div className="row">

                            <div className="col-md-12 col-xs-12">
                                <input type="radio" className="review_capture marginbt5" id="review_type_second" name="button_respond_on_review" value="2" onChange={(e) => this.onInputChange(e)} checked={this.state.button_respond_on_review == 2 ? true : false} />
                                <div className={
                                    errors && errors['REVIEW']
                                        ? "form-control is-invalid"
                                        : "form-control"
                                } style={{ display: "none" }}></div>
                                {errors && errors.hasOwnProperty('REVIEW') && (
                                    <CustomErrorField
                                        message={errors['REVIEW']}
                                        className="error invalid-feedback"
                                    />
                                )}
                                <span className="review_tb_content marginbt5" > Skip the location question and capture leads for:</span>
                                <select className={
                                    errors && errors['REVIEWERR']
                                        ? "form-control is-invalid mt-10 reviewselect"
                                        : "form-control mt-10 reviewselect"
                                } name="button_review_location" id="reviewbutton_review_location_location_button" value={this.state.button_review_location} style={{ marginLeft: "10px", display: "block" }} onChange={(e) => this.onInputChange(e)}>
                                    <option value="">Select Location</option>
                                    {this.props.location_list && this.props.location_list.map((value, index) => {
                                        return (
                                            <option value={value.id}>{value.location}</option>
                                        )
                                    })}
                                </select>
                                {errors && errors.hasOwnProperty('REVIEWERR') && (
                                    <CustomErrorField
                                        message={errors['REVIEWERR']}
                                        className="error invalid-feedback"
                                    />
                                )}

                            </div>

                        </div>

                    </div>
                </Tab.Pane>,
            },
            ] : [{
                menuItem: 'Branch',
                render: () => <Tab.Pane attached={false} >
                    <div className="form-group">
                        <label>Go to branch</label>
                        <select className={
                            errors && errors['Branch']
                                ? "form-control is-invalid"
                                : "form-control"
                        } onChange={(e) => this.changeBranch(e)} value={this.state.button_respond_on_branch}>
                            <option value="">Select Branch</option>
                            {this.state.branchList && this.state.branchList.map((value, index) => {
                                return (
                                    <option value={value.branch_id}>{value.branch_name}</option>
                                )
                            })}
                        </select>
                        {errors && errors.hasOwnProperty('Branch') && (
                            <CustomErrorField
                                message={errors['Branch']}
                                className="error invalid-feedback"
                            />
                        )}
                        <a nohref="" class="addTextArea cursorpointer" onClick={() => this.props.createNewBranch('button')}><br />
                            <i class="la la-plus"></i> Create new branch</a>
                    </div>
                </Tab.Pane>,
            },
            {
                menuItem: 'URL',
                render: () => <Tab.Pane attached={false}>
                    <div className="form-group">
                        <label>Open URL</label>
                        <input type="text" className={
                            errors && errors['URL']
                                ? "form-control is-invalid"
                                : "form-control"
                        } defaultValue={this.state.button_respond_on_url} placeholder="Add URL" onChange={(e) => this.onChangeUrlTab(e)} />
                        {errors && errors.hasOwnProperty('URL') && (
                            <CustomErrorField
                                message={errors['URL']}
                                className="error invalid-feedback"
                            />
                        )}
                    </div>
                </Tab.Pane>,
            },
            {
                menuItem: 'Phonecall',
                render: () => <Tab.Pane attached={false}>
                    <div className="form-group">
                        <label>Add Contact</label>

                        <div className="row marginbt5">
                            <div className="col-md-7 col-xs-10"> <div> Enter manual phone number </div></div>
                            <div className="col-md-5 col-xs-2">

                                <input type="radio" className="phone_var" id="button_phone_call_use_input_method" name="button_phone_call_use_input_method" phone_tex_val="" onChange={(e) => this.onSelectPhoneVar(e, 'button_phone_call_use_input_method')} checked={this.state.button_phone_call_use_input_method > 0 ? true : false} />
                                <span></span>

                            </div>
                        </div>
                        <div className={
                            errors && errors['PhoneCall']
                                ? "form-control is-invalid"
                                : "form-control"
                        } style={{ display: "none" }}></div>
                        {errors && errors.hasOwnProperty('PhoneCall') && (
                            <CustomErrorField
                                message={errors['PhoneCall']}
                                className="error invalid-feedback"
                            />
                        )}
                        {this.state.button_phone_call_use_input_method > 0 &&
                            <div className="select_simple_call" style={{ display: "block" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <select name="button_respond_on_phonecall_country_code" style={{ float: "left", width: "114px;" }} value={this.state.button_respond_on_phonecall_country_code} className="form-control" onChange={(e) => { this.onInputChange(e) }}>

                                            {this.props.defaultCountryCodeLists && this.props.defaultCountryCodeLists.map((value, index) => {
                                                return (
                                                    <option value={value.country_code} >{value.country_name}</option>

                                                )
                                            })}

                                            <optgroup label="Other countries">

                                                {this.props.countryCodeLists && this.props.countryCodeLists.map((value, index) => {
                                                    return (
                                                        <option value={value.country_code}  >{value.country_name}</option>

                                                    )
                                                })} </optgroup>
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <input className={
                                            errors && errors['PhoneCallNumber']
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        } autocomplete="off" type="text" id="phonecall" name="button_respond_on_phonecall" placeholder="Add Call No." value={this.state.button_respond_on_phonecall} onChange={(e) => this.onInputChange(e)} />
                                        {errors && errors.hasOwnProperty('PhoneCallNumber') && (
                                            <CustomErrorField
                                                message={errors['PhoneCallNumber']}
                                                className="error invalid-feedback"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        }



                    </div>
                </Tab.Pane>,
            }];
        if (this.props.review_links.length == 0) {
            panes.pop();
        }

        if (this.props.bot_type == 'Voice' || this.props.bot_type=='GEN_VOICE') {
            const voiceItem = ['Branch', 'Phonecall'];
            panes = panes.filter((item) => voiceItem.includes(item.menuItem));
        }
        return (
            <div className="yr-max-height addbutton" style={{ overflow: "visible", position: "relative" }}>
                <div className="yr-chat-round-box yr-closebtn yr-automation-uodate">
                    <button type="button" class="close linear-gradient" onClick={() => this.props.popupClose()}>&#10006;</button>

                    <div className="yr-addButtonBox">
                        <h4>Add New Button</h4>
                        <div className="form-group focusError">
                            <label>Button Name</label>
                            <div>
                                <input type="text"
                                    name='buttontitle'
                                    value={this.state.buttontitle}
                                    onChange={(e) => this.onchange(e)} placeholder="Button Title"
                                    className={
                                        errors && errors['buttontitle']
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }

                                />
                                {errors && errors.hasOwnProperty('buttontitle') && (
                                    <CustomErrorField
                                        message={errors['buttontitle']}
                                        className="error invalid-feedback"
                                    />
                                )}
                            </div>
                            {/* <div className="input-group-append">
                                <span className="input-group-text">
                                    <label className="kt-checkbox kt-checkbox--single chat-checkbox">
                                        <input type="checkbox" checked={false} onChange={(e) => { }} />
                                        <span />
                                    </label>
                                </span>
                                Exclude from button click tracking
                            </div> */}
                            {!['Voice','GEN_VOICE'].includes(this.props.bot_type) &&
                                <div class="form-check" style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                    <input checked={this.state.is_exclude == 1 ? true : false} name='is_exclude' onChange={(e) => this.onchange(e)} style={{ marginTop: 0 }} class="form-check-input kt-checkbox--single chat-checkbox" type="checkbox" value="" id="flexCheckDefault" />
                                    <label style={{ marginTop: 0 }} class="form-check-label" for="flexCheckDefault">
                                    Exclude from button click tracking
                                </label>
                            </div>
                            }
                        </div>
                    </div>
                    <div style={{ display: 'block' }} className="addButtonDiv">
                        <Tab menu={{ secondary: true, pointing: true }} panes={panes} activeIndex={this.state.activeIndex} onTabChange={this.onTabChange} />
                    </div>
                </div>
                <div className="text-right form-group">
                    <button type="reset" class="btn linear-gradient-cancel yr-cancelbtn mg-r" onClick={() => this.props.popupClose()} ><span> Cancel </span></button>
                    <button type="submit" className="btn linear-gradient yr-submitbtn" onClick={() => this.saveButton(this.props.button_action_id)}>Save</button>
                </div>

            </div>
        )
    }
}

export default AddButton



