import React from "react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../../store/Page";
import api from "../../../../../api";

import config from "../../../../../config";
import util from "../../../../../util";
import utilFunc from "../../../../../util_funs";

import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import DialogBoxDetail from "./DialogBoxDetail";
import DialogBoxDetailDelete from "./DialogBoxDetailDelete";
import DialogBoxDetailAddSegment from "./DialogBoxDetailAddSegment";
import DialogBoxDetailActive from "./DialogBoxDetailActive";
import CustomErrorField from "../../../../Form/CustomErrorField";

//import DatatableList from "./dataTables/DatatableList"
import { Message } from "semantic-ui-react";
import './Datasource.css';

class DataSource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: {},
      bucketList: [],
      tab: "tab1",
      bid: "",
      subscriber: 0,
      bucket_name: "",
      contacts: 0,
      modalstatus: false,
      modalstatusDelete: false,
      modalstatusaddSegment: false,
      msg: '',
      segMentmsg: '',
      segMentrulelist: [],
      delmag: '',
      sgr_id: '',
      name_rule: '',
      check1: '',
      check2: '',
      list: [],
      datatype: '',
      poperror: {},
      checkedOnOF: '',
      modalstatusActive: false,
      modalstatuscsv: false,
      uploadCSV: "",
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      heading: [],
      rows: [],
      pval: {},
      checkedProperty: [],
      isEmailError:false,
      emailErrors:[],
     
    };

  }

  componentDidMount() {
    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "Contacts",
      navigationName: "marketing",
      subHeaderTitle: "Rengage",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    this.loadData();

    api.marketing.getWebhooks({}).then((data) => {
      //console.log(data.data);
      this.setState({
        sg_id: data.id,
        sg_status: data.status,
        sg_tittle: data.webTittle,
        sg_url: data.webHookurl
      })

    });

    api.marketing.getSegment({}).then((data) => {
      this.setState({
        segMentdatalist: data.segMentdata,
        datarow: data.datarow,
      });

    });

    this.getSegmentRule();
  }

  // Rule function
  getSegmentRule = () => {

    api.marketing.getSegmentRule({}).then((data) => {
      console.log(data.segMentRuleOption);
      this.setState({
        segMentRuleOption_dataValue: data.segMentRuleOption_dataValue,
        segMentRuleOption_dataValue2: data.segMentRuleOption_dataValue2,
        segMentRuleOption_dataVariables: data.segMentRuleOption_dataVariables

      });

    });

  }


  loadData = () => {
    this.setState({
      loading: false,
    });
  };

  editData = () => {
    //alert('hi');
    this.setState({
      modalstatus: true,
    });
  }

  editSegment = (id, datatype) => {
    //alert(id);
    api.marketing.editSegment({ sg_id: id }).then((data) => {
      console.log(data.segMentrule);
      let sgr_id = data.segMentlist[0].id;
      let name_rule = data.segMentlist[0].name;
      let check1 = data.segMentlist[0].check1;
      let check2 = data.segMentlist[0].check2;
      let user_id = data.segMentlist[0].user_id;
      this.setState({
        modalstatusaddSegment: true,
        segMentrulelist: data.segMentrule,
        getSegBucketlist: data.getSegBucket,
        sgr_id: sgr_id,
        name_rule: name_rule,
        check1: check1,
        check2: check2,
        user_id: user_id,
        datatype: datatype

      });
    });
  }

  segmentData = (datatype) => {
    // When Open
    api.marketing.getAddsegment({}).then((data) => {
      this.setState({
        modalstatusaddSegment: true,
        getSegBucketlist: data.data.getSegBucket,
        name_rule: '',
        check1: '',
        check2: '',
        sgr_id: 0,
        datatype: datatype
        //showrule:0
      });
    });
    this.addMoreOne();
  }

  confirmDeletedata = (id) => {
    //alert('hi');confirmDeletedata
    this.setState({
      modalstatusDelete: true,
      d_id: id
    });
  }
  getSegment = () => {
    api.marketing.getSegment({}).then((data) => {
      this.setState({
        segMentdatalist: data.segMentdata,
        datarow: data.datarow,
      });
    });
  }

  deleteData = (d_id) => {
    //alert(d_id);
    api.marketing.deleteData({ did: d_id }).then((data) => {
      //console.log(data);
      this.setState({
        modalstatusDelete: false,
        delmag: data.delmag
      });
      setTimeout(
        function () {
          this.setState({ delmag: false });
        }.bind(this),
        3000
      );
      this.getSegment();

    });

  }
  modalClose = (flag, fm) => {
    this.setState({
      modalstatus: false,

    });
  };

  modalCloseDelete = (flag, fm) => {
    this.setState({
      modalstatusDelete: false,

    });
  };

  modalCloseAddsegment = (flag, fm) => {
    this.setState({
      modalstatusaddSegment: false,

    });
  };



  changeTittle = (e) => {
    this.setState({
      sg_tittle: e.target.value,

    });
  }


  changeStatus = (e) => {
    this.setState({
      sg_status: e.target.value,
    });
  }

  saveData = (id, title, flag) => {
    //alert(title +'-'+ flag);
    api.marketing.updateWebhooks({ id: id, namewebHooks: title, status: flag }).then((data) => {
      //console.log(data.data);
      this.setState({
        modalstatus: false,
        msg: data.msg
      }); setTimeout(
        function () {
          this.setState({ msg: false });
        }.bind(this),
        4000
      );
    });
  }

  changeRulename = (e) => {
    this.setState({
      name_rule: e.target.value,
    });
  }
  
  changecheck1 = (e) => {
    //console.log(e);

    if (e.target.checked == true) {
      this.setState({
        check1: 'checked'
      });
    } else {
      this.setState({
        check1: ''
      });
    }
  }

  changecheck2 = (e) => {
    if (e.target.checked == true) {
      this.setState({
        check2: 'checked'
      });
    } else {
      this.setState({
        check2: ''
      });
    }
  }
  changeRulename = (e) => {
    this.setState({
      name_rule: e.target.value,
    });
  }


  validateSegmentRule = (action_type) => {
    const errors = [];
    console.log(this.props)
    let name_rule = this.state.name_rule.trim();
    let err_segment = '';
    if (name_rule == "") {

      err_segment = "This field is required.";
      this.setState({ err_segment: err_segment },
      );
      setTimeout(
        function () {
          this.setState({ err_segment: false });
        }.bind(this),
        4000
      );
    }
    new Promise(resolve => {
      console.log(this.state.segMentrulelist)

      this.state.segMentrulelist.forEach((data, key) => {

        if (data.variable_name == '') {

          if (typeof errors.variable_name != 'undefined') {
            errors.variable_name[key] = 'This field is required'

          } else {
            errors.variable_name = { [key]: 'This field is required' }
          }


        }

        if (data.rule_name == '') {


          if (typeof errors.rule_name != 'undefined') {
            errors.rule_name[key] = 'This field is required'

          } else {
            errors.rule_name = { [key]: 'This field is required' }
          }

        }

        // if(data.remark_value==''){

        //   if(typeof errors.remark_value !='undefined'){
        //     errors.remark_value[key]='This field is required'

        //    }else{
        //     errors.remark_value={[key]:'This field is required'}
        //    }
        // }

        if (data.bucket_id == '') {


          if (typeof errors.bucket_id != 'undefined') {
            errors.bucket_id[key] = 'This field is required'

          } else {
            errors.bucket_id = { [key]: 'This field is required' }
          }

        }
        // Start 
        let recordlist = this.state.segMentrulelist.filter(
          (list, typeindex) => {

            if (list.variable_name == data.variable_name && list.bucket_id == data.bucket_id && key != typeindex) {

              return true
              // errors.bucket_id={[key]:'This rule already exist'}

            } else {
              return false;
            }
          }
        );

        if (recordlist != '') {

          //intent_err = true;
          //intent_msg = 'This set od data already exist';
          errors.bucket_id = { [key]: 'This rule already exist' }

        }

        // End 


      })




      resolve(errors);


    }).then((data) => {
      if (Object.keys(data).length > 0) {
        this.setState({ poperror: data }, function () {
          //console.log(this.state.poperror);
        })

        setTimeout(
          function () {
            this.setState({ poperror: false });
          }.bind(this),
          3000
        );
      } else {
        //alert(action_type);
        if (action_type == 'Add') {
          this.addSegmentData();
        } else if (action_type == 'Update') {
          this.updateSegmentData();
        }
      }


    })



  }

  displayEmailErrors=()=>{
    if(this.state.emailErrors.length>0){
      return(<div className="list">{this.state.emailErrors.map((item)=>{
        var msg=(item.err==1) ? 'Email Id is invalid' :'Email Id already exists';
        return(<div class="form-group" style={{paddingLeft: '10px'}}><p style={{marginTop: '10px'}}>{msg}: <b style={{ color: "#de0a0a" }}>{item.email}</b> (Row: <b style={{ color: "#de0a0a" }}>{item.row})</b></p></div>);
      })}</div>);
    }
  }

  addSegmentData = () => {

    let name_rule = this.state.name_rule.trim();
    let err_segment = '';
    if (name_rule == "") {

      err_segment = "This field is required.";
      this.setState({ err_segment: err_segment }); setTimeout(
        function () {
          this.setState({ err_segment: false });
        }.bind(this),
        4000
      );
    }
    else {

      let check1 = (this.state.check1 !== '') ? this.state.check1 : 'unchecked';
      let check2 = (this.state.check2 !== '') ? this.state.check2 : 'unchecked';
      let segOption = this.state.segMentrulelist;
      //let segMentrule = document.querySelector('input[name=variableSelect]').value;
      //console.log(segMentrule);
      api.marketing.addSegmentData({ name: name_rule, check1: check1, check2: check2, segOption: segOption }).then((data) => {
        //console.log(data.data);

        if (data.isFind == 0) {

          this.setState({
            modalstatusaddSegment: false,
            delmag: data.delmag,
            isFind: data.isFind
          }); setTimeout(
            function () {
              this.setState({ delmag: false });
            }.bind(this),
            4000
          );
          this.getSegment();
        } else {

          err_segment = "This tittle already exists.";
          this.setState({ err_segment: err_segment }); setTimeout(
            function () {
              this.setState({ err_segment: false });
            }.bind(this),
            4000
          );

        }
      });
    }

  }
  updateSegmentData = () => {
    let sgr_id = this.state.sgr_id;
    let name_rule = this.state.name_rule.trim();
    let err_segment = '';

    if (name_rule == "") {

      err_segment = "This field is required.";
      this.setState({ err_segment: err_segment }); setTimeout(
        function () {
          this.setState({ err_segment: false });
        }.bind(this),
        4000
      );
    } else {
      let check1 = (this.state.check1 == '') ? 'unchecked' : this.state.check1;
      let check2 = (this.state.check2 == '') ? 'unchecked' : this.state.check2;
      let segOption = this.state.segMentrulelist;
      //let segMentrule = document.querySelector('input[name=variableSelect]').value;
      //console.log(segMentrule);
      api.marketing.updateSegmentData({ sgr_id: sgr_id, name: name_rule, check1: check1, check2: check2, segOption: segOption }).then((data) => {
        //console.log(data.data);
        if (data.isFind == 0) {
          this.setState({
            modalstatusaddSegment: false,
            delmag: data.delmag,
            isFind: data.isFind
          }); setTimeout(
            function () {
              this.setState({ delmag: false });
            }.bind(this),
            4000
          );
          this.getSegment();
        } else {

          err_segment = "This tittle already exists.";
          this.setState({ err_segment: err_segment }); setTimeout(
            function () {
              this.setState({ err_segment: false });
            }.bind(this),
            4000
          );
          this.getSegment();
        }


        //this.getSegment();
      });
    }

  }
  addMoreOne = (user_id) => {
    let list = [];
    var appendlist = {
      added_date: "",
      bucket_id: "",
      remark_value: "0",
      rule_name: "",
      rule_name_id: '',
      status: 1,
      update_date: "",
      user_id: user_id,
      variable_name: ""
    };
    list.push(appendlist);
    this.setState({ segMentrulelist: list, list: list })
    //this.setState({ })
  }
  addMore = (user_id) => {

    //alert('Hi');
    var appendlist = {
      added_date: "",
      bucket_id: "",
      remark_value: "0",
      rule_name: "",
      rule_name_id: '',
      status: "",
      update_date: "",
      user_id: user_id,
      variable_name: ""
    };
    this.state.list.push(appendlist);
    this.setState({ segMentrulelist: this.state.list })
  }

  addMoreUpdate = (sgr_id, user_id) => {

    let list = this.state.segMentrulelist;
    var appendlist = {
      added_date: "",
      bucket_id: "",
      id: "",
      remark_value: "0",
      rule_name: "",
      rule_name_id: sgr_id,
      status: "",
      update_date: "",
      user_id: user_id,
      variable_name: ""
    };
    list.push(appendlist);
    this.setState({ segMentrulelist: list })
  }

  removeSegoptin = (key) => {

    let list = this.state.segMentrulelist;
    //let activeindex = listcaptured.findIndex((list) => list.slug == data.key);
    list.splice(key, 1);
    this.setState({ segMentrulelist: list })
  }

  changeRule = (e, key, v) => {
    if (v == 'variable') {
      let fv = key + '-' + e.target.value;
      let option_amount_spent = key + '-' + 'amount_spent';
      let option_year_home = key + '-year_home_was_built';

      fv = fv.trim();
      option_amount_spent = option_amount_spent.trim();
      //alert( option_amount_spent );
      let showrule = 0;
      if (fv == option_amount_spent) {
        //showrule = 1;
        this.getSegmentRule();

      } else if (fv == option_year_home) {

        this.getSegmentRule();

      } else {

        this.getSegmentRule();
      }

      let list = this.state.segMentrulelist;
      list[key].variable_name = e.target.value;
      this.setState({ segMentrulelist: list, showrule: showrule }, function () {
        let error = this.state.poperror;

        if (typeof error.variable_name != 'undefined' && typeof error.variable_name[key] != 'undefined') {
          delete error.variable_name[key];
          this.setState({ poperror: error })
          setTimeout(
            function () {
              this.setState({ poperror: false });
            }.bind(this),
            3000
          );
        }


      })
      //console.log(this.state.segMentrulelist);
    } else if (v == 'rule') {

      let list = this.state.segMentrulelist;
      list[key].rule_name = e.target.value;
      this.setState({ segMentrulelist: list }, function () {
        let error = this.state.poperror;

        if (typeof error.rule_name != 'undefined' && typeof error.rule_name[key] != 'undefined') {
          delete error.rule_name[key];
          this.setState({ poperror: error })
          setTimeout(
            function () {
              this.setState({ poperror: false });
            }.bind(this),
            3000
          );
        }


      })

    } else if (v == 'remark_value') {

      //alert(  e.target.value );
      let list = this.state.segMentrulelist;
      list[key].remark_value = e.target.value;
      this.setState({ segMentrulelist: list }, function () {
        let error = this.state.poperror;

        if (typeof error.remark_value != 'undefined' && typeof error.remark_value[key] != 'undefined') {
          delete error.remark_value[key];
          this.setState({ poperror: error })
          setTimeout(
            function () {
              this.setState({ poperror: false });
            }.bind(this),
            3000
          );
        }

      })
    }

    else if (v == 'bucket') {

      let list = this.state.segMentrulelist;
      list[key].bucket_id = e.target.value;
      this.setState({ segMentrulelist: list }, function () {
        let error = this.state.poperror;

        if (typeof error.bucket_id != 'undefined' && typeof error.bucket_id[key] != 'undefined') {
          delete error.bucket_id[key];
          this.setState({ poperror: error })
          setTimeout(
            function () {
              this.setState({ poperror: false });
            }.bind(this),
            3000
          );
        }

      })
    }




  }

  modalCloseActive = () => {

    this.setState({
      modalstatusActive: false,
    })
  }

  changeAStatus = (checkedOnOFF, sgid) => {

    //alert( checkedOnOFF + ' - sg_id ' + sgid );
    api.marketing.changeOnOff({ status: checkedOnOFF, sg_id: sgid }).then((data) => {
      //console.log(data.data);
      if (data.status == 0) {
        this.setState({
          modalstatusActive: true,
          status: data.status,
          delmag: data.delmag,
          activeSegmnet: data.activeSegmnet

        })
        this.getSegment();
      } else {
        this.setState({
          modalstatusActive: false,
          status: data.status,
          delmag: data.delmag,
          activeSegmnet: data.activeSegmnet
        })

        this.getSegment();
      }
    });



  }

  //for csv

  importManually = () => {
    //alert('hi');
    this.setState({
      modalstatuscsv: true,
      errors:[],
      uploadCSV:''
    });
    //obj.setState({ errors:false });

  }
  // For csv
  showToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ userToaster: false });
      }.bind(this),
      3000
    );
  };

  onClickNext = (tab) => {
    if (tab == "tab3") {
     // this.setState({ uploadCSV:false });
      var obj = this;
      console.log(obj);
      this.validateForm(this.state, function (errors) {
        obj.setState({ errors });
        if (Object.keys(errors).length == 0) {
          obj.setState({ loading: true });
          var myform = document.getElementById("uploadContacts");
          var formData = new FormData(myform);
              

          const tokenInfo = util.getAuthTokens();
          var xhr = new XMLHttpRequest();
          xhr.open(
            "POST",
            config.apiBaseURL2 + "Segment/uploadcsv",
            true
          );
          xhr.setRequestHeader(
            config.access_token_name,
            tokenInfo.access_token
          );
          xhr.setRequestHeader(
            config.refresh_token_name,
            tokenInfo.refresh_token
          );
          xhr.onreadystatechange = function () {
            if (xhr.readyState == XMLHttpRequest.DONE) {
              const resp = JSON.parse(xhr.response);
              if (resp.isFind == 1) {
                //obj.showToaster('green', 'Data saved successfully.');
                //utilFunc.scrollTop(800);
                let emerr=(resp.emailErrors.length>0) ? true :false;
                obj.setState({
                  loading: false,
                  delmag: resp.delmag,
                  modalstatuscsv: false,
                  isEmailError:emerr,
                  emailErrors:resp.emailErrors
                });
                setTimeout(
                  function () {
                    obj.setState({ delmag:false, modalstatuscsv:false, uploadCSV:false });
                  }.bind(obj),
                  4000
                );
                //obj.getSegment();
              } else {
                obj.setState({
                  loading: false,
                });
                obj.showToaster("red", resp.data);
                utilFunc.scrollTop(800);
              }
            }
          };
          xhr.send(formData);
        } else {
          let scrolltop =
            document.querySelector(".focusError").offsetTop -
            document.body.scrollTop;
          window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
        }
      });
    } else {
      this.setState({
        tab: tab,
      });
    }
  }

  validateForm3 = (data, callback) => {
    if (this.state.checkedProperty.length != this.state.rows.length - 1) {
      this.showToaster("red", "Please categorize each columns.");
      utilFunc.scrollTop(800);
      callback(false);
    } else {
      callback(true);
    }
    // const errors = {};
    // if (data.title=='') {
    //     errors['title'] = "This filed is required.";
    // }
    // callback(errors);
    //return errors;
  };

  validateForm = (data, callback) => {
   console.log(data)
    const errors = {};
    if (data.title == "") {
      errors["title"] = "This filed is required.";
    }
    if (data.uploadCSV == "") {
      errors["uploadCSV"] = "This filed is required.";
    }
    callback(errors);
    //return errors;
  };
   
  addColumn = (action, data) => {
    this.setState({isEmailError:false});
  }


  handleCSV = (event) => {
    event.preventDefault()
    //alert('hi')
    this.setState({ loading: true });
    var myform = document.getElementById("uploadContacts");
    var formData = new FormData(myform);
    console.log(myform)
    console.log(formData)
    const tokenInfo = util.getAuthTokens();
    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      config.apiBaseURL2 + "Segment/chkvalidation",
      true
    );
    xhr.setRequestHeader(config.access_token_name, tokenInfo.access_token);
    xhr.setRequestHeader(config.refresh_token_name, tokenInfo.refresh_token);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        this.setState({ loading: false });
        try {
          const resp = JSON.parse(xhr.response);
          console.log(resp)
          if (resp.status) {
            let errList = this.state.errors;
            delete errList["uploadCSV"];
            this.setState({
              errors: errList,
              uploadCSV: formData.get("uploadCSV").name,
            });
            setTimeout(
              function () {
                this.setState({ delmag: false});
              }.bind(this),
              10
            );
          } else {
            let errList = this.state.errors;
            errList["uploadCSV"] = resp.data.uploadCSV;
            this.setState({ errors: errList, uploadCSV: "" });
          }
        } catch (err) {
          console.log(err);
          let errList = this.state.errors;
          errList["uploadCSV"] = xhr.response;
          this.setState({ errors: errList, uploadCSV: "" });
        }
      }
    };
    xhr.send(formData);
  };

  modalClosecsv = () => {
    this.setState({
      modalstatuscsv: false,

    });
  };


  render() {
    const segrule = this.state.segMentdatalist;
    const confirmDeletedata = this.confirmDeletedata;
    const editSegment = this.editSegment;
    const changeAStatus = this.changeAStatus;
    const { errors } = this.state;
    return (
      <div className="kt-container kt-body  kt-grid kt-grid--ver" id="kt_body">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div class="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-manage-contact2 yr-manageUser-table">
            {this.state.loading && <div className="ui loading form"></div>}
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">

                <div class="yr-mt-10">
                  <div class="bck-btn kt-font-bolder kt-margin-b-20">
                    <Link to={"/marketing/contacts"} >
                      <i
                        aria-hidden="true"
                        class="angle caret icon fas fa-caret-left"
                      ></i>
                      Back to all buckets
                    </Link>
                  </div>
                  {/* <h2>Data Source</h2> */}
                  <div class="clearfix"></div>
                  <br />
                  <div class="row">
                    <div class="col-md-6">
                      <h3 class="sm-b-title">Data Source</h3>
                    </div>
                    <div class="col-md-12" onClick={() => this.importManually()}>
                      <a
                        className="btn linear-gradient  mb--linear__btn yr-add-new float-right cursorpointer"
                      >
                        Import Manually
                      </a>
                    </div>
                    {/*  <div class="col-md-2">
                          <a onClick={this.props.createBucket} href="#" class="btn linear-gradient yr-add-new float-right cursorpointer" data-toggle="modal" data-target="#kt_modal_6"  onClick={() => this.segmentData()}>Add New Data Source</a>
                    </div> */}
                  </div>
                  <div class="clearfix"></div>
                </div>

                <div class="kt-portlet__body kt-margin-t-30">
                  <div class="tab-content yr-manageContacts-2">

                    {this.state.msg != "" ? (
                      <div className="col-lg-12 ui green message del-msg-padding">
                        {this.state.msg}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className="table-responsive">
                      <table class="ui celled table mh-legacyNoti cst-table unstackable first--table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Webhooks URL</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{this.state.sg_tittle}</td>
                            <td>{this.state.sg_url}</td>
                            <td>{this.state.sg_status == "Active" ? 'Active':'Deactive'}</td>
                            <td>
                              <a className="cursorpointer"
                                onClick={() => this.editData(this.state.sg_id)}
                                style={{ color: 'rgb(8, 122, 182)' }}
                              >
                                <i class="far fa-edit"></i>
                              </a>

                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* Model Of DataSource */}
                  <DialogBoxDetail
                    show={this.state.modalstatus}
                    id={this.state.sg_id}
                    title={this.state.sg_tittle}
                    status={this.state.sg_status}
                    modalType={this.state.modalType}
                    modalClose={this.modalClose}
                    changeTittle={this.changeTittle}
                    changeStatus={this.changeStatus}
                    saveData={this.saveData}
                    loading={this.state.loading}
                  />

                  {/* Model Of Delete */}
                  <DialogBoxDetailDelete
                    show={this.state.modalstatusDelete}
                    d_id={this.state.d_id}
                    deleteData={this.deleteData}
                    modalCloseDelete={this.modalCloseDelete}
                    confrimmsg='Are you sure you want to delete it?'
                    loading={this.state.loading}
                  />

                  {/* Start Model Of CSV */}
                  <div
                    className={this.state.modalstatuscsv ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
                    data-backdrop="static"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="staticBackdrop"
                    aria-hidden="true"
                    style={{ display: this.state.modalstatuscsv ? "block" : "none" }}
                  >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      {this.state.loading && <div className="ui loading form"></div>}
                      <div className="modal-content white-bg yr-campaigns-model">
                        <button
                          type="button"
                          className="close linear-gradient"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => this.modalClosecsv()}
                        >
                          X
                        </button>
                        <div className="modal-header border-bottom-0">
                          <h5 className="modal-title pt-0">Import Manually Data Source</h5>
                        </div>
                        <div className="modal-body new-modal-body pb-4">
                          <div class="mb-3">

                            <form
                              id="uploadContacts"
                              name="uploadContactsForm"
                              encType="multipart/form-data"
                              method="post"
                            >
                              <div className="col-lg-12 col-md-12 col-sm-12 focusError">
                                {/* <div>
                                  <span style={{ minWidth: 60, margin: '0' }}>Segmenting rule name</span>

                                </div> */}
                                <p>
                                  Let's import some contacts! Please choose a CSV file and
                                  separate each column with relevant information.&nbsp;For each contact,  in addition to the Email address,  you
                                  can include other properties 
                                  <br />
                                  <br />
                                  <div style={{ color: 'rgb(105 105 105)' }}>(email, first_name, last_name, phone_number, address_line_1,
                                    city, state, zip_code, amount_spent, customer_birthday, date_of_sale, type_Of_Job, customer_type, residential_or_commercial,
                                    pending_estimate, year_home_was_built etc.)</div>
                                  <a href="/assets/Segmenting.csv">
                                    Download our sample CSV file
                                  </a>{" "}
                                  to structure your import.
                                </p>
                              </div>
                              <div className="col-sm-12 yr-mt-20 focusError">
                                <div className="yr-workspace">
                                  <div className="uploadfile">
                                    <label for="file-upload" className="custom-file-upload">
                                      <div>
                                        <i className="fa fa-cloud-upload"></i>
                                      </div>
                                      <div>Browse</div>
                                    </label>
                                    <input
                                      id="file-upload"
                                      name="uploadCSV"
                                      type="file"
                                      onChange={(e) => this.handleCSV(e)}
                                      // onClick={(e) => this.handleCSV(e)}
                                      accept="text/csv"
                                      style={{ display: "none" }}
                                    />
                                  </div>
                                  <p>{this.state.uploadCSV}</p>
                                  {errors && errors.hasOwnProperty("uploadCSV") && (
                                    <div style={{ color: "red" }}>
                                      {errors["uploadCSV"]}
                                    </div>
                                  )}
                                  <div className="yr-mt-20 text-right">
                                    {/* <input
                                      className="btn linear-gradient zy-save-btn width-100"
                                      type="button"
                                      onClick={() => this.onClickNext("tab3")}
                                      value="Continue"
                                    /> */}
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>

                          <div className="md-footer d-flex justify-content-end align-items-center mt-5">
                            <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" data-dismiss="modal">
                              <span style={{ paddingTop: 10, paddingBottom: 10 }} onClick={() => this.modalClosecsv()}>Cancel</span></button>
                            <button type="button" className="ml-2 btn linear-gradient yr-submitbtn btn-sm" onClick={() => this.onClickNext("tab3")}>Continue</button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  {/* End Model Of CSV */}



                  {/* Model Of Active */}
                  <DialogBoxDetailActive
                    show={this.state.modalstatusActive}
                    modalCloseActive={this.modalCloseActive}
                    activeSegmnet={this.state.activeSegmnet}
                    loading={this.state.loading}
                  />

                  {/* Model Of Add Segment */}
                  <DialogBoxDetailAddSegment
                    show={this.state.modalstatusaddSegment}
                    sgr_id={this.state.sgr_id}
                    name_rule={this.state.name_rule}
                    check1={this.state.check1}
                    check2={this.state.check2}
                    user_id={this.state.user_id}
                    segMentrulelist={this.state.segMentrulelist}
                    getSegBucketlist={this.state.getSegBucketlist}
                    datatype={this.state.datatype}
                    showrule={this.state.showrule}
                    err_segment={this.state.err_segment}
                    segMentRuleOption_dataValue={this.state.segMentRuleOption_dataValue}
                    segMentRuleOption_dataValue2={this.state.segMentRuleOption_dataValue2}
                    segMentRuleOption_dataVariables={this.state.segMentRuleOption_dataVariables}
                    modalCloseAddsegment={this.modalCloseAddsegment}
                    addSegmentData={this.addSegmentData}
                    updateSegmentData={this.updateSegmentData}
                    changeRulename={this.changeRulename}
                    changecheck1={this.changecheck1}
                    changecheck2={this.changecheck2}
                    changeRule={this.changeRule}
                    addMoreUpdate={this.addMoreUpdate}
                    removeSegoptin={this.removeSegoptin}
                    addMore={this.addMore}
                    loading={this.state.loading}
                    validateSegmentRule={this.validateSegmentRule}
                    validateSegmentRuleUpdate={this.validateSegmentRuleUpdate}
                    errors={this.state.poperror}
                  />
                </div>

                 {/* email errors */}

            <div
              className={
                this.state.isEmailError ? "modal fade show" : "modal fade"
              }
              data-backdrop="static"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="staticBackdrop"
              aria-hidden="true"
              style={{ display: this.state.isEmailError ? "block" : "none" }}
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
                style={{ maxWidth: 600 }}
              >
                {this.state.dialogLoading && (
                  <div className="ui loading form"></div>
                )}
                <div className="modal-content">
                  <button
                    type="button"
                    className="close linear-gradient"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => this.addColumn("Close1", "")}
                  >
                    X
                  </button>
                  <div className="modal-header">
                    <h5 className="modal-title">CSV Errors</h5>
                  </div>
                  <div className="modal-body">
                  <div class="kt-section__body" style={{maxHeight:'500px',overflow:'auto'}}>
                      {this.displayEmailErrors()}
                  </div>
                  </div>
                  
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                        onClick={(e) => this.addColumn("Close1", "")}
                        data-dismiss="modal"
                      >
                        <span>Cancel</span>
                      </button>
                      
                    </div>
                
</div>
                </div>
              </div>
            

{/* end email errors */}

                <br />
                <br />
                <div class="row">
                  <div class="col-md-10">
                    <h3 class="sm-b-title">Data Segmenting Rule</h3>
                  </div>
                  <div class="col-md-2">
                    <a onClick={this.props.createBucket} href="#" class="btn mb--linear__btn linear-gradient yr-add-new float-right cursorpointer" data-toggle="modal" data-target="#kt_modal_6" onClick={() => this.segmentData(3)}>Add Segmenting Rule</a>
                  </div>
                </div>

                <div class="kt-portlet__body kt-margin-t-30">
                  <div class="tab-content yr-manageContacts-2">

                    {this.state.delmag != "" ? (
                      <div className="col-lg-12 ui green message del-msg-padding">
                        {this.state.delmag}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className="table-responsive">
                      <table class="ui celled table mh-legacyNoti cst-table table-col-fix unstackable second--table">
                        <thead>
                          <tr>
                            <th>Rule Name</th>
                            <th>Variable</th>
                            <th>Rule</th>
                            <th>Bucket</th>
                            {/* <td>Remark</td> */}
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        {this.state.datarow != "" ? (
                          <tbody>
                            <tr>
                              <td colspan={6}>
                                <p className="text-center mt-0">
                                  {this.state.datarow}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {this.state.segMentdatalist &&
                              Object.keys(segrule).map(
                                function (key) {
                                  return (
                                    <tr key={key}>
                                      <td style={{ whiteSpace: 'pre' }}>{segrule[key].name}</td>
                                      {segrule[key].variable_name != null ? (

                                        <td>{segrule[key].variable_name}, <a className="cursorpointer" style={{ color: 'rgb(8, 122, 182)' }} onClick={() => editSegment(segrule[key].id, 2)}>Show More</a></td>
                                      ) : (<td>No rule set</td>)
                                      }

                                      {segrule[key].rule_name != null ? (
                                        <td>{segrule[key].rule_name}, <a className="cursorpointer" style={{ color: 'rgb(8, 122, 182)' }} onClick={() => editSegment(segrule[key].id, 2)}>Show More</a></td>
                                      ) : (<td>No rule set</td>)
                                      }
                                      <td>{(segrule[key].title !== null) ? segrule[key].title : 'No bucket selected'}</td>
                                      {/* <td>{segrule[key].remark_value}</td> */}
                                      <td>{segrule[key].checkedOnOFF == 'Activate' ? (<span>Active</span>) : (<span>Inactive</span>)}</td>
                                      <td>
                                        <a href="#"
                                          className="btn btn-icon dropdown-toggle"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                          style={{ color: "rgb(8, 122, 182)", marginTop: -8 }}
                                        >Manage
                                        </a>

                                        <div className="dropdown-menu dropdown-menu-right yr-custom-menu">
                                          <ul>
                                            <li>
                                              <a
                                                className="cursorpointer"
                                                onClick={() => editSegment(segrule[key].id, 3)}
                                              >
                                                <span>Edit</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="cursorpointer"
                                                onClick={() => confirmDeletedata(segrule[key].id)}
                                              >
                                                <span>Delete</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="cursorpointer"
                                                onClick={(e) => changeAStatus(segrule[key].checkedOnOFF, segrule[key].id)}
                                              >
                                                {segrule[key].checkedOnOFF == 'Activate' ? (<span>Deactivate</span>) : (<span>Activate</span>)}

                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>
                        )}
                      </table>
                    </div>
                    {/*  <DatatableList
                      activetab={this.state.activetab}
                      indextime={new Date()}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(DataSource);

