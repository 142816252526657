import axios from "../../config/axios";

const keep = {
  list: (params) =>
    axios
      .post("/integration/keep/list", params)
      .then((resp) => (resp ? resp.data : null)),

  locationList: (params) =>
    axios
      .post("/integration/keep/locations", params)
      .then((resp) => (resp ? resp.data : null)),

  createNew: (params) =>
    axios
      .post("/integration/keep/create_new", params)
      .then((resp) => (resp ? resp.data : null))
      .catch((err) => err),

  getKeepCredentionalInfo: (params) =>
    axios
      .post("/integration/keep/getKeepCredentionalInfo", params)
      .then((resp) => (resp ? resp.data : null)),

  deleteKeepCredential: (params) =>
    axios
      .post("/integration/keep/delete_credentials", params)
      .then((resp) => (resp ? resp.data : null)),
};

export default keep;
