import React, { useState } from "react";
import {
	Form,Dropdown
} from 'formsy-semantic-ui-react';
import {Message } from 'semantic-ui-react';
import validationRule from "../../../../validation";
import "../../../Modal/dialog.css";


const DialogBoxDetail = ({ obj, show, title, message, modalType,modalClose,buttonmsg,modalbuttofun,modaldata}) => {
	let accessModuleList = obj.accessModuleList;
	
	
	
	
	return (
		<React.Fragment>
			<div
				className={show ? "modal fade show" : "modal fade"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:600}}>
					<div className="modal-content">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalClose(false, '')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
						</div>
						{(obj.modalmessage=="tree")?
						""
							:<div style={{marginLeft:33,marginTop:13}}>{obj.modalmessage}</div>}
						<div className="modal-footer">
							<button type="button" onClick={() => modalClose(false, '')} style={{ display: buttonmsg.cancel != '' ? "block" : "none" }} className="yr-cancelbtn btn-sm linear-gradient-cancel yr-btn-small" data-dismiss="modal">
								<span>{buttonmsg.cancel}</span>
							</button>
							<button type="button" onClick={() => modalbuttofun({ modaldata })} style={{ display: buttonmsg.submit != '' ? "block" : "none" }} className="btn linear-gradient yr-submitbtn btn-sm">
								{buttonmsg.submit}
							</button>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxDetail;
