import React from "react";
import { Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import util_funs from "../../../../../util_funs";
function createMarkup(html) {
  return { __html: html };
}

export const DatatableRow = (props) => (
  <Table.Row>
    {/* <Table.Cell>
      <div className="yr-ep-icon">
        <a href="#"  class="btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="flaticon-more"></i></a>
      <div className="dropdown-menu dropdown-menu-right yr-custom-menu">
        <ul>
          <li>
            <a className="cursorpointer" onClick={()=>props.bucketAction('edit',props.list.bid)}>
              <span>Edit Name</span>
            </a>
          </li>
          <li>
            <a className="cursorpointer" onClick={()=>props.bucketAction('delete',props.list.bid)}>
              <span>Delete</span>
            </a>
          </li>
        </ul>
      </div>
       <div className="kt-form__label">
        <label className="kt-checkbox">
          <input type="checkbox" />
          <span></span>
        </label>

      </div> 
      </div>
    </Table.Cell> */}
    <Table.Cell style={{ textAlign: "left" }}>
      <div className="yr-ep-icon">
        <a
          href="#"
          class="btn-icon"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="flaticon-more"></i>
        </a>
        <div className="dropdown-menu dropdown-menu-right yr-custom-menu">
          <ul>
            <li>
              <a
                className="cursorpointer"
                onClick={() => props.bucketAction("edit", props.list.bid)}
              >
                <span>Edit Name</span>
              </a>
            </li>
            <li>
              <a
                className="cursorpointer"
                onClick={() => props.bucketAction("delete", props.list.bid)}
              >
                <span>Delete</span>
              </a>
            </li>
          </ul>
        </div>
        {/* <div className="kt-form__label">
        <label className="kt-checkbox">
          <input type="checkbox" />
          <span></span>
        </label>

      </div> */}
      </div>
      <div style={{ display: "inline-block" }}>
        <span
          style={{ textAlign: "left" }}
          className="blue b t-sm cursorpointer"
        >
          <Link
            className="blue"
            to={"/marketing/contactlist/" + util_funs.encrypt_url(props.list.bid)}
          >
            {props.list.title}
          </Link>
        </span>
        <span>Created {props.list.added_datetime}</span>
      </div>
    </Table.Cell>
    <Table.Cell>
      <span>{props.list.bucket_type === 1 ? 'Email Bucket' 
      : props.list.bucket_type === 2 ? 'Postcard Bucket' 
      : props.list.bucket_type === 3 ? 'SMS Bucket' 
      : props.list.bucket_type === 4 ? 'Email & Postcard Bucket' 
      : props.list.bucket_type === 5 ? 'Email & SMS Bucket' 
      : props.list.bucket_type === 5 ? 'Email & SMS Bucket' 
      : props.list.bucket_type === 6 ? 'Postcard & SMS Bucket' 
      : 'Email,Postcard & SMS Bucket'
        }</span>
    </Table.Cell>
    <Table.Cell>
      <span className="b">{props.list.contacts}</span>
      <span>Contacts</span>
    </Table.Cell>
    {/* <Table.Cell>
      <span className="b">{props.list.subscriber}</span>
      <span>Subscribers</span>
    </Table.Cell> */}

    <Table.Cell>
      <a
        className="btn btn-icon"
        onClick={() => props.addContact(props.list.bid,props.list.bucket_type)}
      >
        <i className="fa fa-user"></i>
      </a>
    </Table.Cell>
  </Table.Row>
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
