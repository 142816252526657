import React from "react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../store/Page";
import "../../../Modal/dialog.css";
import api from "../../../../api";
import config from "../../../../config";
import util from "../../../../util";
import utilFunc from "../../../../util_funs";
import { Message } from "semantic-ui-react";
import { connect } from "react-redux";

import "../../Marketing/yr-style.css";

import { render } from "react-dom";
// import {GEditor} from 'grapesjs-react';
// import 'antd/dist/antd.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

// import { CKEditor } from '@ckeditor/ckeditor5-react';

// import InlineEditor from '@ckeditor/ckeditor5-build-inline';

class testEditor extends React.Component {
  content = `<div class="simple-block"> This is a simple block </div>`;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      emailEditorRef: null,
    };
    this.editor = window.editor;
  }

  exportHtml = () => {
    this.state.emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log("exportHtml", html);
    });
  };

  onLoad = () => {
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  componentDidMount() {
    // var body = document.body,
    //     html = document.documentElement;

    // var height = Math.max( body.scrollHeight, body.offsetHeight,
    //                        html.clientHeight, html.scrollHeight, html.offsetHeight );

    //                       height = height-155

    var editor1 = this.editor.init({
      container: "#gjs",
      height: "700px",
      plugins: ["gjs-preset-newsletter"],
      pluginsOpts: {
        "gjs-preset-newsletter": {
          modalTitleImport: "Import template",
          // ... other options
        },
      },
      assetManager: {
        assets: [
          "http://placehold.it/350x250/78c5d6/fff/image1.jpg",
          // Pass an object with your properties
          {
            type: "image",
            src: "http://placehold.it/350x250/459ba8/fff/image2.jpg",
            height: 350,
            width: 250,
          },
          {
            // As the 'image' is the base type of assets, omitting it will
            // be set as `image` by default
            src: "http://placehold.it/350x250/79c267/fff/image3.jpg",
            height: 350,
            width: 250,
          },
        ],
        // Default assets
        // eg. [
        //  'https://...image1.png',
        //  'https://...image2.png',
        //  {type: 'image', src: 'https://...image3.png', someOtherCustomProp: 1},
        //  ..
        // ]

        // Content to add where there is no assets to show
        // eg. 'No <b>assets</b> here, drag to upload'
        noAssets: "",

        // Upload endpoint, set `false` to disable upload
        // upload: 'https://endpoint/upload/assets',
        // upload: false,
        upload: "https://endpoint/upload/assets",

        // The name used in POST to pass uploaded files
        uploadName: "files",

        // Custom headers to pass with the upload request
        headers: {},

        // Custom parameters to pass with the upload request, eg. csrf token
        params: {},

        // The credentials setting for the upload request, eg. 'include', 'omit'
        credentials: "include",

        // Allow uploading multiple files per request.
        // If disabled filename will not have '[]' appended
        multiUpload: true,

        // If true, tries to add automatically uploaded assets.
        // To make it work the server should respond with a JSON containing assets
        // in a data key, eg:
        // {
        //  data: [
        //    'https://.../image.png',
        //    ...
        //    {src: 'https://.../image2.png'},
        //    ...
        //  ]
        // }
        autoAdd: 1,

        // Text on upload input
        uploadText: "Drop files here or click to upload",

        // Label for the add button
        addBtnText: "Add image",

        // Custom uploadFile function
        // @example
        // uploadFile: (e) => {
        //   var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        //   // ...send somewhere
        // }
        uploadFile: "",

        // Handle the image url submit from the built-in 'Add image' form
        // @example
        // handleAdd: (textFromInput) => {
        //   // some check...
        //   editor.AssetManager.add(textFromInput);
        // }
        handleAdd: "",

        // Enable an upload dropzone on the entire editor (not document) when dragging
        // files over it
        dropzone: 1,

        // Open the asset manager once files are been dropped via the dropzone
        openAssetsOnDrop: 1,

        // Any dropzone content to append inside dropzone element
        dropzoneContent: "",

        // Default title for the asset manager modal
        modalTitle: "Select Image",
      },
    });

    var mdlClass = "gjs-mdl-dialog-sm";
    var pnm = this.editor.editors[0].Panels;
    var cmdm = this.editor.editors[0].Commands;
    var blockManager = this.editor.editors[0].BlockManager;

    var testContainer = document.getElementById("test-form");
    var contentEl = testContainer.querySelector("input[name=body]");
    var md = this.editor.editors[0].Modal;
    cmdm.add("send-test", {
      run(editor, sender) {
        sender.set("active", 0);
        var modalContent = md.getContentEl();
        var mdlDialog = document.querySelector(".gjs-mdl-dialog");
        var cmdGetCode = cmdm.get("gjs-get-inlined-html");
        contentEl.value = cmdGetCode && cmdGetCode.run(editor);
        mdlDialog.className += " " + mdlClass;
        testContainer.style.display = "block";
        md.setTitle("Test your Newsletter");
        md.setContent("");
        md.setContent(testContainer);
        md.open();
        md.getModel().once("change:open", function () {
          mdlDialog.className = mdlDialog.className.replace(mdlClass, "");
          //clean status
        });
      },
    });

    pnm.addButton("options", {
      id: "send-test",
      className: "fa fa-paper-plane",
      command: "send-test",
      attributes: {
        title: "Test Newsletter",
        "data-tooltip-pos": "bottom",
      },
    });

    blockManager.add("the-row-block", {
      label: "2 Columns",
      content:
        '<div class="row" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row">' +
        '<div class="row-cell" data-gjs-draggable=".row"></div>' +
        '<div class="row-cell" data-gjs-draggable=".row"></div>' +
        "</div>",
    });

    // this.editor.editors[0].BlockManager.getAll().reset();

    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "Contacts",
      navigationName: "marketing",
      subHeaderTitle: "Automated Marketing",
    });
    toggleNavigation({ toggle: false });
    toggleBreadcrum({ toggle: false });
    document.getElementById("kt_header_mobile").style.display = "none";
    document.getElementById("kt_header").style.display = "none";

    var x = document.querySelectorAll("body");
    x[0].style.setProperty("padding-top", "0px", "important");
    // document.getElementsByClassName("body.kt-header--fixed").classList.add("my-class");;
  }

  render() {
    const { errors } = this.state;

    return (
      <div
        className="kt-container kt-body  kt-grid kt-grid--ver completedchatmain"
        id="kt_body"
        style={{ margin: "0px", marginLeft: "10px", marginRight: "10px" }}
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className=" yr-manage-contact">
            <div>
              <div className="row">
                <div width="100%" height="700px" id="gjs"></div>

                {/* <CKEditor
                    editor={ InlineEditor }
                    data="<p>Hello from CKEditor 5!</p>"
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};

export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(testEditor);
