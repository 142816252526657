import axios from "../config/axios";
import config from "../config/index";
const playbookdetail = {
  getPlaybookDetails: (params) => {
    console.log(params);
    return axios
      .post(`${config.apiBaseURL2}playbookDetail`, params)
      .then((resp) => {
        console.log(resp.data);
        return resp.data;
      })
      .catch((err) => console.log(err));
  },

  getDomainEmailList: () =>
    axios
      .get(`${config.apiBaseURL2}domainEmailList`, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

      getDomainTextList: () =>
    axios
      .get(`${config.apiBaseURL2}domainTextsList`, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
      

  /* savePlaybookTemplate: (params) =>
    axios
      .post(`/marketing/PlaybookDetail/savePlaybookTemplate`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)), 
  */

  // api to create or edit branch of a playbook
  savePlaybookTemplate: (params) =>
    axios
      .post(
        `${config.apiBaseURL2}savePlaybookTemplate`,
        params
      )
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

    saveSmsPlaybookTemplate: (params) =>
      axios
        .post(
          `${config.apiBaseURL2}saveSmsPlaybookTemplate`,
          params
        )
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),  

  /* deletePlaybookTemplate: (params) =>
    axios
      .post(`/marketing/PlaybookDetail/deletePlaybookTemplate`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)), */

  checkRuleForExistance:(params)=>
    axios
      .post(`${config.apiBaseURL2}checkRuleForExistance`,params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  deletePlaybookTemplate: (params) =>
    axios
      .post(
        `${config.apiBaseURL2}deletePlaybookTemplate`,
        params
      )
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  copyPlaybookTemplate: (params) =>
    axios
      .post(`/marketing/PlaybookDetail/copyPlaybookTemplate`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  sendPreviewTest: (params) =>
    axios
      .post(`/marketing/PlaybookDetail/sendPreviewTest`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  uploademailtemp: (params) =>
    axios
      .post(`/marketing/PlaybookDetail/uploademailtemp`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  saveeditor: (params) =>
    axios
      .post(`/marketing/PlaybookDetail/saveeditor`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  updateeditor: (params) =>
    axios
      .post(`/marketing/PlaybookDetail/updateeditor`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  openEditor: (params) =>
    axios
      .post(`${config.apiBaseURL2}openEditor`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  openRules: (params) =>
    axios
      .post(`${config.apiBaseURL2}openRules`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  saveRules: (params) =>
    axios
      .post(`${config.apiBaseURL2}saveRules`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  saveSeasonalRules: (params) =>
    axios
      .post(`${config.apiBaseURL2}saveRules`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  /* updateSendEmail: (params) =>
    axios
      .post(`/marketing/PlaybookDetail/updateSendEmail`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)), */

  updateSendEmail: (params) =>
    axios
      .post(`${config.apiBaseURL2}updateSendEmail`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

      updateSendPhone: (params) =>
      axios
        .post(`${config.apiBaseURL2}updateSendPhone`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),

  updateNeweditor: (params) =>
    axios
      .post(`${config.apiBaseURL2}updateNeweditor`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  saveNeweditor: (params) =>
    axios
      .post(`${config.apiBaseURL2}saveNewEditor`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

      saveNewMmseditor: (params) =>
      axios
      .post(`${config.apiBaseURL2}saveNewSmsEditor`, params,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

      saveNewSmseditor: (params) =>
      axios
      .post(`${config.apiBaseURL2}saveNewSmsEditor`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  /* copyPlaybookNewTemplate: (params) =>
    axios
      .post(`/marketing/PlaybookDetail/copyPlaybookNewTemplate`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)), 
  */

  //api to copy branch of a playbook
  copyPlaybookNewTemplate: (params) =>
    axios
      .post(
        `${config.apiBaseURL2}copyPlaybookNewTemplate`,
        params
      )
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  sentEmailtemplate: (params) =>
    axios
      // .post(`/marketing/PlaybookDetail/sentEmailtemplate`, params)
      .post(`${config.apiBaseURL2}sentEmailtemplate`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  loadDirectEmailRules: (params) =>
    axios
      .post(`${config.apiBaseURL2}loadDirectEmailRules`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  openTemplateFooter: (params) =>
    axios
      .post(`/marketing/PlaybookDetail/openTemplateFooter`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  updateContentNewEditorFooter: (params) =>
    axios
      .post(`/marketing/PlaybookDetail/updateContentNewEditorFooter`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getPostCardContent: (params) =>
    axios
      .post(`/marketing/PlaybookDetail/getPostCardContent`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getAltValue: (params) =>
    axios
      .post(`${config.apiBaseURL2}getAltValue`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  saveAltValue: (params) =>
    axios
      .post(`${config.apiBaseURL2}saveAltValue`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  /* updateposition: (params) =>
    axios
      .post(`/marketing/PlaybookDetail/updateposition`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)), */

  updateposition: (params) =>
    axios
      .post(`${config.apiBaseURL2}updateposition`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getSendingQueuelist: (params) =>
    axios
      .get(`${config.apiBaseURL2}getSendingQueuelist?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  confirmDeleteEntry: (params) =>
    axios
      .post(`/marketing/PlaybookDetail/confirmDeleteEntry`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  getTempplateButton: (params) =>
    axios
      .post(`${config.apiBaseURL2}getTempplateButton`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  resetRules: (params) =>
    axios
      .post(`${config.apiBaseURL2}removeRule`, params)
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),
};

export default playbookdetail;
