import React,{useEffect, useState} from "react";
import faqclasses from "./FAQs.module.css";
export default function ConversationGoal( props ){
    const handleConversationGoal = ( event ) => {
        props.setValue(event.target.value,"conversationGoal");
    }
    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="box-title">This is overview and overall goal of the conversation flow:</div>
                </div>
                <div className="col-md-8">
                   <textarea className={`${faqclasses.conversationText}`} onChange={handleConversationGoal}
                   value={props.conversationData} default={props.conversationData}>
                    {props.conversationData}
                   </textarea>
                </div>
            </div>
        </>
    )
}