import React from "react";
import {
    Form, Dropdown, TextArea
} from 'formsy-semantic-ui-react';
import { Message } from 'semantic-ui-react';
import validationRule from "../../../../validation";
import "../../../Modal/dialog.css";
import './Popup.css';
import 'semantic-ui-css/semantic.min.css';
import { addValidationRule } from "formsy-react";
import axios from "../../../../config/axios";
import api from "../../../../api";

validationRule.isRequired();
validationRule.usPhoneLength();
class DialogBoxSetNotificationAndMiscSetting extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: props.obj.modalstatusNotiAandMisc,
            title: props.modaltitle,
            modalFormLoader: props.modalFormLoader,
            modalstatusNotiAandMiscData: props.modalstatusNotiAandMiscData,
            message1: "none",
            color1:"green",
            mgs1:"Setting has been saved successfully.",
            message2: "none",
            message3: "none",
            memberLogo: "",
            imgError: "none",
            loading: "none",
            isd_code: "",
            jumpinMsg: "",
            switchValue1:"",
            switchValue2:"",
            switchValue3:"",
            switchValue4:"",
            switchValue5:"",
            switchValue6:"",
            switchValue7:"",
            switchValue8:"",
            pin:"",
            cpin:""
        }


        let modalstatusNotiAandMiscData = this.props.modalstatusNotiAandMiscData;

    }

    handleCompanyLogo = (event) => {
        // let newState = this.state.customizeinfo; 
        let imageMIME = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
        if (imageMIME.includes(event.target.files[0].type) == true && event.target.files[0].type != '') {
            // setImgError("none")
            var companyLogo = URL.createObjectURL(event.target.files[0])
            this.setState({
                memberLogo: companyLogo,
                imgError: "none"
            })
        } else {
            this.setState({
                imgError: "block"
            })
        }
    }

    handleCompanyLogoRemove = (event) => {
        this.setState({
            memberLogo: "",
            imgError: "none"
        })
    }
    changeSwitch = (e) => {
        var val = "";
        
        if(e.target.value=='ON'){
            val = "OFF";
        } else{
            val = "ON";
        }
        if(e.target.name=="is_recieve_text_message_on_livechat"){
            this.setState({
                switchValue1: val,
            })
        }
        else if(e.target.name=="is_recieve_email_on_livechat"){
            this.setState({
                switchValue2: val,
            })
        }
        else if(e.target.name=="is_recieve_text_message_on_completechat"){
            this.setState({
                switchValue3: val,
            })
        }
        else if(e.target.name=="is_recieve_email_on_completechat"){
            this.setState({
                switchValue4: val,
            })
        }
        else if(e.target.name=="is_leave_note"){
            this.setState({
                switchValue5: val,
            })
        }
        else if(e.target.name=="is_recieve_notification_on_confuse"){
            
            this.setState({
                switchValue6: val,
            })
        }
        else if(e.target.name=="is_recieve_notification_on_human"){
            this.setState({
                switchValue7: val,
            })
        }  else if(e.target.name=="is_revieve_notification_incompleted_chat"){
            this.setState({
                switchValue7: val,
            })
        }
        
        this.onValidSubmitSwitch(e.target.name,val)
    }
    
    
    componentDidMount = () => {
    }

    onValidSubmitSwitch = (which,value) => {
        this.setState({
            loading: "block"
        })
        let id = this.props.obj.notiAndSettingmodalData.id;
        api.manageUser
            .teamMemberProfileSwitchSave({which:which,value:value,id:id})
            .then((resp) => {
                if(value=='ON')
                    value = "1";
                else
                    value = "0";
                this.props.obj.notiAndSettingmodalData[which] = value;
                
                this.props.onValidSubmitSwicth(this.props.obj.notiAndSettingmodalData, this.props.obj.notiAndSettingmodalData.id)
                if(which=="is_leave_note" || which=='is_revieve_notification_incompleted_chat'){
                    this.setState({
                        loading: "none",
                        message3:"block"
                    })
                    setTimeout(function(){
                        ref.setState({
                            message3:"none"
                        })
                    },5000)
                } else {
                    this.setState({
                        loading: "none",
                        message2:"block"
                    })
                    setTimeout(function(){
                        ref.setState({
                            message2:"none"
                        })
                    },5000)
                }
                
                var ref = this;
                
            })
            .catch((e) => {
                console.log(e);
            });

    }

    renderDropDownDefault = (defaultCountryCodeArr, countryId = null) => {
        return defaultCountryCodeArr.map((option, index) => {
            return (
                <option key={index} value={option.country_code} selected={countryId == option.country_code ? 'selected' : ''} >
                    {option.country_name}
                </option>
            );
        });
    }

    renderDropDownRemaining = (remailCountryCodeArr, countryId = null) => {

        return remailCountryCodeArr.map((option, index) => {
            return (
                <option key={index} value={option.country_code} selected={countryId == option.country_code ? 'selected' : 'qqq'} >
                    {option.country_name}
                </option>
            );
        });
    }

    handleChangeISD = (e) => {
        this.setState({
            isd_code: e.target.value
        })
        // setIsd(e.target.value)
    }



    phoneFormat = (p) => {
        p = p.replace(/[^\d]*/gi, "");
        if (p.length <= 3) {
            return p;
        } else if (p.length > 3 && p.length < 7) {
            p = "(" + p;
            let l30 = p.length;
            let p30 = p.substring(0, 4);
            p30 = p30 + ")";

            let p31 = p.substring(4, l30);
            let pp = p30 + p31;
            return pp;
        } else if (p.length >= 7) {
            p = "(" + p;
            let l30 = p.length;
            let p30 = p.substring(0, 4);
            p30 = p30 + ")";

            let p31 = p.substring(4, l30);
            let pp = p30 + p31;

            let l40 = pp.length;
            let p40 = pp.substring(0, 8);
            p40 = p40 + "-";

            let p41 = pp.substring(8, l40);
            let ppp = p40 + p41;
            let maxphonelength = 13;
            let finalPhone = ppp.substring(0, maxphonelength);
            return finalPhone;
        }
    };
    handlePhoneChange = (e) => {
        let phone1 = e.target.value;
        let p = this.phoneFormat(phone1)
        setTimeout(function () {
            document.getElementById("phoneNumber").value = p;
        }, 100)
    };

    onValidSubmitFirstCol = (e) => {
        this.setState({
            loading: "block"
        })
        var myform = document.getElementById("manageNotification");
        var formData = new FormData(myform);
        formData.append('id', this.props.obj.notiAndSettingmodalData.id);
        api.manageUser
            .teamMemberProfileSetting(formData)
            .then((resp) => {

                if(resp.data.status==true){
                    if (this.state.isd_code != "")
                        this.props.obj.notiAndSettingmodalData.isd = this.state.isd_code;

                    var phoneNumber = document.getElementById("phoneNumber").value;
                    this.props.obj.notiAndSettingmodalData.phone = phoneNumber;
                    if (resp.data.imgName !== null && resp.data.imgName !== "")
                        this.props.obj.notiAndSettingmodalData.image = resp.data.imgName;
                    this.props.onValidSubmitFirstCol(this.props.obj.notiAndSettingmodalData, this.props.obj.notiAndSettingmodalData.id)
                    this.setState({
                        loading: "none",
                        message1:"block",
                        mgs1:"Setting has been saved successfully.",

                    })
                    var ref = this;
                    setTimeout(function(){
                        ref.setState({
                            message1:"none"
                        })
                    },5000)
                } else {
                    if(resp.data.message=="duplicate_phone"){
                        var msg = "The phone number you changed is already associted with other account.";
                    }
                    this.setState({
                        loading: "none",
                        message1:"block",
                        color1:"red",
                        msg1:msg
                    })
                    var ref = this;
                    setTimeout(function(){
                        ref.setState({
                            message1:"none",
                            color1:"green"

                        })
                    },5000)
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }
    notiAndMiscmodalClose = () => {
        this.setState({
            memberLogo: "",
            imgError: "none",
            message1: "none",
            color1: "green",
            mgs1:"Setting has been saved successfully.",
            message2: "none",
            message3: "none",
            loading: "none",
            isd_code: "",
            jumpinMsg: "",
            switchValue1:"",
            switchValue2:"",
            switchValue3:"",
            switchValue4:"",
            switchValue5:"",
            switchValue6:"",
            switchValue7:"",
            switchValue8:"",
        })
        this.props.notiAndMiscmodalClose(false, 'manageNotification')
    }
    random = () => {
        return (
            Math.random()
        )
    }

    onValidSubmitSecondCol = () => {
        this.setState({
            loading: "block"
        })
        var myform = document.getElementById("manageNotificationSecond");
        var formData = new FormData(myform);
        formData.append('id', this.props.obj.notiAndSettingmodalData.id);
        api.manageUser
            .teamMemberProfileSettingSecond(formData)
            .then((resp) => {
                if (this.state.jumpinMsg != ""){
                    this.props.obj.notiAndSettingmodalData.jumpin_message = this.state.jumpinMsg;
                    this.props.onValidSubmitSecondCol(this.props.obj.notiAndSettingmodalData, this.props.obj.notiAndSettingmodalData.id)
                }
                this.setState({
                    loading: "none",
                    message2:"block"
                })
                var ref = this;
                setTimeout(function(){
                    ref.setState({
                        message2:"none"
                    })
                },5000)
            })
            .catch((e) => {
                console.log(e);
            });
    }

    handleJUmpinMessage = (e) => {
        this.setState({
            jumpinMsg: e.target.value
        })
    }

    handleChangePIN = (e) =>{
        var name = e.target.name;
        var val = e.target.value;
        if(name=='pin'){
            this.setState({
                "pin":val
            })
        }

        if(name=='cpin'){
            this.setState({
                "cpin":val
            })
        }
    }

    onValidSubmitPINSetting = (e) =>{

    }

    render() {
        const errorLabel = <div className="red" />
        return (
            // (this.props.loadNow)?
            <React.Fragment>
                {<div style={{ display: this.state.loading }} class="ui loading form mh-loading-custom"></div>}
                <div
                    id="notificationSetingModalPopup"
                    className={this.props.obj.modalstatusNotiAandMisc ? "modal fade show" : "modal fade"}
                    data-backdrop="static"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="staticBackdrop"
                    aria-hidden="true"
                    style={{ display: this.props.obj.modalstatusNotiAandMisc ? "block" : "none" }}
                >
                    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "90%" }} role="document">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="close linear-gradient"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => this.notiAndMiscmodalClose()}
                            >
                                X
						</button>


                            {this.state.modalFormLoader && <div className="ui loading form"></div>}
                            <div className="modal-body">
                                <div className="row" style={{ width: 100 + '%' }}>
                                    <div className="col-lg-12 yr-chat-box-row">
                                        <div className="yr-max-height kt-scroll yr-col-3" data-scroll="true">
                                            <Form noValidate autoComplete="off" className="manageNotification" id="manageNotification" onValidSubmit={this.onValidSubmitFirstCol}>
                                                <div className="yr-popBox-1">
                                                    <div className="box-title">Team member profile:</div>
                                                    <Message
                                                        color={
                                                            this.state.color1
                                                        }
                                                        style={{
                                                            display: this.state.message1,
                                                        }}
                                                    >{this.state.msg1}</Message>
                                                    <div className="form-group" style={{ marginBottom: 10 }}>
                                                        <label style={{ marginBottom: 10 }}>Full Name</label>
                                                        <input type="fname" className="form-control mh-Readonly" value={this.props.obj.notiAndSettingmodalData.name} readOnly />
                                                    </div>
                                                    <div className="form-group" style={{ marginBottom: 10 }}>
                                                        <label style={{ marginBottom: 10 }}>Email Address</label>
                                                        <input type="email" className="form-control mh-Readonly" value={this.props.obj.notiAndSettingmodalData.email} readOnly />
                                                    </div>
                                                    <div className="form-group" style={{ marginBottom: 10 }}>
                                                        <label>Phone Number</label>
                                                        <select class="form-control" name="isd_code" id="isd_code" onChange={this.handleChangeISD} id="isd_code_id" >
                                                            {this.renderDropDownDefault(this.props.obj.ISDrenderOption1, this.props.obj.notiAndSettingmodalData.isd)}
                                                            <optgroup label="Other countries">
                                                                {this.renderDropDownRemaining(this.props.obj.ISDrenderOption2, this.props.obj.notiAndSettingmodalData.isd)}
                                                            </optgroup>
                                                        </select>
                                                        
                                                                <Form.Input
                                                                    fluid
                                                                    autoComplete="off"
                                                                    name="phone"
                                                                    maxLength="13"
                                                                    onChange={this.handlePhoneChange}
                                                                    placeholder="Phone Number"
                                                                    validations="isRequired,usPhoneLength"
                                                                    validationErrors={{
                                                                        isRequired: "This field is required.",
                                                                        usPhoneLength: "Phone number must be at least 10 numbers."
                                                                    }}
                                                                    value={this.props.obj.notiAndSettingmodalData.phone}
                                                                    id="phoneNumber"
                                                                    errorLabel={errorLabel}
                                                                />

                                                            </div>
                                                        
                                                    <div className="form-group">
                                                        <label>Jumpin Profile Logo</label>
                                                        <div className="logo-img">
                                                            <img src={this.state.memberLogo == "" ? this.props.obj.notiAndSettingmodalData.image == '' || this.props.obj.notiAndSettingmodalData.image == null ? "assets/media/logos/upload-bg.png" : this.props.sessionUser.assetPath + "/images/chatlogo/" + this.props.obj.notiAndSettingmodalData.image + "?v=" + this.random() : this.state.memberLogo} alt="" />
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            <div className="upload-btn-wrapper">
                                                                <button className="btn linear-gradient zy-save-btn width-100">Edit Logo</button>
                                                                <input type="file" name="image" id='comanyLogoId' onChange={this.handleCompanyLogo} accept="image/jpg,image/png,image/jpeg,image/gif" />
                                                            </div>
                                                            {this.state.memberLogo && (<button type="reset" className="btn linear-gradient zy-cancel-btn ml-2" onClick={this.handleCompanyLogoRemove}>Remove</button>)}
                                                        </div>
                                                        <div style={{ display: this.state.imgError, color: 'red' }}>Invalid file format. Only "jpg, jpeg, png, gif" files are supported.</div>
                                                        {/* <input type="hidden" id="dbimgchat" name="dbimgchat"  value={this.state.companyLogo}/> */}
                                                        {/* <input type="hidden" id="oldChatImgID" name="oldChatImgname" value={this.props.cplogo}/> */}
                                                        <div className="row">
                                                            <div className="col-lg-12 text-center" style={{ marginTop: 33 }}>
                                                                <button type="submit" class="btn linear-gradient yr-submitbtn">Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                        <div className="yr-max-height  yr-col-3">
                                            <Form noValidate autoComplete="off" className="manageNotification" id="manageNotificationSecond" onValidSubmit={this.onValidSubmitSecondCol}>

                                                <div className="yr-popBox-2">
                                                    <div className="box-title">Live chat notifications:</div>
                                                    <Message
                                                        color={
                                                            "green"
                                                        }
                                                        style={{
                                                            display: this.state.message2,
                                                        }}
                                                    >Setting has been saved successully1.</Message>
                                                    <div className="form-group row" style={{ marginBottom: 0 }}>
                                                        <label className="col-7 col-form-label">Receive text message</label>
                                                        <div className="col-4">
                                                            <span className={(this.state.switchValue1==""?this.props.obj.notiAndSettingmodalData.is_recieve_text_message_on_livechatChecked==true?"ON":"OFF":this.state.switchValue1)=="ON"?"kt-switch kt-switch-sm kt-switch--success chat-cus-check":"kt-switch kt-switch-sm kt-switch--error chat-cus-check"}>
                                                                <label>
                                                                    <input name="is_recieve_text_message_on_livechat" type="checkbox" value={this.state.switchValue1==""?this.props.obj.notiAndSettingmodalData.is_recieve_text_message_on_livechatChecked==true?"ON":"OFF":this.state.switchValue1}  onClick={(e) => this.changeSwitch(e)}  defaultChecked={this.props.obj.notiAndSettingmodalData.is_recieve_text_message_on_livechatChecked} />
                                                                    <span />
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row" style={{ marginBottom: 10 }}>
                                                        <label className="col-5 col-form-label">Receive email</label>
                                                        <div className="col-7">
                                                            <span className={(this.state.switchValue2==""?this.props.obj.notiAndSettingmodalData.is_recieve_email_on_livechatChecked==true?"ON":"OFF":this.state.switchValue2)=="ON"?"kt-switch kt-switch-sm kt-switch--success chat-cus-check":"kt-switch kt-switch-sm kt-switch--error chat-cus-check"}>
                                                                <label>
                                                                    <input type="checkbox" name="is_recieve_email_on_livechat" value={this.state.switchValue2==""?this.props.obj.notiAndSettingmodalData.is_recieve_email_on_livechatChecked==true?"ON":"OFF":this.state.switchValue2} onClick={(e) => this.changeSwitch(e)} defaultChecked={this.props.obj.notiAndSettingmodalData.is_recieve_email_on_livechatChecked} />
                                                                    <span />
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    {/* <div className="form-group row" style={{ marginBottom: 10 }}>
                                                        <label className="col-5 col-form-label">Users are only notified if the bot get confused</label>
                                                        <div className="col-7">
                                                            <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                                                                <label>
                                                                    <input type="checkbox" name="is_recieve_notification_on_confuse" value={this.state.switchValue6==""? this.props.obj.notiAndSettingmodalData.is_recieve_notification_on_confuseChecked==true ?"ON":"OFF":this.state.switchValue6} onClick={(e) => this.changeSwitch(e)} defaultChecked={this.props.obj.notiAndSettingmodalData.is_recieve_notification_on_confuseChecked} />
                                                                    <span />
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row" style={{ marginBottom: 10 }}>
                                                        <label className="col-5 col-form-label">Users are only notified if someone wants to talk to human</label>
                                                        <div className="col-7">
                                                            <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                                                                <label>
                                                                    <input type="checkbox" name="is_recieve_notification_on_human" value={this.state.switchValue7==""?this.props.obj.notiAndSettingmodalData.is_recieve_notification_on_humanChecked==true?"ON":"OFF":this.state.switchValue7} onClick={(e) => this.changeSwitch(e)} defaultChecked={this.props.obj.notiAndSettingmodalData.is_recieve_notification_on_humanChecked} />
                                                                    <span />
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>     */}

                                                    <div className="form-group" style={{ marginBottom: 10 }}>
                                                        <label style={{ marginBottom: 10 }}>Message when breaking automation:</label>
                                                        <TextArea className=""
                                                            validations="isRequired"
                                                            validationErrors={{
                                                                isRequired: "This field is required.",
                                                            }}
                                                            onChange={this.handleJUmpinMessage}
                                                            id="description" placeholder="Hi, human jumping in!" rows="5" value={this.props.obj.notiAndSettingmodalData.jumpin_message} name="jumpin_message"></TextArea>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12 text-right" style={{ marginTop: 10 }}>
                                                            <button type="submit" class="btn linear-gradient yr-submitbtn">Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="yr-line"><hr></hr></div>
                                                <div className="yr-popBox-2">
                                                    <div className="box-title">Completed chats notification:</div>
                                                    <div className="form-group row" style={{ marginBottom: 0 }}>
                                                        <label className="col-7 col-form-label">Receive text message</label>
                                                        <div className="col-4">
                                                            <span className={((this.state.switchValue3==""?this.props.obj.notiAndSettingmodalData.is_recieve_text_message_on_completechat==true?"ON":"OFF":this.state.switchValue3)=="ON"?"kt-switch kt-switch-sm kt-switch--success chat-cus-check":"kt-switch kt-switch-sm kt-switch--error chat-cus-check")}>
                                                                <label>z
                                                                    <input type="checkbox" name="is_recieve_text_message_on_completechat" value={this.state.switchValue3==""?this.props.obj.notiAndSettingmodalData.is_recieve_text_message_on_completechat==true?"ON":"OFF":this.state.switchValue3} onClick={(e) => this.changeSwitch(e)} defaultChecked={this.props.obj.notiAndSettingmodalData.is_recieve_text_message_on_completechatChecked} />
                                                                    <span />
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row" style={{ marginBottom: 10 }}>
                                                        <label className="col-5 col-form-label">Receive email</label>
                                                        <div className="col-7">
                                                            <span className={((this.state.switchValue4==""?this.props.obj.notiAndSettingmodalData.is_recieve_email_on_completechat==true?"ON":"OFF":this.state.switchValue4)=="ON")?"kt-switch kt-switch-sm kt-switch--success chat-cus-check":"kt-switch kt-switch-sm kt-switch--error chat-cus-check"}>
                                                                <label>
                                                                    <input  type="checkbox" name="is_recieve_email_on_completechat" value={this.state.switchValue4==""?this.props.obj.notiAndSettingmodalData.is_recieve_email_on_completechat==true?"ON":"OFF":this.state.switchValue4} onClick={(e) => this.changeSwitch(e)} defaultChecked={this.props.obj.notiAndSettingmodalData.is_recieve_email_on_completechatChecked} />
                                                                    <span />
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>


                                                </div>
                                            </Form>
                                        </div>
                                        <div className="yr-col-3">
                                            <div class="mh-thirdCol">
                                            <div className="box-title p-t2em">Incompleted chats notifications:</div>
                                            <Message
                                                color={
                                                    "green"
                                                }
                                                style={{
                                                    display: this.state.message3,
                                                }}
                                            >Setting has been saved successully.</Message>
                                            <div className="form-group row" style={{ marginBottom: 0 }}>
                                                <label className="col-10 col-form-label">Send incompleted chats that capture email or phone number 5 minutes after user left the chat</label>
                                                <div className="col-2">
                                                    <span className={(this.state.switchValue8==""?this.props.obj.notiAndSettingmodalData.is_revieve_notification_incompleted_chat==true?"ON":"OFF":this.state.switchValue8)=="ON"?"kt-switch kt-switch-sm kt-switch--success chat-cus-check":"kt-switch kt-switch-sm kt-switch--error chat-cus-check"}>
                                                        <label>
                                                            <input type="checkbox" name = "is_revieve_notification_incompleted_chat" value={this.state.switchValue8==""?this.props.obj.notiAndSettingmodalData.is_revieve_notification_incompleted_chat==true?"ON":"OFF":this.state.switchValue8} onClick={(e) => this.changeSwitch(e)} defaultChecked={this.props.obj.notiAndSettingmodalData.is_revieve_notification_incompleted_chatChecked} />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="box-title p-t2em">Misc customization:</div>
                                            {/* <Message
                                                color={
                                                    "green"
                                                }
                                                style={{
                                                    display: this.state.message3,
                                                }}
                                            >Setting has been saved successully</Message> */}
                                            <div className="form-group row" style={{ marginBottom: 0 }}>
                                                <label className="col-10 col-form-label">Enable team members to leave notes when viewing completed chat transcripts.</label>
                                                <div className="col-2">
                                                    <span className={(this.state.switchValue5==""?this.props.obj.notiAndSettingmodalData.is_leave_note==true?"ON":"OFF":this.state.switchValue5)=="ON"?"kt-switch kt-switch-sm kt-switch--success chat-cus-check":"kt-switch kt-switch-sm kt-switch--error chat-cus-check"}>
                                                        <label>
                                                            <input type="checkbox" name = "is_leave_note" value={this.state.switchValue5==""?this.props.obj.notiAndSettingmodalData.is_leave_note==true?"ON":"OFF":this.state.switchValue5} onClick={(e) => this.changeSwitch(e)} defaultChecked={this.props.obj.notiAndSettingmodalData.is_leave_noteChecked} />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="yr-buttonOk">
                                                <button type="reset" onClick={() => this.notiAndMiscmodalClose()} className="btn linear-gradient-cancel  yr-cancelbtn mg-r"><span> Cancel </span></button>
                                            </div>
                                            <div className="form-group row" style={{ marginBottom: 0 }}>

                                            <div className="yr-max-height kt-scroll col-lg-12" data-scroll="true">
                                            <Form noValidate autoComplete="off" className="managePIN" id="managePIN" onValidSubmit={this.onValidSubmitPINSetting}>
                                                <div className="yr-popBox-1" style={{paddingLeft:0}}>
                                                    <div className="box-title">PIN Setting:</div>

                                                <div className="form-group row" style={{ marginBottom: 0 }}>
                                                    <label className="col-12 col-form-label">Please set up a PIN to view sensitive transcript data</label>

                                                </div>
                                                    <Message
                                                        color={
                                                            this.state.color1
                                                        }
                                                        style={{
                                                            display: this.state.message1,
                                                        }}
                                                    >{this.state.msg1}</Message>
                                                    <div className="form-group" style={{ marginBottom: 10 }}>
                                                        <Form.Input name="pin" 
                                                                maxLength="4" 
                                                                type="password" 
                                                                placeholder='4 digit PIN' 
                                                                className="" 
                                                                onChange={this.handleChangePIN}  
                                                                value={this.state.pin}  
                                                                validations="isRequired"  
                                                                    validationErrors={{
                                                                        isRequired: "This field is required.",
                                                                    }} 
                                                                    
                                                                    errorLabel={errorLabel} 
                                                        />
                                                    </div>
                                                    <div className="form-group" style={{ marginBottom: 10 }}>
                                                        <Form.Input 
                                                        name="cpin" maxLength="4" 
                                                        type="password" 
                                                        placeholder="Confirm 4 digit PIN" 
                                                        className="" 
                                                        onChange={this.handleChangePIN}  
                                                        value={this.state.cpin} 
                                                        validations="isRequired" 
                                                        validationErrors={{
                                                            isRequired: "This field is required.",
                                                        }}
                                                        
                                                        errorLabel={errorLabel} 
                                                        />
                                                    </div>
                                                    
                                                        
                                                    <div className="form-group">
                                                       <div className="row">
                                                            <div className="col-lg-12 text-center" style={{ marginTop: 33 }}>
                                                                <button type="submit" class="btn linear-gradient yr-submitbtn">Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                            </div>
                                        </div>

                                            <div className="yr-buttonOk">
                                                <button type="reset" onClick={() => this.notiAndMiscmodalClose()} className="btn linear-gradient-cancel yr-cancelbtn mg-r"><span> Cancel </span></button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
}

export default DialogBoxSetNotificationAndMiscSetting;
