import React from "react";
import config from "../../../config";
class DefaultBreadCrum extends React.Component {
  render() {
    const { pageTitle, subHeaderTitle, toggleBreadcrum } = this.props;
    return (
      <div
        className="kt-header__bottom"
        style={{ display: toggleBreadcrum ? "block" : "none" }}
      >
        <div className="kt-container">
          <div className="kt-subheader kt-grid__item" id="kt_subheader">
            <div className="kt-subheader__main yr-body-p">
              <h3 className="kt-subheader__title">{subHeaderTitle}</h3>
              <span className="kt-subheader__separator kt-hidden" />
              <div className="kt-subheader__breadcrumbs">
                <a to="#" className="kt-subheader__breadcrumbs-home">
                  <img
                    src={
                      config.imageUrl +
                      "/assets/media/icons/dashboard-icon-svg.svg"
                    }
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="yr-pagetitle yr-body-p">
            <h2>{pageTitle}</h2>
          </div>
         </div>
      </div>
    );
  }
}
export default DefaultBreadCrum;
