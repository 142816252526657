import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

let follow;
let needtocall;
let archive;
let teamSelected;
export const DatatableRow = props => (
   //@Rohit
   <Table.Row>
     <Table.Cell>
     <ReactTooltip 
            place={"top"}
            type={"dark"}
            effect={"float"}
           multiline={true}
             />
     {(props.activetabDelay==3)?<label className="kt-checkbox kt-checkbox--single"><input type="checkbox" checked={props.list.checked ? true : false}  cid={props.list.cid} onChange={()=>props.channgeEvent(props.list.cid)}></input>&nbsp;<span></span></label>:""}
       </Table.Cell>
      <Table.Cell>{props.list.email}</Table.Cell>
      <Table.Cell></Table.Cell>
      <Table.Cell>{(props.activetabDelay==3)?(props.list.toolTipText)?
      <span style={{width:150,backgroundColor:"#ef6767"}} data-tip={props.list.toolTipText} class="kt-badge kt-badge--info kt-badge--inline kt-badge--pill">
        {props.list.subscribedText} 
      </span>
      :<span   className={"kt-badge kt-badge--info kt-badge--inline kt-badge--pill"}>
        {props.list.subscribedText}
    </span>:""}</Table.Cell>
      <Table.Cell>{props.list.updated_date}</Table.Cell>
      
  </Table.Row>
);
DatatableRow.propTypes = {
  list: PropTypes.object.isRequired, 
  onSubmitFilter: PropTypes.func.isRequired,  
  
};
