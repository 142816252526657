import React from 'react';
import CustomErrorField from "../../../../Form/CustomErrorField";
import api from "../../../../../api";
const urldata={'url':'','branch_id':'','user_id':'','workspace_id':'','afterurl':0,'auto_popup_time':''}
const teamdata={'id':'','user_id':'','workspace_id':'','branch_id':'','email':'','isd_code':'','phone':'','created_at':''}
var  workspace_branch_data;
class AddLinks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errors:[],
            workspace_branch_url:[],
            initial_value:'',
            link_team_memebers:[],
            is_link_notification: "0",
            is_link_team_members: "0",
            is_link_team_profile: "0",
        };
        
    }


    componentDidMount() {
       
        this.onLoad();
    }
    
    componentDidUpdate(prevProps) {
     
        if(this.props.indextime!=prevProps.indextime){
          
           this.onLoad();
        }
      
      }

      onLoad=()=>{
       this.setState({loading:true})
        api.botbuilder.getLinkDetails({ 'branch_id': this.props.branch_id,'workspace_id':this.props.workspace_id }).then(data => {

            try {
                var temdata_temp=data.link_team_memebers.length>0 ? data.link_team_memebers :[teamdata]
                if(data.workspace_branch_url.length==0){
                   
                  this.setState({
                      loading:false,
                      workspace_branch_url:[urldata],
                      link_team_memebers:temdata_temp,
                      is_link_notification: data.is_link_notification,
                      is_link_team_members: data.is_link_team_members,
                      is_link_team_profile: data.is_link_team_profile,
                      errors:[],
                  })
                }else{
                
                  this.setState({
                     loading:false,
                      workspace_branch_url:data.workspace_branch_url,
                      link_team_memebers:temdata_temp,
                      is_link_notification: data.is_link_notification,
                      is_link_team_members: data.is_link_team_members,
                      is_link_team_profile: data.is_link_team_profile,
                      errors:[],
                  },function(){
                      console.log(data.workspace_branch_url)
                  })
                }    

              
            } catch (err) {
              console.log(err);
            }
      
          });





          
          
       
      }

      linkDataAction=(tab,data,index)=>{

         if(tab=='add'){
             console.log(this.props.branchDetails.workspace_branch_url)
             let link_temp=this.state.workspace_branch_url;
             link_temp.push({'url':'','branch_id':'','user_id':'','workspace_id':'','afterurl':0,'auto_popup_time':''});
             this.setState({workspace_branch_url:link_temp})
             console.log(this.props.branchDetails.workspace_branch_url)
           
             
         }
         if(tab=='delete'){
            let link_temp=this.state.workspace_branch_url;
            let activeindex = link_temp.findIndex(
                (list) => list == index
               );
            link_temp.splice(activeindex, 1);
            this.setState({workspace_branch_url:link_temp})
        }
        if(tab=='input_change'){
           
            let link_temp=this.state.workspace_branch_url;
          
            link_temp[index].url=data.target.value;
            this.setState({workspace_branch_url:link_temp})
        }
        if(tab=='input_change_popup'){
            const re = /^[0-9\b]+$/;
            
            if (data.target.value === '' || re.test(data.target.value)) {
                let link_temp=this.state.workspace_branch_url;
                link_temp[index].auto_popup_time=data.target.value;;
                this.setState({workspace_branch_url:link_temp})
            }
        }

        
        if(tab=='checkboxchange'){
            let link_temp=this.state.workspace_branch_url;
            let value=data.target.checked ? 1 : 0;
            link_temp[index].afterurl=value;
            this.setState({workspace_branch_url:link_temp})
        }

        if(tab=='is_link_notification_change'){
            
            let value=data.target.checked ? 1 : 0;
          
            this.setState({is_link_notification:value,is_link_team_members:'',is_link_team_profile:1,link_team_memebers:[{'id':'','user_id':'','workspace_id':'','branch_id':'','email':'','isd_code':'','phone':'','created_at':''}]})
        }
        if(tab=='is_link_team_members_change'){
            
            let value=data.target.checked ? 1 : 0;
            
            if(value==1){
                this.setState({is_link_team_members:value,is_link_team_profile:'',link_team_memebers:[{'id':'','user_id':'','workspace_id':'','branch_id':'','email':'','isd_code':'','phone':'','created_at':''}]})
            }else{
                this.setState({is_link_team_members:value})
            }
            
        }

        if(tab=='is_link_team_profile_change'){
            
            let value=data.target.checked ? 1 : 0;
         // alert(value)
            if(value==1){
                this.setState({is_link_team_profile:value,is_link_team_members:''})
            }else{
                this.setState({is_link_team_profile:value,link_team_memebers:[{'id':'','user_id':'','workspace_id':'','branch_id':'','email':'','isd_code':'','phone':'','created_at':''}]})
            }
        }

        if(tab=='add_team_member'){
            
             let link_temp=this.state.link_team_memebers;
             link_temp.push({'id':'','user_id':'','workspace_id':'','branch_id':'','email':'','isd_code':'','phone':'','created_at':''});
             this.setState({link_team_memebers:link_temp})
        }

        if(tab=='delete_team_member'){
            let link_temp=this.state.link_team_memebers;
            let activeindex = link_temp.findIndex(
                (list) => list == index
               );
            link_temp.splice(activeindex, 1);
            this.setState({link_team_memebers:link_temp})
        }
         
        if(tab=='input_change_email'){
            let link_temp=this.state.link_team_memebers;
          
            link_temp[index].email=data.target.value;
            this.setState({link_team_memebers:link_temp})
        }
        if(tab=='input_change_isd'){
            let link_temp=this.state.link_team_memebers;
          
            link_temp[index].isd_code=data.target.value;
            this.setState({link_team_memebers:link_temp})
        }
        if(tab=='input_change_phone'){
            let link_temp=this.state.link_team_memebers;
          
            link_temp[index].phone=this.phoneFormat(data.target.value);
            this.setState({link_team_memebers:link_temp})
        }

        
      }

      phoneFormat = (p) => { 
        p = p.replace(/[^\d]*/gi, "");
        if (p.length <= 3) {
            return p;
        }else if (p.length > 3 && p.length < 7) {
          p = "(" + p;
          let l30 = p.length;
          let p30 = p.substring(0, 4);
          p30 = p30 + ")";
      
          let p31 = p.substring(4, l30);
          let pp = p30 + p31;
          return pp;
        } else if (p.length >= 7) {
          p = "(" + p;
          let l30 = p.length;
          let p30 = p.substring(0, 4);
          p30 = p30 + ")";
      
          let p31 = p.substring(4, l30);
          let pp = p30 + p31;
      
          let l40 = pp.length;
          let p40 = pp.substring(0, 8);
          p40 = p40 + "-";
      
          let p41 = pp.substring(8, l40);
          let ppp = p40 + p41;
          let maxphonelength = 13;
          let finalPhone = ppp.substring(0, maxphonelength);
          return finalPhone;
        }
      };


   saveLinkData=(data)=>{
          const errors ={};
          new Promise(resolve => {

            
           this.state.workspace_branch_url.forEach((element,index)=>{
           //alert(index)
           var valueArr = this.state.workspace_branch_url.map(function(item){ return item });
           valueArr.splice(index,1);
        //    console.log(valueArr)
        //    console.log('-----------------')
        //    console.log(this.state.workspace_branch_url)

           let activeindex = valueArr.findIndex(
              (list) => list.url === element.url
            );

            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ //port
            '(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i');
           
            // var lastChar = element.url.substr(-1);
            // alert(lastChar)
            let urlmsg=false
            if(element.url==''){
                urlmsg='Please enter valid url';
                
            } else if(!pattern.test(element.url)){
               
                urlmsg='Please enter valid url';
            }else{
                if(activeindex!=-1){
                    
                    urlmsg='This is duplicate value';
                }
            } 

            if(urlmsg!=false){
            if(typeof errors.url !='undefined'){
                errors.url[index]=urlmsg
                
               }else{
                errors.url={[index]:urlmsg}
               }
            }

             // Selects the last character
            // if (lastChar == '/') {         
            //     errors['url']={[index]:'please remove slash from the last of the url'}
            // }
            })
           

            if(this.state.is_link_team_members==1){
                       
                this.state.link_team_memebers.forEach((element,index)=>{
                    let phoneemailmsg=false
                    let emailmsg=false
                    let phonemsg=false
                     if(element.email=='' && element.phone==''){
                        //errors['email_phone']={[index]:'Please select at least email or phone'} 
                        phoneemailmsg='Please select at least email or phone';
                        
                     }
                     var reg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                     if(element.email!='' ){
                        if(!reg.test(element.email)) {
                        //errors['email']={[index]:'Please enter valid email address'} 
                        emailmsg='Please enter valid email address'
                        }
                       
                        var emailarr = this.state.link_team_memebers.map(function(item){ return item });
                        emailarr.splice(index,1);
                         let activeindexemail = emailarr.findIndex(
                            (list) => (list.email == element.email )
                          );
                          if(activeindexemail!=-1){
                            //errors['email']={[index]:'This is duplicate value'}
                            emailmsg='This is duplicate value'
                         }

                     }

                     
                     if(element.phone!=''){
                         if(element.phone.length<13){
                            //errors['phone']={[index]:'Please enter valid phone number'} 
                            phonemsg='Please enter valid phone number'
                         }
                         var phoneArr = this.state.link_team_memebers.map(function(item){ return item });
                        
                         phoneArr.splice(index,1);
                         let activeindexphone = phoneArr.findIndex(
                            (list) => (list.phone == element.phone && list.isd_code == element.isd_code )
                          );
                          if(activeindexphone!=-1){
                            //errors['phone']={[index]:'This is duplicate value'}
                            phonemsg='This is duplicate value'
                         }
                        
                     }
                    
                     if(phoneemailmsg!=false){
                       
                        if(typeof errors.phoneemail !='undefined'){
                            errors.phoneemail[index]=phoneemailmsg
                            
                            }else{
                            errors.phoneemail={[index]:phoneemailmsg}
                            }
                    }
                     if(emailmsg!=false){
                        if(typeof errors.email !='undefined'){
                            errors.email[index]=emailmsg
                            
                            }else{
                            errors.email={[index]:emailmsg}
                            }
                    }
                     
                    if(phonemsg!=false){
                        if(typeof errors.phone !='undefined'){
                            errors.phone[index]=phonemsg
                            
                            }else{
                            errors.phone={[index]:phonemsg}
                            }
                    }
                    

                });
            }


            resolve(errors);


        }).then((errors) => {
            console.log(errors)

            if(Object.keys(errors).length>0){
                this.setState({errors:errors})
                let scrolltop=document.querySelector(".focusError").offsetTop - document.body.scrollTop;       
                window.scroll({top: scrolltop, left: 0, behavior: 'smooth' });
            }else{
                this.setState({errors:[]})
                
                this.setState({loading:true})
                this.props.saveBranchLinks(this.state,(givendata)=>{
                    this.setState({loading:false})
                    console.log('-----------------')
                    console.log(givendata.link_exist)
                    
                    if(givendata.link_exist.length>0){


                        new Promise(resolve => {

                            givendata.link_exist.forEach((element,index)=>{
                            let urlmsg=false
                            var valueArr = this.state.workspace_branch_url.map(function(item){ return item });
                            let activeindex = valueArr.findIndex(
                               (list) => list.url === element.url
                             );
                 
                             
                             if(activeindex!=-1){
                                     
                                urlmsg='This link is already exist in '+element.branch_name+' branch';
                            }
                 
                             if(urlmsg!=false){
                             if(typeof errors.url !='undefined'){
                                 errors.url[index]=urlmsg
                                 
                                }else{
                                 errors.url={[index]:urlmsg}
                                }
                             }
                 
                             })
                            
                             resolve(errors);
                 
                 
                         }).then((errors) => {
                             console.log(errors)
                 
                             if(Object.keys(errors).length>0){
                                 this.setState({errors:errors})
                                 let scrolltop=document.querySelector(".focusError").offsetTop - document.body.scrollTop;       
                                 window.scroll({top: scrolltop, left: 0, behavior: 'smooth' });
                             }
                            
                         })
                    }else{
                        this.props.popupClose();
                        let scrolltop=document.querySelector("#linkisdetected").offsetTop - document.body.scrollTop;
                        window.scroll({top: scrolltop, left: 0, behavior: 'smooth' });
                    }
                })
               
            }
           
        })

   }


    render(){
    const {errors}=this.state   
    return(
        <div>
            {this.state.loading && <div className="ui loading form"></div>}
        <div className="yr-chat-round-box yr-closebtn">
                    <button type="button" class="close linear-gradient" onClick={() => this.props.popupClose()}>&#10006;</button>
                    <div className="box-title">Add link to start a chat on branch</div>
                    { this.state.workspace_branch_url.map((obj,index)=>{
                        return (
                    <div className="form-group row relative" style={{ marginBottom: 10 }}>
                        <input type="text" className={
                                        Object.keys(errors).length > 0 && typeof errors['url'] !='undefined' && typeof errors['url'][index] != 'undefined' 
                                            ? "form-control is-invalid focusError"
                                            : "form-control"
                                    } defaultValue={obj.url} style={{width:"95%"}} onChange={(e)=>this.linkDataAction('input_change',e,index)}/>
                        {index>0 && 
                                <a class="cusrsorpointer choicedelete rightzero" title="Delete" onClick={()=>this.linkDataAction('delete','',index)}><i class="la la-trash"></i></a>
                                }

                                    <div className="row morebranchlink" style={{marginTop:"10px"}}>
                                    <div className="col-md-6">Chat auto pop up after- Seconds</div>
                                    <div className="col-md-6">
                                        
                                    <input  type="text"   step="1"  style={{width:"90%"}} onChange={(e)=>this.linkDataAction('input_change_popup',e,index)} className="autopopup form-control" min="0" name="chatpopupStartlinkbuilder" value={obj.auto_popup_time} placeholder="Chat Pop in seconds" />
                                    
                                    </div>
                                    </div>        

                        <div className="kt-checkbox-list"><label className="kt-checkbox chat-checkbox"><input type="checkbox" defaultChecked={obj.afterurl==1 ?'checked':''} onChange={(e)=>this.linkDataAction('checkboxchange',e,index)}/><span></span></label></div>
                        <span style={{marginLeft:"30px"}}>  Include URLs following the same path. For example, if you use this feature and your URL is https://google.com, we would also include a URL like https://google.com/search or https://google.com/aboutus.</span>
                          { Object.keys(errors).length > 0 && typeof errors['url'] !='undefined' && typeof errors['url'][index]  != 'undefined' && (
                                                                    <CustomErrorField
                                                                        message={ errors['url'][index]  }
                                                                        className="error invalid-feedback"
                                                                    />
                           )}                      
                   </div>
                        )
                    })}
                    <a className="add-gradient form-group cursorpointer" onClick={()=>this.linkDataAction('add','','')}>+Add</a>

                    <p>If a chat start from this branch, use the rule below to send lead notification when a chat completes. If notification setting are entered below, we will not use the setting on the branch where the chat completes.</p>    

                    <div className="form-group row" style={{ marginBottom: 10 }}>
                        <label className="col-10 col-form-label" style={{ marginTop: 0 }}>Use notification setting configured below.</label>
                        <div className="col-2">
                            <div className="kt-checkbox-list">
                                <label className="kt-checkbox chat-checkbox">
                                    <input type="checkbox" checked={this.state.is_link_notification ==1 ? 'checked' :''} onChange={(e)=>this.linkDataAction('is_link_notification_change',e,'')}/>
                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                    {this.state.is_link_notification == 1 &&
                    <div className="form-group row" style={{ marginBottom: 10 }}>
                        <label className="col-10 col-form-label" style={{ marginTop: 0 }}>Send to team member based on profile/manage team rules</label>
                        <div className="col-2">
                            <div className="kt-checkbox-list">
                                <label className="kt-checkbox chat-checkbox">
                                    <input type="checkbox" checked={this.state.is_link_team_profile ==1 ? 'checked' :''} onChange={(e)=>this.linkDataAction('is_link_team_profile_change',e,'')} />
                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                    }
                    {this.state.is_link_notification ==1 &&
                    <div className="form-group row" style={{ marginBottom: 10 }}>
                        <label className="col-10 col-form-label" style={{ marginTop: 0 }}>Please provide and email and/or SMS number to send to when a conversation is deemed "complete"</label>
                        <div className="col-2">
                            <div className="kt-checkbox-list">
                                <label className="kt-checkbox chat-checkbox">
                                    <input type="checkbox" checked={this.state.is_link_team_members == 1 ? 'checked' :''} onChange={(e)=>this.linkDataAction('is_link_team_members_change',e,'')} />
                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                }
                    
                    {this.state.is_link_team_members == 1 &&
                    <div>
                    {this.state.link_team_memebers.length > 0  && this.state.link_team_memebers.map((obj,index)=>{
                        return (
                                <div className="row">
                                    <div className="col-md-6">
                                        <div  style={{ marginBottom: 10 }}>
                                            <input type="email" className={
                                        Object.keys(errors).length > 0 && typeof errors['email'] !='undefined' && typeof errors['email'][index] != 'undefined' 
                                            ? "form-control is-invalid focusError"
                                            : "form-control"
                                    } placeholder="Email" value={obj.email} onChange={(e)=>this.linkDataAction('input_change_email',e,index)}/>
                                            { Object.keys(errors).length > 0 && typeof errors['email'] !='undefined' && typeof errors['email'][index]  != 'undefined' && (
                                                                    <CustomErrorField
                                                                        message={ errors['email'][index]  }
                                                                        className="error invalid-feedback"
                                                                    />
                           )}  
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group" style={{ marginBottom: 10 }}>
                                        <select name="button_respond_on_phonecall_country_code" style={{  width: "114px;" }} value={obj.isd_code} className="form-control" onChange={(e)=>this.linkDataAction('input_change_isd',e,index)}>

                                            {this.props.defaultCountryCodeLists && this.props.defaultCountryCodeLists.map((value, index) => {
                                                return (
                                                    <option value={value.country_code} >{value.country_name}</option>

                                                )
                                            })}

                                            <optgroup label="Other countries">

                                                {this.props.countryCodeLists && this.props.countryCodeLists.map((value, index) => {
                                                    return (
                                                        <option value={value.country_code}  >{value.country_name}</option>

                                                    )
                                                })} </optgroup>
                                       </select>

                                        </div>
                                        <div className="form-group" style={{ marginBottom: 10 }}>
                                            <input type="text" className={
                                        Object.keys(errors).length > 0 && typeof errors['phone'] !='undefined' && typeof errors['phone'][index] != 'undefined' 
                                            ? "form-control is-invalid focusError"
                                            : "form-control"
                                    } placeholder="Phone Number" value={obj.phone} onChange={(e)=>this.linkDataAction('input_change_phone',e,index)}/>
                                            {index>0 && 
                                            <a class="cusrsorpointer choicedelete rightfive" title="Delete" onClick={()=>this.linkDataAction('delete_team_member','',index)}><i class="la la-trash"></i></a>
                                            }
                                            { Object.keys(errors).length > 0 && typeof errors['phone'] !='undefined' && typeof errors['phone'][index] != 'undefined' && (
                                                                    <CustomErrorField
                                                                        message={ errors['phone'][index]  }
                                                                        className="error invalid-feedback"
                                                                    />
                           )}  
                                        </div>
                                        <div className="delBtn">
                                            <button type="button" class="btn btn-icon"><i class="yr-delete red"></i></button>
                                        </div>
                                        <div className={
                                        Object.keys(errors).length > 0 && typeof errors['phoneemail'] !='undefined' && typeof errors['phoneemail'][index] != 'undefined' 
                                            ? "form-control is-invalid focusError"
                                            : "form-control"
                                    } style={{display:'none'}}></div>
                                        { Object.keys(errors).length > 0 && typeof errors['phoneemail'] !='undefined' && typeof errors['phoneemail'][index]  != 'undefined' && (
                                                                    <CustomErrorField
                                                                        message={ errors['phoneemail'][index]  }
                                                                        className="error invalid-feedback"
                                                                    />
                           )} 
                                    </div>
                                </div>
                        )
                    })}
                    <a className="add-gradient form-group" onClick={()=>this.linkDataAction('add_team_member','','')}>+Add</a>
                    </div>
                }
        
        
    </div>
    <div className="row">
        <div className="col-12 d-flex justify-content-end">
        <button type="reset" class="btn linear-gradient-cancel yr-cancelbtn mg-r" onClick={() => this.props.popupClose()} ><span> Close </span></button>
     <button type="submit" className="btn linear-gradient yr-submitbtn" onClick={()=>this.saveLinkData()}>Save</button>
 </div>
    </div>
 </div>
    )
}
}


export default AddLinks;