import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
let follow;
let needtocall;
let archive;
let teamSelected;
export const DatatableRow = props => (
   //@Rohit
  <Table.Row>
    <Table.Cell>{props.list.added_date}</Table.Cell>
    <Table.Cell>
      <select value={props.list.mass_action} name="mass_action" id={props.list.your_list_id} className="form-control bootstrap-select arrow-color mat-style-call-list call-list-option" onChange={()=>props.saveMass(props.list.your_list_id)}>
          <option value="0">Select mass action</option>
          <option value="1" selected={needtocall}>Need to call</option>
          <option value="2" selected={follow}>Follow up</option>
          <option value="3" selected={archive}>Archive contact</option>
    </select>
       </Table.Cell>
       <Table.Cell>
         
          
               <select id={props.list.your_list_id+'_call_list_tm_id'} name="mass_action"  className="form-control bootstrap-select arrow-color mat-style-call-list call-list-option" onChange={()=>props.updateTeam(props.list.your_list_id)}>
               <option value="0">Select team member</option>
                {props.teamData &&
                            props.teamData.map(function (obj) {
                              //console.log(key);
                              
                              { props.list.call_list_team_id==obj.tm_id ? teamSelected= 'selected' :  teamSelected = "" } 
                              return (
                                <option value={obj.tm_id} selected={teamSelected} >
                                  {obj.tm_name}
                                </option>
                              );
                            })}
                </select>
        </Table.Cell>
      <Table.Cell>{props.list.action }</Table.Cell>
      <Table.Cell>
      {props.list.email_subject} <a onClick={()=>props.showEmaildata(props.list.branch_id)}>Show Content</a>
        </Table.Cell>
      <Table.Cell>{props.list.email}</Table.Cell>
      <Table.Cell>{props.list.fullname}</Table.Cell>
      <Table.Cell>{props.list.phonenumber}</Table.Cell>
      <Table.Cell>
      <a  onClick={()=>props.yourNotes(props.list.your_list_id, props.list.call_list_team_id, 1)}>View/Edit Notes</a></Table.Cell>
    </Table.Row>
);
DatatableRow.propTypes = {
  list: PropTypes.object.isRequired, 
  onSubmitFilter: PropTypes.func.isRequired,  
  
};
