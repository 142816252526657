import React from "react";
import "./integration.css";
import api from "../../api";
import { Form } from "formsy-semantic-ui-react";
import validationRule from "../../validation";
import { Icon, List, Message } from "semantic-ui-react";
import ConfirmDialog from "../Modal/ConfirmDialog";
import config from "../../config";
import utilFunc from "../../util_funs";

validationRule.isRequired();
validationRule.isValidDomain();
class Facebook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: [],
      pageData: null,
      fbData: null,
      selectedPage: null,
      subscribedPages: [],
      subwhitelist: "",
      domainParams: null,
      fbScriptModal: false,
      currentPageId: null,
      whitelistedDomains: [],
      whitelist: "",
      faceBookLogin: true,
      message: null,
      loading: false,
      scriptLoading: false,
      errorMessage: null,
      confirmDialog: null,
      unsubscribePageInfo: null,
    };
    this.FB = window.FB;
    this.responseAT = null;
    this.pageRef = React.createRef();
    this.formRef = React.createRef();
    this.scriptFormRef = React.createRef();
  }

  loadFacebookApi = () => {};

  componentDidMount = () => {
    const { user } = this.props;
    if (user && user.user_id) {
      this.setState({ loading: true });
      api.facebook
        .getSubscribedPages({ userId: user.user_id })
        .then((resp) => {
          if (resp) {
            this.setState({ loading: false, subscribedPages: resp.pageList });
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    }
  };

  confirmUnsubscribePage = (accessToken, pageId, autoId) => {
    this.setState({
      confirmDialog: "Do you really want to disconnect this page.",
      unsubscribePageInfo: {
        accessToken,
        pageId,
        autoId,
      },
    });
  };

  unSubscribePage = () => {
    const { accessToken, pageId, autoId } = this.state.unsubscribePageInfo;
    this.setState({
      loading: true,
      pageData: null,
      faceBookLogin: true,
      confirmDialog: null,
      unsubscribePageInfo: null,
    });

    this.FB.api(
      "/" + pageId + "/subscribed_apps",
      "DELETE",
      {
        subscribed_fields: "['feed', 'mention', 'name', 'picture']",
        App: config.facebookAppId,
        access_token: accessToken,
      },
      (response) => {
        if (!response.success) {
          alert("Sorry! You are not authorized to delete this App.");
        } else {
          api.facebook
            .deleteFbPage({ delete_id: autoId })
            .then((resp) => {
              this.removePage(pageId);
              if (resp && resp.message !== "") {
                this.setState({ loading: false, message: resp.message }, () => {
                  setTimeout(() => {
                    this.setState({ message: null });
                  }, 5000);
                });
              } else {
                this.setState({ loading: false });
              }
            })
            .catch((err) => {
              console.log(err);
              this.setState({ loading: false });
            });
        }
      }
    );
  };

  removePage = (pageId) => {
    const subscribedPages = [];
    this.state.subscribedPages.map((page) => {
      if (page.page_id !== pageId) {
        subscribedPages.push(page);
      }
    });

    this.setState({ subscribedPages });
  };

  loadFbScript = (accessToken, pageId) => {
    this.setState({ loading: true });
    api.facebook
      .fbWhiteListDomain({ access_token: accessToken })
      .then((resp) => {
        if (
          resp &&
          resp.fbresult.data[0] &&
          resp.fbresult.data[0].whitelisted_domains !== undefined &&
          resp.fbresult.data[0].whitelisted_domains.length > 0
        ) {
          this.setState({
            fbScriptModal: true,
            currentPageId: pageId,
            whitelistedDomains: resp.fbresult.data[0].whitelisted_domains,
            domainParams: { accessToken, pageId },
            loading: false,
          });
        } else {
          this.setState({
            domainModal: true,
            domainParams: { accessToken, pageId },
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };
  loadPages = () => {
    return this.state.subscribedPages.map((page, index) => {
      return (
        <div className="page-item" id="" key={index}>
          <div
            className="page-item-container"
            style={{ fontSize: "15px", color: "#a0a0a0" }}
          >
            {page.fbpagename}
          </div>
          <div className="action-block yr-block">
            <button
              onClick={(accessToken, pageId, autoId) =>
                this.confirmUnsubscribePage(
                  page.page_access_token,
                  page.page_id,
                  page.auto_id
                )
              }
              className="btn linear-gradient yr-submitbtn btn-sm"
              style={{ margin: "10px 10px 10px 0" }}
            >
              Disconnect
            </button>
            <button
              onClick={(accessToken, pageId) =>
                this.loadFbScript(page.page_access_token, page.page_id)
              }
              className="btn linear-gradient yr-submitbtn btn-sm"
              style={{ margin: "10px 10px 10px 0" }}
            >
              FACEBOOK SCRIPT
            </button>
          </div>
        </div>
      );
    });
  };

  subscribeToZyra = () => {
    if (Object.keys(this.state.selectedPage).length > 0) {
      api.facebook
        .checkFbPages({ pageId: this.state.selectedPage.page_id })
        .then((resp) => {
          if (resp && !resp.pageExist) {
            this.suscribePages(this.state.selectedPage.page_id);
          }else{
            this.setState({ loading: false, message: "This page is already connected with another user." }, () => {
              setTimeout(() => {
                this.setState({ message: null });
              }, 5000);
            });
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    }
  };

  suscribePages = (pageId) => {
    const page_access_token = this.state.selectedPage.page_access_token;
    const obj = this;
    this.setState({ loading: true });
    this.FB.login(
      function (response) {
        this.FB.api(
          "/" + pageId + "/subscribed_apps",
          "POST",
          {
            subscribed_fields:
            "['feed', 'mention', 'name', 'picture','messaging_postbacks','messages','category','description','conversations','feature_access_list','inbox_labels','standby','message_mention','messages','message_reactions','messaging_account_linking','messaging_checkout_updates','messaging_customer_information','message_echoes','message_deliveries','message_context','messaging_game_plays','messaging_optins','messaging_optouts','messaging_payments','messaging_postbacks','messaging_pre_checkouts','message_reads','messaging_referrals','messaging_handovers','messaging_policy_enforcement','messaging_appointments','messaging_direct_sends','messaging_fblogin_account_linking','user_action','messaging_feedback','group_feed']",
            App: config.facebookAppId,
            access_token: page_access_token,
          },
          function (response) {
            if (!response.success) {
              alert("Sorry! You are not authorized to use this App.");
            } else {
              obj.updateFbPageInfo();
            }
          }
        );
      },
      {
        scope: "pages_messaging,manage_pages",
        return_scopes: true,
        auth_type: "rerequest",
      }
    ); //permissions for facebook
  };

  updateFbPageInfo = () => {
    const { page_name, page_access_token, page_id } = this.state.selectedPage;
    api.facebook
      .updateFbPageInfo({
        page_name,
        page_access_token,
        page_id,
        fb_user_id: this.state.fbData.authResponse.userID,
      })
      .then((resp) => {
        if (resp) {
          const { user } = this.props;
          const message = resp.message;

          api.facebook
            .getSubscribedPages({ userId: user.user_id })
            .then((resp) => {
              if (resp) {
                this.setState(
                  {
                    subscribedPages: resp.pageList,
                    pageData: null,
                    fbData: null,
                    selectedPage: null,
                    pages: [],
                    message,
                    faceBookLogin: true,
                    loading: false,
                  },
                  () => {
                    utilFunc.scrollTop(800);

                    setTimeout(() => {
                      this.setState({ message: null });
                    }, 5000);
                  }
                );
              }
            })
            .catch((err) => {
              console.log(err);
              this.setState({ loading: false });
            });
        }
      });
  };

  handleChange = () => {
    var e = document.getElementById("fbPages");
    var selectedPageID = e.options[e.selectedIndex].value;
    if (this.state.pages.length > 0) {
      const selectedPageInfo = this.state.pages.map((page) => {
        if (page.id === selectedPageID) {
          this.setState({
            selectedPage: {
              page_name: page.name,
              page_id: page.id,
              page_access_token: page.access_token,
            },
          });
        }
      });
    }
  };
  loadFbPages = () => {
    const { pageData, fbData } = this.state;

    return (
      <React.Fragment>
        {pageData.length > 0}
        <div id="lastMessage" className="yr-mainTitle">
          Choose a Page to Connect Your Bot to
        </div>
        <div id="pagesBar" className="yr-cplans">
          <p className="req-tos">
            {" "}
            By connecting your page you accept
            <a
              href="https://www.zyratalk.com/terms"
              style={{ margin: "15px 0 12px 5px" }}
              target="_blank"
            >
              terms of service
            </a>
          </p>
          <div className="yr-sml-h"> YOUR FACEBOOK PAGES </div>

          <div className="pages-list">
            <div className="page-item">
              <div className="page-item-container">
                <div
                  className="page-item-image"
                  style={{
                    backgroundImage: `url("/assets/images/fb-flag.png")`,
                  }}
                ></div>

                <span className="page-item-text ng-binding">
                  <select
                    className="form-control"
                    id="fbPages"
                    name="fbPages"
                    onChange={this.handleChange}
                  >
                    {pageData.map((page, index) => {
                      return (
                        <option value={page.id} key={index}>
                          {page.name}
                        </option>
                      );
                    })}
                  </select>
                </span>
              </div>
              <div className="">
                <button
                  onClick={this.subscribeToZyra}
                  className="btn linear-gradient yr-submitbtn btn-sm"
                >
                  Connect to page
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  formatData = (pageData) => {
    if (this.state.subscribedPages.length > 0) {
      let subscribePageIdList = [];

      this.state.subscribedPages.map((page) => {
        subscribePageIdList.push(page.page_id);
      });
      let newElm = [];
      if (subscribePageIdList.length > 0) {
        pageData.map((page) => {
          if (!subscribePageIdList.includes(page.id)) {
            newElm.push(page);
          }
        });
      }
      return newElm;
    }
    return pageData;
  };
  LoginPopUp = () => {
    const obj = this;
    this.FB.login(
      function (response) {
        if (response.authResponse) {
          this.FB.api(
            `/oauth/access_token?grant_type=fb_exchange_token&client_id=${config.facebookAppId}&client_secret=${config.facebookClientSecret}&fb_exchange_token=` +
              response.authResponse.accessToken,
            function (response3) {
              this.responseAT = response3;
              this.FB.api(
                "/me/accounts?access_token=" +
                  this.responseAT.access_token +
                  "&fields=picture{url},access_token,category,category_list,id,name,tasks",
                function (response1) {
                  console.log(response1);
                  if (response1.data) {
                    const formatedPageList = obj.formatData(response1.data);

                    if (response1.data.length === 0) {
                      obj.setState(
                        {
                          errorMessage: "You dont have any page to connect.",
                        },
                        () => {
                          setTimeout(() => {
                            obj.setState({ errorMessage: null });
                          }, 5000);
                        }
                      );
                    } else {
                      if (formatedPageList.length === 0) {
                        obj.setState(
                          {
                            errorMessage:
                              "All facebook pages has been already connected.",
                          },
                          () => {
                            setTimeout(() => {
                              obj.setState({ errorMessage: null });
                            }, 5000);
                          }
                        );
                      } else {
                        if (formatedPageList.length > 0) {
                          obj.setState({
                            fbData: response,
                            pageData: formatedPageList,
                            selectedPage: {
                              page_name: formatedPageList[0].name,
                              page_id: formatedPageList[0].id,
                              page_access_token:
                                formatedPageList[0].access_token,
                            },
                            pages: response1.data,
                            faceBookLogin: false,
                          });
                        }
                      }
                    }
                  }
                }
              );
            }
          );
        }
      },
      {
        scope: "email,pages_show_list,pages_messaging,manage_pages,pages_manage_metadata",
        return_scopes: true,
      }
    );
  };

  toggleModal = () => {
    this.setState({ domainModal: false, fbScriptModal: false });
  };

  handleInput = (e) => this.setState({ [e.target.name]: e.target.value });

  handleFbscriptFormSubmit = () => {
    this.setState({ scriptLoading: true });
    api.facebook
      .updateDomain({
        whitelist: this.state.whitelist,
        olddomain: this.state.whitelistedDomains,
        access_token: this.state.domainParams.accessToken,
      })
      .then((resp) => {
        if (resp && resp.fbresult && resp.fbresult.result === "success") {
          this.setState(
            {
              whitelist: "",
              scriptLoading: false,
            },
            () => {
              this.scriptFormRef.current.reset();
              this.loadFbScript(
                this.state.domainParams.accessToken,
                this.state.currentPageId
              );
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ scriptLoading: false });
        console.log(err);
      });
  };
  handleSubmit = () => {
    api.facebook
      .updateDomain({
        whitelist: this.state.subwhitelist,
        olddomain: "",
        access_token: this.state.domainParams.accessToken,
      })
      .then((resp) => {
        console.log(resp);
      })
      .then((err) => console.log(err));
  };
  formSubmit = (type = false) => {
    if (type && type === "script") {
      return this.scriptFormRef.current.submit();
    }
    this.formRef.current.submit();
  };

  loadDomainModal = () => {
    const errorLabel = <div className="red" pointing={"true"} />;

    return (
      <div
        className={this.state.domainModal ? "modal fade show " : "modal fade"}
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: this.state.domainModal ? "block" : "none" }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ maxWidth: "600px" }}
        >
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.toggleModal}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{"Add Domain"}</h5>
            </div>
            <div
              className="modal-body"
              style={{ maxHeight: "300px", overflowX: "auto" }}
            >
              <Form
                noValidate
                autoComplete="off"
                onValidSubmit={this.handleSubmit}
                ref={this.formRef}
              >
                <Form.Input
                  label="Please enter the domain which will be used for the Zyratalk messenger chatbot."
                  type="text"
                  autoComplete="off"
                  name="subwhitelist"
                  placeholder="Domain"
                  onChange={this.handleInput}
                  validations="isRequired,isValidDomain"
                  validationErrors={{
                    isRequired: "This field is required.",
                    isValidDomain: "Please enter valid domain url.",
                  }}
                  errorLabel={errorLabel}
                />
              </Form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={this.toggleModal}
                className="btn linear-gradient yr-submitbtn btn-sm"
              >
                Cancel
              </button>

              <button
                type="button"
                onClick={this.formSubmit}
                className="btn linear-gradient yr-submitbtn btn-sm"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getFbscriptText = () => {
    return (
      <div
        style={{
          fontStyle: "italic",
          fontWeight: "600",
          fontSize: "14px",
          color: "#6c6c6c",
        }}
        dangerouslySetInnerHTML={{
          __html: `&#x3C;!-- Load Facebook SDK for JavaScript --&#x3E;<br>
    &#x3C;div id=&#x22;fb-root&#x22;&#x3E;&#x3C;/div&#x3E;<br>
    &#x3C;script&#x3E;<br>
      window.fbAsyncInit = function() {<br>
        FB.init({<br>
          xfbml            : true,<br>
          version          : &#x27;v3.2&#x27;<br>
        });<br>
      };<br>
      (function(d, s, id) {<br>
      var js, fjs = d.getElementsByTagName(s)[0];<br>
      if (d.getElementById(id)) return;<br>
      js = d.createElement(s); js.id = id;<br>
      js.src = &#x27;https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js&#x27;;<br>
      fjs.parentNode.insertBefore(js, fjs);<br>
    }(document, &#x27;script&#x27;, &#x27;facebook-jssdk&#x27;));
    <br>
    &#x3C;/script&#x3E;<br>

    &#x3C;!-- Your customer chat code --&#x3E;<br>
    &#x3C;div className=&#x22;fb-customerchat&#x22;<br>
      attribution=setup_tool<br>
      page_id=&#x22;${this.state.currentPageId}&#x22;&#x3E;<br>
    &#x3C;/div&#x3E;<br>
 `,
        }}
      ></div>
    );
  };
  removeDomain = (domainIndex) => {
    const domainInfo = [];
    this.state.whitelistedDomains.map((domain, index) => {
      if (index !== domainIndex) {
        domainInfo.push(domain);
      }
    });

    if (domainInfo) {
      this.setState({ scriptLoading: true });
      api.facebook
        .deletefbDomain({
          domainList: domainInfo,
          access_token: this.state.domainParams.accessToken,
        })
        .then((resp) => {
          if (resp && resp.fbresult && resp.fbresult.result === "success") {
            this.setState({
              whitelistedDomains: domainInfo,
              scriptLoading: false,
            });
          } else {
            this.setState({
              scriptLoading: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ scriptLoading: false });
          console.log(err);
        });
    }
  };

  displayWhiteListDomains = () => {
    return this.state.whitelistedDomains.map((domain, index) => {
      return (
        <List.Item as="li" key={index}>
          {domain}{" "}
          {this.state.whitelistedDomains.length > 1 ? (
            <Icon
              name="trash"
              onClick={(domain) => this.removeDomain(index)}
              style={{
                color: "red",
                cursor: "pointer",
                margin: "0 0 0 8px",
                fontSize: "12px",
              }}
            />
          ) : null}
        </List.Item>
      );
    });
  };
  loadFbScriptModal = () => {
    const errorLabel = <div className="red" pointing={"true"} />;

    return (
      <div
        className={this.state.fbScriptModal ? "modal fade show " : "modal fade"}
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{
          display: this.state.fbScriptModal ? "block" : "none",
          overflow: "auto",
        }}
      >
        {this.state.scriptLoading && <div className="ui loading form"></div>}
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.toggleModal}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{"Facebook Messenger"}</h5>
            </div>
            <div className="modal-body" style={{ margin: "0 15px" }}>
              <div
                style={{
                  background: "#e8e8e8",
                  padding: "10px 15px",
                  margin: "15px 0 16px 0",
                }}
              >
                <h4
                  style={{
                    fontWeight: "600px",
                    margin: 0,
                    lineHeight: "1.42857",
                  }}
                >
                  Facebook messenger script
                </h4>
              </div>

              {this.getFbscriptText()}
              <div
                style={{
                  background: "#e8e8e8",
                  padding: "10px 15px",
                  margin: "15px 0 16px 0",
                }}
              >
                <h4
                  style={{
                    fontWeight: "600px",
                    margin: 0,
                    lineHeight: "1.42857",
                  }}
                >
                  Domain Listings
                </h4>
              </div>

              <div className="form-group">
                <label className="control-label">
                  You have already added the below white listed domain for
                  Zyratalk messenger chatbot: <br />
                  <div
                    id="wt_domain"
                    style={{ paddingTop: "10px", margin: "7px 0 9px 27px" }}
                  >
                    {this.state.whitelistedDomains.length > 0 && (
                      <List as="ol">{this.displayWhiteListDomains()}</List>
                    )}
                  </div>
                  <br />
                  <div>
                    If you want to enable the Zyratalk messenger chatbot to
                    another domain. Please add below:{" "}
                  </div>
                </label>
                <div className="">
                  <Form
                    noValidate
                    autoComplete="off"
                    onValidSubmit={this.handleFbscriptFormSubmit}
                    ref={this.scriptFormRef}
                  >
                    <Form.Input
                      label="Please enter the domain which will be used for the Zyratalk messenger chatbot."
                      type="text"
                      autoComplete="off"
                      name="whitelist"
                      value={this.state.whitelist}
                      placeholder="Domain"
                      onChange={this.handleInput}
                      validations="isRequired,isValidDomain"
                      validationErrors={{
                        isRequired: "This field is required.",
                        isValidDomain: "Please enter valid domain url.",
                      }}
                      errorLabel={errorLabel}
                    />
                  </Form>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                onClick={this.toggleModal}
                className="btn linear-gradient yr-submitbtn btn-sm"
              >
                Close
              </button>

              <button
                type="button"
                onClick={(type) => this.formSubmit("script")}
                className="btn linear-gradient yr-submitbtn btn-sm"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  toggleConfrimModal = () => {
    this.setState({ confirmDialog: null });
  };
  confirmDialog = () => {
    return (
      <ConfirmDialog
        show={this.state.confirmDialog}
        toggleModal={this.unSubscribePage}
        closeAction={this.toggleConfrimModal}
        title="Confirm your action"
        message={this.state.confirmDialog}
      />
    );
  };
  render() {
    return (
      <React.Fragment>
        {this.state.message && (
          <Message color={"green"}>{this.state.message}</Message>
        )}

        {this.state.errorMessage && (
          <Message attached="bottom" warning color="red">
            {this.state.errorMessage}
          </Message>
        )}

        {this.state.loading && <div className="ui loading form"></div>}
        {this.state.subscribedPages.length > 0 && (
          <div>
            <div className="yr-fbBox" id="yr-fbBox-main">
              <div className="yr-sml-h" style={{ fontSize: "15px" }}>
                Already subscribed facebook pages{" "}
              </div>
              {this.state.subscribedPages.length > 0 && this.loadPages()}
            </div>
            <br />
          </div>
        )}

        <div className="yr-fbBox" id="pagesContainer">
          {this.state.pageData && this.loadFbPages()}
          <div id="lastMessage" className="yr-mainTitle">
            {this.state.faceBookLogin && (
              <a
                className="fbloginpopup"
                onClick={this.LoginPopUp}
                style={{ cursor: "pointer" }}
              >
                <img
                  src="/assets/images/facebook.svg"
                  title="Facebook"
                  style={{ maxWidth: "300px", width: "100%" }}
                />
              </a>
            )}
          </div>
        </div>

        {this.state.domainModal && this.loadDomainModal()}
        {this.state.fbScriptModal && this.loadFbScriptModal()}
        {this.state.confirmDialog && this.confirmDialog()}
      </React.Fragment>
    );
  }
}

export default Facebook;
