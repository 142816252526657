import React from "react";
import {
    Form, Dropdown, TextArea
} from 'formsy-semantic-ui-react';
import { Message } from 'semantic-ui-react';
import validationRule from "../../../../validation";
import "../../../Modal/dialog.css";
import './Popup.css';
import 'semantic-ui-css/semantic.min.css';
import { addValidationRule } from "formsy-react";
import axios from "../../../../config/axios";
import api from "../../../../api";
import utilFunc from "../../../../util_funs";


validationRule.isRequired();
validationRule.usPhoneLength();
validationRule.newPinAndOldPinNotSame();

class DialogBoxAddEditLocations extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading:"none"

		}


	}
	
	onValidSubmit = e =>{
		if(this.state.within=="zip")
			var t = "Zip Codes";
		else
			var t = "States"
		this.setState({
			popup:this.state.within,
			title:t
		})
	}

	

	modalCloseHere = e =>{
		
		this.props.deleteLocationConfirmationPopupClose();
		
	}

	deleteLocationNow = e =>{
		this.setState({
			loading:"block",
		})
		api.manageUser
            .deleteLocation({loc_id:this.props.obj.loc_id})
            .then((resp) => {
				
					this.props.deleteLocationNow(this.props.obj.loc_id,this.props.obj.type)
					this.props.afterLocationAddEditDelete("delete",this.props.obj.type)
					this.setState({
						loading:"none",
					})
				
            })
            .catch((e) => {
                console.log(e);
            });

	}

	



    render() {
        const errorLabel = <div className="red" />
        return (
            // (this.props.loadNow)?
            <React.Fragment>
                <div
                    id="notificationSetingModalPopup"
                    className={this.props.obj.confirmationPopup ? "modal fade show" : "modal fade"}
                    data-backdrop="static"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="staticBackdrop"
                    aria-hidden="true"
                    style={{ display: this.props.obj.confirmationPopup ? "block" : "none" }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:600}}>
					<div className="modal-content">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => this.modalCloseHere(false, '')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{this.props.obj.confirmationPopupTitle}</h5>
						</div>

							<div style={{marginLeft:33,marginTop:13}}>{this.props.obj.confirmationPopupMsg}</div>
						<div className="modal-footer">
							<button type="button" onClick={() => this.modalCloseHere()}  className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" data-dismiss="modal">
								<span>No</span>
							</button>
							<button type="button" onClick={() => this.deleteLocationNow()}   className="btn linear-gradient yr-submitbtn btn-sm">
								Yes
							</button>
						</div>
					</div>
				</div>
                </div>
            </React.Fragment>
        );
    };
}

export default DialogBoxAddEditLocations;
