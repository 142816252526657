import React from "react";
import { Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
export const DatatableRow = (props) => (
  <Table.Row>
    <Table.Cell>
      {props.list.user_type != "main" ? (
        <span>
          <a
            onClick={() => props.deleteLiveChat(props.list.id)}
            title="Delete"
            className="btn btn-icon gray"
          >
            <i className="la la-trash"></i>
          </a>{" "}
        </span>
      ) : (
        ""
      )}
    </Table.Cell>
    <Table.Cell>{props.list.username}</Table.Cell>
    <Table.Cell>{props.list.email_id}</Table.Cell>
    <Table.Cell>
      {props.list.isd_code + " " + props.list.phone_number}{" "}
    </Table.Cell>
    <Table.Cell>{props.list.jump_in_question}</Table.Cell>
    <Table.Cell>
      {props.list.profile_pic == "" ? (
        <img
          height="50px"
          src={
            props.sessionUser.assetPath +
            "/layouts/layout2/img/default-user-icon-4.jpg"
          }
        />
      ) : (
        <img
          height="50px"
          src={
            props.sessionUser.assetPath +
            "/images/chatlogo/" +
            props.list.profile_pic
          }
        />
      )}
    </Table.Cell>
  </Table.Row>
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
