import React, { Component, useState, useEffect } from 'react'
import billing from "../../api/billing";
import { Loader } from 'semantic-ui-react'
import './invoiceDetail.css';
import config from '../../config';
import util_funs from '../../util_funs';
// import ReactDOM from "react-dom";
// import Pdf from "react-to-pdf";

const ref = React.createRef();
export default function InvoiceDetail(props) {
    const [invoiceDetail, setInvoiceDetail] = useState({ invoiceIdStatus: false, invoiceData: {}, loaderstatus: true, loader: true });
    useEffect(() => {
        let requestData = { invoice_id: props.invoiceId }
        if( props.invoiceId != invoiceDetail.invoiceData.invoice_id  ){
            setInvoiceDetail({...invoiceDetail,loader:true});
        }
        if( props.invoiceId != invoiceDetail.invoiceData.invoice_id ){
            billing.GetInvoiceDetail( requestData ).then( (resp) => {
                if( resp ){
                    setInvoiceDetail({...invoiceDetail,invoiceIdStatus:true,invoiceData:resp,loader:false});
                }
            });
        }
    },[props.invoiceId]);
    const downloadPDF = ( e ) => {
        console.log(props.invoiceId,'invoice id')
        let link = config.serverBaseURL+"/backendportal/invoice_download/"+props.invoiceId+"/"+util_funs.encrypt_url(props.user_id);
        window.open(link, "_blank")
    }
    const showAddress  = ( adressArray ) => {
        // adressArray =  JSON.parse( adressArray);
        let address = adressArray;
       /*  let street = adressArray.street;
        let city = adressArray.city;
        let state = adressArray.state;
        let zip = adressArray.zip;
        let country = adressArray.country; */
        
        return (
            <React.Fragment>
                <div style={{marginTop:"5px"}}>{address?.biller_street} </div>
                <div>{address?.biller_address} </div>
                <div>{address?.biller_country} </div>
           </React.Fragment>
        )
    }
    if (!invoiceDetail.loader) {
        return (
            invoiceDetail.invoiceIdStatus ?
                <div className="container">
                    <div className="text-right">
                    <i className="fa fa-download  fa-3x" onClick={(e) => downloadPDF(e)}></i>
                        {/* <Pdf targetRef={ref} filename={"invoice"+invoiceDetail.invoiceData.number+".pdf"}>
                            {({ toPdf }) => <i className="fa fa-download  fa-3x" onClick={toPdf}></i>}
                        </Pdf> */}
                    </div>
                    <div className="pdf-preview" ref={ref}>
                        <span className="status"><i></i>{invoiceDetail.invoiceData.status}<i></i></span>
                        <div className="content">
                            <div className="row justify-content-between">
                                <div className="col-sm-5">
                                    <h5 className="invoice-to mb-0" style={{fontSize: '10pt', color: '#333', fontFamily: 'sans-serif',fontWeight:"600"}}>{invoiceDetail.invoiceData.biller.biller_name  }</h5>
                                    <p className="to-address m-0" style={{ fontSize: '10pt',fontWeight:"400"}}>
                                    {showAddress(invoiceDetail.invoiceData.biller)}
                                    {/* {(invoiceDetail.invoiceData.billing_address.street ).toUpperCase() } &nbsp;
                                    {(invoiceDetail.invoiceData.billing_address.address).toUpperCase()  } <br/>
                                    {(invoiceDetail.invoiceData.billing_address.city ).toUpperCase() } &nbsp;
                                    {(invoiceDetail.invoiceData.billing_address.country).toUpperCase()  } 
                                    <br className="d-none d-sm-inline-block" /> 
                                    {invoiceDetail.invoiceData.zip  } */}
                                    </p>
                                </div>
                                <div className="col-sm-5 offset-sm-2">
                                    <div className="text-right mt-2 text-dark">
                                        <h2 className="font-weight-normal invoice-title" style={{fontSize: '28pt', fontFamily: 'sans-serif', marginBottom: 0}}>INVOICE</h2>
                                        <p className="mt-2 mb-4  invoice-no" style={{fontSize: '10pt',fontWeight: 600,fontFamily: 'sans-serif',color: '#333'}}>{'# '+invoiceDetail.invoiceData.number}
                                        </p>
                                        <div className="mb-4">
                                            <span className="small-title" style={{fontWeight:"701"}}>Balance Due</span>
                                            <h5 style={{fontSize: '12pt', fontWeight: 600,fontFamily: 'sans-serif', marginBottom: 10,marginTop:"0px"}}>${parseFloat(invoiceDetail.invoiceData.total).toFixed(2)  }</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-between align-items-end">
                                <div className="col-md-3 small">
                                    <span style={{fontFamily: 'sans-serif',fontSize: '9pt',color: '#333'}}>Bill To</span>
                                    <p className='m-top-0' style={{fontFamily: 'sans-serif',fontSize: '9pt',fontWeight:"701", lineHeight: 1.42857,margin: 0}}>{invoiceDetail.invoiceData.customer_name  }</p>
                                </div>
                                <div className="col-md-5 offset-md-4">
                                    <div className="text-right text-dark">
                                        <table className="table table-borderless text-right table-sm no-padding" style={{marginBottom: 0}}>
                                            <tr>
                                                <td>Invoice Date :</td>
                                                <td>{invoiceDetail.invoiceData.invoice_date.substring(0,10)  }</td>
                                            </tr>
                                            <tr>
                                                <td>Terms :</td>
                                                <td>{invoiceDetail.invoiceData.payment_terms_label  }</td>
                                            </tr>
                                            <tr>
                                                <td>Due Date :</td>
                                                <td>{invoiceDetail.invoiceData.due_date  }</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <table className="table border-bottom border-dark item-list mb-0">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>#</th>
                                        <th className="w-50" >Item & Description</th>
                                        <th className="w-20">Qty</th>
                                        <th>Rate</th>
                                        <th className="text-right">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{fontWeight:"600"}}>{1}</td>
                                    <td>
                                        {/* <p style={{fontWeight:"600",fontSize:"13px"}} className="text-dark m-0 small">{invoiceDetail.invoiceData.customer_name  }</p> */}
                                        <p style={{fontWeight:"600",fontSize:"13px"}} className="m-0 small">{invoiceDetail.invoiceData.description}</p>
                                        <p style={{fontWeight:"600"}} className="m-0 small">{invoiceDetail.invoiceData.invoice_date.length>0 && 'Charges for this duation (from '+ invoiceDetail.invoiceData.date_from +' to '+ invoiceDetail.invoiceData.date_to+')'}</p>
                                    </td>
                                    <td style={{fontWeight:"600"}}>1{/* {invoiceDetail.invoiceData.quantity} */}</td>
                                    <td style={{fontWeight:"600"}} className="text-right">{parseFloat(invoiceDetail.invoiceData.total).toFixed(2)  }</td>
                                    <td style={{fontWeight:"600"}} className="text-right">{parseFloat(invoiceDetail.invoiceData.total).toFixed(2)  }</td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="row justify-content-end mb-4">
                                <div className="col-md-5 offset-md-7">
                                    <table className="table table-borderless text-right table-overview pe-2 mt-0">
                                        <tr>
                                            <td style={{fontWeight:"600"}}>Sub Total</td>
                                            <td style={{fontWeight:"600"}}>{parseFloat(invoiceDetail.invoiceData.total).toFixed(2)  }</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:"800",fontSize:"12px"}}>Total</td>
                                            <td style={{fontWeight:"800",fontSize:"12px"}}>${parseFloat(invoiceDetail.invoiceData.total).toFixed(2)  }</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:"600"}}>Payment Made</td>
                                            <td style={{fontWeight:"600"}} className="text-danger">(-) {parseFloat(invoiceDetail.invoiceData.total).toFixed(2)  }</td>
                                        </tr>
                                        <tr style={{backgroundColor: '#efefef'}}>
                                            <td style={{fontWeight:"800",fontSize:"12px"}}>Balance Due</td>
                                            <td style={{fontWeight:"800",fontSize:"12px"}}>$0.00</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div className="text-dark fw-light">
                                <p className="mb-2" style={{fontSize: '10pt', color: '#333', fontFamily: 'sans-serif',fontWeight:600 }}>Notes</p>
                                <p className="mb-0" style={{fontSize: '8pt',color: '#333',fontFamily: 'sans-serif',fontWeight:600}}>{invoiceDetail.invoiceData?.notes }</p>
                            </div>
                        </div>
                    </div>
                </div> :
                <div className="container text-center">
                    <i className="fa fa-exclamation-triangle text-danger fa-3x" ></i>
                    <p style={{ fontSize: '1.2rem' }}>Invalid Invoice ID</p>
                </div>
        );
    } else {
        return (<Loader style={{ display: "block" }}></Loader>)
    }
}
