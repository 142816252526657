import React, { useState } from "react";
import { Form, Dropdown } from "formsy-semantic-ui-react";
import { Message } from "semantic-ui-react";
import validationRule from "../../../../../validation";
import "../../../../Modal/dialog.css";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
validationRule.isRequired();
validationRule.minCustomLength(6);
validationRule.usPhoneLengthTM();

const DialogBoxAddEdit = ({
  obj,
  modalClose,
  onValidSubmit,
  handleCheck,
  checkEmail,
  checkPhone,
  handleDrop,
  onImageLoaded,
  onCropComplete,
  onCropChange,
  handleFile,
  getCroppedImg,
  handleRemovePic,
  handleRemovePicAdd,
  userdata,
}) => {
  let show = obj.modalstatusAddEdit;
  let title = obj.modaltitle;
  let phone = obj.phone;
  let modalErrorMsg = obj.modalErrorMsg;
  let modaldata = obj.modaldata;
  let modalFormLoader = obj.modalFormLoader;
  const errorLabel = <div className="red" />;
  const mystyle = {
    padding: "3.25rem",
  };
  let redButton = {
    marginTop: '-11px'
  };

  console.log(userdata)

  //const imageUrl = "http://localhost/zyratalk/assets/teamImg/"+modaldata.team_src 
  const imageUrl = userdata.assetPath + "/teamImg/" + modaldata.team_src
  const renderDropDownDefault = (defaultCountryCodeArr, countryId = null) => {

    return defaultCountryCodeArr.map((option, index) => {
      return (
        <option key={index} value={option.country_code} selected={countryId == option.country_code ? 'selected' : ''} >
          {option.country_name}
        </option>
      );
    });
  }
  const renderDropDownRemaining = (remailCountryCodeArr, countryId = null) => {

    return remailCountryCodeArr.map((option, index) => {
      return (
        <option key={index} value={option.country_code} selected={countryId == option.country_code ? 'selected' : ''} >
          {option.country_name}
        </option>
      );
    });
  }

  return (
    <React.Fragment>
      <div
        className={show ? "modal fade show" : "modal fade"}
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => modalClose(false, "formManageUser")}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
            </div>
            <Form
              noValidate
              autoComplete="off"
              id="formManageUser"
              onValidSubmit={onValidSubmit}
            >
              {modalFormLoader && <div className="ui loading form"></div>}
              <div className="modal-body" style={mystyle}>
                <div className="kt-portlet__body py-0">
                  <div className="kt-section kt-section--first">
                    <Message
                      color="red"
                      style={{ display: modalErrorMsg ? "block" : "none" }}
                    >
                      This email-id is already exists.
                    </Message>
                    <div className="kt-section__body">
                      <div className="form-group row">
                        <label className="col-lg-4 col-form-label">
                          Team Member Name :
                        </label>
                        <div className="col-lg-8">
                          <Form.Input
                            fluid
                            autoComplete="off"
                            name="member_name"
                            value={modaldata.member_name}
                            placeholder=""
                            validations="isRequired"
                            validationErrors={{
                              isRequired: "This field is required.",
                            }}
                            errorLabel={errorLabel}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-4 col-form-label">
                          Email :
                        </label>
                        <div className="col-lg-8">
                          <Form.Input
                            fluid
                            autoComplete="off"
                            name="email"
                            value={modaldata.email}
                            placeholder=""
                            validations="isEmail,isRequired"
                            onBlur={checkEmail}
                            validationErrors={{
                              isEmail: "Please enter a valid email address.",
                              isRequired: "This field is required.",
                            }}
                            errorLabel={errorLabel}
                          />
                          {obj.duplicateEmailError && (
                            <div class="red" style={redButton}>{obj.duplicateEmailError}.</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-4 col-form-label">
                          Phone Number :
                        </label>
                        <div className="col-lg-4">
                          <select class="form-control Country_code" name="isd_code" onChange={handleDrop} id="isd_code_id_review" >
                            {renderDropDownDefault(obj.renderOption1, modaldata.country_code)}
                            <optgroup label="Other countries">
                              {renderDropDownRemaining(obj.renderOption2, modaldata.country_code)}
                            </optgroup>
                          </select>
                        </div>
                        <div className="col-lg-4">
                          <Form.Input
                            fluid
                            name="phone"
                            value={obj.phone}
                            placeholder=""
                            validations="usPhoneLengthTM"
                            onChange={checkPhone}
                            validationErrors={{
                              usPhoneLengthTM: "Phone number must be at least 10 numbers."
                            }}
                            errorLabel={errorLabel}
                          />
                          {obj.duplicatePhoneError && (
                            <div class="red" style={redButton}>{obj.duplicatePhoneError}.</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-12 col-form-label">
                          Receive review notifications via text or email?
                          (Optional)
                        </label>
                        <div className="col-lg-12">
                          <div className="kt-checkbox-inline">
                            <label className={(obj.dataInPhone.length == 0) ? "kt-checkbox mh-BlurrOpt" : "kt-checkbox"}>
                              {modaldata.notification_text === "1" && (
                                <input
                                  type="checkbox"
                                  onChange={handleCheck}
                                  name="notification_text"
                                  defaultChecked={true}
                                  id="notification_text"
                                />
                              )}
                              {modaldata.notification_text !== "1" && (
                                <input
                                  type="checkbox"
                                  onChange={handleCheck}
                                  name="notification_text"
                                  defaultChecked={false}
                                  id="notification_text"
                                />
                              )}{" "}
                              Text
                              <span />
                            </label>
                            <label className="kt-checkbox">
                              {modaldata.notification_email === "1" && (
                                <input
                                  type="checkbox"
                                  onChange={handleCheck}
                                  name="notification_email"
                                  defaultChecked={true}
                                  id="notification_email"
                                />
                              )}
                              {modaldata.notification_email !== "1" && (
                                <input
                                  type="checkbox"
                                  onChange={handleCheck}
                                  name="notification_email"
                                  defaultChecked={false}
                                  id="notification_email"
                                />
                              )}{" "}
                              Email
                              <span />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row" style={{ marginTop: 24 }}>
                        <label className="col-lg-4 col-form-label" style={{ marginTop: 0 }} htmlFor="profile_pic">Photo</label>
                        <div className="col-lg-4">

                          {obj.modalType == "Edit" && (
                            <div>
                              {modaldata.team_src ?
                                <div>
                                  {obj.src && (
                                    <div style={{ textAlign: 'center', marginBottom: 5 }}>
                                      {obj.DeletePic ?
                                        <ReactCrop
                                          src={obj.src}
                                          crop={obj.crop}
                                          onImageLoaded={onImageLoaded}
                                          onComplete={onCropComplete}
                                          onChange={onCropChange}
                                        />
                                        : ""}
                                    </div>
                                  )}
                                  <div style={{ textAlign: 'center', marginBottom: 5 }}>
                                    {obj.DeletePic && modaldata.team_src && obj.oldImage ?
                                      <img src={imageUrl} ></img>
                                      : ""}
                                  </div>
                                  <div class="d-flex justify-content-center">
                                    <div class="upload-btn-wrapper">
                                      <button class="btn linear-gradient zy-save-btn width-100">{obj.DeletePic && modaldata.team_src ? "Edit Photo" : "Add Photo"}</button>
                                      <input type='file' id='profile_pic' value={obj.profile_pic}
                                        onChange={handleFile} />
                                    </div>
                                    {obj.DeletePic && modaldata.team_src ?
                                      <button type="reset" class="btn linear-gradient zy-cancel-btn ml-2" onClick={handleRemovePic}>Remove</button>
                                      : ""}
                                  </div>
                                </div>
                                :
                                <div>
                                  {obj.src && (
                                    <div style={{ textAlign: 'center', marginBottom: 5 }}>
                                      {obj.DeletePicAdd ?
                                        <ReactCrop
                                          src={obj.src}
                                          crop={obj.crop}
                                          onImageLoaded={onImageLoaded}
                                          onComplete={onCropComplete}
                                          onChange={onCropChange}
                                        />
                                        : ""}
                                    </div>
                                  )}
                                  <div class="d-flex justify-content-center">
                                    <div class="upload-btn-wrapper">
                                      <button class="btn linear-gradient zy-save-btn width-100">Add Photo</button>
                                      <input type='file' id='profile_pic' value={obj.profile_pic}
                                        onChange={handleFile} />
                                    </div>
                                    {obj.DeletePicAdd ?
                                      <button type="reset" class="btn linear-gradient zy-cancel-btn ml-2" onClick={handleRemovePicAdd}>Remove</button>
                                      : ""}
                                  </div>
                                </div>

                              }
                            </div>
                          )}
                          {obj.modalType == "Add" && (
                            <div>
                              {obj.src && (
                                <div style={{ textAlign: 'center', marginBottom: 5 }}>
                                  {obj.DeletePicAdd ?
                                    <ReactCrop
                                      src={obj.src}
                                      crop={obj.crop}
                                      onImageLoaded={onImageLoaded}
                                      onComplete={onCropComplete}
                                      onChange={onCropChange}
                                    />
                                    : ""}
                                </div>
                              )}
                              <div class="d-flex justify-content-center">
                                <div class="upload-btn-wrapper">
                                  <button class="btn linear-gradient zy-save-btn width-100">Add Photo</button>
                                  <input type='file' id='profile_pic' value={obj.profile_pic}
                                    onChange={handleFile} />
                                </div>
                                {obj.DeletePicAdd ?
                                  <button type="reset" class="btn linear-gradient zy-cancel-btn ml-2" onClick={handleRemovePicAdd}>Remove</button>
                                  : ""}
                              </div>
                            </div>
                          )}



                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="kt-portlet__foot">
                <div className="kt-form__actions">
                  <div className="row">
                    <div className="col-lg-12 d-flex justify-content-end">
                      <Form.Input
                        name="id"
                        type="hidden"
                        value={modaldata.id}
                      />
                      <button
                        type="button"
                        onClick={() => modalClose(false, "formManageUser")}
                        className="btn linear-gradient-cancel yr-cancelbtn mg-r"
                      >
                        <span> Cancel </span>
                      </button>
                      <button type="submit" className="btn linear-gradient yr-submitbtn">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DialogBoxAddEdit;
