import React from "react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../store/Page";

import "./Postcard.css";
import "../../../Grid/grid.css"; 
import "../../../Modal/dialog.css";
import DatatableList from "./List/DatatableList";
import { Message } from "semantic-ui-react";
import "../../Marketing/yr-style.css";

import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import api from "../../../../api"; 
import utilFunc from "../../../../util_funs";
import config from "../../../../config";
import util_funs from "../../../../util_funs";

class ListPostcard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templatename:'',
      uploadtemplate:'',
      choosetemplate:'',
      errors: [], 
      dialogMessageM: '',
      dialogLoadingM: false,
      dialogShowM: false, 
      loading:true,
      defaultTemplate:'',
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      tempStatus:'',
      dialogLoadingE: false,
      dialogShowE:false,
      editTempid:'',
    };
    this.html2canvas = window.html2canvas;
    this.dataTable = React.createRef();
  }

  componentDidMount() {
    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "Direct Email",
      navigationName: "marketing",
      subHeaderTitle: "Engage",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    this.loadData();
  }

  loadData = () => {
    api.postcard.onload().then(data => {
        try {    
            this.setState({
              loading: false,
              defaultTemplate: data.data.defaultTemplate,
            }); 
         } catch (err) {
            console.log(err);
        }
        });
    };

  actionModel = (action,id=null) => { 
    if (action == 'Open') {
      this.setState({ 
        templatename:'',
        uploadtemplate:'',
        choosetemplate:'',
        dialogLoadingM: false,
        dialogShowM: true,
        errors: {}, 
        tempStatus:'N'
      });
    }

    if (action == 'Close') {
      this.setState({  
        templatename:'',
        uploadtemplate:'',
        choosetemplate:'',
        dialogLoadingM: false,
        dialogShowM: false,
        errors: {}, 
        dialogLoadingE: false,
        dialogShowE: false,
        tempStatus:''
      });
    }

    if (action == 'Edit') {
      this.setState({  
        templatename:id.title,
        uploadtemplate:'',
        choosetemplate:'',
        dialogLoadingE: false,
        dialogShowE: true,
        errors: {}, 
        tempStatus:id.status,
        editTempid:id.auto_id,
      });
    }

    if (action == 'Save') {
      this.actionEdit();
    }

    if (action == 'Yes') {
      this.actionAdd();
    }
 
}


  onchange = (e) => { 
    this.setState({ [e.target.name]: e.target.value }); 
    if(e.target.value!=''){
      let errList = this.state.errors;
      delete errList[e.target.name];
      this.setState({ errors: errList });
    }else{
        let errList = this.state.errors;
        errList[e.target.name] = "This filed is required.";
        this.setState({ errors: errList }) 
    }
  }


  actionAdd = () => {
    var obj = this; 
    this.validateForm(this.state, function(errors){
        obj.setState({ errors });
        if(Object.keys(errors).length==0){ 
          
         if(obj.state.choosetemplate!=''){
          obj.setState({ dialogLoadingM: true });
          
           let datalist = obj.state.defaultTemplate;
           let recordlist = datalist.findIndex((list) => list.auto_id === obj.state.choosetemplate);
           if(obj.state.defaultTemplate[recordlist].html_content==''){
             
              api.postcard.addTemplate({'title':obj.state.templatename,'uploadImg':'','choosetemplate':obj.state.choosetemplate,'byteCodeImg':'','status':obj.state.tempStatus,'contentHtml':''}).then(data => {
                obj.setState({ dialogLoadingM: false });
                try {
                  window.location.replace("/marketing/editpostcard/" +  util_funs.encrypt_url(data.data.auto_id));
                    //obj.showToaster('green', data.data);
                    //utilFunc.scrollTop(800);
                } catch (err) {
                    console.log(err);
                }
            });

           }else{
 
          document.querySelector('iframe').contentDocument.write(obj.state.defaultTemplate[recordlist].html_content);
          if(obj.props.user.chat_logo!='')
          if(document.getElementById('iframe').contentWindow.document.getElementById('logid')!=null){
          document.getElementById('iframe').contentWindow.document.getElementById('logid').src = config.serverBaseURL+"/assets/images/chatlogo/"+obj.props.user.chat_logo
          }
          obj.html2canvas(document.getElementById('iframe').contentWindow.document.getElementsByTagName("html")[0],{
            logging: true, 
            letterRendering: 1, 
            useCORS: true,
          }).then((canvas)=> {			
          var extra_canvas = document.getElementById('iframe').contentWindow.document.createElement("canvas");
          extra_canvas.setAttribute('width',1875);
          extra_canvas.setAttribute('height',1275);
          var ctx = extra_canvas.getContext('2d');
          ctx.drawImage(canvas,0,0,canvas.width, canvas.height,0,0,1875,1275);
          ctx.scale(3,3);
          var dataURL = extra_canvas.toDataURL();   

              let byteCodeImg;
              let uploadImg;
              
                obj.getImageUrl(obj, function(img){
                  if(obj.state.choosetemplate==''){
                    uploadImg = img;
                  }else{
                    byteCodeImg = img;
                  }
                  api.postcard.addTemplate({'title':obj.state.templatename,'uploadImg':uploadImg,'choosetemplate':obj.state.choosetemplate,
                  'byteCodeImg':byteCodeImg,
                  'status':obj.state.tempStatus,
                  'contentHtml':'<!DOCTYPE html><html lang="en">'+document.getElementById('iframe').contentWindow.document.getElementsByTagName("html")[0].innerHTML+'</html>',
                  'templateImg':dataURL
                  }).then(data => {
                    obj.setState({ dialogLoadingM: false });
                    try {
                      window.location.replace("/marketing/editpostcard/" + util_funs.encrypt_url(data.data.auto_id));
                        //obj.showToaster('green', data.data);
                        //utilFunc.scrollTop(800);
                    } catch (err) {
                        console.log(err);
                    }
                });
              });

            });
          
        }

        }else{

            let byteCodeImg;
            let uploadImg;
            obj.setState({ dialogLoadingM: true });
              obj.getImageUrl(obj, function(img){
                if(obj.state.choosetemplate==''){
                  uploadImg = img;
                }else{
                  byteCodeImg = img;
                }
                api.postcard.addTemplate({'title':obj.state.templatename,'uploadImg':uploadImg,'choosetemplate':obj.state.choosetemplate,'byteCodeImg':byteCodeImg,'status':obj.state.tempStatus}).then(data => {
                  obj.setState({ dialogLoadingM: false });
                  try {
                    window.location.replace("/marketing/editpostcard/" + util_funs.encrypt_url(data.data.auto_id));
                      //obj.showToaster('green', data.data);
                      //utilFunc.scrollTop(800);
                  } catch (err) {
                      console.log(err);
                  }
              });
            });
 
          }



        }else{
            let scrolltop=document.querySelector(".focusError").offsetTop - document.body.scrollTop;
            window.scroll({top: scrolltop, left: 0, behavior: 'smooth' });
        }
    })
  }

  actionEdit = () =>{
    var obj = this; 
    this.validateFormEdit(this.state, function(errors){
        obj.setState({ errors });
        if(Object.keys(errors).length==0){ 
          obj.setState({ dialogLoadingM: true });
          api.postcard.editTemplate({'title':obj.state.templatename,'status':obj.state.tempStatus,'id':obj.state.editTempid}).then(data => {
            obj.setState({ dialogLoadingE: false, editTempid:'' });
            obj.actionModel('Close');
            obj.dataTable.current.loadData({});
            try {
                obj.showToaster('green', data.data);
                utilFunc.scrollTop(800);
            } catch (err) {
                console.log(err);
            }
          });
        }
    })
  }

  validateFormEdit = (data,callback) =>{
    const errors = {}; 
    if (data.templatename.trim()=='') {
        errors['templatename'] = "This filed is required.";
    } 
    callback(errors);
  }

 validateForm = (data,callback) => { 
    const errors = {}; 
    if (data.templatename.trim()=='') {
        errors['templatename'] = "This filed is required.";
    } 
    if (data.uploadtemplate =='' && data.choosetemplate =='') {
      errors['alertOne'] = "Please select upload or choose template option.";
      this.showToaster('red', errors['alertOne']);
      utilFunc.scrollTop(800);
    }   
    callback(errors);
 };


 onchangeTemplate = (event1) => {   
  var imageMIME = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
  var mainfile=event1.target.files[0];
  if(mainfile.size <'2000000'){
      var _URL = window.URL || window.webkitURL;
      var img = new Image();
      var objectUrl = _URL.createObjectURL(mainfile);
      var objnew=this;
      var event=event1
      img.onload =  function(event) {
       //alert(this.width + " " + this.height);
        _URL.revokeObjectURL(objectUrl);
        if(this.width == 1875 && this.height==1275){
          if(imageMIME.includes(mainfile.type) == true && mainfile.type!=''){
            let errList = objnew.state.errors;
            delete errList['uploadtemplate'];
            objnew.setState({ errors: errList });
            let file = mainfile;
            //let imgurl = URL.createObjectURL(event.target.files[0]);
            //console.log(imgurl);
            objnew.setState({
              uploadtemplate: file,
              choosetemplate: '',
            })
            var all = document.getElementsByClassName('postcard--listitem');
            for (var i = 0; i < all.length; i++) {
              all[i].style.pointerEvents = 'none';
            }
        }else{
            let errList = objnew.state.errors;
            errList['uploadtemplate'] = 'Invalid file format. Only "jpg, jpeg, png, gif" files are supported.';
            objnew.setState({ errors: errList }) 
        }
        }else{
          let errList = objnew.state.errors;
          errList['uploadtemplate'] = 'Image size should be 1875px wide by 1275px tall.';
          objnew.setState({ errors: errList }) 
        }
        


    };
    img.src = objectUrl;

  }else{
    let errList = this.state.errors;
    errList['uploadtemplate'] = 'Image size should be less than 2 MB.';
    this.setState({ errors: errList }) 
  }
  

} 

onremoveTemplate =(event) =>{
  document.getElementById("uploadtemplateID").value = "";
  this.setState({
    uploadtemplate: '',
    choosetemplate: ''
  })
  var all = document.getElementsByClassName('postcard--listitem');
  for (var i = 0; i < all.length; i++) {
    all[i].style.pointerEvents = '';
  }
}

onclickTemp =(e,id) =>{
  this.setState({
    choosetemplate: id
  })
  
}

getImageUrl = (obj,callback) =>{ 
  if(obj.state.choosetemplate==''){
  //console.log(obj.state.uploadtemplate)
      var reader = new FileReader();
      reader.readAsDataURL(obj.state.uploadtemplate); 
      reader.onloadend = function() {
          var base64data = reader.result;   
          callback(base64data);
      }

    }else{ 
      // let recordid = obj.state.defaultTemplate.findIndex((list_data) => list_data.auto_id == obj.state.choosetemplate);
      // document.getElementById("html-content-holder").innerHTML=obj.state.defaultTemplate[recordid].html_content;
      // this.html2canvas(document.getElementById("html-content-holder"),{logging: true, letterRendering: 1, useCORS: true}).then(function (canvas) {			
      // //var anchorTag = document.createElement("a");
      // var extra_canvas = document.createElement("canvas");
      // extra_canvas.setAttribute('width',1200);
      // extra_canvas.setAttribute('height',1800);
      // var ctx = extra_canvas.getContext('2d');
      // ctx.drawImage(canvas,0,0,canvas.width, canvas.height,0,0,1200,1800);
      // ctx.scale(3,3);
      // var dataURL = extra_canvas.toDataURL(); 
      // callback(dataURL);
      // });
      callback('');
    }
}

showToaster = (color, msg) => {
  this.setState({
    userToaster: true,
    userToasterMsg: msg,
    userToasterColor: color,
  });
  setTimeout(
    function () {
      this.setState({ userToaster: false });
    }.bind(this),
    3000
  );
};

  render() {
    const { errors } = this.state; 
    return (
      
      <div style={{ width: "100%"}} className="kt-container">
        {this.state.loading ? (<div className="ui loading form"></div>):(
          <div
          className=" kt-body  kt-grid kt-grid--ver "
          id="kt_body"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-ameplaybooks">
              <div class="kt-form kt-form--label-right kt-margin-b-30 yr-manageUser-table">
                <div class="row align-items-center" style={{ marginLeft: '0px' }}>
                  <div class="col-xl-12 order-2 order-xl-1">
                    <div class="row align-items-center" style={{ marginLeft: '0px' }}>
                    <div class="col-md-6 kt-margin-b-20-tablet-and-mobile">
                        <div style={{ paddingTop: "10px" }}>
                        <h3 class="sm-b-title">My Templates</h3>
                        </div>
                      </div>
                      <div class="col-md-6 kt-margin-b-20-tablet-and-mobile">
                        <div style={{ paddingTop: "10px" }}>
                          {/* <a href="javascript:void(0)" class="btn yr-add-new kt-font-bold float-right" data-toggle="modal" data-target="#kt_modal_6"><i class="fa fa-plus" style={{fontSize: "10px"}}></i>Add Domain</a> */}

                          <a
                            href="javascript:void(0)"
                            className="btn linear-gradient yr-add-new kt-font-bold float-right"
                            onClick={(e) => this.actionModel('Open')}
                          >
                            <i className="fa fa-plus"></i>
                            Add Template
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
              <Message
              color={
                this.state.userToasterColor
                  ? this.state.userToasterColor
                  : "teal"
              }
              style={{
                display: this.state.userToaster
                  ? "block"
                  : "none",
                marginTop: 30,
              }}
            >
              {this.state.userToasterMsg}
            </Message>
                <DatatableList 
                action = {this.actionModel}
                ref= {this.dataTable}
                />
              </div>
            </div>
          </div>
        </div>
        )}
        {/* <div className="kt-header__bottom">
          <div className="kt-container" style={{ backgroundColor: "#fff" }}>
            <div className="yr-pagetitle d-flex justify-content-between kt-margin-t-10 kt-padding-b-0">
              <div className="left yr-titleSelect">
                <h2>Direct email</h2>
              </div>
            </div>

          </div>
        </div> */}
        

        
        <div
                    className={this.state.dialogShowM ? "modal fade show" : "modal fade"}
                    data-backdrop="static"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="staticBackdrop"
                    aria-hidden="true"
                    style={{ display: this.state.dialogShowM ? "block" : "none" }}
                  >
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 1200 }}>
                      {this.state.dialogLoadingM && <div className="ui loading form"></div>}
                      <div className="modal-content focusError">
                        <button
                          type="button"
                          className="close linear-gradient"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) => this.actionModel('Close')}
                        >
                          X
						</button>
            <div>
            <Message
              color={
                this.state.userToasterColor
                  ? this.state.userToasterColor
                  : "teal"
              }
              style={{
                display: this.state.userToaster
                  ? "block"
                  : "none",
                marginTop: 30,
              }}
            >
              {this.state.userToasterMsg}
            </Message>
            </div>
                        <div className="modal-header">
                          <h5 className="modal-title">Add Template</h5>
                        </div>
                        <div className="modal-body">
                          <div className="px-4">
                          <div className="form-group row"> 
							              <label className="col-lg-3 col-form-label">Title :</label>
							               <div className="col-lg-9">
                                <div className="field">
                                  <div className="ui fluid input">
                                    <input autocomplete="off" name="templatename" placeholder="" type="text" 
                                    style={{maxWidth: '50%'}}
                                    value={this.state.templatename} 
                                    onChange={(e) => this.onchange(e)}
                                    />
                                  </div>
                                {errors && errors.hasOwnProperty('templatename') && (
                                    <div className="red">{errors['templatename']}</div>
                                )}
                                </div>
                              </div>
                          </div>
                          <div className="form-group row"> 
							              <label className="col-lg-3 col-form-label">Upload template :</label>
							               <div className="col-lg-9">
                                <div className="field">
                                  <div className="ui fluid input" style={{display: 'inline'}}>
                                  <div class="logo-img" style={{width: '33%', height:150}}><img src={this.state.uploadtemplate=='' ? "assets/media/logos/upload-bg.png" : URL.createObjectURL(this.state.uploadtemplate) } alt="" /></div>
                                  <div class="d-flex" style={{width: '33%'}}>
                                    <div class="upload-btn-wrapper">
                                       <button class="btn linear-gradient zy-save-btn width-100">Upload</button>
                                       <input type="file" name="uploadtemplate" id="uploadtemplateID"   onChange={(e) => this.onchangeTemplate(e)} accept="image/jpg,image/png,image/jpeg,image/gif"/>
                                    </div>
                                    {this.state.uploadtemplate && (<button type="reset" className="btn linear-gradient zy-cancel-btn ml-2" onClick={(e) => this.onremoveTemplate(e)}>Remove</button>)}
                                  </div>
                                  {errors && errors.hasOwnProperty('uploadtemplate') && (
                                      <div style={{color:'red'}}>{errors.uploadtemplate}</div>
                                  )}
                                  </div> 
                                </div>
                              </div>
                          </div>
                          <div className="form-group row"> 
							              <label className="col-lg-3 col-form-label"> </label>
                            <div className="col-lg-9"  style={{padding:'20px 10px 20px'}}><span style={{color:'red'}}>For creating the postcard please upload the template of size "1875px wide by 1275px tall" or choose any default template.</span></div>
                          </div>
                          <div className="form-group row"> 
							              <label className="col-lg-3 col-form-label">Choose template :</label>
							               <div className="col-lg-9">
                                <div className="field"> 
                                <ul className="postcard--list d-flex flex-wrap">
                                {this.state.defaultTemplate!='' && this.state.defaultTemplate.map((data,key) => 
                                      <li  key={key}  
                                      className={ this.state.choosetemplate==data.auto_id ? "postcard--listitem border_highlighted":'postcard--listitem'}
                                      onClick={(e) => this.onclickTemp(e,data.auto_id)}
                                      >
                                            <a>
                                                <img src={data.image_url} className="img-fluid" alt="" />
                                            </a>
                                      </li>
                                    )} 
                                </ul>
                                </div>
                              </div>
                          </div>

                          <div className="form-group row"> 
							              <label className="col-lg-3 col-form-label">Status:</label>
							               <div className="col-lg-2">
                                <div className="field"> 
                                
                               <select 
                                  name='tempStatus'
                                  className={
                                    errors && errors["Branch"]
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  onChange={(e) => this.onchange(e)}
                                  value={this.state.tempStatus}
                                >
                                  <option value="N">Inactive</option>
                                  <option value="Y">Active</option>
                                  
                                </select>
                                </div>
                              </div>
                          </div>  
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={(e) => this.actionModel('Close')} data-dismiss="modal">
                            <span>Cancel</span>
                          </button>
                          <button type="button" className="btn linear-gradient yr-submitbtn btn-sm" onClick={(e) => this.actionModel('Yes')} >
                            Add
            </button>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div
                    className={this.state.dialogShowE ? "modal fade show" : "modal fade"}
                    data-backdrop="static"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="staticBackdrop"
                    aria-hidden="true"
                    style={{ display: this.state.dialogShowE ? "block" : "none" }}
                  >
                    <div className="modal-dialog modal-dialog-centered" role="document" >
                      {this.state.dialogLoadingE && <div className="ui loading form"></div>}
                      <div className="modal-content focusError">
                        <button
                          type="button"
                          className="close linear-gradient"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) => this.actionModel('Close')}
                        >
                          X
					            	</button>
            
                        <div className="modal-header">
                          <h5 className="modal-title">Edit Template</h5>
                        </div>
                        <div className="modal-body">
                          <div className="px-4">
                          <div className="form-group row"> 
							              <label className="col-lg-3 col-form-label">Title :</label>
							               <div className="col-lg-9">
                                <div className="field">
                                  <div className="ui fluid input">
                                    <input autocomplete="off" name="templatename" placeholder="" type="text" 
                                    style={{maxWidth: '50%'}}
                                    value={this.state.templatename} 
                                    onChange={(e) => this.onchange(e)}
                                    />
                                  </div>
                                {errors && errors.hasOwnProperty('templatename') && (
                                    <div className="red">{errors['templatename']}</div>
                                )}
                                </div>
                              </div>
                          </div> 
                          <div className="form-group row"> 
							              <label className="col-lg-3 col-form-label">Status:</label>
							               <div className="col-lg-3">
                                <div className="field"> 
                                
                               <select 
                                  name='tempStatus'
                                  className={
                                    errors && errors["Branch"]
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  onChange={(e) => this.onchange(e)}
                                  value={this.state.tempStatus}
                                >
                                  <option value="N">Inactive</option>
                                  <option value="Y">Active</option>
                                  
                                </select>
                                </div>
                              </div>
                          </div>  
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={(e) => this.actionModel('Close')} data-dismiss="modal">
                            <span>Cancel</span>
                          </button>
                          <button type="button" className="btn linear-gradient yr-submitbtn btn-sm" onClick={(e) => this.actionModel('Save')} >
                            Edit
            </button>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div style={{position: 'absolute',opacity: '0', pointerEvents:"none"}}>
                  <iframe id="iframe"  style={{position:'relative',
                      width: '1875px',
                      height: '1275px',
                      fontFamily: 'sans-serif',
                      transform: 'scale(.42) translate(-70%, -65%)',
                      marginBottom: '-93%',
                      border: 'none'
                      }}></iframe>
                  </div> 
      </div>
      
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(ListPostcard);
