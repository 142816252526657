import React from 'react'
import { Link } from "react-router-dom";
const Top10Url = (props) => {
    if (props.data.length === 0) {
        return (
            <li>
                <span>Records not found.</span>
            </li>
        )
    } else {
        const listItems = props.data.map((url, i) =>

            <li key={i}>
                <p style={{lineBreak : 'anywhere', paddingRight: 10}}>{url.domainName}</p>
                <strong>{url.total}</strong>
            </li>

        );
        return ( <ul>{listItems}</ul> );
    }

}

export default Top10Url