import React from "react";
import { Segment } from "semantic-ui-react";
import {
  toggleBreadcrum,
  setPageTitle,
  
} from "../../store/Page";

import { connect } from "react-redux";
class ChatDashboardWebhooks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false, 
    }; 
    this.props.toggleBreadcrum({ toggle: false });
  }
   
  render() {
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
      <div className="kt-content kt-grid__item kt-grid__item--fluid">
          <div className="yr-body-p kt-margin-t-20">
              <div className="row">
                  <div className="col-md-12">
                      <h3>Steps for integration Chat Dashboard Webhooks.</h3>
                      <p>Reference Url for Chat Dashboard Webhooks:</p> 
                      <p><a style={{wordBreak:'break-all'}} href="https://docs.google.com/document/d/1S0lzB7wsoTVpjsEhlnoAEIlPtY_XWnNer1zBmhcnax0/edit?usp=sharing">
                                https://docs.google.com/document/d/1S0lzB7wsoTVpjsEhlnoAEIlPtY_XWnNer1zBmhcnax0/edit?usp=sharing</a> 
                      </p>
                      <h3>Steps for Using Chat Dashboard Webhooks</h3>
                      <p> There are 5 parameters you can add to your URLs:</p>
                      <p><b>Step 1.</b> User should go to Webhook inside Setting from Left Bar.</p>
                      <p><b>Step 2.</b> Click on the Enable Webhook and Enter the valid Webhook Url then Save the Configuration.</p>
                      <p><b>Step 3.</b> User Data to be sent to the Webhook Url is : </p>
                      <ul>
                          <li>message : "Chat Conversation" (Text Content)</li>
                          <li>origin_from : web|sms, (String)</li>
                          <li>msg_from : Agent|AI|User, (String)</li>
                          <li>live_chat_start : true|false, (String)</li>
                          <li>conversation_id : "Conversation Unique Id" (String)</li>
                          <li>welcome_msg : "Which is configured by User in Customized chatbot section"(Text Content).</li>
                      </ul> 
                      <p><b>Step 4.</b> All Above data will send to the Webhook Url Using POST method.</p>

                  </div>
              </div>
          </div>
      </div>
  </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};

export default connect(mapStateToProps, {
  toggleBreadcrum,
  setPageTitle,
  
})(ChatDashboardWebhooks); 
