import React from "react";
import CustomErrorField from "../../../../Form/CustomErrorField";

class QuestionResponse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      questions: [""],
      errors: [],
      edit_id: "",
    };
  }

  componentDidMount() {
    this.onLoad();
  }

  componentDidUpdate(prevProps) {
    if (this.props.button_action_id != prevProps.button_action_id) {
      this.onLoad();
    }
  }

  onLoad = () => {
    if (this.props.button_action_id == "add") {
      this.setState({ questions: [""], errors: [] });
    } else {
      console.log(this.props.branchDetails.question_response_list);
      let activeindex = this.props.branchDetails.question_response_list.findIndex(
        (list) => list.id == this.props.button_action_id
      );
      this.setState({
        errors: [],
        questions: [
          this.props.branchDetails.question_response_list[activeindex].question,
        ],
        edit_id: this.props.branchDetails.question_response_list[activeindex]
          .id,
      });
    }
  };

  changeQuestions = (e, index) => {
    let ques = this.state.questions;
    ques[index] = e.target.value;
    this.setState({ questions: ques });
  };

  addMoreQuestion = () => {
    let ques = this.state.questions;
    ques.push("");
    this.setState({ questions: ques });
  };
  deleteQuestionResponse = (index) => {
    let ques = this.state.questions;
    let activeindex = ques.findIndex((list) => list == index);
    ques.splice(activeindex, 1);
    this.setState({ questions: ques });
  };

  saveQuestionResponse = () => {
    const errors = [];

    new Promise((resolve) => {
      let ques = this.state.questions;
      ques.forEach((data, key) => {
        if (data == "") {
          errors[key] = "This field is required";
        }
      });

      resolve(errors);
    }).then((data) => {
      if (data.length > 0) {
        this.setState({ errors: data });
      } else {
        this.setState({ loading: true });
        let edit_number = "";
        if (this.state.edit_id != "") {
          edit_number = this.state.edit_id;
        }
        this.props.saveQuestionResponse(
          this.state.questions,
          edit_number,
          (data) => {
            this.setState({ loading: false }, function () {
              this.props.popupClose();
              let scrolltop =
                document.querySelector("#responsetoquestion").offsetTop -
                document.body.scrollTop;
              window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
            });
          }
        );
      }
    });
  };

  render() {
    const { errors, edit_id } = this.state;
    return (
      <div>
        <div className="yr-chat-round-box yr-closebtn">
          <button
            type="button"
            class="close linear-gradient"
            onClick={() => this.props.popupClose()}
          >
            &#10006;
          </button>
          <div className="box-title">Add Response To A Question</div>
          {this.state.loading && <div className="ui loading form"></div>}

          {this.state.questions.length > 0 &&
            this.state.questions.map((obj, index) => {
              return (
                <div class="row" un_key={index}>
                  <div className="col-md-11">
                    <div className="form-group" style={{ marginBottom: 10 }}>
                      <textarea
                        className={
                          errors.length > 0 &&
                          typeof errors[index] != "undefined"
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        onChange={(e) => this.changeQuestions(e, index)}
                        value={obj}
                        placeholder="Response"
                      ></textarea>
                      {errors.length > 0 &&
                        typeof errors[index] != "undefined" && (
                          <CustomErrorField
                            message={errors[index]}
                            className="error invalid-feedback"
                          />
                        )}
                    </div>
                  </div>
                  <div className="col-md-1">
                    {index > 0 && (
                      <button
                        type="button"
                        class="btn btn-icon"
                        title="Delete"
                        onClick={() => this.deleteQuestionResponse(index)}
                      >
                        <i class="la la-trash"></i>
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          {this.state.edit_id == "" && (
            <a
              className="add-gradient form-group cusrsorpointer"
              onClick={() => this.addMoreQuestion()}
            >
              +Add
            </a>
          )}
          {/* <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button type="reset" className="btn linear-gradient-cancel yr-cancelbtn mg-r"><span> Close </span></button>
                        <button type="submit" className="btn linear-gradient yr-submitbtn">Save</button>
                    </div>
                </div>             */}
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <button
              type="reset"
              class="btn yr-cancelbtn linear-gradient-cancel mg-r"
              onClick={() => this.props.popupClose()}
            >
              <span> Close </span>
            </button>
            <button
              type="submit"
              className="btn linear-gradient yr-submitbtn"
              onClick={() => this.saveQuestionResponse()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionResponse;
