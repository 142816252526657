import { Table } from 'semantic-ui-react';
import React from 'react';

export function DatatableTableHeader(props) {
  const tableHeader = props.header.map((head, index) => {
    if (head.sort != '') {
      return <Table.HeaderCell key={index} width={head.width} sorted={props.column === head.sort ? props.direction : null}
        onClick={() => props.handleSort(head.sort)}>{head.title} </Table.HeaderCell>;
    } else {
      return <Table.HeaderCell key={index} width={head.width} >{head.title} </Table.HeaderCell>;
    }
  });
  return (
    <Table.Header>
      <Table.Row>
        {tableHeader}
      </Table.Row>
    </Table.Header>
  );
}
