import React from "react";
import DefaultBreadCrum from "./Default";
import Comment from "./Comment";
class BreadCrum extends React.Component {
  displayBreadCrum = (breadCrum) => {
    if (!breadCrum) {
      return <DefaultBreadCrum {...this.props} />;
    } else if (
      breadCrum &&
      typeof breadCrum === "object" &&
      breadCrum.type === "comment"
    ) {
      return <Comment {...this.props} />;
    }
  };
  render() {
    const { toggleBreadcrum, breadcrum } = this.props;
    return toggleBreadcrum && this.displayBreadCrum(breadcrum);
  }
}

export default BreadCrum;
