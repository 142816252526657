import React from 'react';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2,
} from "react-html-parser";
const WaitingForResponse = props => {
    const renderTime = (anonymousUserId, waittime) => {
        var { waittime } = props.state
        const {joinedTheConversation} = props
        return waittime.map(({
            anonymous_user_id,
            displayTime,
            time
        }, idx) => (
            (anonymousUserId == anonymous_user_id) ?
                <div> {displayTime}</div>
                : ''
        ));
    }
    const getTooltip = (msg) => {
        var elem = document.createElement('div');
        elem.innerHTML = msg;
        return elem.innerText;
    }

    const getAvtar = (name) => {
        let newa = name.match(/(\b\S)?/g).join("")
            .match(/(^\S|\S$)?/g)
            .join("").toUpperCase();
        if (newa.length === 1) {
            newa += name.slice(-1).toUpperCase();
            // console.log(name.slice(-1));
            // console.log(newa);
        }
        return newa;
    }

    const renderNameAndWelcomeMgs = (anonymousUserId, name, type = "") => {
        var { name } = props.state
        return name.map(({
            anonymous_user_id,
            name,
            hippaOn,
            welcomeMsg,
            read
        }, idx) => {
            if (welcomeMsg.includes('<img')) {
                welcomeMsg = welcomeMsg.substr(0, welcomeMsg.indexOf('<img')); 
            }
            return (
                (anonymousUserId == anonymous_user_id && hippaOn == "none") ?
                    <div className="kt-notification-v2__itek-wrapper mh-visitorDetailOnLeftBar">
                        <div className="kt-notification-v2__item-title">
                            <span className="kt-widget3__username">{name}</span>
                            {type && <> <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={(e) => props.onChnageName(e, anonymous_user_id, name)}>
                                <i title='Update name' style={{ color: '#5a9c12' }} class="fa fa-pencil" aria-hidden="true"></i>
                            </button>
                                <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={(e) => props.onArchive(e, anonymous_user_id)}>
                                    <i title='Archive chat' style={{ color: '#5a9c12' }} class="fa fa-archive" aria-hidden="true"></i>
                                </button>
                            </>
                            }

                        </div>
                        <div title={getTooltip(welcomeMsg)} className="kt-notification-v2__item-desc mh-leftBarChatMessage">
                            {ReactHtmlParser(welcomeMsg)}
                        </div>
                    </div> : (anonymousUserId == anonymous_user_id && hippaOn == "block" && name == "Site Visitor") ?
                        <div className="kt-notification-v2__itek-wrapper mh-visitorDetailOnLeftBar">
                            <div className="kt-notification-v2__item-title">
                                <span className="kt-widget3__username">{name}</span>
                            </div>
                            <div className="kt-notification-v2__item-desc mh-leftBarChatMessage">
                                {"Hidden"}
                            </div>
                        </div> : (anonymousUserId == anonymous_user_id) ?
                            <div className="kt-notification-v2__itek-wrapper mh-visitorDetailOnLeftBar">
                                <div className="kt-notification-v2__item-title">
                                    <span className="kt-widget3__username">{"Hidden"}</span>
                                </div>
                                <div className="kt-notification-v2__item-desc mh-leftBarChatMessage">
                                    {"Hidden"}
                                </div>
                            </div> : ""
            )
        });
    }
    var siteVisitorImgPath = props.state.assetPath
    var joinedTheConversation = props.joinedTheConversation;
    // console.log("all chat from engage", props.chatListEngage, props.state.chatLeftList, props.state.chatRepondedTimeWise);
    // console.log("all chat from livechat", props.chatListEngage, props.state.chatRepondedTimeWise);
    if (joinedTheConversation == 0)

        var chat = props.state.chatLeftList;
    else
        var chat = props.state.chatRepondedTimeWise;
    if (props.chatListEngage == 'live') {
        chat = chat.filter((chatItem) => chatItem.from == 0 || chatItem.from == 'web');
    } else {
        chat = chat.filter((chatItem) => chatItem.from == 1 || chatItem.from == 'sms');
    }
    var length = 0;
    // console.log("chat list filetr", chat);
    chat = chat.filter((item) => item.is_archive != 1);
    if (chat.length > 0) {
        if (joinedTheConversation == 0) {

            var valueArr1 = chat.map(function (item) {
                // check if chat is from sms then join_into_conversation will not check
                if (props.chatListEngage == 'engage') {
                    return item.join_into_conversation
                } else {
                    if (item.join_into_conversation == 0) {
                        return item.join_into_conversation
                    }
                }
            });
            valueArr1 = valueArr1.filter(function (element) {
                return element !== undefined;
            });
            length = valueArr1.length
        } else {
            length = 1;
        }
    }
    var anonymous_user_id,
        hippaOn,
        join_into_conversation = "";
    // console.log("prop", props);

    return (
        (chat.length == 0 || length == 0) ? <span className="kt-notification-v2__item"  >
            <div className="mh-noChatAvailableOnLeftBar" >
                <div className="kt-notification-v2__item-time ">No chats available.</div>
            </div>
        </span> : chat.map(({
            anonymous_user_id,
            hippaOn,
            leftBarClass,
            join_into_conversation,
            read,
            from,
            name
        }, idx) => ((join_into_conversation == joinedTheConversation && props.chatListEngage == 'live') ?
            <span className={leftBarClass + " mh-chatLeftBarHover " + read} onClick={() => props.openLiveChatBoxOnClickLeftRow(anonymous_user_id, hippaOn)}>
                <div style={{ overflow: 'hidden' }} className="kt-notification-v2__item-icon mh-chatLeftAUBox">
                    {name != "Site Visitor" ?
                        <div style={{ backgroundColor: '#d7b144', padding: 6, borderRadius: '50%', color: '#fff', fontWeight: '600' }}>
                            {name ? getAvtar(name) : 'SV'}
                        </div>
                        :
                        <img className="kt-widget3__img" style={{ height: '50px' }} src={siteVisitorImgPath + "/images/chatlogo/visitor-logo.png"} alt="" />
                    }

                </div>
                {renderNameAndWelcomeMgs(anonymous_user_id)}
                <div className="kt-notification-v2__item-time">
                    {renderTime(anonymous_user_id)}
                </div>
            </span>
            : (props.chatListEngage == 'engage' && (from == 1 || from == 'sms'))
                ? <span className={leftBarClass + " mh-chatLeftBarHover " + read} onClick={() => props.openLiveChatBoxOnClickLeftRow(anonymous_user_id, hippaOn)}>
                    <div style={{ overflow: 'hidden' }} className="kt-notification-v2__item-icon mh-chatLeftAUBox">
                        {name != "Site Visitor" ?
                            <div style={{ fontSize: 16, backgroundColor: '#edc04b', padding: 10, borderRadius: '50%', color: '#fff', fontWeight: '600' }}>
                                {name ? getAvtar(name) : 'SV'}
                            </div>
                            :
                            <img className="kt-widget3__img" style={{ height: '50px' }} src={siteVisitorImgPath + "/images/chatlogo/visitor-logo.png"} alt="" />
                        }
                    </div>
                    {renderNameAndWelcomeMgs(anonymous_user_id, "", 'sms')}
                    <div className="kt-notification-v2__item-time">
                        {renderTime(anonymous_user_id)}
                    </div>
                </span> : "")
        ))
}
export default WaitingForResponse;