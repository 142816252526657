import React, { Component } from 'react'
import ConfirmDialog from "../Modal/ConfirmDialog.js";
import billing from "../../api/billing";
export default class Subcription_Cancellation extends Component {
    constructor(props){
        super(props);
        this.state={
        confirmDialog: false,
        msg:'Are you sure want to cancel this subscription?',
        user_subsription_id:this.props.user_subscription_id,
        subscription_status:true,
        }
        this.toggleModal=this.toggleModal.bind(this);
        this.toggleConfirmModal=this.toggleConfirmModal.bind(this);
        
        this.setConfirmAlert=this.setConfirmAlert.bind(this);
    }
    toggleModal = () => this.clearState();
    toggleConfirmModal = () =>
    this.setState({ confirmDialog: false});

    toggleConfirm=()=>{
        console.log("csta",this.props)
    this.setState({ confirmDialog: false});
    let data={subscription_id:this.props.user_subscription_id,user_id:this.props.user_id,subscription_status:this.props.subscription_status};
    billing.calcelSubscription( data )
    .then((userResp) => {
        console.log("billing api response",userResp);
        console.log("sc",userResp);
        if(userResp.msg=="done"){
            this.setState({subscription_status:false});
            this.props.billing_update();
        }
    });


    }
    setConfirmAlert(){
        if(this.props.subscription_status=='ACTIVE'){
        this.setState({ confirmDialog: true});
        //this. showConfirmAlert();
        }else{
           this.toggleConfirm(); 
        }
        this.props.billing_update();
     }
  
    render() {
        
        return (
            <div>
                {this.state.confirmDialog && (
              <ConfirmDialog
              show={this.state.confirmDialog}
              toggleModal={this.toggleConfirm}
              closeAction={this.toggleConfirmModal}
              title="Confirm your action"
              message={"Do you really want to cancel subcription ?"}
            />
            )}
               <button onClick = {this.setConfirmAlert} style={{display:"none"}} className="btn rounded-btn border-2 btn-outline-primary" type={this.props.type}>{this.props.text}</button>   
            </div>
        )
    }
}
