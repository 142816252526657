import user from "./user";
import ticket from "./ticket";
import chatsetup from "./chatsetup";
import manageUser from "./manageUser";
import widget from "./installation-and-integration/widget";
import facebook from "./installation-and-integration/facebook";
import salesforce from "./installation-and-integration/salesforce";
import marketsharp from "./installation-and-integration/marketsharp";
import keep from "./installation-and-integration/keep";
import zapier from "./installation-and-integration/zapier";
import webhook from "./installation-and-integration/webhook";
import dialogflow from "./installation-and-integration/dialogflow";
import actcrm from "./installation-and-integration/actcrm";
import servicetitan from "./installation-and-integration/servicetitan";

import chatlist from "./chatlist";
import transcriptchatlist from "./transcriptchatlist";
import automation from "./automation";

import customize from "./customize";
import liveChat from "./liveChat";
import botbuilder from "./botbuilder";

import manageTeam from "./reviewManagement/manageTeam";
import reviews from "./reviewManagement/reviews";
import socialLogin from "./reviewManagement/socialLogin";
import sensitiveDataActivityLog from "./sensitiveDataActivityLog";
import googleReview from "./reviewManagement/google_review";
import reviewchat from "./reviewchat";
import HippLogData  from "./HippLogData";
import marketing  from "./marketing";
import playbookdetail  from "./playbookdetail";
import ConfigSetting  from "./configSetting";
import postcard  from "./postcard";

export default {
  user,
  ticket,
  chatsetup,
  manageUser,
  widget,
  facebook,
  salesforce,
  customize,
  chatlist,
  automation,
  keep,
  liveChat,
  botbuilder,
  manageTeam,
  reviews,
  liveChat,
  marketsharp,
  zapier,
  webhook,
  socialLogin,
  sensitiveDataActivityLog,
  googleReview,
  reviewchat,
  transcriptchatlist,
  HippLogData,
  marketing,
  playbookdetail,
  ConfigSetting,
  postcard,
  actcrm,
  servicetitan,
  dialogflow
};
