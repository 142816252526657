import React from "react";
import { Dropdown } from "semantic-ui-react";
import config from "../../config";

export class PagingOption extends React.Component {
  changePageSize = (event, data) => {
    if (data.value > 0) {
      this.props.onPageLimitChange(data.value);
    }
  };
  render() {
    const { limit } = this.props;
    return (
      <React.Fragment>
        {" "}
        {limit > 0 && (
          <Dropdown
            inline
            options={config.dataTableSettings.limitOptions}
            defaultValue={limit.toString()}
            onChange={this.changePageSize}
          />
        )}
      </React.Fragment>
    );
  }
}
