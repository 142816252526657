import React, { useState } from "react";
import "../../../../../Modal/dialog.css";
import api from "../../../../../../api";


const DialogBoxContactVia = ({obj,modalCloseContactViaBox,openContactAddEdit, addNotes, notesdata, notesDelmsg , yclid, nerrmsg, n_team_id, deleteConfirm, yourNotes,Delmag} ) => {
	
	const noteslist = notesdata; 
	const ycall_list_id = yclid;
    const[option,setOption] = useState("static")
	const setOptionValue = e=>{
		setOption(e)
	}
	const openContactAddEditHere = e =>{
		modalCloseContactViaBox(false, '')
		openContactAddEdit(option)
		setOption("static")

	}
    const  modalCloseContactViaBoxHere=()=>{
		modalCloseContactViaBox(false, '')
		setOption("static")

	}
	// @Rohit
	addNotes= ()=> {
			var call_list_id   = document.getElementById("notes_call_list_id").value;
			var notes_comment  = document.getElementById("comment").value;
			//alert(n_team_id);
			if( n_team_id == 0 || n_team_id == 'noteam' ){
				  
					//alert(n_team_id);
					n_team_id = 'noteam';
					yourNotes(call_list_id, n_team_id, notes_comment);
					notes_comment = 0;
					
		 	 }
			else if( notes_comment == ''){
				
				 notes_comment = 0;
				 yourNotes(call_list_id, n_team_id, notes_comment);
			}
           else{
					//alert(n_team_id);
					// api.marketing.getContactDetails({'call_notes_id':call_notes_id}).then((data) => {
					api.marketing.addYourNotes({'call_list_id':call_list_id, 'notes_comment': notes_comment, 'notes_team_id': n_team_id}).then((data) => {
					console.log(data)
					try {   
							//modalCloseContactViaBoxHere();
							var notes_comment  = document.getElementById("comment").value = '';
							yourNotes(ycall_list_id, n_team_id, 1);
							
							//n_team_id
						} catch (err) {
							console.log(err);
						}
				});
				let modalContactViaObject = {
					    show: false,
						title: "Notes",
						loading: false
				}
			}
	}

return (

<React.Fragment>
<div className={obj.modalContactViaObject.show ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: obj.modalContactViaObject.show ? "block" : "none", "z-index": "117"}}
				id = "modalContactViaID"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
				   {obj.modalContactViaObject.loading && <div className="ui loading form"></div>}
					<div className="modal-content yr-campaigns-model">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalCloseContactViaBoxHere()}>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{obj.modalContactViaObject.title}</h5>
						</div>
					   <div className="modal-body mt-4">
						   <div className="kt-portlet__body">
								<div className="kt-section kt-section--first">									
									<div className="kt-section__body mt-4">
										<div className="row">
											<div className="col-lg-12 col-md-12 col-sm-12 form-group">
											{ //Check if message failed
											  (Delmag == "Notes has deleted successfully.")
											     ? <div className="col-lg-12 ui green message del-msg-padding">{Delmag}</div>
											   : <div></div>
											}
										      { notesdata && Object.keys(noteslist).map(function (key){
													return (   
															<div className="c-comment-list">
																	<div className="media" id="deletedNote">
																		<div className="media-left">
																			<a href="#"><img className="media-object" alt="" src="../../assets/images/unknown_profile.png" className="call-notes-img"/></a>
																		</div>
																		<div className="media-body">
																			<h4 className="media-heading"><a href="#" className="tittle call-notes-font">{noteslist[key].notes_username}</a>&nbsp;
																			<span className="c-date call-notes-font">&nbsp; on {noteslist[key].notes_added_date}</span>&nbsp;<span title="Delete" className="call-notes-red" onClick={()=>deleteConfirm(noteslist[key].notes_id, n_team_id)}><i className="la la-trash" aria-hidden="true"></i></span></h4><span className="call-notes-font">{noteslist[key].notes_comment}</span>
																		</div>
																	</div>
																</div>
															)
														})}
												 </div>
											<div className="col-lg-12 col-md-12 col-sm-12 form-group">
											<br/>
												Leave a notes
												<input type="hidden" id="notes_call_list_id" value={ycall_list_id}/>
												<textarea rows="5" id="comment"  placeholder="Write notes here ..." class="form-control-notes">{n_team_id}</textarea>
												<div className="red">{nerrmsg}</div>
											</div>
										</div>
									</div>									
								</div>
							</div>
							<div className="kt-portlet__foot">
								<div className="kt-form__actions">
									<div className="row mt-4">										
										<div className="col-lg-12">
										   <div style={{ float:"right"}}>
											<button type="reset" className="btn linear-gradient-cancel yr-cancelbtn mg-r" onClick={() => modalCloseContactViaBoxHere()}><span> Cancel </span></button>
											<button type="submit" className="btn linear-gradient yr-submitbtn" onClick={addNotes}>Submit</button>
										   </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxContactVia;