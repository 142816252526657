import React from 'react';
import { Table } from 'semantic-ui-react';
import { Link ,Redirect} from 'react-router-dom';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
                                                                                 

import "../../../../Modal/dialog.css";

const DialogBoxDetail = ({ show, title, emailcontent, modalType, modalClose, buttonmsg, modalbuttofun, loading }) => {
	
return (
		<React.Fragment>
			<div
				className={show ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
				data-backdrop="static"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="staticBackdrop"
				aria-hidden="true"
				style={{ display: show ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
				   {loading && <div className="ui loading form"></div>}
					<div className="modal-content white-bg yr-campaigns-model">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalClose(false, '')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">Email Content</h5>
						</div>
						<div className="modal-body"><div className="">
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 form-group">
								
								<div style={{overflow: 'auto'}}>{ ReactHtmlParser(emailcontent) }</div>
							</div>
						</div>
						
					   </div></div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxDetail;