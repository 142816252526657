import React from "react";
import CustomLabel from "./CustomLabel";
import CustomErrorField from "./CustomErrorField";

const DropDown = ({ labelInfo, errors, name, children, ...rest }) => {
	return (
		<React.Fragment>
			<CustomLabel labelInfo={labelInfo} />

			<select {...rest} name={name}>
				{" "}
				{children}
			</select>
			{errors && errors[name] && errors.hasOwnProperty(name) && (
				<CustomErrorField
					message={errors[name]}
					id="help-error"
					className="error invalid-feedback"
				/>
			)}
		</React.Fragment>
	);
};

export default DropDown;
