import React from "react";
import "./colorPicker/ColorPicker.css";
import InputColorField from "./colorPicker/InputColorField";

class Design extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.customizeinfo;
  }

  render() {
    const { errors } = this.props;
    return (
      <div
        className="yr-chat-round-box width-30 yr-max-height kt-scroll"
        data-scroll="true"
      >
        <div className="box-title">Design</div>
        <div className="form-group">
          <label>Company Logo</label>
          <div className="logo-img">
            <img
              src={
                this.state.companyLogo == ""
                  ? "assets/media/logos/upload-bg.png"
                  : this.state.companyLogo
              }
              alt=""
            />
          </div>
          <div className="d-flex justify-content-center">
            <div className="upload-btn-wrapper">
              <button className="btn linear-gradient zy-save-btn width-100">
                Edit Logo
              </button>
              <input
                type="file"
                name="companyLogo"
                id="comanyLogoId"
                onChange={this.props.handleCompanyLogo}
                accept="image/jpg,image/png,image/jpeg,image/gif"
              />
            </div>
            {this.state.companyLogo && (
              <button
                type="reset"
                className="btn linear-gradient-cancel zy-cancel-btn ml-2"
                onClick={this.props.handleCompanyLogoRemove}
              >
                Remove
              </button>
            )}
          </div>
          {errors && errors.hasOwnProperty("companyLogo") && (
            <div style={{ color: "red" }}>
              Invalid file format. Only "jpg, jpeg, png, gif" files are
              supported.
            </div>
          )}
          {/* <input type="hidden" id="dbimgchat" name="dbimgchat"  value={this.state.companyLogo}/> */}
          <input
            type="hidden"
            id="oldChatImgID"
            name="oldChatImgname"
            value={this.props.cplogo}
          />
        </div>
        <div className="form-group">
          <label>Header Color</label>
          {this.state.buttonHeaderColor &&
            this.state.buttonHeaderColor.map((x, y) => {
              return (
                <div key={y} style={{ display: "flex" }}>
                  <InputColorField
                    name={"buttonHeaderColor_" + y}
                    value={x}
                    onchangeColor={this.props.onchangeUpdateColor}
                  />
                  {y != 0 && (
                    <a
                      onClick={(e) => this.props.deleteMoreColor(y, "header")}
                      title="Delete"
                      className="btn btn-icon gray"
                      style={{ marginRight: -39 }}
                    >
                      <i className="la la-trash"></i>
                    </a>
                  )}
                </div>
              );
            })}
          <span
            className="add-gradient"
            onClick={() => this.props.addMoreColor("header")}
          >
            +Add Gradient
          </span>
        </div>
        <label style={{ fontWeight: 600 }}>Chat Icon Color</label>
        <div className="form-group" style={{ marginBottom: 10 }}>
          <label>Primary</label>
          <InputColorField
            name="primaryColor"
            value={this.state.primaryColor}
            onchangeColor={this.props.onchangeUpdateColor}
          />
        </div>
        <div className="form-group" style={{ marginBottom: 10 }}>
          <label>Secondary</label>
          <InputColorField
            name="secondaryColor"
            value={this.state.secondaryColor}
            onchangeColor={this.props.onchangeUpdateColor}
          />
        </div>
        <div className="form-group" style={{ marginBottom: 10 }}>
          <label>Button Color</label>
          {this.state.buttonColor &&
            this.state.buttonColor.map((x, y) => {
              return (
                <div key={y} style={{ display: "flex" }}>
                  <InputColorField
                    name={"buttonColor_" + y}
                    value={x}
                    onchangeColor={this.props.onchangeUpdateColor}
                  />
                  {y != 0 && (
                    <a
                      onClick={(e) => this.props.deleteMoreColor(y, "button")}
                      title="Delete"
                      className="btn btn-icon gray"
                      style={{ marginRight: -39 }}
                    >
                      <i className="la la-trash"></i>
                    </a>
                  )}
                </div>
              );
            })}
          <span
            className="add-gradient"
            onClick={() => this.props.addMoreColor("button")}
          >
            +Add Gradient
          </span>
        </div>
        <div className="form-group" style={{ marginBottom: 10 }}>
          <label>Button Text Color</label>
          <InputColorField
            name="buttonTextColor"
            value={this.state.buttonTextColor}
            onchangeColor={this.props.onchangeUpdateColor}
          />
        </div>
        <div className="form-group" style={{ marginBottom: 10 }}>
          <label>User response box color</label>
          <InputColorField
            name="responseBoxColor"
            value={this.state.responseBoxColor}
            onchangeColor={this.props.onchangeUpdateColor}
          />
        </div>
        <div className="form-group" style={{ marginBottom: 10 }}>
          <label>User response text color</label>
          <InputColorField
            name="responseTextColor"
            value={this.state.responseTextColor}
            onchangeColor={this.props.onchangeUpdateColor}
          />
        </div>
        <div className="form-group" style={{ marginBottom: 15 }}>
          <label style={{ fontWeight: 600 }}>Select a Chat Icon</label>
          <div className="chat-image-box">
            <label
              className="image-checkbox"
              onClick={(e) =>
                this.props.onchangeChatIcon("img-icon1-white.png", "icon1")
              }
              style={
                this.state.selectedTheme == "img-icon1-white.png"
                  ? {
                      backgroundImage:
                        "linear-gradient(45deg," +
                        this.state.primaryColor +
                        "," +
                        this.state.secondaryColor +
                        ")",
                    }
                  : { backgroundImage: "unset" }
              }
            >
              <img
                className="img-responsive"
                id="icon1"
                src={
                  this.state.selectedTheme == "img-icon1-white.png"
                    ? "assets/media/logos/" + this.state.selectedTheme
                    : "assets/media/logos/chat-icon-1.png"
                }
                alt=""
              />
            </label>
            <label
              className="image-checkbox"
              onClick={(e) =>
                this.props.onchangeChatIcon("img-icon2-white.png", "icon2")
              }
              style={
                this.state.selectedTheme == "img-icon2-white.png"
                  ? {
                      backgroundImage:
                        "linear-gradient(45deg," +
                        this.state.primaryColor +
                        "," +
                        this.state.secondaryColor +
                        ")",
                    }
                  : { backgroundImage: "unset" }
              }
            >
              <img
                className="img-responsive"
                id="icon2"
                src={
                  this.state.selectedTheme == "img-icon2-white.png"
                    ? "assets/media/logos/" + this.state.selectedTheme
                    : "assets/media/logos/chat-icon-2.png"
                }
                alt=""
              />
            </label>
            <label
              className="image-checkbox"
              onClick={(e) =>
                this.props.onchangeChatIcon("img-icon3-white.png", "icon3")
              }
              style={
                this.state.selectedTheme == "img-icon3-white.png"
                  ? {
                      backgroundImage:
                        "linear-gradient(45deg," +
                        this.state.primaryColor +
                        "," +
                        this.state.secondaryColor +
                        ")",
                    }
                  : { backgroundImage: "unset" }
              }
            >
              <img
                className="img-responsive"
                id="icon3"
                src={
                  this.state.selectedTheme == "img-icon3-white.png"
                    ? "assets/media/logos/" + this.state.selectedTheme
                    : "assets/media/logos/chat-icon-3.png"
                }
                alt=""
              />
            </label>
            <label
              className="image-checkbox"
              onClick={(e) =>
                this.props.onchangeChatIcon("img-icon4-white.png", "icon4")
              }
              style={
                this.state.selectedTheme == "img-icon4-white.png"
                  ? {
                      backgroundImage:
                        "linear-gradient(45deg," +
                        this.state.primaryColor +
                        "," +
                        this.state.secondaryColor +
                        ")",
                    }
                  : { backgroundImage: "unset" }
              }
            >
              <img
                className="img-responsive"
                id="icon4"
                src={
                  this.state.selectedTheme == "img-icon4-white.png"
                    ? "assets/media/logos/" + this.state.selectedTheme
                    : "assets/media/logos/chat-icon-4.png"
                }
                alt=""
              />
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Or add a custom chat logo</label>
          <div className="logo-img">
            <img
              src={
                this.state.customizeLogo == ""
                  ? "assets/media/logos/upload-bg.png"
                  : this.state.customizeLogo
              }
              alt=""
            />
          </div>
          <div className="d-flex justify-content-center">
            <div className="upload-btn-wrapper">
              <button className="btn linear-gradient zy-save-btn width-100">
                Edit Logo
              </button>
              <input
                type="file"
                name="customizeLogo"
                id="customizeLogoId"
                onChange={this.props.handleCustomLogo}
                accept="image/jpg,image/png,image/jpeg,image/gif"
              />
            </div>
            {this.state.customizeLogo && (
              <button
                type="reset"
                className="btn linear-gradient zy-cancel-btn ml-2"
                onClick={this.props.handleCustomLogoRemove}
              >
                Remove
              </button>
            )}
          </div>
          {errors && errors.hasOwnProperty("customizeLogo") && (
            <div style={{ color: "red" }}>
              Invalid file format. Only "jpg, jpeg, png, gif" files are
              supported.
            </div>
          )}
          <input
            type="hidden"
            id="oldCustomizeImgID"
            name="oldCustomizeImgname"
            value={this.props.customlogo}
          />
          {/* <input type="hidden" id="customize_logo" name="customize_logo"  value={this.state.customizeLogo}/> */}
        </div>
        <div className="form-group row mb-3">
          <div className="col-6">
            <label>Height</label>
            <input
              type="text"
              max="120"
              name="customizedHeight"
              className="form-control"
              value={this.state.customizedHeight}
              onChange={this.props.onchange}
            />
            {errors.hasOwnProperty('customizedHeight') && (
              <small style={{ color: "red" }}>{errors['customizedHeight']}</small>
            )}
          </div>
          <div className="col-6">
            <label>Width</label>
            <input
              type="text"
              max="120"
              name="customizedWidth"
              className="form-control"
              value={this.state.customizedWidth}
              onChange={this.props.onchange}
            />
            {errors.hasOwnProperty('customizedWidth') && (
              <small style={{ color: "red" }}>{errors['customizedWidth']}</small>
            )}
          </div>
        </div>
        <div className="form-group">
          <label>Chat Popup Size</label>
          <div className="kt-checkbox-list">
            <label className="kt-radio inalign">
              <input type="radio" name='chatPopupSize' defaultChecked={(this.state.chatPopupSize == 'SMALL') ? true : false} value='SMALL' onChange={this.props.onchange} />Small<span></span>
            </label>
          </div>
          <div className="kt-checkbox-list">
            <label className="kt-radio inalign">
              <input type="radio" name='chatPopupSize' defaultChecked={(this.state.chatPopupSize == 'MEDIUM') ? true : false} value='MEDIUM' onChange={this.props.onchange} />Medium<span></span>
            </label>
          </div>
          <div className="kt-checkbox-list">
            <label className="kt-radio inalign">
              <input type="radio" name='chatPopupSize' value='2' defaultChecked={(this.state.chatPopupSize == 'DEFAULT') ? true : false} value="DEFAULT" onChange={this.props.onchange}  />Default<span></span>
            </label>
          </div>
        </div>
{/* emoji on off */}
<div className="form-group row" style={{ marginBottom: 4 }}>
                    <label className="col-8 col-form-label" style={{ lineHeight: "2" }}>Enable emoji on chat</label>
                    <div className="col-4">
                        <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                            <label>
                                <input type="checkbox" name="isEmoji" defaultChecked={(this.props.customizeinfo.isEmoji == '1' || this.props.customizeinfo.isEmoji == 'ON')   ? true : false} onChange={this.props.onchange}  />
                                <span />
                            </label>
                        </span>
                    </div>
                </div>

{/* end emogi */}

      </div>
    );
  }
}

export default Design;
