import React from "react";
import { connect } from "react-redux";
import {
  toggleNavigation,
  setPageTitle,
  setMessage,
  toggleBreadcrum,

} from "../../../store/Page";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

class Dashboard extends React.Component {
  constructor(props) {
    console.log("HURR",props)
    super(props);
    this.state = {
      
      loading: false
    };

  }
  // alert(value)
  componentDidMount = () => {
    // set page title configuration
this.props.setPageTitle({
 title: "Dashboard",
 subHeaderTitle: "Dashboard",
//  navigationName: "liveChat",
});

this.props.toggleNavigation({ toggle: false });
this.props.toggleBreadcrum({ toggle: false });
}

  render() {
    return (
      <div
        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="liveChatPage"
      >
        {this.state.loading && <div class="ui loading form"></div>}
        <div className="kt-content kt-grid__item kt-grid__item--fluid mh-custom-marg0">
          <div className="yr-body-p mh-custom-pad0">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="kt-portlet mh-custom-marg0">
                  <div className="kt-portlet__body py-0 mh-custom-pad0">
                    <div className="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 text-center" style={{fontWeight:600,fontSize:22}}>
                      <img height="476px" src={this.props.user.assetPath+"/uc.png"} />
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company ? state.user.company : null,
  };
};
export default connect(mapStateToProps, {
  toggleNavigation,
  setPageTitle,
  setMessage,
  toggleBreadcrum,

})(Dashboard);
// export default LiveChat;
