import React from "react";
import { connect } from "react-redux";
import { Message } from "semantic-ui-react";
import utilFunc from "../../../util_funs";
import AddTeamMember from './components/AddTeamMember';
import DatatableList from './dataTables/dataTableTeam/DatatableList';
import {
    setPageTitle,
    toggleNavigation,
    setMessage,
    setSubHeaderTitle,
    setNavigationName,
    toggleBreadcrum
} from "../../../store/Page";
import {Link} from "react-router-dom";
const queryString = require("query-string");

class TeamListing extends React.Component{
constructor(props){
super(props);
this.state = {
  userToaster: false,
  userToasterMsg: "",
  userToasterColor: "",
};
}



componentDidMount(){
    const {
        setPageTitle,
        toggleNavigation,
        location,
        PageMessage,
        setMessage,
        setSubHeaderTitle,
        setNavigationName,
        toggleBreadcrum

      } = this.props;
      setPageTitle({ title:"Team Members",subHeaderTitle:'Review Management' });
      setSubHeaderTitle({subHeaderTitle:"Review Management" });
      setNavigationName({navigationName:"reviewManagement"})
      toggleNavigation({ toggle: true });
      toggleBreadcrum({ toggle: false });
    const params = queryString.parse(location.search);

    }

    showToaster = (color, msg) => {
      this.setState({
        userToaster: true,
        userToasterMsg: msg,
        userToasterColor: color,
      });
      setTimeout(
        function () {
          this.setState({ userToaster: false });
        }.bind(this),
        3000
      );
    };


render(){
  console.log(this.props.user)
    return(
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">            
      <div className="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20">               
          <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 px-5 d-flex justify-content-end">
              <button
                          className="btn linear-gradient yr-add-new float-right"
                          data-toggle="modal"
                          onClick={() => this.child.addteam()}
                        >
                          Add Team Member
                        </button>
              <AddTeamMember />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 px-5 yr-manageUser-table">
          <Message
                          color={
                            this.state.userToasterColor
                              ? this.state.userToasterColor
                              : "green"
                          }
                          style={{
                            display: this.state.userToaster ? "block" : "none",
                            marginTop: 30,
                          }}
                        >
                          {this.state.userToasterMsg}
                        </Message>
          <DatatableList ref={child => { this.child = child; }} showUserToaster={this.showToaster} userdata =  {this.props.user} />
          </div>
          </div>                
      </div>
      
  </div>

    );
}


}
const mapStateToProps = (state) => {
    return {
      PageMessage: state.page.message,
      user: state.user.company,
    };
  };
export default connect(mapStateToProps,{
    setPageTitle,
    toggleNavigation,
    setMessage,
    setSubHeaderTitle,
    setNavigationName,
    toggleBreadcrum

  })(TeamListing);




