import React from "react";
import { Table } from "semantic-ui-react";
import PropTypes from "prop-types";
console.log("datatable row");
export const DatatableRow = (props) => (

  <Table.Row>
    <Table.Cell width={16} colSpan='1'>
      <div className="memberListSchedule">
        <span>
          <input
            className={props.list.hasSchedule ? 'has' : 'not has'}
            checked={props.list.hasSchedule}
            type="checkbox"
            id={`list_${props.list.id}`}
            onChange={(e) => props.handleSelectMember(e, props.list.id, props.list.scheduleId, props.listindex, props.list)}
          />
        </span>
        <span>
          <label htmlFor={`list_${props.list.id}`}>
            {props.list.name}
          </label>
        </span>
        <a className="" href="#" onClick={(e) => props.handleHourSelect(e, props.list.id, props.list.scheduleId, props.listindex, props.list)}>Adjust Hour</a>
      </div>
    </Table.Cell>
  </Table.Row>
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
