import React, { Component } from 'react'
import DatePicker from "react-datepicker";
import { Message} from "semantic-ui-react";

import CustomErrorField from "../../../../Form/CustomErrorField";



export class SeasonalRules extends Component {

  render() {
    return (
    <React.Fragment>
    <div id={"seasonal_list"}>
    <Message
      color={
          this.props.userToasterColor
          ? this.props.userToasterColor
          : "teal"
      }
      style={{
          display: this.props.userToaster ? "block" : "none",
          marginTop: 30,
      }}
      >
      {this.props.userToasterMsg}
    </Message>
        {
        this.props.active_rule == 7 &&
        Object.keys(
        this.props.seasonalRule
        ).map((val, key) => {
        return (
          <div
            className="calllistrule"
            key={key}
            id={`seasonal_list_${key}`}
          >
            <div className="form-group mb-3">
              <div
                style={{ display: "flex" }}
              >
                <div style={{ width: "50%" }}>
                  <label className="bold">
                    Add a seasonal timeframe.
                    if the date range is
                    between
                  </label>
                </div>
                {this.props.seasonalRule.length > 1 && <div
                  style={{
                    width: "50%",
                    textAlign: "right",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-icon text--blue"
                    title="Delete"
                    onClick={() =>
                      this.props.addSeasonalRule(
                        "delete",
                        key
                      )
                    }
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>}
              </div>
              {/* <DateRangePicker
                    initialSettings={{ startDate: final }}
                    onApply={(event, picker)=>this.applyDateRange(event, picker, key)}
                >
                <div className="showrangepicker">
                <div className="form-control arrow-color mat-style" style={{cursor:"pointer",marginTop:'10px'}}> {this.state.seasonalRule[key].dateRang}</div> 
                </div>
              </DateRangePicker> */}

              <div class="row">
                <div className="col-md-6">
                  <input
                    type="hidden"
                    value={this.props.setRuleDate(
                      this.props.seasonalRule[
                        key
                      ]?.schedule_start_date
                    )}
                  />
                  <input
                    type="hidden"
                    value={
                      this.props.seasonalRule[
                        key
                      ]?.schedule_start_date
                    }
                  />
                  <input
                    type="hidden"
                    value={
                      this.props.seasonalRule[
                        key
                      ]?.isSet
                    }
                  />
                  <DatePicker
                    selected={this.props.setRuleDate(
                      this.props.seasonalRule[
                        key
                      ]?.schedule_start_date
                    )}
                    //selected={new Date('13-12-2021')}
                    //selected={this.state.startD}
                    onChange={(date) =>
                      this.props.setStartD(
                        date,
                        key
                      )
                    }
                    minDate={new Date()}
                    dateFormat="M-d-yyyy"
                    className={
                      Object.keys(
                        this.props.poperror
                      ).length > 0 &&
                        typeof this.props
                          .poperror[
                        "schedule_start_date"
                        ] != "undefined" &&
                        typeof this.props
                          .poperror[
                        "schedule_start_date"
                        ][key] != "undefined"
                        ? "form-control is-invalid focusError custom-select"
                        : "form-control custom-select"
                    }
                    id={"setDate_" + key}
                    placeholderText="Enter Start Date Range"
                  />
                  {Object.keys(
                    this.props.poperror
                  ).length > 0 &&
                    typeof this.props.poperror[
                    "schedule_start_date"
                    ] != "undefined" &&
                    typeof this.props.poperror[
                    "schedule_start_date"
                    ][key] != "undefined" ? (
                    <div
                      style={{
                        color: "#fd2727",
                      }}
                    >
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6">
                  <input
                    type="hidden"
                    value={
                      this.props.seasonalRule[
                        key
                      ]?.schedule_end_date
                    }
                  />
                  <DatePicker
                    selected={this.props.setRuleDate2(
                      this.props.seasonalRule[
                        key
                      ]?.schedule_end_date
                    )}
                    //selected={this.state.startD}
                    onChange={(date) =>
                      this.props.setStartE(
                        date,
                        key
                      )
                    }
                    minDate={
                      this.props.startE
                    }
                    dateFormat="M-d-yyyy"
                    className={
                      Object.keys(
                        this.props.poperror
                      ).length > 0 &&
                        typeof this.props
                          .poperror[
                        "schedule_end_date"
                        ] != "undefined" &&
                        typeof this.props
                          .poperror[
                        "schedule_end_date"
                        ][key] != "undefined"
                        ? "form-control is-invalid focusError custom-select"
                        : "form-control custom-select"
                    }
                    name="setDate"
                    placeholderText="Enter End Date Range"
                  />

                  {Object.keys(
                    this.props.poperror
                  ).length > 0 &&
                    typeof this.props.poperror[
                    "schedule_end_date"
                    ] != "undefined" &&
                    typeof this.props.poperror[
                    "schedule_end_date"
                    ][key] != "undefined" ? (
                    <div
                      style={{
                        color: "#fd2727",
                      }}
                    >
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="form-group mb-3">
              <div className="bold">THEN</div>
              <label>Send to email/sms:</label>
              <select
                className={
                  Object.keys(
                    this.props.poperror
                  ).length > 0 &&
                    typeof this.props.poperror[
                    "send_email_branch"
                    ] != "undefined" &&
                    typeof this.props.poperror[
                    "send_email_branch"
                    ][key] != "undefined"
                    ? "form-control is-invalid focusError custom-select"
                    : "form-control custom-select"
                }
                name="send_email_branch"
                onChange={(e) =>
                  this.props.changeSeasonalRule(
                    key,
                    e
                  )
                }
                value={
                  typeof this.props
                    .seasonalRule[key]
                    .send_email_branch !=
                    "udefined"
                    ? this.props.seasonalRule[
                      key
                    ]?.send_email_branch
                    : ""
                }
              >
                <option value="">
                  Select
                </option>
                {typeof this.props.details
                  .list != "undefined" &&
                  this.props.details.list
                    .length > 0 &&
                  this.props.details.list.map(
                    (obj, index) => {
                      return (
                        <option
                          value={obj.id}
                          key={obj.id}
                        >
                          {obj.title}{" "}
                        </option>
                      );
                    }
                  )}
              </select>
              {Object.keys(
                this.props.poperror
              ).length > 0 &&
                typeof this.props.poperror[
                "send_email_branch"
                ] != "undefined" &&
                typeof this.props.poperror[
                "send_email_branch"
                ][key] != "undefined" && (
                  <CustomErrorField
                    message={
                      this.props.poperror[
                      "send_email_branch"
                      ][key]
                    }
                    className="error invalid-feedback"
                  />
                )}
            </div>
            <div className="form-group mb-3">
              <label>After:</label>
              <div className="row">
                <div className="col-md-6">
                  <select
                    className={
                      Object.keys(
                        this.props.poperror
                      ).length > 0 &&
                        typeof this.props
                          .poperror[
                        "after_time"
                        ] != "undefined" &&
                        typeof this.props
                          .poperror[
                        "after_time"
                        ][key] != "undefined"
                        ? "form-control is-invalid focusError custom-select"
                        : "form-control custom-select"
                    }
                    name="after_time"
                    onChange={(e) =>
                      this.props.changeAfter(
                        "after_time",
                        e,
                        key
                      )
                    }
                    value={
                      typeof this.props
                        .seasonalRule[key]
                        .after_time !=
                        "udefined"
                        ? this.props
                          .seasonalRule[key]
                          .after_time
                        : ""
                    }
                  >
                    <option value="">
                      Select
                    </option>
                    <option value="1">
                      1
                    </option>
                    <option value="2">
                      2
                    </option>
                    <option value="3">
                      3
                    </option>
                  </select>
                  {Object.keys(
                    this.props.poperror
                  ).length > 0 &&
                    typeof this.props
                      .poperror[
                    "after_time"
                    ] != "undefined" &&
                    typeof this.props
                      .poperror["after_time"][
                    key
                    ] != "undefined" && (
                      <CustomErrorField
                        message={
                          this.props.poperror[
                          "after_time"
                          ][key]
                        }
                        className="error invalid-feedback"
                      />
                    )}
                </div>
                <div
                  className="col-md-6"
                  id={"duration_week_" + key}
                >
                  <select
                    className={
                      Object.keys(
                        this.props.poperror
                      ).length > 0 &&
                        typeof this.props
                          .poperror[
                        "duration_week"
                        ] != "undefined" &&
                        typeof this.props
                          .poperror[
                        "duration_week"
                        ][key] != "undefined"
                        ? "form-control is-invalid focusError custom-select"
                        : "form-control custom-select"
                    }
                    name="duration_week"
                    onChange={(e) =>
                      this.props.changeAfter(
                        "time",
                        e,
                        key
                      )
                    }
                    value={
                      typeof this.props
                        .seasonalRule[key]
                        .duration_week !=
                        "udefined"
                        ? this.props
                          .seasonalRule[key]
                          .duration_week
                        : ""
                    }
                  >
                    <option value="">
                      Select
                    </option>
                    <option value="days">
                      days
                    </option>
                    <option value="week">
                      week
                    </option>
                    <option value="month">
                      month
                    </option>
                  </select>
                  {Object.keys(
                    this.props.poperror
                  ).length > 0 &&
                    typeof this.props
                      .poperror[
                    "duration_week"
                    ] != "undefined" &&
                    typeof this.props
                      .poperror[
                    "duration_week"
                    ][key] != "undefined" && (
                      <CustomErrorField
                        message={
                          this.props.poperror[
                          "duration_week"
                          ][key]
                        }
                        className="error invalid-feedback"
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        );
        })}
        {
        this.props.active_rule == 7 && 
        (<div className="calllistrule">
            <div className="form-group">
            <a
                className={"add-gradient mb-3"}
                style={{ color: "#087ab6" }}
                onClick={() =>
                this.props.addSeasonalRule("add","")
                }
            >
                + Add additional timeframe
            </a>
            </div>
        </div>
        )}
    </div>
    </React.Fragment>
    )
  }
}

export default SeasonalRules